import * as React from 'react'

import {
  CreditCardIcon,
  VisaCardIcon,
  MasterCardIcon,
  DiscoverCardIcon,
  AmexCardIcon,
} from '../../icons'

export interface CreditCardLogoProps {
  cardType?: string
  width?: number
  height?: number
}

const defaultProps: CreditCardLogoProps = {
  cardType: undefined,
  width: 26,
  height: 21.28,
}

const CreditCardLogo = ({ cardType, width, height }: CreditCardLogoProps) => {
  switch (cardType) {
    case 'VISA':
      return <VisaCardIcon width={width} height={height} />
    case 'MC':
      return <MasterCardIcon width={width} height={height} />
    case 'DISC':
      return <DiscoverCardIcon width={width} height={height} />
    case 'AMEX':
      return <AmexCardIcon width={width} height={height} />
    case 'DINERS':
      return <DiscoverCardIcon width={width} height={height} />
    case 'JCB':
      return <DiscoverCardIcon width={width} height={height} />
    case 'CUP':
      return <DiscoverCardIcon width={width} height={height} />
    default:
      return <CreditCardIcon width={width} height={height} />
  }
}

CreditCardLogo.defaultProps = defaultProps

export default CreditCardLogo
