import { Box } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getUserInfo } from '../../../store/userInfo/userInfoSlice'
import tokens from '../../../styles/tokens.json'
import { Styles } from '../../../types'
import { useAppSelector } from '../../../utils/hooks'
import SectionHeader from '../../layouts/SectionHeader'

const styles: Styles = {
  mainContainer: (theme) => ({
    display: 'flex',
    minHeight: '19rem',
    maxWidth: '24rem',
    background: theme.palette.common.white,
    border: `1px solid ${tokens.colors.rsmGray.disabled}`,
    padding: '1.5rem',
    fontFamily: 'prelo-book',
    [theme.breakpoints.up('tablet')]: {
      minWidth: '24rem',
    },
  }),
  contentContainer: {
    width: '100%',
    fontWeight: '300',
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    fontStyle: 'normal',
  },
  contactUsTitle: (theme) => ({
    color: theme.palette.text.primary,
  }),
  description: {
    maxWidth: '16rem',
    whiteSpace: 'pre-line',
  },
  boxSpacing: {
    display: 'flex',
  },
  boxSpacing2: {
    marginTop: '1rem',
  },
  phoneLinkStyle: (theme) => ({
    marginLeft: '0.15rem',
    color: theme.palette.secondary.main,
    fontWeight: '800',
  }),
  linkStyle: (theme) => ({
    marginLeft: '0.15rem',
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
  }),
}

const InvoiceSupport = () => {
  const userInfo = useAppSelector(getUserInfo)
  const internalPhone = '883-597-2118'
  const externalPhone = '883-597-2118'
  const displayPhone = userInfo.isEmployee ? internalPhone : externalPhone

  const { t } = useTranslation()

  return (
    <Box sx={styles.mainContainer} data-testid="Inv_Support_ContactUs">
      <Box sx={styles.contentContainer}>
        <SectionHeader
          title={t('CallUs.Heading')}
          testId="Lbl_ContactUs_Header"
        />
        <Box sx={styles.contactUsTitle} data-testid="Txt_ContactUs_Title">
          {t('CallUs.Title')}
        </Box>
        <Box sx={styles.description} data-testid="Txt_ContactUs_Description">
          {t('CallUs.Description')}
        </Box>
        <Box sx={styles.boxSpacing}>
          {userInfo.isEmployee !== undefined ? (
            <>
              <Box sx={{ fontWeight: 800 }}>{t('CallUs.TollFree')}</Box>
              <Box sx={styles.phoneLinkStyle}>
                <a
                  data-testid="phone-number-field"
                  href={`tel:${displayPhone?.replaceAll('.', '')}`}>
                  {displayPhone}
                </a>
              </Box>
            </>
          ) : (
            <span>XXX-XXX-XXXX</span>
          )}
        </Box>
        <Box sx={{ fontWeight: 800 }}>{t('CallUs.USandCANADA')}</Box>
        <Box sx={styles.boxSpacing2}>{t('Invoicing.EmailUs')}</Box>
        <Box sx={styles.linkStyle}>
          <a
            data-testid="us-email-field"
            href="mailto:Accounts_receivable@rsmus.com">
            Accounts_receivable@rsmus.com
          </a>
        </Box>
        <Box sx={styles.linkStyle}>
          <a
            data-testid="canadian-email-field"
            href="mailto:Accounts_receivable@rsmcanada.com">
            Accounts_receivable@rsmcanada.com
          </a>
        </Box>
      </Box>
    </Box>
  )
}

export default InvoiceSupport
