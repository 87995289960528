import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import { differenceInMinutes } from 'date-fns'
import styles from './WelcomeModal.styles'
import NavigationCard from './NavigationCard'
import { useAppDispatch, useAppSelector } from '../../utils/hooks'
import CheckListIcon from '../icons/CheckListIcon/CheckListIcon'
import RsmDialog from '../overlay/RsmDialog'
import {
  getReadyState,
  getUserInfo,
  getCemFeatures,
} from '../../store/userInfo/userInfoSlice'
import { setPopoverContent, setWelcomeState } from '../../store/form/formSlice'
import { useGetUserContactPreferenceDetailsQuery } from '../../store/userService'
import { INTEREST_REMINDER_FREQUENCY } from '../../envVariables'
import { useGetTaxonomyPreferencesQuery } from '../../store/cmsService'
import { isCemFeatureEnabled } from '../../rsmCoreComponents/utils/featureFlagUtils'
import { CEM_FEATURE_WELCOME_OPT_OUT } from '../../utils/constants/constants'

const WelcomeModal = () => {
  const {
    popover: { isWelcomeShowing },
  } = useAppSelector((state) => state)
  const [newVisitor, setNewVisitor] = useState(false)
  const { t } = useTranslation()
  const userInfo = useAppSelector(getUserInfo)
  const cemFeatures = useAppSelector(getCemFeatures)
  const isReady = useAppSelector(getReadyState)
  const dispatch = useAppDispatch()
  const bodyEl = document.querySelector('body') as HTMLBodyElement
  const mainEl = document.querySelector('main') as HTMLDivElement
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('tablet'))
  const navigate = useNavigate()
  const getUserContactPreferenceDetails =
    useGetUserContactPreferenceDetailsQuery()
  const taxonomyPreferences = useGetTaxonomyPreferencesQuery()
  const { pathname } = useLocation()

  useEffect(() => {
    const interestReminderDate =
      localStorage.getItem('interestReminder') ?? undefined
    const dateDifference = interestReminderDate
      ? differenceInMinutes(new Date(), new Date(interestReminderDate))
      : 0

    if (!interestReminderDate) {
      localStorage.setItem('interestReminder', new Date().toISOString())
    }

    const reminderFrequency = INTEREST_REMINDER_FREQUENCY
      ? Number(INTEREST_REMINDER_FREQUENCY)
      : 10080

    if (
      getUserContactPreferenceDetails.data?.data.visited &&
      !!interestReminderDate &&
      dateDifference >= reminderFrequency &&
      taxonomyPreferences.data?.data.length === 0
    ) {
      localStorage.setItem('interestReminder', new Date().toISOString())
    }
  }, [
    taxonomyPreferences.data,
    setPopoverContent,
    getUserContactPreferenceDetails.data,
  ])

  useEffect(() => {
    if (
      !getUserContactPreferenceDetails.isLoading &&
      !getUserContactPreferenceDetails.data?.data.visited
    ) {
      setNewVisitor(true)
    }
  }, [getUserContactPreferenceDetails])

  useEffect(() => {
    if (
      newVisitor &&
      isReady &&
      userInfo &&
      !isCemFeatureEnabled(CEM_FEATURE_WELCOME_OPT_OUT, cemFeatures, 'any')
    ) {
      dispatch(setWelcomeState(true))
      setNewVisitor(false)
    }
  }, [userInfo, isReady, newVisitor, dispatch])

  useEffect(() => {
    const updateScroll = (el: HTMLDivElement | HTMLBodyElement) => {
      if (el) {
        if (isWelcomeShowing) {
          // eslint-disable-next-line no-param-reassign
          el.style.overflow = 'hidden'
        } else {
          // eslint-disable-next-line no-param-reassign
          el.style.overflow = 'initial'
        }
      }
    }

    updateScroll(bodyEl)
    updateScroll(mainEl)
  }, [bodyEl, mainEl])

  const handleClose = () => {
    dispatch(setWelcomeState(false))
  }

  const handleNoThanksClick = () => {
    handleClose()
  }

  const handleXClick = () => {
    dispatch(setPopoverContent('Incomplete'))
    handleClose()
  }

  const onNavigatingTo = (to: string) => {
    handleClose()
    navigate(to)
  }

  return (
    <Box className="Rsm-WelcomeModal" sx={styles.modalContainer}>
      <RsmDialog
        isOpen={isWelcomeShowing}
        closeHandler={handleXClick}
        aria-label={t('UserProfile.ECPProfileTitle')}
        hasRsmLogo={fullScreen}
        dialogCustomProps={{
          maxWidth: 'md',
          sx: styles.modalContent,
          fullScreen,
        }}>
        <Box sx={styles.container}>
          <Box component="h1" sx={styles.heading} id="ecp_top_modal_title">
            {t('WelcomeMessageModal.WelcomeTitle', {
              firstName: userInfo.firstName,
            })}
          </Box>
          <Box component="p" sx={styles.headerDesc}>
            {t('WelcomeMessageModal.WelcomeDesc')}
          </Box>
          <Box component="h2" sx={styles.question}>
            {t('WelcomeMessageModal.WelcomeQuestion')}
          </Box>
          <Box sx={styles.boxWrapper}>
            <NavigationCard
              title={t('WelcomeMessageModal.CustomizeMyExperienceTitle')}
              desc={t('WelcomeMessageModal.CustomizeMyExperienceDesc')}
              linkText={t('WelcomeMessageModal.CustomizeMyExperienceLink')}
              onNavigatingTo={onNavigatingTo}
              navigateOnContentClick
              link={`${pathname}?profileModalOpen=true`}
              icon={<CheckListIcon />}
            />
            <NavigationCard
              title={t('WelcomeMessageModal.NoThanksTitle')}
              desc={t('WelcomeMessageModal.NoThanksDesc')}
              linkText={t('WelcomeMessageModal.NoThanksLink')}
              navigateOnContentClick
              link={pathname}
              onNavigatingTo={handleNoThanksClick}
            />
          </Box>
        </Box>
      </RsmDialog>
    </Box>
  )
}

export default WelcomeModal
