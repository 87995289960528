/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import * as signalR from '@microsoft/signalr'
import { SignalRMethods } from '@rsmus/ecp-core-constants'

import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { OidcIdentityContext, getAccessToken } from '@rsmus/react-auth'
import IdleTimer from 'react-idle-timer'
import { Button } from '@mui/material'
import CountdownTimer from '../CountdownTimer'
import { Modal } from '../overlay'
import TimeIcon from '../icons/TimeIcon'
import { useAppSelector, useUser } from '../../utils/hooks'
import { setBrowserTimeout } from '../../store/configuration/configurationSlice'
import {
  calculateIdleMinutesUntilIdle,
  idleMinutesUntilTimeout,
} from './sessionManager.service'
import {
  setReadyState,
  setUserInfo,
  setCemFeatures,
  setTargetedFeatureFlags,
} from '../../store/userInfo/userInfoSlice'
import {
  BROWSER_TIMEOUT,
  COMMUNICATIONSERVICE_BASE_URL,
} from '../../envVariables'
import analyticsClickTracker from '../../analytics/eventListeners'
import {
  setDeleteNotificationId,
  setNewNotificationCount,
} from '../../store/notification/notificationSlice'
import { RootState } from '../../store'
import api from '../../api'

const idleTimer = null

function adobeAnalyticsSetup(): void {
  window.adobeDataLayer = window.adobeDataLayer || []
  window.addEventListener('click', analyticsClickTracker)
}

const getToken = async () => {
  const result = await getAccessToken()
  return result || ''
}

const SessionManager = () => {
  const [userIsIdle, setUserIsIdle] = useState(false)
  const isEmployee =
    useAppSelector((state) => state.userInfo.UserInfo.isEmployee) ?? false
  const browserTimeoutTotal: number =
    BROWSER_TIMEOUT ??
    useAppSelector((state) => state.configurations.browserTimeout)
  const inactivityWarningMinutes = calculateIdleMinutesUntilIdle(
    browserTimeoutTotal,
    idleMinutesUntilTimeout,
  )
  const { logout } = React.useContext(OidcIdentityContext)

  const idleLogout = () => {
    logout(true)
  }

  const { t } = useTranslation()

  const dispatch = useDispatch()

  const getBrowserTimeoutData = () => {
    api.user.configuration_GetBrowserInactivityTimeout().then((response) => {
      dispatch(setBrowserTimeout(response.data))
    })
  }

  const requestTimeout = 45000
  const getUserInfoData = async () => {
    try {
      const [userResponse, userFeaturesResponse, targetedFeatureFlagsResponse] =
        await Promise.allSettled([
          api.user.userInfo_GetUserInfo(),
          api.user.userInfo_GetUserFeatures(requestTimeout),
          api.user.featureFlag_GetFeatureFlags(true, true),
        ])

      if (userResponse.status === 'rejected') {
        throw new Error('User info request failed')
      }
      if (userFeaturesResponse.status === 'rejected') {
        throw new Error('User features request failed')
      }
      if (targetedFeatureFlagsResponse.status === 'rejected') {
        throw new Error('Targeted feature flags request failed')
      }

      dispatch(setUserInfo(userResponse.value.data))
      dispatch(setCemFeatures(userFeaturesResponse.value.data))
      dispatch(
        setTargetedFeatureFlags(
          targetedFeatureFlagsResponse.value.data
            ?.filter((x) => x.isEnabled)
            .map((x) => x.name),
        ),
      )
      dispatch(setReadyState(true))
    } catch (e) {
      try {
        const uResponse = await api.user.userInfo_GetUserInfo()
        dispatch(setUserInfo(uResponse.data))
        dispatch(setReadyState(true))
      } catch (err) {
        const user = useUser()
        if (user?.profile) {
          const { FirstName, LastName } = user.profile
          dispatch(setUserInfo({ firstName: FirstName, lastName: LastName }))
        } else {
          dispatch(setUserInfo({ firstName: undefined, lastName: undefined }))
        }
        dispatch(setReadyState(false))
      }
    }
  }

  const initSignalR = async () => {
    try {
      const connection = new signalR.HubConnectionBuilder()
        .withUrl(`${COMMUNICATIONSERVICE_BASE_URL}/hub`, {
          accessTokenFactory: getToken,
          withCredentials: true,
        })
        .withAutomaticReconnect()
        .build()

      const notificationsNewCount: SignalRMethods = 'NotificationsNewCount'
      connection.on(notificationsNewCount, (data) => {
        dispatch(setNewNotificationCount(data))
      })
      const notificationsDelete: SignalRMethods = 'NotificationsDelete'
      connection.on(notificationsDelete, (data) => {
        dispatch(setDeleteNotificationId(data))
      })

      const startSignalR = () => {
        try {
          connection.start()
        } catch (err) {
          setTimeout(() => startSignalR(), 5000)
        }
      }
      startSignalR()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  const handleOnIdle = () => {
    // buisness logic, only non-employees are logged out
    if (!isEmployee) {
      setUserIsIdle(true)
    }
  }

  const handleCloseIdleModal = () => {
    setUserIsIdle(false)
  }

  useEffect(() => {
    initSignalR()
    getBrowserTimeoutData()
    adobeAnalyticsSetup()
    getUserInfoData()
    initSignalR()
  }, [])

  return (
    <div id="session-manager-component">
      <IdleTimer
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ref={idleTimer as any}
        timeout={inactivityWarningMinutes * 100 * 60 * 10}
        onIdle={handleOnIdle}
        debounce={250}
      />
      <Modal isOpen={userIsIdle} closeHandler={handleCloseIdleModal}>
        <div
          className="Modal-Body text-center"
          id="session-manager-timeout-warning-modal">
          <div>
            <TimeIcon classNames="block m-auto w-[120px] h-[120px] mb-[8px]" />
          </div>
          <div className="my-[8px] text-[36px] text-[#515356] leading-[40px]">
            <CountdownTimer
              minutesRemaining={idleMinutesUntilTimeout}
              onComplete={idleLogout}
            />
          </div>
          <div className="my-[8px] text-[36px] text-gray leading-[40px]">
            {t('SessionTimeout')}
          </div>
          <div className="my-[8px] text-[16px] text-gray leading-[24px]">
            {t('SessionTimeoutExplination')}
          </div>
          <Button
            onClick={handleCloseIdleModal}
            variant="contained"
            color="primary"
            className="my-[18px]  tablet:w-[inherit]  mobile:w-full text-[18px] ">
            {t('Reset')}
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default SessionManager
