import React from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../../utils/hooks'
import { Styles } from '../../../../types'
import { getUserInfo } from '../../../../store/userInfo/userInfoSlice'

const styles: Styles = {
  paddingContainer: (theme) => ({
    [theme.breakpoints.down('tablet')]: {
      width: '100%',
    },
    [theme.breakpoints.up('mobile')]: {
      width: '50%',
    },
    marginBottom: '2.5rem',
  }),
}

const FormAutoPopEmail = () => {
  const userInfo = useAppSelector(getUserInfo)
  const { t } = useTranslation()

  return (
    <Box style={{ padding: '0 1.4375rem' }} sx={styles.paddingContainer}>
      <Box
        sx={{
          fontFamily: 'Prelo-Black, sans-serif',
          fontSize: '1rem',
          paddingBottom: '0.5rem',
          color: '#515356',
        }}
        data-testid="Lbl_Report_Email">
        {t('BusinessEmail')}
      </Box>
      <Box
        sx={{
          wordBreak: 'break-all',
          fontFamily: 'Prelo-Book, sans-serif',
          fontSize: '1rem',
        }}
        data-testid="Txt_Report_Email">
        {userInfo?.emailAddress}
      </Box>
    </Box>
  )
}

export default FormAutoPopEmail
