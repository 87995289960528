/* eslint-disable react/destructuring-assignment */
import * as React from 'react'
import { SVGProps } from 'react'

const DiscoverCardIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    x={0}
    y={0}
    width={props.width}
    height={props.height}
    // this svg attribute has been deprecated so it's commented out
    // style={{
    //     enableBackground: "new 0 0 95.69 62.85",
    // }}
    viewBox="0 0 95.69 62.85"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}>
    <title>Discover Card</title>
    <style>
      {
        '.st0{display:none}.st2{opacity:.5}.st4{fill:#261f20}.st239{fill:#e45716}'
      }
    </style>
    <path
      d="M3.3 5.3v52.26c0 1.1.9 2 2 2h55.48c15.53 0 28.13-12.59 28.13-28.13C88.91 15.9 76.32 3.3 60.78 3.3H5.3c-1.11 0-2 .89-2 2z"
      style={{
        fill: '#fff',
      }}
    />
    <path
      d="M10.06 25.1H6.51v12.38h3.53c1.87 0 3.23-.44 4.42-1.43 1.41-1.17 2.25-2.93 2.25-4.75-.01-3.65-2.74-6.2-6.65-6.2zm2.82 9.3c-.76.68-1.74.98-3.3.98h-.65V27.2h.65c1.56 0 2.51.28 3.3 1 .84.74 1.34 1.9 1.34 3.08 0 1.19-.51 2.38-1.34 3.12zM17.82 25.1h2.41v12.38h-2.41zM26.13 29.85c-1.45-.54-1.87-.89-1.87-1.56 0-.78.76-1.37 1.8-1.37.72 0 1.32.3 1.95 1l1.26-1.65a5.41 5.41 0 0 0-3.64-1.37c-2.19 0-3.86 1.52-3.86 3.55 0 1.71.78 2.58 3.05 3.39.94.33 1.43.56 1.67.7.48.31.72.76.72 1.28 0 1-.8 1.75-1.88 1.75-1.15 0-2.08-.58-2.64-1.65l-1.56 1.5c1.11 1.63 2.45 2.36 4.29 2.36 2.51 0 4.27-1.67 4.27-4.06 0-1.97-.81-2.86-3.56-3.87zM30.46 31.3c0 3.64 2.86 6.46 6.53 6.46 1.04 0 1.93-.2 3.03-.72V34.2c-.97.97-1.82 1.36-2.92 1.36-2.43 0-4.16-1.76-4.16-4.27 0-2.38 1.78-4.25 4.05-4.25 1.15 0 2.02.41 3.03 1.39v-2.84c-1.06-.54-1.93-.76-2.97-.76-3.66-.01-6.59 2.87-6.59 6.47zM59.16 33.42l-3.3-8.32h-2.64l5.26 12.7h1.3l5.34-12.7h-2.61zM66.22 37.48h6.84v-2.09h-4.43v-3.34h4.27v-2.1h-4.27V27.2h4.43v-2.1h-6.84zM82.63 28.76c0-2.32-1.6-3.65-4.38-3.65h-3.58v12.38h2.41v-4.97h.32l3.34 4.97h2.97l-3.9-5.22c1.81-.37 2.82-1.62 2.82-3.51zm-4.85 2.04h-.71v-3.75h.74c1.5 0 2.32.63 2.32 1.84.01 1.24-.81 1.91-2.35 1.91zM84.55 25.75c0-.22-.15-.34-.41-.34h-.35v1.09h.26v-.42l.31.42h.32l-.36-.45c.14-.03.23-.14.23-.3zm-.46.15h-.04v-.29h.05c.13 0 .2.05.2.14-.01.1-.08.15-.21.15z"
      className="st4"
    />
    <path
      d="M84.19 25.01c-.53 0-.95.42-.95.95s.43.95.95.95.94-.43.94-.95-.43-.95-.94-.95zm-.01 1.73a.77.77 0 0 1-.76-.78c0-.43.34-.78.76-.78s.75.36.75.78c.01.43-.33.78-.75.78z"
      className="st4"
    />
    <linearGradient
      id="SVGID_00000037690906798386411460000017649220278812237489_"
      x1={50.711}
      x2={46.172}
      y1={36.534}
      y2={29.428}
      gradientUnits="userSpaceOnUse">
      <stop
        offset={0}
        style={{
          stopColor: '#ff9200',
        }}
      />
      <stop
        offset={0.207}
        style={{
          stopColor: '#ff8e00',
        }}
      />
      <stop
        offset={0.442}
        style={{
          stopColor: '#ff8000',
        }}
      />
      <stop
        offset={0.62}
        style={{
          stopColor: '#ff7100',
        }}
      />
      <stop
        offset={0.818}
        style={{
          stopColor: '#ff5e00',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#ff5200',
        }}
      />
    </linearGradient>
    <path
      d="M53.91 31.28c0 3.62-2.93 6.56-6.56 6.56s-6.56-2.93-6.56-6.56c0-3.62 2.93-6.56 6.56-6.56s6.56 2.94 6.56 6.56z"
      style={{
        fill: 'url(#SVGID_00000037690906798386411460000017649220278812237489_)',
      }}
    />
    <linearGradient
      id="SVGID_00000039827802965621538930000004410527316233173677_"
      x1={49.994}
      x2={43.36}
      y1={36.438}
      y2={23.472}
      gradientUnits="userSpaceOnUse">
      <stop
        offset={0}
        style={{
          stopColor: '#ff7100',
          stopOpacity: 0,
        }}
      />
      <stop
        offset={0.217}
        style={{
          stopColor: '#ff5900',
          stopOpacity: 0.1635,
        }}
      />
      <stop
        offset={0.505}
        style={{
          stopColor: '#ff4000',
          stopOpacity: 0.381,
        }}
      />
      <stop
        offset={0.769}
        style={{
          stopColor: '#ff3100',
          stopOpacity: 0.5795,
        }}
      />
      <stop
        offset={0.982}
        style={{
          stopColor: '#ff2b00',
          stopOpacity: 0.74,
        }}
      />
    </linearGradient>
    <path
      d="M53.91 31.28c0 3.62-2.93 6.56-6.56 6.56s-6.56-2.93-6.56-6.56c0-3.62 2.93-6.56 6.56-6.56s6.56 2.94 6.56 6.56z"
      style={{
        opacity: 0.65,
        fill: 'url(#SVGID_00000039827802965621538930000004410527316233173677_)',
      }}
    />
    <g className="st2">
      <defs>
        <path
          id="SVGID_00000088102811359170015380000001268295182850528923_"
          d="M53.91 31.28c0 3.62-2.94 6.56-6.56 6.56-3.62 0-6.56-2.93-6.56-6.56 0-3.62 2.93-6.56 6.56-6.56 3.63.01 6.56 2.94 6.56 6.56z"
          className="st2"
        />
      </defs>
      <clipPath id="SVGID_00000122685817159539800550000018195488921616796065_">
        <use
          xlinkHref="#SVGID_00000088102811359170015380000001268295182850528923_"
          style={{
            overflow: 'visible',
          }}
        />
      </clipPath>
      <g
        style={{
          clipPath:
            'url(#SVGID_00000122685817159539800550000018195488921616796065_)',
        }}>
        <path
          d="M47.38 24.63c3.69 0 6.69 2.99 6.69 6.68a6.69 6.69 0 1 1-13.38 0c.01-3.69 3-6.68 6.69-6.68z"
          style={{
            fill: '#020000',
          }}
        />
        <path
          d="M47.39 24.64c3.69 0 6.69 2.98 6.69 6.67s-3 6.7-6.69 6.7c-3.69 0-6.69-2.99-6.69-6.68.01-3.7 3-6.69 6.69-6.69z"
          style={{
            fill: '#030100',
          }}
        />
        <path
          d="M47.4 24.65c3.69 0 6.7 2.98 6.7 6.67s-3 6.7-6.7 6.7c-3.69 0-6.69-2.99-6.68-6.68 0-3.7 2.99-6.69 6.68-6.69z"
          style={{
            fill: '#050100',
          }}
        />
        <path
          d="M47.4 24.66c3.69 0 6.7 2.97 6.7 6.66s-3.01 6.7-6.7 6.7c-3.69 0-6.69-2.99-6.68-6.68.01-3.69 3-6.68 6.68-6.68z"
          style={{
            fill: '#070200',
          }}
        />
        <path
          d="M47.41 24.67c3.69.01 6.7 2.96 6.7 6.65 0 3.69-3.01 6.71-6.7 6.71-3.69 0-6.69-2.99-6.68-6.68.01-3.69 3-6.68 6.68-6.68z"
          style={{
            fill: '#080200',
          }}
        />
        <path
          d="M47.42 24.68c3.69.01 6.7 2.96 6.7 6.65 0 3.69-3.01 6.71-6.7 6.71-3.69 0-6.69-2.99-6.68-6.68 0-3.69 3-6.69 6.68-6.68z"
          style={{
            fill: '#0a0300',
          }}
        />
        <path
          d="M47.43 24.69c3.69.01 6.71 2.95 6.71 6.64 0 3.69-3.02 6.72-6.71 6.72s-6.69-2.99-6.68-6.68c0-3.69 3-6.69 6.68-6.68z"
          style={{
            fill: '#0c0300',
          }}
        />
        <path
          d="M47.43 24.7c3.69.01 6.71 2.95 6.71 6.63 0 3.69-3.02 6.72-6.71 6.72s-6.69-2.99-6.68-6.68c.01-3.68 3.01-6.68 6.68-6.67z"
          style={{
            fill: '#0e0400',
          }}
        />
        <path
          d="M47.44 24.71c3.69.01 6.71 2.94 6.71 6.63s-3.03 6.73-6.71 6.73c-3.69 0-6.69-2.99-6.68-6.68.01-3.69 3.01-6.69 6.68-6.68z"
          style={{
            fill: '#0f0400',
          }}
        />
        <path
          d="M47.45 24.73c3.69.01 6.71 2.93 6.71 6.62s-3.03 6.73-6.71 6.73c-3.69 0-6.69-2.99-6.68-6.68.01-3.69 3.01-6.69 6.68-6.67z"
          style={{
            fill: '#110500',
          }}
        />
        <path
          d="M47.45 24.74c3.69.01 6.72 2.93 6.72 6.61 0 3.69-3.03 6.74-6.72 6.74s-6.69-2.99-6.67-6.67c.01-3.7 3-6.7 6.67-6.68z"
          style={{
            fill: '#130500',
          }}
        />
        <path
          d="M47.46 24.75c3.68.01 6.72 2.92 6.72 6.61 0 3.69-3.04 6.74-6.72 6.74-3.69 0-6.69-2.99-6.67-6.67.01-3.7 3-6.7 6.67-6.68z"
          style={{
            fill: '#140600',
          }}
        />
        <path
          d="M47.47 24.76c3.68.01 6.72 2.91 6.72 6.6 0 3.68-3.04 6.74-6.72 6.74-3.69 0-6.69-2.99-6.67-6.67.01-3.69 3-6.69 6.67-6.67z"
          style={{
            fill: '#160600',
          }}
        />
        <path
          d="M47.48 24.77c3.68.02 6.73 2.91 6.73 6.59 0 3.68-3.04 6.75-6.73 6.75-3.68 0-6.68-2.99-6.67-6.67.01-3.69 3-6.69 6.67-6.67z"
          style={{
            fill: '#180600',
          }}
        />
        <path
          d="M47.48 24.78c3.68.02 6.73 2.9 6.73 6.59 0 3.68-3.05 6.75-6.73 6.75-3.68 0-6.68-2.99-6.67-6.67.02-3.69 3.01-6.69 6.67-6.67z"
          style={{
            fill: '#190700',
          }}
        />
        <path
          d="M47.49 24.79c3.68.02 6.73 2.9 6.73 6.58s-3.05 6.76-6.73 6.76c-3.68 0-6.68-2.99-6.67-6.67.02-3.69 3.01-6.69 6.67-6.67z"
          style={{
            fill: '#1b0700',
          }}
        />
        <path
          d="M47.5 24.8c3.68.02 6.73 2.89 6.73 6.57s-3.05 6.76-6.73 6.76c-3.68 0-6.68-2.98-6.67-6.67.02-3.68 3.01-6.68 6.67-6.66z"
          style={{
            fill: '#1d0800',
          }}
        />
        <path
          d="M47.5 24.81c3.68.02 6.74 2.88 6.74 6.57 0 3.68-3.06 6.77-6.74 6.77s-6.68-2.98-6.67-6.67c.03-3.7 3.02-6.69 6.67-6.67z"
          style={{
            fill: '#1f0800',
          }}
        />
        <path
          d="M47.51 24.82c3.68.02 6.74 2.88 6.74 6.56 0 3.68-3.06 6.77-6.74 6.77s-6.68-2.98-6.66-6.66c.02-3.7 3.01-6.69 6.66-6.67z"
          style={{
            fill: '#200900',
          }}
        />
        <path
          d="M47.52 24.84c3.68.02 6.74 2.87 6.74 6.55 0 3.68-3.06 6.78-6.74 6.78s-6.68-2.98-6.66-6.66c.02-3.71 3.01-6.7 6.66-6.67z"
          style={{
            fill: '#220900',
          }}
        />
        <path
          d="M47.53 24.85c3.68.02 6.74 2.87 6.74 6.54 0 3.68-3.07 6.78-6.74 6.78-3.68 0-6.68-2.98-6.66-6.66.02-3.7 3.01-6.69 6.66-6.66z"
          style={{
            fill: '#240a00',
          }}
        />
        <path
          d="M47.53 24.86c3.68.03 6.75 2.86 6.75 6.54s-3.07 6.78-6.75 6.78-6.68-2.98-6.66-6.66c.02-3.7 3.02-6.69 6.66-6.66z"
          style={{
            fill: '#250a00',
          }}
        />
        <path
          d="M47.54 24.87c3.68.03 6.75 2.85 6.75 6.53s-3.07 6.79-6.75 6.79-6.68-2.98-6.66-6.66c.02-3.7 3.02-6.69 6.66-6.66z"
          style={{
            fill: '#270b00',
          }}
        />
        <path
          d="M47.55 24.88c3.68.03 6.75 2.85 6.75 6.52 0 3.68-3.08 6.79-6.75 6.79-3.68 0-6.68-2.98-6.66-6.66.02-3.69 3.01-6.68 6.66-6.65z"
          style={{
            fill: '#290b00',
          }}
        />
        <path
          d="M47.55 24.89c3.68.03 6.76 2.84 6.76 6.52 0 3.68-3.08 6.8-6.76 6.8-3.68 0-6.68-2.98-6.66-6.66.03-3.7 3.02-6.69 6.66-6.66z"
          style={{
            fill: '#2a0c00',
          }}
        />
        <path
          d="M47.56 24.9c3.68.03 6.76 2.83 6.76 6.51 0 3.68-3.08 6.8-6.76 6.8-3.68 0-6.68-2.98-6.66-6.66.03-3.69 3.02-6.68 6.66-6.65z"
          style={{
            fill: '#2c0c00',
          }}
        />
        <path
          d="M47.57 24.91c3.67.03 6.76 2.83 6.76 6.5 0 3.68-3.09 6.81-6.76 6.81-3.68 0-6.68-2.98-6.66-6.65.03-3.7 3.02-6.69 6.66-6.66z"
          style={{
            fill: '#2e0d00',
          }}
        />
        <path
          d="M47.58 24.92c3.67.03 6.76 2.82 6.76 6.5s-3.09 6.81-6.76 6.81c-3.68 0-6.68-2.98-6.65-6.65.02-3.7 3.01-6.69 6.65-6.66z"
          style={{
            fill: '#300d00',
          }}
        />
        <path
          d="M47.58 24.94c3.67.03 6.77 2.82 6.77 6.49 0 3.67-3.09 6.82-6.77 6.82-3.67 0-6.68-2.98-6.65-6.65.03-3.71 3.02-6.7 6.65-6.66z"
          style={{
            fill: '#310d00',
          }}
        />
        <path
          d="M47.59 24.95c3.67.03 6.77 2.81 6.77 6.48 0 3.67-3.1 6.82-6.77 6.82-3.67 0-6.68-2.98-6.65-6.65.03-3.7 3.02-6.69 6.65-6.65z"
          style={{
            fill: '#330e00',
          }}
        />
        <path
          d="M47.6 24.96c3.67.04 6.77 2.8 6.77 6.48 0 3.67-3.1 6.82-6.77 6.82-3.67 0-6.68-2.98-6.65-6.65.03-3.7 3.02-6.69 6.65-6.65z"
          style={{
            fill: '#350e00',
          }}
        />
        <path
          d="M47.6 24.97c3.67.04 6.78 2.8 6.78 6.47s-3.1 6.83-6.78 6.83c-3.67 0-6.68-2.98-6.65-6.65.04-3.7 3.03-6.69 6.65-6.65z"
          style={{
            fill: '#360f00',
          }}
        />
        <path
          d="M47.61 24.98c3.67.04 6.78 2.79 6.78 6.46s-3.11 6.83-6.78 6.83c-3.67 0-6.68-2.98-6.65-6.65.04-3.69 3.03-6.68 6.65-6.64z"
          style={{
            fill: '#380f00',
          }}
        />
        <path
          d="M47.62 24.99c3.67.04 6.78 2.79 6.78 6.46s-3.11 6.84-6.78 6.84c-3.67 0-6.68-2.98-6.65-6.65.04-3.7 3.03-6.69 6.65-6.65z"
          style={{
            fill: '#3a1000',
          }}
        />
        <path
          d="M47.63 25c3.67.04 6.78 2.78 6.78 6.45 0 3.67-3.11 6.84-6.78 6.84-3.67 0-6.68-2.98-6.65-6.65.04-3.69 3.03-6.68 6.65-6.64z"
          style={{
            fill: '#3b1000',
          }}
        />
        <path
          d="M47.63 25.01c3.67.04 6.79 2.77 6.79 6.44 0 3.67-3.12 6.85-6.79 6.85s-6.68-2.98-6.64-6.64c.04-3.7 3.03-6.69 6.64-6.65z"
          style={{
            fill: '#3d1100',
          }}
        />
        <path
          d="M47.64 25.02c3.67.04 6.79 2.77 6.79 6.44 0 3.67-3.12 6.85-6.79 6.85s-6.68-2.97-6.64-6.64c.03-3.7 3.03-6.69 6.64-6.65z"
          style={{
            fill: '#3f1100',
          }}
        />
        <path
          d="M47.65 25.03c3.67.04 6.79 2.76 6.79 6.43s-3.12 6.85-6.79 6.85-6.68-2.97-6.64-6.64c.03-3.69 3.02-6.68 6.64-6.64z"
          style={{
            fill: '#411200',
          }}
        />
        <path
          d="M47.65 25.05c3.67.04 6.79 2.75 6.79 6.42s-3.13 6.86-6.79 6.86c-3.67 0-6.68-2.97-6.64-6.64.04-3.7 3.03-6.69 6.64-6.64z"
          style={{
            fill: '#421200',
          }}
        />
        <path
          d="M47.66 25.06c3.67.05 6.8 2.75 6.8 6.42s-3.13 6.86-6.8 6.86-6.68-2.97-6.64-6.64c.04-3.7 3.03-6.69 6.64-6.64z"
          style={{
            fill: '#441300',
          }}
        />
        <path
          d="M47.67 25.07c3.67.05 6.8 2.74 6.8 6.41s-3.13 6.87-6.8 6.87-6.68-2.97-6.64-6.64c.04-3.7 3.03-6.69 6.64-6.64z"
          style={{
            fill: '#461300',
          }}
        />
        <path
          d="M47.68 25.08c3.66.05 6.8 2.74 6.8 6.4 0 3.67-3.14 6.87-6.8 6.87-3.67 0-6.68-2.97-6.64-6.64.04-3.69 3.03-6.68 6.64-6.63z"
          style={{
            fill: '#471300',
          }}
        />
        <path
          d="M47.68 25.09c3.66.05 6.81 2.73 6.81 6.4 0 3.67-3.14 6.88-6.81 6.88-3.67 0-6.68-2.97-6.64-6.64.05-3.7 3.04-6.69 6.64-6.64z"
          style={{
            fill: '#491400',
          }}
        />
        <path
          d="M47.69 25.1c3.66.05 6.81 2.72 6.81 6.39 0 3.66-3.14 6.88-6.81 6.88-3.66 0-6.68-2.97-6.64-6.63.05-3.7 3.04-6.69 6.64-6.64z"
          style={{
            fill: '#4b1400',
          }}
        />
        <path
          d="M47.7 25.11c3.66.05 6.81 2.72 6.81 6.38 0 3.66-3.15 6.89-6.81 6.89-3.66 0-6.68-2.97-6.63-6.63.04-3.7 3.03-6.69 6.63-6.64z"
          style={{
            fill: '#4c1500',
          }}
        />
        <path
          d="M47.7 25.12c3.66.05 6.81 2.71 6.81 6.37s-3.15 6.89-6.81 6.89c-3.66 0-6.68-2.97-6.63-6.63.05-3.69 3.04-6.68 6.63-6.63z"
          style={{
            fill: '#4e1500',
          }}
        />
        <path
          d="M47.71 25.13c3.66.05 6.82 2.71 6.82 6.37s-3.15 6.89-6.82 6.89c-3.66 0-6.68-2.97-6.63-6.63.05-3.69 3.04-6.68 6.63-6.63z"
          style={{
            fill: '#501600',
          }}
        />
        <path
          d="M47.72 25.15c3.66.05 6.82 2.7 6.82 6.36s-3.16 6.9-6.82 6.9-6.68-2.97-6.63-6.63c.05-3.7 3.04-6.69 6.63-6.63z"
          style={{
            fill: '#521600',
          }}
        />
        <path
          d="M47.73 25.16c3.66.06 6.82 2.69 6.82 6.35 0 3.66-3.16 6.9-6.82 6.9s-6.68-2.97-6.63-6.63c.05-3.69 3.04-6.68 6.63-6.62z"
          style={{
            fill: '#531700',
          }}
        />
        <path
          d="M47.73 25.17c3.66.06 6.82 2.69 6.82 6.35 0 3.66-3.16 6.91-6.82 6.91s-6.68-2.97-6.63-6.63c.06-3.7 3.05-6.69 6.63-6.63z"
          style={{
            fill: '#551700',
          }}
        />
        <path
          d="M47.74 25.18c3.66.06 6.83 2.68 6.83 6.34 0 3.66-3.17 6.91-6.83 6.91s-6.68-2.97-6.63-6.63c.06-3.69 3.04-6.68 6.63-6.62z"
          style={{
            fill: '#571800',
          }}
        />
        <path
          d="M47.75 25.19c3.66.06 6.83 2.67 6.83 6.33s-3.17 6.92-6.83 6.92-6.68-2.97-6.63-6.63c.06-3.69 3.04-6.68 6.63-6.62z"
          style={{
            fill: '#581800',
          }}
        />
        <path
          d="M47.75 25.2c3.66.06 6.83 2.67 6.83 6.33s-3.17 6.92-6.83 6.92-6.68-2.97-6.62-6.62c.05-3.7 3.04-6.69 6.62-6.63z"
          style={{
            fill: '#5a1900',
          }}
        />
        <path
          d="M47.76 25.21c3.66.06 6.84 2.66 6.84 6.32s-3.18 6.93-6.84 6.93c-3.66 0-6.68-2.97-6.62-6.62.05-3.7 3.04-6.69 6.62-6.63z"
          style={{
            fill: '#5c1900',
          }}
        />
        <path
          d="M47.77 25.22c3.66.06 6.84 2.66 6.84 6.31 0 3.66-3.18 6.93-6.84 6.93-3.66 0-6.68-2.97-6.62-6.62.05-3.7 3.04-6.68 6.62-6.62z"
          style={{
            fill: '#5e1a00',
          }}
        />
        <path
          d="M47.78 25.23c3.66.06 6.84 2.65 6.84 6.31 0 3.66-3.18 6.93-6.84 6.93-3.66 0-6.68-2.96-6.62-6.62.05-3.7 3.04-6.68 6.62-6.62z"
          style={{
            fill: '#5f1a00',
          }}
        />
        <path
          d="M47.78 25.24c3.65.06 6.84 2.64 6.84 6.3 0 3.66-3.19 6.94-6.84 6.94-3.66 0-6.68-2.96-6.62-6.62.06-3.7 3.05-6.68 6.62-6.62z"
          style={{
            fill: '#611a00',
          }}
        />
        <path
          d="M47.79 25.26c3.65.07 6.85 2.64 6.85 6.29 0 3.66-3.19 6.94-6.85 6.94-3.66 0-6.68-2.96-6.62-6.62.06-3.7 3.05-6.68 6.62-6.61z"
          style={{
            fill: '#631b00',
          }}
        />
        <path
          d="M47.8 25.27c3.65.07 6.85 2.63 6.85 6.29 0 3.65-3.2 6.95-6.85 6.95-3.65 0-6.68-2.96-6.62-6.62.06-3.71 3.05-6.69 6.62-6.62z"
          style={{
            fill: '#641b00',
          }}
        />
        <path
          d="M47.8 25.28c3.65.07 6.85 2.63 6.85 6.28 0 3.65-3.2 6.95-6.85 6.95-3.65 0-6.68-2.96-6.62-6.62.07-3.7 3.06-6.68 6.62-6.61z"
          style={{
            fill: '#661c00',
          }}
        />
        <path
          d="M47.81 25.29c3.65.07 6.85 2.62 6.85 6.27 0 3.65-3.2 6.96-6.85 6.96-3.65 0-6.68-2.96-6.61-6.61.06-3.71 3.05-6.69 6.61-6.62z"
          style={{
            fill: '#681c00',
          }}
        />
        <path
          d="M47.82 25.3c3.65.07 6.86 2.61 6.86 6.27 0 3.65-3.21 6.96-6.86 6.96-3.65 0-6.68-2.96-6.61-6.61.06-3.71 3.05-6.69 6.61-6.62z"
          style={{
            fill: '#691d00',
          }}
        />
        <path
          d="M47.83 25.31c3.65.07 6.86 2.61 6.86 6.26s-3.21 6.97-6.86 6.97c-3.65 0-6.68-2.96-6.61-6.61.06-3.71 3.05-6.69 6.61-6.62z"
          style={{
            fill: '#6b1d00',
          }}
        />
        <path
          d="M47.83 25.32c3.65.07 6.86 2.6 6.86 6.25s-3.21 6.97-6.86 6.97c-3.65 0-6.68-2.96-6.61-6.61.07-3.7 3.06-6.68 6.61-6.61z"
          style={{
            fill: '#6d1e00',
          }}
        />
        <path
          d="M47.84 25.33c3.65.07 6.87 2.59 6.87 6.25 0 3.65-3.22 6.97-6.87 6.97s-6.68-2.96-6.61-6.61c.07-3.7 3.05-6.68 6.61-6.61z"
          style={{
            fill: '#6f1e00',
          }}
        />
        <path
          d="M47.85 25.34c3.65.08 6.87 2.59 6.87 6.24 0 3.65-3.22 6.98-6.87 6.98s-6.68-2.96-6.61-6.61c.07-3.7 3.05-6.68 6.61-6.61z"
          style={{
            fill: '#701f00',
          }}
        />
        <path
          d="M47.85 25.35c3.65.08 6.87 2.58 6.87 6.23 0 3.65-3.22 6.98-6.87 6.98s-6.67-2.96-6.61-6.61c.08-3.69 3.06-6.67 6.61-6.6z"
          style={{
            fill: '#721f00',
          }}
        />
        <path
          d="M47.86 25.37c3.65.08 6.87 2.58 6.87 6.23 0 3.65-3.23 6.99-6.87 6.99-3.65 0-6.67-2.96-6.61-6.61.07-3.71 3.06-6.69 6.61-6.61z"
          style={{
            fill: '#742000',
          }}
        />
        <path
          d="M47.87 25.38c3.65.08 6.88 2.57 6.88 6.22s-3.23 6.99-6.88 6.99-6.67-2.96-6.61-6.61c.07-3.7 3.06-6.68 6.61-6.6z"
          style={{
            fill: '#752000',
          }}
        />
        <path
          d="M47.88 25.39c3.65.08 6.88 2.56 6.88 6.21s-3.23 7-6.88 7-6.67-2.96-6.6-6.6c.06-3.71 3.05-6.69 6.6-6.61z"
          style={{
            fill: '#772000',
          }}
        />
        <path
          d="M47.88 25.4c3.65.08 6.88 2.56 6.88 6.2 0 3.65-3.24 7-6.88 7-3.65 0-6.67-2.96-6.6-6.6.07-3.7 3.06-6.68 6.6-6.6z"
          style={{
            fill: '#792100',
          }}
        />
        <path
          d="M47.89 25.41c3.64.08 6.89 2.55 6.89 6.2 0 3.65-3.24 7-6.89 7-3.65 0-6.67-2.96-6.6-6.6.07-3.7 3.06-6.68 6.6-6.6z"
          style={{
            fill: '#7a2100',
          }}
        />
        <path
          d="M47.9 25.42c3.64.08 6.89 2.55 6.89 6.19 0 3.65-3.24 7.01-6.89 7.01-3.65 0-6.67-2.96-6.6-6.6.07-3.7 3.06-6.68 6.6-6.6z"
          style={{
            fill: '#7c2200',
          }}
        />
        <path
          d="M47.9 25.43c3.64.08 6.89 2.54 6.89 6.18 0 3.64-3.25 7.01-6.89 7.01-3.64 0-6.67-2.96-6.6-6.6.08-3.69 3.07-6.67 6.6-6.59z"
          style={{
            fill: '#7e2200',
          }}
        />
        <path
          d="M47.91 25.44c3.64.09 6.89 2.53 6.89 6.18 0 3.64-3.25 7.02-6.89 7.02-3.64 0-6.67-2.95-6.6-6.6.08-3.7 3.07-6.68 6.6-6.6z"
          style={{
            fill: '#802300',
          }}
        />
        <path
          d="M47.92 25.45c3.64.09 6.9 2.53 6.9 6.17s-3.25 7.02-6.9 7.02c-3.64 0-6.67-2.95-6.6-6.6.08-3.69 3.07-6.67 6.6-6.59z"
          style={{
            fill: '#812300',
          }}
        />
        <path
          d="M47.93 25.47c3.64.09 6.9 2.52 6.9 6.16s-3.26 7.03-6.9 7.03c-3.64 0-6.67-2.95-6.6-6.6.08-3.7 3.07-6.68 6.6-6.59z"
          style={{
            fill: '#832400',
          }}
        />
        <path
          d="M47.93 25.48c3.64.09 6.9 2.52 6.9 6.16s-3.26 7.03-6.9 7.03c-3.64 0-6.67-2.95-6.59-6.59.08-3.71 3.06-6.69 6.59-6.6z"
          style={{
            fill: '#852400',
          }}
        />
        <path
          d="M47.94 25.49c3.64.09 6.9 2.51 6.9 6.15 0 3.64-3.26 7.04-6.9 7.04-3.64 0-6.67-2.95-6.59-6.59.08-3.71 3.06-6.69 6.59-6.6z"
          style={{
            fill: '#862500',
          }}
        />
        <path
          d="M47.95 25.5c3.64.09 6.91 2.5 6.91 6.14 0 3.64-3.27 7.04-6.91 7.04s-6.67-2.95-6.59-6.59c.08-3.7 3.06-6.68 6.59-6.59z"
          style={{
            fill: '#882500',
          }}
        />
        <path
          d="M47.95 25.51c3.64.09 6.91 2.5 6.91 6.14 0 3.64-3.27 7.04-6.91 7.04s-6.67-2.95-6.59-6.59c.09-3.7 3.07-6.68 6.59-6.59z"
          style={{
            fill: '#8a2600',
          }}
        />
        <path
          d="M47.96 25.52c3.64.09 6.91 2.49 6.91 6.13 0 3.64-3.27 7.05-6.91 7.05s-6.67-2.95-6.59-6.59c.09-3.7 3.07-6.68 6.59-6.59z"
          style={{
            fill: '#8b2600',
          }}
        />
        <path
          d="M47.97 25.53c3.64.09 6.92 2.48 6.92 6.12s-3.28 7.05-6.92 7.05-6.67-2.95-6.59-6.59c.09-3.69 3.07-6.67 6.59-6.58z"
          style={{
            fill: '#8d2600',
          }}
        />
        <path
          d="M47.98 25.54c3.64.1 6.92 2.48 6.92 6.12s-3.28 7.06-6.92 7.06-6.67-2.95-6.59-6.59c.08-3.7 3.07-6.68 6.59-6.59z"
          style={{
            fill: '#8f2700',
          }}
        />
        <path
          d="M47.98 25.55c3.64.1 6.92 2.47 6.92 6.11s-3.28 7.06-6.92 7.06-6.67-2.95-6.59-6.59c.09-3.69 3.08-6.67 6.59-6.58z"
          style={{
            fill: '#912700',
          }}
        />
        <path
          d="M47.99 25.56c3.63.1 6.92 2.47 6.92 6.1 0 3.64-3.29 7.07-6.92 7.07-3.64 0-6.67-2.95-6.58-6.58.08-3.7 3.07-6.68 6.58-6.59z"
          style={{
            fill: '#922800',
          }}
        />
        <path
          d="M48 25.58c3.63.1 6.93 2.46 6.93 6.1 0 3.64-3.29 7.07-6.93 7.07-3.64 0-6.67-2.95-6.58-6.58.08-3.71 3.07-6.69 6.58-6.59z"
          style={{
            fill: '#942800',
          }}
        />
        <path
          d="M48 25.59c3.63.1 6.93 2.45 6.93 6.09 0 3.64-3.29 7.08-6.93 7.08-3.64 0-6.67-2.95-6.58-6.58.09-3.71 3.08-6.69 6.58-6.59z"
          style={{
            fill: '#962900',
          }}
        />
        <path
          d="M48.01 25.6c3.63.1 6.93 2.45 6.93 6.08s-3.3 7.08-6.93 7.08-6.67-2.95-6.58-6.58c.09-3.7 3.08-6.68 6.58-6.58z"
          style={{
            fill: '#972900',
          }}
        />
        <path
          d="M48.02 25.61c3.63.1 6.93 2.44 6.93 6.08 0 3.63-3.3 7.08-6.93 7.08s-6.67-2.95-6.58-6.58c.09-3.7 3.08-6.68 6.58-6.58z"
          style={{
            fill: '#992a00',
          }}
        />
        <path
          d="M48.03 25.62c3.63.1 6.94 2.44 6.94 6.07 0 3.63-3.3 7.09-6.94 7.09-3.63 0-6.67-2.95-6.58-6.58.09-3.7 3.08-6.68 6.58-6.58z"
          style={{
            fill: '#9b2a00',
          }}
        />
        <path
          d="M48.03 25.63c3.63.1 6.94 2.43 6.94 6.06 0 3.63-3.31 7.09-6.94 7.09-3.63 0-6.67-2.95-6.58-6.58.1-3.7 3.08-6.67 6.58-6.57z"
          style={{
            fill: '#9c2b00',
          }}
        />
        <path
          d="M48.04 25.64c3.63.11 6.94 2.42 6.94 6.06 0 3.63-3.31 7.1-6.94 7.1-3.63 0-6.67-2.95-6.58-6.58.1-3.71 3.08-6.68 6.58-6.58z"
          style={{
            fill: '#9e2b00',
          }}
        />
        <path
          d="M48.05 25.65c3.63.11 6.95 2.42 6.95 6.05s-3.31 7.1-6.95 7.1c-3.63 0-6.67-2.95-6.58-6.58.1-3.7 3.08-6.67 6.58-6.57z"
          style={{
            fill: '#a02c00',
          }}
        />
        <path
          d="M48.05 25.66c3.63.11 6.95 2.41 6.95 6.04s-3.32 7.11-6.95 7.11c-3.63 0-6.67-2.94-6.57-6.57.1-3.71 3.08-6.68 6.57-6.58z"
          style={{
            fill: '#a22c00',
          }}
        />
        <path
          d="M48.06 25.67c3.63.11 6.95 2.4 6.95 6.03 0 3.63-3.32 7.11-6.95 7.11-3.63 0-6.67-2.94-6.57-6.57.1-3.7 3.08-6.67 6.57-6.57z"
          style={{
            fill: '#a32d00',
          }}
        />
        <path
          d="M48.07 25.69c3.63.11 6.95 2.4 6.95 6.03 0 3.63-3.32 7.12-6.95 7.12-3.63 0-6.67-2.94-6.57-6.57.1-3.72 3.08-6.69 6.57-6.58z"
          style={{
            fill: '#a52d00',
          }}
        />
        <path
          d="M48.08 25.7c3.63.11 6.96 2.39 6.96 6.02 0 3.63-3.33 7.12-6.96 7.12s-6.67-2.94-6.57-6.57c.1-3.71 3.08-6.68 6.57-6.57z"
          style={{
            fill: '#a72d00',
          }}
        />
        <path
          d="M48.08 25.71c3.63.11 6.96 2.39 6.96 6.01 0 3.63-3.33 7.12-6.96 7.12s-6.67-2.94-6.57-6.57c.1-3.7 3.09-6.67 6.57-6.56z"
          style={{
            fill: '#a82e00',
          }}
        />
        <path
          d="M48.09 25.72c3.63.11 6.96 2.38 6.96 6.01s-3.33 7.13-6.96 7.13-6.67-2.94-6.57-6.57c.1-3.71 3.09-6.68 6.57-6.57z"
          style={{
            fill: '#aa2e00',
          }}
        />
        <path
          d="M48.1 25.73c3.62.11 6.96 2.37 6.96 6s-3.34 7.13-6.96 7.13c-3.63 0-6.67-2.94-6.57-6.57.1-3.7 3.09-6.67 6.57-6.56z"
          style={{
            fill: '#ac2f00',
          }}
        />
        <path
          d="M48.1 25.74c3.62.12 6.97 2.37 6.97 5.99 0 3.63-3.34 7.14-6.97 7.14s-6.67-2.94-6.57-6.57c.11-3.7 3.1-6.67 6.57-6.56z"
          style={{
            fill: '#ad2f00',
          }}
        />
        <path
          d="M48.11 25.75c3.62.12 6.97 2.36 6.97 5.99 0 3.63-3.34 7.14-6.97 7.14s-6.67-2.94-6.56-6.56c.1-3.71 3.09-6.68 6.56-6.57z"
          style={{
            fill: '#af3000',
          }}
        />
        <path
          d="M48.12 25.76c3.62.12 6.97 2.36 6.97 5.98 0 3.62-3.35 7.15-6.97 7.15-3.62 0-6.67-2.94-6.56-6.56.1-3.71 3.09-6.68 6.56-6.57z"
          style={{
            fill: '#b13000',
          }}
        />
        <path
          d="M48.13 25.77c3.62.12 6.98 2.35 6.98 5.97 0 3.62-3.35 7.15-6.98 7.15-3.62 0-6.67-2.94-6.56-6.56.1-3.7 3.09-6.67 6.56-6.56z"
          style={{
            fill: '#b33100',
          }}
        />
        <path
          d="M48.13 25.79c3.62.12 6.98 2.34 6.98 5.97 0 3.62-3.35 7.15-6.98 7.15-3.62 0-6.67-2.94-6.56-6.56.11-3.71 3.09-6.68 6.56-6.56z"
          style={{
            fill: '#b43100',
          }}
        />
        <path
          d="M48.14 25.8c3.62.12 6.98 2.34 6.98 5.96s-3.36 7.16-6.98 7.16c-3.62 0-6.67-2.94-6.56-6.56.11-3.71 3.09-6.68 6.56-6.56z"
          style={{
            fill: '#b63200',
          }}
        />
        <path
          d="M48.15 25.81c3.62.12 6.98 2.33 6.98 5.95s-3.36 7.16-6.98 7.16c-3.62 0-6.67-2.94-6.56-6.56.11-3.7 3.09-6.67 6.56-6.55z"
          style={{
            fill: '#b83200',
          }}
        />
        <path
          d="M48.15 25.82c3.62.12 6.99 2.32 6.99 5.95 0 3.62-3.37 7.17-6.99 7.17s-6.67-2.94-6.56-6.56c.12-3.71 3.1-6.68 6.56-6.56z"
          style={{
            fill: '#b93300',
          }}
        />
        <path
          d="M55.15 31.77c0 3.62-3.37 7.17-6.99 7.17S41.49 36 41.6 32.38c.11-3.71 3.1-6.68 6.56-6.56 3.62.13 6.99 2.33 6.99 5.95z"
          style={{
            fill: '#b30',
          }}
        />
        <path
          d="M48.17 25.84c3.62.12 6.99 2.32 6.99 5.95 0 3.62-3.36 7.17-6.99 7.17-3.62 0-6.67-2.94-6.56-6.56.11-3.71 3.1-6.68 6.56-6.56z"
          style={{
            opacity: 0.9909,
            fill: '#bc3502',
          }}
        />
        <path
          d="M48.17 25.85c3.62.12 6.98 2.33 6.98 5.95s-3.36 7.16-6.98 7.16c-3.62 0-6.66-2.94-6.56-6.56.12-3.7 3.1-6.67 6.56-6.55z"
          style={{
            opacity: 0.9818,
            fill: '#bc3705',
          }}
        />
        <path
          d="M48.18 25.85c3.62.12 6.98 2.34 6.98 5.96s-3.36 7.16-6.98 7.16c-3.62 0-6.66-2.94-6.56-6.56.11-3.7 3.09-6.67 6.56-6.56z"
          style={{
            opacity: 0.9727,
            fill: '#bd3907',
          }}
        />
        <path
          d="M48.18 25.86c3.62.12 6.97 2.34 6.97 5.96s-3.35 7.15-6.97 7.15c-3.62 0-6.66-2.94-6.56-6.56.12-3.69 3.1-6.66 6.56-6.55z"
          style={{
            opacity: 0.9636,
            fill: '#bd3a09',
          }}
        />
        <path
          d="M48.19 25.87c3.62.12 6.97 2.35 6.97 5.97 0 3.62-3.35 7.14-6.97 7.14-3.62 0-6.66-2.94-6.56-6.56.11-3.69 3.09-6.66 6.56-6.55z"
          style={{
            opacity: 0.9545,
            fill: '#be3c0c',
          }}
        />
        <path
          d="M48.19 25.88c3.62.12 6.97 2.35 6.97 5.97 0 3.62-3.34 7.14-6.97 7.14-3.62 0-6.66-2.94-6.56-6.56.12-3.7 3.1-6.66 6.56-6.55z"
          style={{
            opacity: 0.9455,
            fill: '#bf3e0e',
          }}
        />
        <path
          d="M48.2 25.89c3.62.12 6.96 2.36 6.96 5.98 0 3.62-3.34 7.13-6.96 7.13s-6.66-2.94-6.56-6.56c.11-3.7 3.09-6.66 6.56-6.55z"
          style={{
            opacity: 0.9364,
            fill: '#bf4010',
          }}
        />
        <path
          d="M48.21 25.9c3.62.12 6.96 2.36 6.96 5.98 0 3.62-3.34 7.13-6.96 7.13s-6.66-2.94-6.56-6.56c.1-3.7 3.08-6.66 6.56-6.55z"
          style={{
            opacity: 0.9273,
            fill: '#c04213',
          }}
        />
        <path
          d="M48.21 25.9c3.62.11 6.95 2.37 6.95 5.99s-3.33 7.12-6.95 7.12-6.66-2.94-6.56-6.56c.11-3.69 3.09-6.66 6.56-6.55z"
          style={{
            opacity: 0.9182,
            fill: '#c14415',
          }}
        />
        <path
          d="M48.22 25.91c3.62.11 6.95 2.37 6.95 6 0 3.62-3.33 7.12-6.95 7.12s-6.66-2.94-6.56-6.56c.1-3.7 3.08-6.67 6.56-6.56z"
          style={{
            opacity: 0.9091,
            fill: '#c14617',
          }}
        />
        <path
          d="M48.22 25.92c3.62.11 6.95 2.38 6.95 6s-3.33 7.11-6.95 7.11-6.66-2.94-6.56-6.56c.11-3.69 3.09-6.66 6.56-6.55z"
          style={{
            opacity: 0.9,
            fill: '#c24719',
          }}
        />
        <path
          d="M48.23 25.93c3.62.11 6.94 2.39 6.94 6.01 0 3.62-3.32 7.11-6.94 7.11-3.62 0-6.65-2.94-6.56-6.56.1-3.7 3.08-6.67 6.56-6.56z"
          style={{
            opacity: 0.8909,
            fill: '#c2491c',
          }}
        />
        <path
          d="M48.23 25.94c3.62.11 6.94 2.39 6.94 6.01 0 3.62-3.32 7.1-6.94 7.1-3.62 0-6.65-2.94-6.56-6.56.11-3.69 3.09-6.66 6.56-6.55z"
          style={{
            opacity: 0.8818,
            fill: '#c34b1e',
          }}
        />
        <path
          d="M48.24 25.95c3.62.11 6.93 2.4 6.93 6.02 0 3.62-3.31 7.09-6.93 7.09-3.62 0-6.65-2.94-6.56-6.56.1-3.69 3.08-6.66 6.56-6.55z"
          style={{
            opacity: 0.8727,
            fill: '#c44d20',
          }}
        />
        <path
          d="M48.25 25.96c3.62.11 6.93 2.4 6.93 6.02 0 3.62-3.31 7.09-6.93 7.09-3.62 0-6.65-2.94-6.56-6.56.1-3.69 3.07-6.66 6.56-6.55z"
          style={{
            opacity: 0.8636,
            fill: '#c44f23',
          }}
        />
        <path
          d="M48.25 25.96c3.62.11 6.93 2.41 6.93 6.03s-3.31 7.08-6.93 7.08c-3.62 0-6.65-2.94-6.56-6.56.1-3.68 3.08-6.65 6.56-6.55z"
          style={{
            opacity: 0.8545,
            fill: '#c55125',
          }}
        />
        <path
          d="M48.26 25.97c3.62.11 6.92 2.41 6.92 6.03s-3.3 7.08-6.92 7.08-6.65-2.94-6.56-6.56c.1-3.68 3.07-6.65 6.56-6.55z"
          style={{
            opacity: 0.8455,
            fill: '#c65327',
          }}
        />
        <path
          d="M48.26 25.98c3.62.1 6.92 2.42 6.92 6.04s-3.3 7.07-6.92 7.07-6.65-2.94-6.56-6.56c.1-3.69 3.08-6.65 6.56-6.55z"
          style={{
            opacity: 0.8364,
            fill: '#c6542a',
          }}
        />
        <path
          d="M48.27 25.99c3.62.1 6.91 2.42 6.91 6.05 0 3.62-3.29 7.07-6.91 7.07s-6.65-2.94-6.56-6.56c.09-3.7 3.07-6.66 6.56-6.56z"
          style={{
            opacity: 0.8273,
            fill: '#c7562c',
          }}
        />
        <path
          d="M48.27 26c3.62.1 6.91 2.43 6.91 6.05 0 3.62-3.29 7.06-6.91 7.06s-6.65-2.94-6.56-6.56c.1-3.69 3.07-6.65 6.56-6.55z"
          style={{
            opacity: 0.8182,
            fill: '#c7582e',
          }}
        />
        <path
          d="M48.28 26.01c3.62.1 6.91 2.44 6.91 6.06 0 3.62-3.29 7.05-6.91 7.05s-6.65-2.94-6.56-6.56c.09-3.69 3.07-6.65 6.56-6.55z"
          style={{
            opacity: 0.8091,
            fill: '#c85a31',
          }}
        />
        <path
          d="M48.28 26.01c3.62.1 6.9 2.44 6.9 6.06 0 3.62-3.28 7.05-6.9 7.05-3.62 0-6.64-2.94-6.56-6.56.1-3.68 3.07-6.64 6.56-6.55z"
          style={{
            opacity: 0.8,
            fill: '#c95c33',
          }}
        />
        <path
          d="M48.29 26.02c3.62.1 6.9 2.45 6.9 6.07s-3.28 7.04-6.9 7.04c-3.62 0-6.64-2.94-6.56-6.56.09-3.68 3.07-6.64 6.56-6.55z"
          style={{
            opacity: 0.7909,
            fill: '#c95e35',
          }}
        />
        <path
          d="M48.3 26.03c3.62.1 6.89 2.45 6.89 6.07s-3.27 7.04-6.89 7.04c-3.62 0-6.64-2.94-6.56-6.56.09-3.68 3.06-6.64 6.56-6.55z"
          style={{
            opacity: 0.7818,
            fill: '#ca6038',
          }}
        />
        <path
          d="M48.3 26.04c3.62.1 6.89 2.46 6.89 6.08s-3.27 7.03-6.89 7.03c-3.62 0-6.64-2.94-6.56-6.56.09-3.68 3.06-6.64 6.56-6.55z"
          style={{
            opacity: 0.7727,
            fill: '#ca613a',
          }}
        />
        <path
          d="M48.31 26.05c3.62.1 6.89 2.46 6.89 6.09 0 3.62-3.27 7.03-6.89 7.03-3.62 0-6.64-2.94-6.56-6.56.09-3.69 3.06-6.65 6.56-6.56z"
          style={{
            opacity: 0.7636,
            fill: '#cb633c',
          }}
        />
        <path
          d="M48.31 26.06c3.62.09 6.88 2.47 6.88 6.09 0 3.62-3.26 7.02-6.88 7.02s-6.64-2.94-6.56-6.56c.09-3.68 3.06-6.64 6.56-6.55z"
          style={{
            opacity: 0.7545,
            fill: '#cc653f',
          }}
        />
        <path
          d="M48.32 26.06c3.62.09 6.88 2.48 6.88 6.1 0 3.62-3.26 7.02-6.88 7.02s-6.64-2.94-6.56-6.56c.09-3.68 3.06-6.65 6.56-6.56z"
          style={{
            opacity: 0.7455,
            fill: '#cc6741',
          }}
        />
        <path
          d="M48.32 26.07c3.62.09 6.88 2.48 6.88 6.1 0 3.62-3.25 7.01-6.88 7.01-3.62 0-6.64-2.94-6.56-6.56.09-3.67 3.06-6.64 6.56-6.55z"
          style={{
            opacity: 0.7364,
            fill: '#cd6943',
          }}
        />
        <path
          d="M48.33 26.08c3.62.09 6.87 2.49 6.87 6.11s-3.25 7-6.87 7-6.64-2.94-6.56-6.56c.09-3.68 3.06-6.64 6.56-6.55z"
          style={{
            opacity: 0.7273,
            fill: '#ce6b46',
          }}
        />
        <path
          d="M48.33 26.09c3.62.09 6.87 2.49 6.87 6.11s-3.25 7-6.87 7-6.64-2.94-6.56-6.56c.09-3.68 3.06-6.64 6.56-6.55z"
          style={{
            opacity: 0.7182,
            fill: '#ce6c48',
          }}
        />
        <path
          d="M48.34 26.1c3.62.09 6.86 2.5 6.86 6.12s-3.24 6.99-6.86 6.99-6.63-2.94-6.56-6.56c.08-3.68 3.05-6.64 6.56-6.55z"
          style={{
            opacity: 0.7091,
            fill: '#cf6e4a',
          }}
        />
        <path
          d="M48.35 26.11c3.62.09 6.86 2.5 6.86 6.12s-3.24 6.99-6.86 6.99-6.63-2.94-6.56-6.56c.08-3.68 3.05-6.64 6.56-6.55z"
          style={{
            opacity: 0.7,
            fill: '#cf704c',
          }}
        />
        <path
          d="M48.35 26.11c3.62.09 6.86 2.51 6.86 6.13s-3.23 6.98-6.86 6.98c-3.62 0-6.63-2.94-6.56-6.56.08-3.67 3.05-6.63 6.56-6.55z"
          style={{
            opacity: 0.6909,
            fill: '#d0724f',
          }}
        />
        <path
          d="M48.36 26.12c3.62.09 6.85 2.51 6.85 6.14 0 3.62-3.23 6.98-6.85 6.98-3.62 0-6.63-2.94-6.56-6.56.08-3.68 3.05-6.64 6.56-6.56z"
          style={{
            opacity: 0.6818,
            fill: '#d17451',
          }}
        />
        <path
          d="M48.36 26.13c3.62.08 6.85 2.52 6.85 6.14 0 3.62-3.23 6.97-6.85 6.97-3.62 0-6.63-2.94-6.56-6.56.08-3.67 3.05-6.63 6.56-6.55z"
          style={{
            opacity: 0.6727,
            fill: '#d17653',
          }}
        />
        <path
          d="M48.37 26.14c3.62.08 6.84 2.53 6.84 6.15 0 3.62-3.22 6.97-6.84 6.97-3.62 0-6.63-2.94-6.56-6.56.08-3.68 3.04-6.64 6.56-6.56z"
          style={{
            opacity: 0.6636,
            fill: '#d27856',
          }}
        />
        <path
          d="M48.37 26.15c3.62.08 6.84 2.53 6.84 6.15 0 3.62-3.22 6.96-6.84 6.96-3.62 0-6.63-2.94-6.56-6.56.08-3.67 3.05-6.63 6.56-6.55z"
          style={{
            opacity: 0.6545,
            fill: '#d27958',
          }}
        />
        <path
          d="M48.38 26.16c3.62.08 6.84 2.54 6.84 6.16s-3.21 6.95-6.84 6.95c-3.62 0-6.63-2.94-6.56-6.56.08-3.67 3.04-6.63 6.56-6.55z"
          style={{
            opacity: 0.6455,
            fill: '#d37b5a',
          }}
        />
        <path
          d="M48.38 26.16c3.62.08 6.83 2.54 6.83 6.16s-3.21 6.95-6.83 6.95-6.63-2.94-6.56-6.56c.08-3.66 3.05-6.62 6.56-6.55z"
          style={{
            opacity: 0.6364,
            fill: '#d47d5d',
          }}
        />
        <path
          d="M48.39 26.17c3.62.08 6.83 2.55 6.83 6.17s-3.21 6.94-6.83 6.94-6.63-2.94-6.56-6.56c.08-3.66 3.04-6.62 6.56-6.55z"
          style={{
            opacity: 0.6273,
            fill: '#d47f5f',
          }}
        />
        <path
          d="M48.4 26.18c3.62.08 6.82 2.55 6.82 6.17s-3.2 6.94-6.82 6.94-6.62-2.94-6.56-6.56c.07-3.67 3.03-6.62 6.56-6.55z"
          style={{
            opacity: 0.6182,
            fill: '#d58161',
          }}
        />
        <path
          d="M48.4 26.19c3.62.08 6.82 2.56 6.82 6.18 0 3.62-3.2 6.93-6.82 6.93s-6.62-2.94-6.56-6.56c.07-3.67 3.04-6.62 6.56-6.55z"
          style={{
            opacity: 0.6091,
            fill: '#d68364',
          }}
        />
        <path
          d="M48.41 26.2c3.62.08 6.82 2.57 6.82 6.19 0 3.62-3.2 6.93-6.82 6.93s-6.62-2.94-6.56-6.56c.07-3.68 3.03-6.63 6.56-6.56z"
          style={{
            opacity: 0.6,
            fill: '#d68566',
          }}
        />
        <path
          d="M48.41 26.21c3.62.07 6.81 2.57 6.81 6.19 0 3.62-3.19 6.92-6.81 6.92-3.62 0-6.62-2.94-6.56-6.56.07-3.67 3.04-6.62 6.56-6.55z"
          style={{
            opacity: 0.5909,
            fill: '#d78668',
          }}
        />
        <path
          d="M48.42 26.22c3.62.07 6.81 2.58 6.81 6.2s-3.19 6.91-6.81 6.91c-3.62 0-6.62-2.94-6.56-6.56.07-3.67 3.03-6.62 6.56-6.55z"
          style={{
            opacity: 0.5818,
            fill: '#d7886b',
          }}
        />
        <path
          d="M48.42 26.22c3.62.07 6.8 2.58 6.8 6.2s-3.18 6.91-6.8 6.91c-3.62 0-6.62-2.94-6.56-6.56.07-3.66 3.04-6.61 6.56-6.55z"
          style={{
            opacity: 0.5727,
            fill: '#d88a6d',
          }}
        />
        <path
          d="M48.43 26.23c3.62.07 6.8 2.59 6.8 6.21s-3.18 6.9-6.8 6.9c-3.62 0-6.62-2.94-6.56-6.56.07-3.66 3.03-6.62 6.56-6.55z"
          style={{
            opacity: 0.5636,
            fill: '#d98c6f',
          }}
        />
        <path
          d="M48.44 26.24c3.62.07 6.8 2.59 6.8 6.21s-3.18 6.9-6.8 6.9c-3.62 0-6.62-2.94-6.56-6.56.06-3.66 3.02-6.62 6.56-6.55z"
          style={{
            opacity: 0.5545,
            fill: '#d98e72',
          }}
        />
        <path
          d="M48.44 26.25c3.62.07 6.79 2.6 6.79 6.22 0 3.62-3.17 6.89-6.79 6.89s-6.62-2.94-6.56-6.56c.07-3.66 3.03-6.62 6.56-6.55z"
          style={{
            opacity: 0.5455,
            fill: '#da9074',
          }}
        />
        <path
          d="M48.45 26.26c3.62.07 6.79 2.6 6.79 6.23 0 3.62-3.17 6.89-6.79 6.89s-6.62-2.94-6.56-6.56c.06-3.67 3.02-6.63 6.56-6.56z"
          style={{
            opacity: 0.5364,
            fill: '#db9276',
          }}
        />
        <path
          d="M48.45 26.27c3.62.07 6.78 2.61 6.78 6.23 0 3.62-3.16 6.88-6.78 6.88s-6.61-2.94-6.56-6.56c.07-3.66 3.03-6.62 6.56-6.55z"
          style={{
            opacity: 0.5273,
            fill: '#db9379',
          }}
        />
        <path
          d="M48.46 26.27c3.62.06 6.78 2.62 6.78 6.24s-3.16 6.88-6.78 6.88-6.61-2.94-6.56-6.56c.06-3.66 3.02-6.62 6.56-6.56z"
          style={{
            opacity: 0.5182,
            fill: '#dc957b',
          }}
        />
        <path
          d="M48.46 26.28c3.62.06 6.78 2.62 6.78 6.24s-3.16 6.87-6.78 6.87-6.61-2.94-6.56-6.56c.06-3.66 3.02-6.61 6.56-6.55z"
          style={{
            opacity: 0.5091,
            fill: '#dc977d',
          }}
        />
        <path
          d="M48.47 26.29c3.62.06 6.77 2.63 6.77 6.25s-3.15 6.86-6.77 6.86c-3.62 0-6.61-2.94-6.56-6.56.06-3.66 3.02-6.61 6.56-6.55z"
          style={{
            opacity: 0.5,
            fill: '#dd9980',
          }}
        />
        <path
          d="M48.47 26.3c3.62.06 6.77 2.63 6.77 6.25s-3.15 6.86-6.77 6.86c-3.62 0-6.61-2.94-6.56-6.56.06-3.66 3.02-6.61 6.56-6.55z"
          style={{
            opacity: 0.4909,
            fill: '#de9b82',
          }}
        />
        <path
          d="M48.48 26.31c3.62.06 6.76 2.64 6.76 6.26 0 3.62-3.14 6.85-6.76 6.85-3.62 0-6.61-2.94-6.56-6.56.06-3.66 3.02-6.61 6.56-6.55z"
          style={{
            opacity: 0.4818,
            fill: '#de9d84',
          }}
        />
        <path
          d="M48.49 26.32c3.62.06 6.76 2.64 6.76 6.26 0 3.62-3.14 6.85-6.76 6.85-3.62 0-6.61-2.94-6.56-6.56.05-3.66 3.01-6.61 6.56-6.55z"
          style={{
            opacity: 0.4727,
            fill: '#df9f86',
          }}
        />
        <path
          d="M48.49 26.32c3.62.06 6.76 2.65 6.76 6.27 0 3.62-3.14 6.84-6.76 6.84-3.62 0-6.61-2.94-6.56-6.56.06-3.65 3.02-6.6 6.56-6.55z"
          style={{
            opacity: 0.4636,
            fill: '#dfa089',
          }}
        />
        <path
          d="M48.5 26.33c3.62.06 6.75 2.65 6.75 6.28 0 3.62-3.13 6.84-6.75 6.84s-6.61-2.94-6.56-6.56c.05-3.66 3.01-6.61 6.56-6.56z"
          style={{
            opacity: 0.4545,
            fill: '#e0a28b',
          }}
        />
        <path
          d="M48.5 26.34c3.62.06 6.75 2.66 6.75 6.28s-3.13 6.83-6.75 6.83-6.61-2.94-6.56-6.56c.06-3.65 3.01-6.6 6.56-6.55z"
          style={{
            opacity: 0.4455,
            fill: '#e1a48d',
          }}
        />
        <path
          d="M48.51 26.35c3.62.05 6.75 2.67 6.75 6.29s-3.12 6.83-6.75 6.83c-3.62 0-6.6-2.94-6.56-6.56.05-3.66 3.01-6.61 6.56-6.56z"
          style={{
            opacity: 0.4364,
            fill: '#e1a690',
          }}
        />
        <path
          d="M48.51 26.36c3.62.05 6.74 2.67 6.74 6.29s-3.12 6.82-6.74 6.82-6.6-2.94-6.56-6.56c.06-3.65 3.01-6.6 6.56-6.55z"
          style={{
            opacity: 0.4273,
            fill: '#e2a892',
          }}
        />
        <path
          d="M48.52 26.37c3.62.05 6.74 2.68 6.74 6.3 0 3.62-3.12 6.81-6.74 6.81s-6.6-2.94-6.56-6.56c.05-3.65 3.01-6.6 6.56-6.55z"
          style={{
            opacity: 0.4182,
            fill: '#e3aa94',
          }}
        />
        <path
          d="M48.52 26.37c3.62.05 6.73 2.68 6.73 6.3 0 3.62-3.11 6.81-6.73 6.81-3.62 0-6.6-2.94-6.56-6.56.05-3.64 3.01-6.59 6.56-6.55z"
          style={{
            opacity: 0.4091,
            fill: '#e3ac97',
          }}
        />
        <path
          d="M48.53 26.38c3.62.05 6.73 2.69 6.73 6.31 0 3.62-3.11 6.8-6.73 6.8-3.62 0-6.6-2.94-6.56-6.56.05-3.65 3-6.59 6.56-6.55z"
          style={{
            opacity: 0.4,
            fill: '#e4ad99',
          }}
        />
        <path
          d="M48.54 26.39c3.62.05 6.73 2.69 6.73 6.31 0 3.62-3.1 6.8-6.73 6.8-3.62 0-6.6-2.94-6.56-6.56.04-3.65 3-6.6 6.56-6.55z"
          style={{
            opacity: 0.3909,
            fill: '#e4af9b',
          }}
        />
        <path
          d="M48.54 26.4c3.62.05 6.72 2.7 6.72 6.32s-3.1 6.79-6.72 6.79c-3.62 0-6.6-2.94-6.56-6.56.05-3.65 3-6.6 6.56-6.55z"
          style={{
            opacity: 0.3818,
            fill: '#e5b19e',
          }}
        />
        <path
          d="M48.55 26.41c3.62.05 6.72 2.71 6.72 6.33s-3.1 6.79-6.72 6.79c-3.62 0-6.6-2.94-6.56-6.56.04-3.66 3-6.61 6.56-6.56z"
          style={{
            opacity: 0.3727,
            fill: '#e6b3a0',
          }}
        />
        <path
          d="M48.55 26.42c3.62.05 6.71 2.71 6.71 6.33s-3.09 6.78-6.71 6.78-6.6-2.94-6.56-6.56c.05-3.65 3-6.6 6.56-6.55z"
          style={{
            opacity: 0.3636,
            fill: '#e6b5a2',
          }}
        />
        <path
          d="M48.56 26.43c3.62.04 6.71 2.72 6.71 6.34 0 3.62-3.09 6.77-6.71 6.77s-6.6-2.94-6.56-6.56c.04-3.65 2.99-6.6 6.56-6.55z"
          style={{
            opacity: 0.3545,
            fill: '#e7b7a5',
          }}
        />
        <path
          d="M48.56 26.43c3.62.04 6.71 2.72 6.71 6.34 0 3.62-3.08 6.77-6.71 6.77-3.62 0-6.59-2.94-6.56-6.56.05-3.64 3-6.59 6.56-6.55z"
          style={{
            opacity: 0.3455,
            fill: '#e8b9a7',
          }}
        />
        <path
          d="M48.57 26.44c3.62.04 6.7 2.73 6.7 6.35 0 3.62-3.08 6.76-6.7 6.76s-6.59-2.94-6.56-6.56c.04-3.64 2.99-6.59 6.56-6.55z"
          style={{
            opacity: 0.3364,
            fill: '#e8baa9',
          }}
        />
        <path
          d="M48.58 26.45c3.62.04 6.7 2.73 6.7 6.35 0 3.62-3.08 6.76-6.7 6.76s-6.59-2.94-6.56-6.56c.04-3.64 2.99-6.59 6.56-6.55z"
          style={{
            opacity: 0.3273,
            fill: '#e9bcac',
          }}
        />
        <path
          d="M48.58 26.46c3.62.04 6.69 2.74 6.69 6.36s-3.07 6.75-6.69 6.75c-3.62 0-6.59-2.94-6.56-6.56.04-3.64 2.99-6.59 6.56-6.55z"
          style={{
            opacity: 0.3182,
            fill: '#e9beae',
          }}
        />
        <path
          d="M48.59 26.47c3.62.04 6.69 2.74 6.69 6.37 0 3.62-3.07 6.75-6.69 6.75-3.62 0-6.59-2.94-6.56-6.56.04-3.65 2.99-6.6 6.56-6.56z"
          style={{
            opacity: 0.3091,
            fill: '#eac0b0',
          }}
        />
        <path
          d="M48.59 26.48c3.62.04 6.69 2.75 6.69 6.37s-3.06 6.74-6.69 6.74c-3.62 0-6.59-2.94-6.56-6.56.04-3.64 2.99-6.59 6.56-6.55z"
          style={{
            opacity: 0.3,
            fill: '#ebc2b3',
          }}
        />
        <path
          d="M48.6 26.48c3.62.04 6.68 2.76 6.68 6.38s-3.06 6.74-6.68 6.74c-3.62 0-6.59-2.94-6.56-6.56.03-3.65 2.98-6.59 6.56-6.56z"
          style={{
            opacity: 0.2909,
            fill: '#ebc4b5',
          }}
        />
        <path
          d="M48.6 26.49c3.62.04 6.68 2.76 6.68 6.38s-3.06 6.73-6.68 6.73c-3.62 0-6.59-2.94-6.56-6.56.04-3.64 2.99-6.58 6.56-6.55z"
          style={{
            opacity: 0.2818,
            fill: '#ecc6b7',
          }}
        />
        <path
          d="M48.61 26.5c3.62.03 6.67 2.77 6.67 6.39 0 3.62-3.05 6.72-6.67 6.72s-6.59-2.94-6.56-6.56c.03-3.64 2.98-6.58 6.56-6.55z"
          style={{
            opacity: 0.2727,
            fill: '#ecc7b9',
          }}
        />
        <path
          d="M48.61 26.51c3.62.03 6.67 2.77 6.67 6.39 0 3.62-3.05 6.72-6.67 6.72s-6.59-2.94-6.56-6.56c.04-3.64 2.99-6.58 6.56-6.55z"
          style={{
            opacity: 0.2636,
            fill: '#edc9bc',
          }}
        />
        <path
          d="M48.62 26.52c3.62.03 6.67 2.78 6.67 6.4 0 3.62-3.05 6.71-6.67 6.71s-6.58-2.94-6.56-6.56c.03-3.64 2.98-6.58 6.56-6.55z"
          style={{
            opacity: 0.2545,
            fill: '#eecbbe',
          }}
        />
        <path
          d="M48.63 26.53c3.62.03 6.66 2.78 6.66 6.4 0 3.62-3.04 6.71-6.66 6.71s-6.58-2.94-6.56-6.56c.03-3.64 2.97-6.58 6.56-6.55z"
          style={{
            opacity: 0.2455,
            fill: '#eecdc0',
          }}
        />
        <path
          d="M48.63 26.53c3.62.03 6.66 2.79 6.66 6.41s-3.04 6.7-6.66 6.7-6.58-2.94-6.56-6.56c.03-3.63 2.98-6.57 6.56-6.55z"
          style={{
            opacity: 0.2364,
            fill: '#efcfc3',
          }}
        />
        <path
          d="M48.64 26.54c3.62.03 6.65 2.8 6.65 6.42s-3.03 6.7-6.65 6.7c-3.62 0-6.58-2.94-6.56-6.56.03-3.64 2.97-6.58 6.56-6.56z"
          style={{
            opacity: 0.2273,
            fill: '#f0d1c5',
          }}
        />
        <path
          d="M48.64 26.55c3.62.03 6.65 2.8 6.65 6.42s-3.03 6.69-6.65 6.69c-3.62 0-6.58-2.94-6.56-6.56.03-3.63 2.98-6.58 6.56-6.55z"
          style={{
            opacity: 0.2182,
            fill: '#f0d2c7',
          }}
        />
        <path
          d="M48.65 26.56c3.62.03 6.65 2.81 6.65 6.43 0 3.62-3.03 6.69-6.65 6.69-3.62 0-6.58-2.94-6.56-6.56.03-3.64 2.97-6.59 6.56-6.56z"
          style={{
            opacity: 0.2091,
            fill: '#f1d4ca',
          }}
        />
        <path
          d="M48.65 26.57c3.62.03 6.64 2.81 6.64 6.43 0 3.62-3.02 6.68-6.64 6.68-3.62 0-6.58-2.94-6.56-6.56.03-3.63 2.98-6.58 6.56-6.55z"
          style={{
            opacity: 0.2,
            fill: '#f1d6cc',
          }}
        />
        <path
          d="M48.66 26.58c3.62.02 6.64 2.82 6.64 6.44 0 3.62-3.02 6.67-6.64 6.67-3.62 0-6.58-2.94-6.56-6.56.02-3.63 2.97-6.58 6.56-6.55z"
          style={{
            opacity: 0.1909,
            fill: '#f2d8ce',
          }}
        />
        <path
          d="M48.66 26.58c3.62.02 6.63 2.82 6.63 6.44 0 3.62-3.01 6.67-6.63 6.67s-6.58-2.94-6.56-6.56c.03-3.63 2.97-6.57 6.56-6.55z"
          style={{
            opacity: 0.1818,
            fill: '#f3dad1',
          }}
        />
        <path
          d="M48.67 26.59c3.62.02 6.63 2.83 6.63 6.45s-3.01 6.66-6.63 6.66-6.58-2.94-6.56-6.56c.02-3.63 2.97-6.57 6.56-6.55z"
          style={{
            opacity: 0.1727,
            fill: '#f3dcd3',
          }}
        />
        <path
          d="M48.68 26.6c3.62.02 6.63 2.83 6.63 6.46 0 3.62-3.01 6.66-6.63 6.66s-6.57-2.94-6.56-6.56c.02-3.64 2.96-6.58 6.56-6.56z"
          style={{
            opacity: 0.1636,
            fill: '#f4ded5',
          }}
        />
        <path
          d="M48.68 26.61c3.62.02 6.62 2.84 6.62 6.46s-3 6.65-6.62 6.65-6.57-2.94-6.56-6.56c.02-3.63 2.97-6.57 6.56-6.55z"
          style={{
            opacity: 0.1545,
            fill: '#f4dfd8',
          }}
        />
        <path
          d="M48.69 26.62c3.62.02 6.62 2.85 6.62 6.47 0 3.62-3 6.65-6.62 6.65s-6.57-2.94-6.56-6.56c.02-3.64 2.96-6.58 6.56-6.56z"
          style={{
            opacity: 0.1455,
            fill: '#f5e1da',
          }}
        />
        <path
          d="M48.69 26.63c3.62.02 6.62 2.85 6.62 6.47 0 3.62-2.99 6.64-6.62 6.64-3.62 0-6.57-2.94-6.56-6.56.02-3.63 2.96-6.57 6.56-6.55z"
          style={{
            opacity: 0.1364,
            fill: '#f6e3dc',
          }}
        />
        <path
          d="M48.7 26.63c3.62.02 6.61 2.86 6.61 6.48 0 3.62-2.99 6.63-6.61 6.63s-6.57-2.94-6.56-6.56c.02-3.62 2.96-6.56 6.56-6.55z"
          style={{
            opacity: 0.1273,
            fill: '#f6e5df',
          }}
        />
        <path
          d="M48.7 26.64c3.62.01 6.61 2.86 6.61 6.48 0 3.62-2.99 6.63-6.61 6.63s-6.57-2.94-6.56-6.56c.02-3.62 2.96-6.56 6.56-6.55z"
          style={{
            opacity: 0.1182,
            fill: '#f7e7e1',
          }}
        />
        <path
          d="M48.71 26.65c3.62.01 6.6 2.87 6.6 6.49s-2.98 6.62-6.6 6.62c-3.62 0-6.57-2.94-6.56-6.56.02-3.62 2.96-6.56 6.56-6.55z"
          style={{
            opacity: 0.1091,
            fill: '#f8e9e3',
          }}
        />
        <path
          d="M48.72 26.66c3.62.01 6.6 2.87 6.6 6.49s-2.98 6.62-6.6 6.62c-3.62 0-6.57-2.94-6.56-6.56.01-3.62 2.95-6.56 6.56-6.55z"
          style={{
            opacity: 0.1,
            fill: '#f8ebe6',
          }}
        />
        <path
          d="M48.72 26.67c3.62.01 6.6 2.88 6.6 6.5s-2.97 6.61-6.6 6.61c-3.62 0-6.57-2.94-6.56-6.56.01-3.62 2.95-6.56 6.56-6.55z"
          style={{
            opacity: 0.0909,
            fill: '#f9ece8',
          }}
        />
        <path
          d="M48.73 26.68c3.62.01 6.59 2.88 6.59 6.51 0 3.62-2.97 6.61-6.59 6.61-3.62 0-6.56-2.94-6.56-6.56.01-3.63 2.95-6.57 6.56-6.56z"
          style={{
            opacity: 0.0818,
            fill: '#f9eeea',
          }}
        />
        <path
          d="M48.73 26.69c3.62.01 6.59 2.89 6.59 6.51 0 3.62-2.97 6.6-6.59 6.6-3.62 0-6.56-2.94-6.56-6.56.01-3.63 2.95-6.56 6.56-6.55z"
          style={{
            opacity: 0.0727,
            fill: '#faf0ec',
          }}
        />
        <path
          d="M48.74 26.69c3.62.01 6.58 2.9 6.58 6.52 0 3.62-2.96 6.6-6.58 6.6s-6.56-2.94-6.56-6.56c.01-3.63 2.95-6.56 6.56-6.56z"
          style={{
            opacity: 0.0636,
            fill: '#fbf2ef',
          }}
        />
        <path
          d="M48.74 26.7c3.62.01 6.58 2.9 6.58 6.52 0 3.62-2.96 6.59-6.58 6.59s-6.56-2.94-6.56-6.56c.01-3.62 2.95-6.55 6.56-6.55z"
          style={{
            opacity: 0.0545,
            fill: '#fbf4f1',
          }}
        />
        <path
          d="M48.75 26.71c3.62.01 6.58 2.91 6.58 6.53s-2.95 6.58-6.58 6.58c-3.62 0-6.56-2.94-6.56-6.56.01-3.62 2.95-6.56 6.56-6.55z"
          style={{
            opacity: 0.0455,
            fill: '#fcf6f3',
          }}
        />
        <path
          d="M48.75 26.72c3.62 0 6.57 2.91 6.57 6.53s-2.95 6.58-6.57 6.58-6.56-2.94-6.56-6.56c.01-3.62 2.95-6.56 6.56-6.55z"
          style={{
            opacity: 0.0364,
            fill: '#fdf8f6',
          }}
        />
        <path
          d="M48.76 26.73c3.62 0 6.57 2.92 6.57 6.54s-2.95 6.57-6.57 6.57-6.56-2.94-6.56-6.56c.01-3.62 2.94-6.56 6.56-6.55z"
          style={{
            opacity: 0.0273,
            fill: '#fdf9f8',
          }}
        />
        <path
          d="M48.77 26.74c3.62 0 6.56 2.92 6.56 6.54s-2.94 6.57-6.56 6.57c-3.62 0-6.56-2.94-6.56-6.56 0-3.62 2.94-6.56 6.56-6.55z"
          style={{
            opacity: 0.0182,
            fill: '#fefbfa',
          }}
        />
        <path
          d="M48.77 26.74c3.62 0 6.56 2.93 6.56 6.55 0 3.62-2.94 6.56-6.56 6.56-3.62 0-6.56-2.94-6.56-6.56.01-3.61 2.94-6.55 6.56-6.55z"
          style={{
            opacity: 0.009090909,
            fill: '#fefdfd',
          }}
        />
      </g>
    </g>
    <defs>
      <path
        id="SVGID_00000098198416266227790640000013158144629069197746_"
        d="M90.39 2.3H5.3c-1.66 0-3 1.34-3 3v52.26c0 1.66 1.34 3 3 3h85.09c1.66 0 3-1.34 3-3V5.3c0-1.66-1.34-3-3-3zm-1.48 29.13c0 15.53-12.59 28.13-28.13 28.13H5.3c-1.1 0-2-.9-2-2V5.3c0-1.1.9-2 2-2h55.48c15.53 0 28.13 12.59 28.13 28.13z"
      />
    </defs>
    <clipPath id="SVGID_00000057837715416962733780000013034616011739736735_">
      <use
        xlinkHref="#SVGID_00000098198416266227790640000013158144629069197746_"
        style={{
          overflow: 'visible',
        }}
      />
    </clipPath>
    <g
      style={{
        clipPath:
          'url(#SVGID_00000057837715416962733780000013034616011739736735_)',
      }}>
      <defs>
        <circle
          id="SVGID_00000025406948150022015250000000529422694433912761_"
          cx={47.88}
          cy={31.43}
          r={54.04}
        />
      </defs>
      <clipPath id="SVGID_00000083774837968719866150000016313868831598301571_">
        <use
          xlinkHref="#SVGID_00000025406948150022015250000000529422694433912761_"
          style={{
            overflow: 'visible',
          }}
        />
      </clipPath>
      <g
        style={{
          clipPath:
            'url(#SVGID_00000083774837968719866150000016313868831598301571_)',
        }}>
        <path
          d="M-6.16-22.61h108.07V85.46H-6.16z"
          style={{
            fill: '#e04e15',
          }}
          transform="rotate(-30 47.876 31.427)"
        />
        <path
          d="M-5.03-20.66h108.07V87.41H-5.03z"
          style={{
            fill: '#e04f15',
          }}
          transform="rotate(-30 49.001 33.376)"
        />
        <path
          d="M-3.91-18.71h108.07V89.36H-3.91z"
          style={{
            fill: '#e15015',
          }}
          transform="rotate(-30 50.126 35.324)"
        />
        <path
          d="M-2.78-16.77h108.07V91.3H-2.78z"
          style={{
            fill: '#e15115',
          }}
          transform="rotate(-30 51.25 37.272)"
        />
        <path
          d="M-1.66-14.82h108.07V93.25H-1.66z"
          style={{
            fill: '#e25215',
          }}
          transform="rotate(-30 52.376 39.22)"
        />
        <path
          d="M-.53-12.87h108.07V95.2H-.53z"
          style={{
            fill: '#e25316',
          }}
          transform="rotate(-30 53.5 41.17)"
        />
        <path
          d="M.59-10.92h108.07V97.15H.59z"
          style={{
            fill: '#e35416',
          }}
          transform="rotate(-30 54.625 43.118)"
        />
        <path
          d="M1.72-8.97h108.07V99.1H1.72z"
          style={{
            fill: '#e35516',
          }}
          transform="rotate(-30 55.75 45.066)"
        />
        <path
          d="M2.84-7.02h108.07v108.07H2.84z"
          style={{
            fill: '#e35616',
          }}
          transform="rotate(-30 56.875 47.015)"
        />
        <path
          d="m-15.812 29.177 93.589-54.035 54.035 93.588-93.589 54.035z"
          className="st239"
        />
        <path
          d="m-14.69 31.129 93.588-54.035 54.035 93.588-93.588 54.035z"
          className="st239"
        />
        <path
          d="M6.22-1.18h108.07v108.07H6.22z"
          style={{
            fill: '#e55816',
          }}
          transform="rotate(-30 60.25 52.86)"
        />
        <path
          d="M7.34.77h108.07v108.07H7.34z"
          style={{
            fill: '#e55916',
          }}
          transform="rotate(-30 61.374 54.809)"
        />
        <path
          d="M8.47 2.72h108.07v108.07H8.47z"
          style={{
            fill: '#e55a16',
          }}
          transform="rotate(-30 62.5 56.757)"
        />
        <path
          d="M9.59 4.67h108.07v108.07H9.59z"
          style={{
            fill: '#e65b16',
          }}
          transform="rotate(-30 63.624 58.706)"
        />
        <path
          d="M10.72 6.62h108.07v108.07H10.72z"
          style={{
            fill: '#e65c17',
          }}
          transform="rotate(-30 64.749 60.654)"
        />
        <path
          d="M11.84 8.56h108.07v108.07H11.84z"
          style={{
            fill: '#e75d17',
          }}
          transform="rotate(-30 65.874 62.603)"
        />
        <path
          d="M12.97 10.51h108.07v108.07H12.97z"
          style={{
            fill: '#e75e17',
          }}
          transform="rotate(-30 66.998 64.551)"
        />
        <path
          d="M14.09 12.46h108.07v108.07H14.09z"
          style={{
            fill: '#e85f17',
          }}
          transform="rotate(-30 68.123 66.5)"
        />
        <path
          d="M15.22 14.41h108.07v108.07H15.22z"
          style={{
            fill: '#e86017',
          }}
          transform="rotate(-30 69.248 68.448)"
        />
        <path
          d="M15.65 15.16h108.07v108.07H15.65z"
          style={{
            fill: '#e86117',
          }}
          transform="rotate(-30 69.683 69.201)"
        />
        <path
          d="M16.09 15.92h108.07v108.07H16.09z"
          style={{
            fill: '#e96217',
          }}
          transform="rotate(-30 70.118 69.954)"
        />
        <path
          d="M16.52 16.67h108.07v108.07H16.52z"
          style={{
            fill: '#e96317',
          }}
          transform="rotate(-30 70.553 70.708)"
        />
        <path
          d="M16.96 17.42h108.07v108.07H16.96z"
          style={{
            fill: '#e96418',
          }}
          transform="rotate(-30 70.987 71.46)"
        />
        <path
          d="M17.39 18.18h108.07v108.07H17.39z"
          style={{
            fill: '#e96518',
          }}
          transform="rotate(-30 71.422 72.214)"
        />
        <path
          d="M17.82 18.93h108.07V127H17.82z"
          style={{
            fill: '#ea6618',
          }}
          transform="rotate(-30 71.857 72.967)"
        />
        <path
          d="M18.26 19.68h108.07v108.07H18.26z"
          style={{
            fill: '#ea6718',
          }}
          transform="rotate(-30 72.292 73.72)"
        />
        <path
          d="M18.69 20.43h108.07V128.5H18.69z"
          style={{
            fill: '#ea6818',
          }}
          transform="rotate(-30 72.727 74.474)"
        />
        <path
          d="M19.13 21.19H127.2v108.07H19.13z"
          style={{
            fill: '#ea6918',
          }}
          transform="rotate(-30 73.162 75.227)"
        />
        <path
          d="M19.56 21.94h108.07v108.07H19.56z"
          style={{
            fill: '#eb6a18',
          }}
          transform="rotate(-30 73.596 75.98)"
        />
        <path
          d="M20 22.69h108.07v108.07H20z"
          style={{
            fill: '#eb6b19',
          }}
          transform="rotate(-30 74.031 76.734)"
        />
        <path
          d="M20.43 23.45H128.5v108.07H20.43z"
          style={{
            fill: '#eb6c19',
          }}
          transform="rotate(-30 74.466 77.487)"
        />
        <path
          d="M20.87 24.2h108.07v108.07H20.87z"
          style={{
            fill: '#eb6d19',
          }}
          transform="rotate(-30 74.9 78.24)"
        />
        <path
          d="M21.3 24.95h108.07v108.07H21.3z"
          style={{
            fill: '#ec6e19',
          }}
          transform="rotate(-30 75.336 78.993)"
        />
        <path
          d="M21.74 25.71h108.07v108.07H21.74z"
          style={{
            fill: '#ec6f19',
          }}
          transform="rotate(-30 75.77 79.747)"
        />
        <path
          d="M22.17 26.46h108.07v108.07H22.17z"
          style={{
            fill: '#ec7019',
          }}
          transform="rotate(-30 76.205 80.5)"
        />
        <path
          d="M22.61 27.21h108.07v108.07H22.61z"
          style={{
            fill: '#ec7119',
          }}
          transform="rotate(-30 76.64 81.253)"
        />
        <path
          d="M23.04 27.97h108.07v108.07H23.04z"
          style={{
            fill: '#ed7219',
          }}
          transform="rotate(-30 77.075 82.006)"
        />
        <path
          d="M23.48 28.72h108.07v108.07H23.48z"
          style={{
            fill: '#ed731a',
          }}
          transform="rotate(-30 77.51 82.76)"
        />
        <path
          d="M23.91 29.47h108.07v108.07H23.91z"
          style={{
            fill: '#ed741a',
          }}
          transform="rotate(-30 77.945 83.513)"
        />
        <path
          d="M24.35 30.23h108.07V138.3H24.35z"
          style={{
            fill: '#ed751a',
          }}
          transform="rotate(-30 78.38 84.266)"
        />
        <path
          d="M24.78 30.98h108.07v108.07H24.78z"
          style={{
            fill: '#ee761a',
          }}
          transform="rotate(-30 78.814 85.02)"
        />
        <path
          d="M25.22 31.73h108.07V139.8H25.22z"
          style={{
            fill: '#ee771a',
          }}
          transform="rotate(-30 79.25 85.773)"
        />
        <path
          d="M25.65 32.49h108.07v108.07H25.65z"
          style={{
            fill: '#ee781a',
          }}
          transform="rotate(-30 79.684 86.526)"
        />
        <path
          d="M26.09 33.24h108.07v108.07H26.09z"
          style={{
            fill: '#ef791a',
          }}
          transform="rotate(-30 80.119 87.28)"
        />
        <path
          d="M26.52 33.99h108.07v108.07H26.52z"
          style={{
            fill: '#ef7a1b',
          }}
          transform="rotate(-30 80.554 88.032)"
        />
        <path
          d="M26.96 34.75h108.07v108.07H26.96z"
          style={{
            fill: '#ef7b1b',
          }}
          transform="rotate(-30 80.989 88.786)"
        />
        <path
          d="M27.39 35.5h108.07v108.07H27.39z"
          style={{
            fill: '#ef7c1b',
          }}
          transform="rotate(-30 81.423 89.539)"
        />
        <path
          d="M27.83 36.25H135.9v108.07H27.83z"
          style={{
            fill: '#f07d1b',
          }}
          transform="rotate(-30 81.858 90.292)"
        />
        <path
          d="M28.26 37.01h108.07v108.07H28.26z"
          style={{
            fill: '#f07e1b',
          }}
          transform="rotate(-30 82.293 91.045)"
        />
        <path
          d="M28.7 37.76h108.07v108.07H28.7z"
          style={{
            fill: '#f07f1b',
          }}
          transform="rotate(-30 82.728 91.799)"
        />
        <path
          d="M29.13 38.51H137.2v108.07H29.13z"
          style={{
            fill: '#f0801b',
          }}
          transform="rotate(-30 83.163 92.552)"
        />
        <path
          d="M29.57 39.27h108.07v108.07H29.57z"
          style={{
            fill: '#f1801c',
          }}
          transform="rotate(-30 83.598 93.305)"
        />
        <path
          d="M30 40.02h108.07v108.07H30z"
          style={{
            fill: '#f1811c',
          }}
          transform="rotate(-30 84.033 94.058)"
        />
        <path
          d="M30.44 40.77h108.07v108.07H30.44z"
          style={{
            fill: '#f1821c',
          }}
          transform="rotate(-30 84.467 94.811)"
        />
        <path
          d="M30.87 41.53h108.07V149.6H30.87z"
          style={{
            fill: '#f1831c',
          }}
          transform="rotate(-30 84.902 95.565)"
        />
        <path
          d="M31.31 42.28h108.07v108.07H31.31z"
          style={{
            fill: '#f2841c',
          }}
          transform="rotate(-30 85.337 96.318)"
        />
        <path
          d="M31.74 43.03h108.07V151.1H31.74z"
          style={{
            fill: '#f2851c',
          }}
          transform="rotate(-30 85.772 97.071)"
        />
        <path
          d="M32.18 43.79h108.07v108.07H32.18z"
          style={{
            fill: '#f2861c',
          }}
          transform="rotate(-30 86.207 97.824)"
        />
        <path
          d="M32.61 44.54h108.07v108.07H32.61z"
          style={{
            fill: '#f2871d',
          }}
          transform="rotate(-30 86.642 98.578)"
        />
        <path
          d="M33.05 45.29h108.07v108.07H33.05z"
          style={{
            fill: '#f3881d',
          }}
          transform="rotate(-30 87.076 99.331)"
        />
        <path
          d="M33.48 46.04h108.07v108.07H33.48z"
          style={{
            fill: '#f3891d',
          }}
          transform="rotate(-30 87.511 100.084)"
        />
        <path
          d="M33.92 46.8h108.07v108.07H33.92z"
          style={{
            fill: '#f38a1d',
          }}
          transform="rotate(-30 87.946 100.838)"
        />
        <path
          d="M34.35 47.55h108.07v108.07H34.35z"
          style={{
            fill: '#f48b1d',
          }}
          transform="rotate(-30 88.38 101.59)"
        />
        <path
          d="M34.79 48.3h108.07v108.07H34.79z"
          style={{
            fill: '#f48c1d',
          }}
          transform="rotate(-30 88.816 102.344)"
        />
        <path
          d="M35.22 49.06h108.07v108.07H35.22z"
          style={{
            fill: '#f48d1d',
          }}
          transform="rotate(-30 89.25 103.097)"
        />
        <path
          d="M35.66 49.81h108.07v108.07H35.66z"
          style={{
            fill: '#f48e1e',
          }}
          transform="rotate(-30 89.685 103.85)"
        />
        <path
          d="M36.09 50.56h108.07v108.07H36.09z"
          style={{
            fill: '#f58f1e',
          }}
          transform="rotate(-30 90.12 104.604)"
        />
        <path
          d="M36.52 51.32h108.07v108.07H36.52z"
          style={{
            fill: '#f5901e',
          }}
          transform="rotate(-30 90.555 105.357)"
        />
        <path
          d="M36.96 52.07h108.07v108.07H36.96z"
          style={{
            fill: '#f5911e',
          }}
          transform="rotate(-30 90.99 106.11)"
        />
        <path
          d="M37.39 52.82h108.07v108.07H37.39z"
          style={{
            fill: '#f5921e',
          }}
          transform="rotate(-30 91.425 106.863)"
        />
        <path
          d="M37.83 53.58H145.9v108.07H37.83z"
          style={{
            fill: '#f6931e',
          }}
          transform="rotate(-30 91.86 107.617)"
        />
        <path
          d="M38.26 54.33h108.07V162.4H38.26z"
          style={{
            fill: '#f6941e',
          }}
          transform="rotate(-30 92.294 108.37)"
        />
        <path
          d="M38.7 55.08h108.07v108.07H38.7z"
          style={{
            fill: '#f6951e',
          }}
          transform="rotate(-30 92.73 109.123)"
        />
        <path
          d="M39.13 55.84H147.2v108.07H39.13z"
          style={{
            fill: '#f6961f',
          }}
          transform="rotate(-30 93.164 109.876)"
        />
        <path
          d="M39.57 56.59h108.07v108.07H39.57z"
          style={{
            fill: '#f7971f',
          }}
          transform="rotate(-30 93.599 110.63)"
        />
        <path
          d="M40 57.34h108.07v108.07H40z"
          style={{
            fill: '#f7981f',
          }}
          transform="rotate(-30 94.034 111.383)"
        />
        <path
          d="M40.44 58.1h108.07v108.07H40.44z"
          style={{
            fill: '#f7991f',
          }}
          transform="rotate(-30 94.469 112.136)"
        />
        <path
          d="M40.87 58.85h108.07v108.07H40.87z"
          style={{
            fill: '#f79a1f',
          }}
          transform="rotate(-30 94.904 112.89)"
        />
        <path
          d="M41.31 59.6h108.07v108.07H41.31z"
          style={{
            fill: '#f89b1f',
          }}
          transform="rotate(-30 95.338 113.643)"
        />
        <path
          d="M41.74 60.36h108.07v108.07H41.74z"
          style={{
            fill: '#f89c1f',
          }}
          transform="rotate(-30 95.773 114.396)"
        />
        <path
          d="M42.18 61.11h108.07v108.07H42.18z"
          style={{
            fill: '#f89d20',
          }}
          transform="rotate(-30 96.208 115.15)"
        />
        <path
          d="M42.61 61.86h108.07v108.07H42.61z"
          style={{
            fill: '#f89e20',
          }}
          transform="rotate(-30 96.643 115.903)"
        />
        <path
          d="M43.05 62.62h108.07v108.07H43.05z"
          style={{
            fill: '#f99f20',
          }}
          transform="rotate(-30 97.078 116.656)"
        />
        <path
          d="M43.48 63.37h108.07v108.07H43.48z"
          style={{
            fill: '#f9a020',
          }}
          transform="rotate(-30 97.513 117.409)"
        />
      </g>
    </g>
  </svg>
)

export default DiscoverCardIcon
