import React, { forwardRef } from 'react'
import { useTheme, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { UserAppModel } from '../../api/idm/getAllUserApplications'
import OverFlowToolTip from '../OverFlowToolTip/OverFlowToolTip'
import { useStyles } from './UserApp.styles'
import CaretLink from '../Navigation/CaretLink'
import { AnalyticEvent } from '../../analytics/adobe/types'

const analyticEventType: AnalyticEvent = 'Navigation_Click'

const UserApp = forwardRef<
  HTMLAnchorElement | null,
  { userApp: UserAppModel; index: number }
>((props, ref) => {
  const { userApp, index } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const styles = useStyles(theme)

  const renderLaunchText = (appId: string | undefined): string => {
    switch (appId) {
      case 'teamdocs':
        return t('HomePage.ViewProjects')
      case 'moretools':
        return t('HomePage.SubmitSupportTicket')
      default:
        return t('HomePage.Launch')
    }
  }

  return (
    <Box sx={styles.root}>
      <Box sx={styles.appName} key={userApp.id}>
        <OverFlowToolTip testId={`Lbl_ToolTile_Title_${index}`}>
          {userApp.name}
        </OverFlowToolTip>
      </Box>
      {!!userApp.category && (
        <Box sx={styles.appCategory}>
          <OverFlowToolTip testId={`Lbl_ToolTile_Category_${index}`}>
            {' '}
            {userApp.category || ''}
          </OverFlowToolTip>
        </Box>
      )}
      {!!userApp.description && (
        <Box sx={styles.appDesc}>
          <OverFlowToolTip
            testId={`Lbl_ToolTile_Description_${index}`}
            numberOfLines={2}>
            {userApp.description || ''}
          </OverFlowToolTip>
        </Box>
      )}
      <CaretLink
        ref={ref}
        href={userApp.launchUri ?? '/'}
        target={
          userApp.identifier === 'teamdocs' ||
          userApp.identifier === 'moretools'
            ? '_self'
            : '_blank'
        }
        aria-label={
          userApp.identifier === 'teamdocs'
            ? `${userApp.name} - ${t('HomePage.ViewProjects')}`
            : `${t('HomePage.Launch')} ${userApp.name}`
        }
        data-testid={`Lnk_Tool_Launch_${index}`}
        data-analytic-event={analyticEventType}>
        {renderLaunchText(userApp.identifier)}
      </CaretLink>
    </Box>
  )
})

export default UserApp
