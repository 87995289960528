import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import FaqIcon from '../../../icons/FaqIcon'
import { Styles } from '../../../../types'
import CaretLink from '../../../Navigation/CaretLink'
import { AnalyticEvent } from '../../../../analytics/adobe/types'

const analyticEventType: AnalyticEvent = 'Navigation_Click'

const styles: Styles = {
  faqCard: (theme) => ({
    maxWidth: '27rem',
    width: '100%',
    background: '#FFFFFF',
    border: '1px solid #CCCCCC',
    padding: '1.5rem',
    display: 'flex',
    flexGrow: 1,

    [theme.breakpoints.up('desktop')]: {
      minWidth: '21.875rem',
      flexGrow: 0,
    },
  }),
  title: (theme) => ({
    fontWeight: 'bold',
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    color: theme.palette.text.primary,
    marginTop: '0.3125rem',

    [theme.breakpoints.up('mobile')]: {
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
    },
  }),
  description: (theme) => ({
    fontWeight: 'normal',
    lineHeight: '1.5rem',
    color: theme.palette.text.primary,
    marginTop: '1rem',

    [theme.breakpoints.up('mobile')]: {
      lineHeight: '1.5rem',
    },
  }),
  icon: () => ({
    paddingLeft: '0.3125rem',
    display: 'inline-block',
  }),
  container: () => ({
    flex: '0 1 auto',
  }),
  faqIcon: () => ({
    flex: '0 1 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
}

const Faq = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Box sx={styles.faqCard} data-testid="Sec_Support_FAQ">
      <Box sx={styles.container}>
        <Box component="h1" sx={styles.title} className="font-prelo-book">
          {t('Faq.Title')}
        </Box>
        <Box component="p" sx={styles.description} className="font-prelo-book">
          {t('Faq.Description')}
        </Box>
        <Box>
          <CaretLink
            onClick={() => navigate('/faq/')}
            href="#ref"
            style={{ fontSize: '1rem', marginTop: 18, marginBottom: 0 }}
            aria-label={t('Faq.ButtonText')}
            data-testid="Lnk_FAQ_ReadFAQ"
            data-analytic-event={analyticEventType}>
            {t('Faq.ButtonText')}
          </CaretLink>
        </Box>
      </Box>
      <Box sx={styles.faqIcon}>
        <FaqIcon />
      </Box>
    </Box>
  )
}

export default Faq
