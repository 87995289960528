/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import MainContent from '../../components/layouts/MainContent'
import MainMenu from '../../components/layouts/MainMenu'
import SectionHeader from '../../components/layouts/SectionHeader'

const Notifications = () => {
  const { t } = useTranslation()

  useEffect(() => {
    document.title = t('ECPNotificationsTitle')
  }, [])

  return (
    <>
      <MainMenu />
      <MainContent>
        <Box sx={{ py: 4 }}>
          <SectionHeader title={t('NotificationsHeader')} />
        </Box>
      </MainContent>
    </>
  )
}

export default Notifications
