import { useCallback, useEffect } from 'react'
import { t } from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { stringComparer } from '../../../utils/helpers/stringHelpers'
import { UserAppModel, getUserApps } from '../../idm/getAllUserApplications'
import {
  setLoading,
  getLoading,
  getCalled,
  getUserApps as getApps,
  getError,
  setUserApps,
  setError,
  setCalled,
} from '../../../store/userApps/userAppsSlice'
import api from '../..'
import { consoleGoodStyle } from '../../../utils/helpers/version.service'

function useApps() {
  const dispatch = useDispatch()
  const userApps = useSelector(getApps)
  const loading = useSelector(getLoading)
  const called = useSelector(getCalled)
  const error = useSelector(getError)

  const SortAndFilterApps = (
    apps: UserAppModel[],
    appsWhitelist: string[],
  ): UserAppModel[] => {
    // note: we can sort/case transform both and make it fwd only for performance but not really required here
    // whitelist should be the shorter one
    const filtered = appsWhitelist.reduce(
      (values: UserAppModel[], item: string) => {
        const match = apps.filter(
          (a) => a.identifier?.toLowerCase() === item?.toLowerCase(),
        )
        if (match.length > 0) values.push(match[0])
        return values
      },
      [],
    )
    return filtered.sort((a: UserAppModel, b: UserAppModel) =>
      stringComparer(a.name, b.name),
    )
  }

  const getAllowedAppsList = async (): Promise<string[]> => {
    try {
      const response = await api.user.configuration_GetAllowedToolsList()
      return response.data ?? []
    } catch {
      return Promise.resolve([])
    }
  }

  const fetch = async (forceLoad = false) => {
    try {
      if (called && !forceLoad) return

      dispatch(setLoading(true))
      dispatch(setCalled(true))

      // parallel execution
      const [apps, appsWhitelist, teamDocsProjectSites] = await Promise.all([
        getUserApps(),
        getAllowedAppsList(),
        api.user
          .teamDocsUserMappings_GetTeamDocsUserMappings()
          .then((res) => res.data || []),
      ])
      // sort here even if data comes sorted, we do not control this source.
      const filtered = SortAndFilterApps(
        apps as UserAppModel[],
        appsWhitelist as string[],
      )

      // eslint-disable-next-line no-console
      console.log('%cProjects', consoleGoodStyle, {
        'TeamDocs Projects': teamDocsProjectSites?.length,
      })

      if (teamDocsProjectSites?.length > 0) {
        filtered.push({
          id: undefined,
          identifier: 'teamdocs',
          name: 'TeamDocs',
          description: t('HomePage.TeamDocsDescription'),
          category: t('HomePage.TeamDocsCategory'),
          launchUri: '/projects',
          logoUri: undefined,
          brandedApplicationType: undefined,
        })
      }

      filtered.sort((a, b) => stringComparer(a.name, b.name))

      // ARE YOU MISSING TOOLS? tile always displays as last tile.
      filtered.push({
        id: undefined,
        identifier: 'moretools',
        name: t('HomePage.AreYouMissingTools'),
        description: t('HomePage.RequestMissingTools'),
        category: t('HomePage.AreYouMissingToolsCategory'),
        launchUri: '/support',
        logoUri: undefined,
        brandedApplicationType: undefined,
      })

      dispatch(setLoading(false))
      dispatch(setUserApps(filtered || []))
    } catch (err) {
      dispatch(setLoading(false))
      dispatch(setUserApps([]))
      dispatch(setError(err as Error))
    }
  }

  useEffect(() => {
    localStorage.removeItem('loopCounter')
    fetch()
  }, [])

  const refetch = useCallback(() => fetch(true), [])

  return { userApps, loading, error, refetch }
}

export default useApps
