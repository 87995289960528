import React from 'react'
import { CircularProgress, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Unavailable from '../../../pages/Unavailable'
import { PaymentsData } from '../../../store/invoices/paymentSearchService'
import PaymentHistoryTable from './PaymentHistoryTable'
import { Styles } from '../../../types'
import RsmPagination, {
  RsmPaginationPageSizes,
} from '../../../rsmCoreComponents/components/RsmPagination/RsmPagination'
import { NoSearchFound } from '../../../rsmCoreComponents/components/NoSearchFound/NoSearchFound'

interface PaymentHistoryResultsProps {
  paymentsData: PaymentsData | undefined
  isPaymentsError: boolean
  isPaymentsLoading: boolean
  pageNumber: number
  pageSize: RsmPaginationPageSizes
  onPageChange: (pageNumber: number, pageSize: RsmPaginationPageSizes) => void
  onSorting: (sortBy: string, isAscending: 'asc' | 'desc') => void
  sortColumn: string //
  sortDirection: 'asc' | 'desc'
}

const styles: Styles = {
  container: () => ({
    display: 'flex',
    justifyContent: 'center',
    verticalAlign: 'middle',
    height: '2rem',
    marginTop: '2rem',
  }),
}

const PaymentHistoryResults = ({
  paymentsData,
  isPaymentsError,
  isPaymentsLoading,
  pageNumber,
  pageSize,
  onPageChange,
  onSorting,
  sortColumn,
  sortDirection,
}: PaymentHistoryResultsProps) => {
  const { t } = useTranslation()

  if (isPaymentsLoading) {
    return (
      <Box sx={styles.container} aria-busy="true">
        <CircularProgress
          color="secondary"
          data-testid="Spn_Payments_Loading"
        />
      </Box>
    )
  }
  if (isPaymentsError) {
    return (
      <Unavailable
        returnUrl={`/invoicing/payments?key=${new Date().getTime()}`}
      />
    )
  }

  if (paymentsData?.data && paymentsData.data.length === 0) {
    return <NoSearchFound title={t('Invoicing.NoData')} subTitle="" />
  }

  if (paymentsData) {
    const handleSort = (columnName: string, direction: 'asc' | 'desc') => {
      onSorting(columnName, direction)
    }
    return (
      <>
        <PaymentHistoryTable
          paymentsData={paymentsData}
          dataLoading={isPaymentsLoading}
          onSort={handleSort}
          sortColumn={sortColumn} // Pass the sort column to PaymentHistory Table
          sortDirection={sortDirection} // Pass the sort direction to  PaymentHistory Table
        />
        <RsmPagination
          pageNumber={pageNumber}
          pageSize={pageSize}
          totalRows={paymentsData.totalRows}
          onPageChange={onPageChange}
        />
      </>
    )
  }

  return (
    <Box sx={styles.container} aria-busy="true">
      <CircularProgress color="secondary" data-testid="Spn_Payments_Loading" />
    </Box>
  )
}

export default PaymentHistoryResults
