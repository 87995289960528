import React from 'react'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import ResponsiveModal from '../../../rsmCoreComponents/components/ResponsiveModal'
import { Styles } from '../../../types'
import WarningIconBig from '../../icons/WarningIcon/WarningIconBig'
import { CheckboxIcon, CheckboxOutlinedIcon } from '../../icons'
import api from '../../../api'
import { USER_PREF_INVOICING_IGNOREUNRELATED } from '../../../utils/constants/constants'

interface TermsAndConditionsDialogProps {
  open: boolean
  onAccept: () => void
  onClose: () => void
}

const styles: Styles = {
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '5%',
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    maxHeight: '100%',
    height: '100%',
  },
  title: {
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '2.25rem',
    lineHeight: '2.5rem',
    marginTop: '2rem',
    marginBottom: '1rem',
  },
  acceptWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1.5rem',
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
}

const TermsAndConditionsDialog = ({
  open,
  onAccept,
  onClose,
}: TermsAndConditionsDialogProps) => {
  const { t } = useTranslation()

  const handleCheckboxChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    // Set the user preference to ignore unrelated invoices for 3 months
    const expirationDate = new Date()
    expirationDate.setMonth(expirationDate.getMonth() + 3)

    await api.user.configuration_SetUserStorage(
      USER_PREF_INVOICING_IGNOREUNRELATED,
      e.target.checked.toString(),
      expirationDate,
    )
  }

  return (
    <Box className="Rsm-ProfileModal">
      <ResponsiveModal
        open={open}
        onClose={onClose}
        aria-label={t('Invoicing.UnrelatedInvoicesDialog.Title')}
        desktopMaxHeight="29rem"
        desktopMaxWidth="40rem"
        removeHeaderSpacing>
        <Box sx={styles.dialogContent}>
          <WarningIconBig />
          <Typography variant="h1" sx={styles.title}>
            {t('Invoicing.UnrelatedInvoicesDialog.Title')}
          </Typography>
          <Typography variant="body1">
            {t('Invoicing.UnrelatedInvoicesDialog.Subtitle')}
          </Typography>
          <Stack sx={styles.acceptWrapper}>
            <Button variant="outlined" onClick={onClose}>
              {t('Invoicing.UnrelatedInvoicesDialog.Cancel')}
            </Button>
            <Button variant="contained" onClick={onAccept}>
              {t('Invoicing.UnrelatedInvoicesDialog.Proceed')}
            </Button>
          </Stack>
          <FormControlLabel
            control={
              <Checkbox
                icon={<CheckboxOutlinedIcon />}
                checkedIcon={<CheckboxIcon />}
                onChange={handleCheckboxChange}
                data-testid="Chk_Invoicing-Unrelated-Invoices-Dont-Ask-Again"
              />
            }
            label={
              <Typography sx={styles.nonEcpUserAckMessage}>
                {t('Invoicing.UnrelatedInvoicesDialog.DontAskAgain')}
              </Typography>
            }
          />
        </Box>
      </ResponsiveModal>
    </Box>
  )
}

export default TermsAndConditionsDialog
