/* eslint-disable */
import { createApi } from '@reduxjs/toolkit/query/react'
import { FINANCESERVICE_BASE_URL } from '../../envVariables'
import { axiosBaseQuery, Response } from '../../api/BaseQuery'
import {
  ApiResponseOfDictionaryOfStringAndIEnumerableOfString,
  ApiResponseOfInteger,
  InvoicesSearchFilter,
  PagingResultOfInvoicesSearchFilterAndInvoiceModel,
} from '@rsmus/ecp-financeservice'

// UX Invoicing search view model
export interface InvoiceData {
  id: string
  customerName: string
  customerNumber: string
  invoiceNumber: string
  date: string
  originalAmount: number
  openAmount: number
  status: string
  company: string
  invoicePaidDate: string
  currency: string
}

export type InvoicesData = {
  data: InvoiceData[]
  totalRows: number
}

function transformInvoiceSearchData(
  inModel: Response<PagingResultOfInvoicesSearchFilterAndInvoiceModel>,
): InvoicesData {
  const outData: InvoicesData = {
    data:
      inModel?.data?.result?.map((i) => ({
        id: i.invoiceId ?? '',
        customerName: i.customer?.customerName ?? '',
        customerNumber: i.customer?.customerId ?? '',
        invoiceNumber: i.invoiceNumber ?? '',
        date: i.invoiceDate?.toString() ?? '',
        originalAmount: i.grossInvoiceAmount ?? 0,
        openAmount: i.openAmount ?? 0,
        status: i.status ?? '',
        company: i.company?.companyName ?? '',
        invoicePaidDate: i.invoicePaidDate?.toString() ?? '', // Added the invoicePaidDate field
        currency: i.currency ?? '',
      })) ?? [],
    totalRows: inModel?.data?.totalRows ?? 0,
  }

  return outData
}

//// These endpoints will be repurposed to use invoices when our api is ready
const invoiceSearchService = createApi({
  reducerPath: 'invoiceSearchService',
  tagTypes: ['searchFilters', 'totalOpenAmount'],
  baseQuery: axiosBaseQuery({
    baseURL: FINANCESERVICE_BASE_URL,
  }),
  endpoints: (builder) => ({
    getInvoiceSearchFilters: builder.query<
      ApiResponseOfDictionaryOfStringAndIEnumerableOfString,
      void
    >({
      query: () => ({
        url: '/api/v1/Invoice/InvoicesFilter',
        method: 'get',
      }),
      providesTags: ['searchFilters'],
    }),
    getTotalOpenAmount: builder.query<ApiResponseOfInteger, string>({
      query: (currencyString) => ({
        url: '/api/v1/Invoice/TotalOpenAmount',
        params: {
          currency: currencyString,
        },
        method: 'get',
      }),
      providesTags: ['totalOpenAmount'],
    }),
    updateInvoiceSearchResults: builder.mutation<
      InvoicesData,
      InvoicesSearchFilter
    >({
      query: (data) => ({
        url: '/api/v1/Invoice/InvoicesSearch',
        data,
        method: 'post',
      }),
      transformResponse: (response) => {
        return transformInvoiceSearchData(response)
      },
    }),
  }),
})

export const {
  useGetInvoiceSearchFiltersQuery,
  useGetTotalOpenAmountQuery,
  useUpdateInvoiceSearchResultsMutation,
} = invoiceSearchService

export default invoiceSearchService
