import { createApi } from '@reduxjs/toolkit/query/react'
import {
  ApiResponseOfDictionaryOfStringAndIEnumerableOfString,
  PagingResultOfPaymentSearchFilterAndPaymentModel,
  PaymentSearchFilter,
} from '@rsmus/ecp-financeservice'
import { axiosBaseQuery, Response } from '../../api/BaseQuery'
import { FINANCESERVICE_BASE_URL } from '../../envVariables'

// UX Invoicing search view model
export interface PaymentData {
  customerName: string
  customerNumber: string
  transactionId: string
  paidAmount: number
  paymentStatus: string
  paidBy: string
  paymentMethod: string
  currency: string
  paymentDate: string
  company: string
  processedByEmail: string
}

export type PaymentsData = {
  data: PaymentData[]
  totalRows: number
}

function transformPaymentSearchData(
  inModel: Response<PagingResultOfPaymentSearchFilterAndPaymentModel>,
): PaymentsData {
  const outData: PaymentsData = {
    data:
      inModel?.data?.result?.map((i) => ({
        customerName: i.customer?.customerName ?? '',
        customerNumber: i.customer?.customerId ?? '',
        transactionId: i.transactionId ?? '',
        paidAmount: i.paidAmount ?? 0,
        paymentStatus: i.paymentStatus?.descriptor ?? 'open',
        paidBy: i.paidBy ?? '',
        paymentMethod: i.paymentMethod ?? '',
        currency: i.currency ?? '',
        paymentDate: i.paymentDate?.toString() ?? '',
        company: i.customer?.customerName ?? '',
        processedByEmail: i.processedByEmail?.toString() ?? '',
      })) ?? [],
    totalRows: inModel?.data?.totalRows ?? 0,
  }

  return outData
}

/// These endpoints will be repurposed to use payments when our api is ready
const paymentSearchService = createApi({
  reducerPath: 'paymentSearchService',
  tagTypes: ['searchFilters'],
  baseQuery: axiosBaseQuery({
    baseURL: FINANCESERVICE_BASE_URL,
  }),
  endpoints: (builder) => ({
    getPaymentSearchFilters: builder.query<
      ApiResponseOfDictionaryOfStringAndIEnumerableOfString,
      void
    >({
      query: () => ({
        url: '/api/v1/Payment/PaymentsFilter',
        method: 'get',
      }),
      providesTags: ['searchFilters'],
    }),
    updatePaymentSearchResults: builder.mutation<
      PaymentsData,
      PaymentSearchFilter
    >({
      query: (data) => ({
        url: '/api/v1/Payment/PaymentsSearch',
        data,
        method: 'post',
      }),
      transformResponse: (response) => transformPaymentSearchData(response),
    }),
  }),
})

export const {
  useGetPaymentSearchFiltersQuery,
  useUpdatePaymentSearchResultsMutation,
} = paymentSearchService

export default paymentSearchService
