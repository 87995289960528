import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { PaymentActivityModel } from '@rsmus/ecp-financeservice'
import { Styles } from '../../../types'
import tokens from '../../../styles/tokens.json'
import ActivityFeedItem from './ActivityFeedItem'
import api from '../../../api'

const styles: Styles = {
  mainContainer: (theme) => ({
    display: 'flex',
    height: '41.875rem',
    background: theme.palette.grey[100],
    border: `1px solid ${tokens.colors.rsmGray.disabled}`,
    padding: '1.5rem',
    fontFamily: 'prelo-book',
    width: '100%',
    overflowY: 'scroll',
    [theme.breakpoints.only('mobile')]: {
      height: '38.875rem',
      maxWidth: '24rem',
      marginBottom: '1.5rem',
    },
    [theme.breakpoints.only('tablet')]: {
      maxWidth: '49rem',
    },
  }),
  contentContainer: {
    display: 'flex-initial',
    width: '100%',
    marginTop: '1rem',
    marginLeft: '1.5rem',
  },
  headerContainer: {
    display: 'flex',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: '2.25rem',
    lineHeight: '2.5rem',
    fontWeight: '300',
    fontStyle: 'normal',
  },
  activityFeedItemContainer: {
    marginTop: '1rem',
  },
  activityFeedItemHeader: {
    fontWeight: 700,
    fontSize: '1rem',
    lineWeight: '1.5rem',
    marginBottom: '0.5rem',
  },
  activityFeedItemDate: {
    fontWeight: 700,
    fontSize: '0.75rem',
    lineWeight: '1rem',
  },
  activityFeedItemBody: {
    fontWeight: 400,
    fontSize: '1rem',
    lineWeight: '1.2rem',
  },
}

interface ActivityFeedProps {
  userAgreementAccepted: boolean
}

const getPaymentActivitiesList = async (
  companyName: string,
): Promise<PaymentActivityModel[]> => {
  const response = await api.finance.activity_GetPaymentActivities(companyName)
  return response.data ?? []
}

const ActivityFeed = ({ userAgreementAccepted }: ActivityFeedProps) => {
  const { t } = useTranslation()
  const [paymentActivities, setPaymentActivities] =
    useState<PaymentActivityModel[]>()

  const loadPaymentActivityData = async (companyName: string) => {
    const data = await getPaymentActivitiesList(companyName)
    setPaymentActivities(data)
  }

  useEffect(() => {
    loadPaymentActivityData('Acme, Inc')
  }, [])

  return (
    <Box
      sx={styles.mainContainer}
      data-testid="Inv_ActivityFeed"
      role="region"
      aria-label="Activity Feed">
      <Box sx={styles.contentContainer}>
        <Box sx={styles.headerContainer} data-testid="Lbl_ActivityFeed_Title">
          <h2>{t('ActivityFeedTitle')}</h2>
        </Box>
        {userAgreementAccepted && (
          <ActivityFeedItem items={paymentActivities || []} />
        )}
      </Box>
    </Box>
  )
}

export default ActivityFeed
