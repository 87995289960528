import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Snackbar } from '@mui/material'
import Slide from '@mui/material/Slide'
import { ToastSuccessIcon } from '../../../icons'
import tokens from '../../../../styles/tokens.json'
import { useStyles } from '../Alert.styles'

export type CustomSuccessAlertProps = {
  message: string
  messageParams?: any
  header: string
  open: boolean
  close: () => void
  testId?: string
}

const CustomSuccessAlert = ({
  header,
  message,
  messageParams,
  open,
  close,
  testId,
}: CustomSuccessAlertProps) => {
  const { t } = useTranslation()
  const toastMsgTimelimit = 8000
  const toastMsgSlideEnter = 1000
  const toastMsgSlideExit = 1000

  const classes = useStyles()

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transitionDuration={{
        enter: toastMsgSlideEnter,
        exit: toastMsgSlideExit,
      }}
      TransitionComponent={Slide}
      autoHideDuration={toastMsgTimelimit}
      open={open}
      onClose={() => close()}
      sx={classes.successSnackbar}>
      <Alert
        severity="success"
        icon={<ToastSuccessIcon color={tokens.colors.rsmGray.copy} />}
        sx={classes.successAlert}
        data-testid={testId ?? ''}>
        <div className="m-[1.5625rem]">
          <div style={{ marginBottom: '0.3125rem', fontWeight: 'bold' }}>
            {t(header)}
          </div>
          <div>{t(message, messageParams)}</div>
        </div>
      </Alert>
    </Snackbar>
  )
}

CustomSuccessAlert.defaultProps = {
  testId: '',
  messageParams: null,
}

export default CustomSuccessAlert
