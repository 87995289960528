import { AdobeAnalyticsEventObject } from './types'

function shouldExcludeAdobeObject(
  analyticsObject: AdobeAnalyticsEventObject,
): boolean {
  // exclude insightsNavigationClicks since already taken care of in "insightsNavigationClick.ts" file
  if (analyticsObject.event_data_id === 'Lnk_Navigation_Insights') {
    return true
  }
  return false
}

const processAdobeAllClicks = (analyticsObject: AdobeAnalyticsEventObject) => {
  if (!shouldExcludeAdobeObject(analyticsObject)) {
    window.adobeDataLayer.push({
      event: analyticsObject.event,
      event_data_id: analyticsObject.event_data_id,
    })
  }
}

export default processAdobeAllClicks
