import React from 'react'
import { CircularProgress, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Unavailable from '../../../pages/Unavailable'
import { InvoicesData } from '../../../store/invoices/invoiceSearchService'
import InvoicesTable from './InvoicesTable'
import { Styles } from '../../../types'
import { NoSearchFound } from '../../../rsmCoreComponents/components/NoSearchFound/NoSearchFound'
import RsmPagination, {
  RsmPaginationPageSizes,
} from '../../../rsmCoreComponents/components/RsmPagination/RsmPagination'

interface InvoicesResultsProps {
  invoicesData: InvoicesData | undefined
  isInvoicesError: boolean
  pageNumber: number
  pageSize: RsmPaginationPageSizes
  onPageChange: (pageNumber: number, pageSize: RsmPaginationPageSizes) => void
  onSorting: (sortBy: string, isAscending: 'asc' | 'desc') => void
  sortColumn: string //
  sortDirection: 'asc' | 'desc'
}

const styles: Styles = {
  container: () => ({
    display: 'flex',
    justifyContent: 'center',
    verticalAlign: 'middle',
    height: '2rem',
    marginTop: '2rem',
  }),
}

const InvoicesResults = ({
  invoicesData,
  isInvoicesError,
  pageNumber,
  pageSize,
  onPageChange,
  onSorting,
  sortColumn,
  sortDirection,
}: InvoicesResultsProps) => {
  const { t } = useTranslation()

  if (isInvoicesError) {
    return (
      <Unavailable
        returnUrl={`/invoicing/invoices?key=${new Date().getTime()}`}
      />
    )
  }

  if (invoicesData?.data && invoicesData.data.length === 0) {
    return <NoSearchFound title={t('Invoicing.NoData')} subTitle="" />
  }

  if (invoicesData) {
    const handleSort = (columnName: string, direction: 'asc' | 'desc') => {
      onSorting(columnName, direction)
    }

    return (
      <>
        <InvoicesTable
          invoicesData={invoicesData}
          onSort={handleSort}
          sortColumn={sortColumn} // Pass the sort column to InvoicesTable
          sortDirection={sortDirection} // Pass the sort direction to InvoicesTable
        />
        <RsmPagination
          pageNumber={pageNumber}
          pageSize={pageSize}
          totalRows={invoicesData.totalRows}
          onPageChange={onPageChange}
        />
      </>
    )
  }

  return (
    <Box sx={styles.container} aria-busy="true">
      <CircularProgress color="secondary" data-testid="Spn_Invoices_Loading" />
    </Box>
  )
}

export default InvoicesResults
