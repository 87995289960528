import { faBookmark } from '@fortawesome/free-regular-svg-icons'
import { faBookmark as faBookmarkSolid } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Button,
  Chip,
  styled,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { SharedArticleInfoViewModel } from '@rsmus/ecp-cmsservice'
import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useDeviceType } from '../../../rsmCoreComponents/hooks/useDeviceType'
import {
  isCemFeatureEnabled,
  isFeatureFlagEnabled,
} from '../../../rsmCoreComponents/utils/featureFlagUtils'
import { fetchAllEngagements } from '../../../store/engagements/engagementSlice'
import {
  getUserInfo,
  getCemFeatures,
} from '../../../store/userInfo/userInfoSlice'
import {
  InsightsFilterObj,
  setAvailableTagFilters,
  setFilterOnMatchingTagValue,
} from '../../../store/insights/insightsSlice'
import tokens from '../../../styles/tokens.json'
import { Styles } from '../../../types'
import { ArticleMetaData } from '../../../utils/helpers/Article.service'
import { useAppSelector } from '../../../utils/hooks'
import { CustomErrorAlert, CustomWarningAlert } from '../../forms/Alert'
import CustomSuccessAlert from '../../forms/Alert/CustomSuccessAlert'
import Spinner from '../../forms/Spinner/Spinner'
import ShareIcon from '../../icons/ShareIcon'
import OverFlowToolTip from '../../OverFlowToolTip/OverFlowToolTip'
import ShareArticleModal from '../ShareArticleModal'
import { ShareArticleModalResult } from '../ShareArticleModal/ShareArticleModal'
import { getFilters } from '../useInsightsSearch'
import ArticleContributors from './ArticleContributors'
import ArticleHeader from '../ArticleSubcomponents/ArticleHeader'
import api from '../../../api'
import { CEM_FEATURE_ARTICLESHARE } from '../../../utils/constants/constants'

const styles: Styles = {
  articleContainer: (theme) => ({
    marginTop: '-2.5rem',
    marginBottom: '-2.5rem',
    color: theme.palette.common.black,
  }),
  articleHeaderContainer: (theme) => ({
    display: 'inline-block',
    paddingRight: '1rem',
    paddingLeft: '1rem',
    width: '100%',
    background: theme.palette.common.white,
    [theme.breakpoints.only('tablet')]: {
      paddingRight: '2rem',
      paddingLeft: '2rem',
    },
    [theme.breakpoints.up('desktop')]: {
      paddingRight: '11.0625rem',
      paddingLeft: '11.0625rem',
    },
  }),
  mainArticleContainer: (theme) => ({
    float: 'right',
    marginTop: '2rem',
    marginBottom: '1.6875rem',
    width: '100%',
    [theme.breakpoints.only('tablet')]: {
      marginBottom: '1.8125rem',
    },
    [theme.breakpoints.up('desktop')]: {
      float: 'left',
      marginBottom: '2.5rem',
      width: '75%',
    },
  }),
  actionIconsContainer: (theme) => ({
    float: 'right',
    marginTop: '-2rem',
    marginRight: '0.875rem',
    [theme.breakpoints.only('tablet')]: {
      marginRight: '2.375rem',
    },
    [theme.breakpoints.up('desktop')]: {
      marginRight: '-1.875rem',
    },
  }),
  contentContainer: (theme) => ({
    marginBottom: '2.5rem',
    [theme.breakpoints.up('desktop')]: {
      marginBottom: '5.4375rem',
    },
    '& a': {
      color: theme.palette.secondary.main,
      fontFamily: 'Prelo-Book, sans-serif',
      textDecoration: 'underline',
    },
    '& th, & td': {
      border: '0.0625rem solid',
      paddingLeft: '0.3125rem',
      paddingRight: '0.3125rem',
    },
    '& ol': {
      listStyleType: 'decimal',
      listStylePosition: 'inside',
      marginBottom: '1.5rem',
    },
    '& ul': {
      listStyleType: 'square',
      listStylePosition: 'inside',
      marginBottom: '1.5rem',
    },
    '& p': {
      fontFamily: 'Prelo-Book, sans-serif',
      fontStyle: 'normal',
      fontSize: '1rem',
      marginBottom: '1.5rem',
    },
    '& h2': {
      fontFamily: 'Prelo-Light, sans-serif',
      fontStyle: 'normal',
      fontSize: '2.25rem',
      marginBottom: '2rem',
    },
    '& h3': {
      fontFamily: 'Prelo-Light, sans-serif',
      fontStyle: 'normal',
      fontSize: '1.5rem',
      marginBottom: '1rem',
    },
    '& .icon-cmp-image__image': {
      backgroundColor: theme.palette.secondary.main,
      height: '6.25rem',
      width: '6.25rem',
    },
  }),
  actionButtonsContainer: {
    flexWrap: 'wrap',
  },
  favoriteButton: (theme: Theme) => ({
    width: '100%',
    [theme.breakpoints.up('tablet')]: {
      width: 'auto',
    },
  }),
  shareButton: (theme) => ({
    width: '100%',
    '& svg path': {
      fill: theme.palette.secondary.main,
      transition: 'fill 250ms cubic-bezier(0.4, 0, 0.2, 1)',
    },
    '&:hover svg path,': {
      fill: theme.palette.common.white,
    },
    '&:active svg path': {
      fill: theme.palette.text.primary,
    },
    [theme.breakpoints.up('tablet')]: {
      width: 'auto',
    },
  }),
  shareIconContainer: {
    marginLeft: '1.25rem',
  },
  additionalDetailsContainer: (theme) => ({
    float: 'right',
    marginTop: '2.625rem',
    marginBottom: '2.4375rem',
    width: '100%',
    [theme.breakpoints.only('tablet')]: {
      marginTop: '1.625rem',
      marginBottom: '3.3125rem',
    },
    [theme.breakpoints.up('desktop')]: {
      marginTop: '7rem',
      width: '25%',
    },
  }),
  verticalDivider: {
    backgroundColor: tokens.colors.rsmGray.disabled,
    width: '0.125rem',
    height: '16.1875rem',
    float: 'left',
    marginLeft: '1.875rem',
    marginRight: '1.5rem',
  },
  chipsContainer: (theme) => ({
    [theme.breakpoints.up('desktop')]: {
      paddingLeft: '3.5rem',
    },
  }),
  headingContainer: {
    textTransform: 'uppercase',
    fontFamily: tokens.type.fontFamilies.preloBold,
    fontSize: '0.875rem',
    lineHeight: '1rem',
    letterSpacing: '0.0625rem',
    marginBottom: '1rem',
  },
  chip: {
    textTransform: 'uppercase',
  },
  tag: {
    textTransform: 'uppercase',
  },
}

const Styled = {
  BookmarkIcon: styled(FontAwesomeIcon)(() => ({
    paddingLeft: '1.25rem',
  })),
  BookmarkIconMainMobile: styled(FontAwesomeIcon)(() => ({
    height: '2.5rem',
  })),
  BookmarkIconMainDesktop: styled(FontAwesomeIcon)(() => ({
    height: '4.1875rem',
  })),
}

interface ArticleProps {
  articleMetadata: ArticleMetaData
  articleContent: string
  sharedArticleInfoData: SharedArticleInfoViewModel
}

// Replace whitespace with dashes.
const Article = ({
  articleMetadata,
  articleContent,
  sharedArticleInfoData,
}: ArticleProps) => {
  const muiTheme = useTheme()
  const { isDesktop } = useDeviceType()
  const { t } = useTranslation()
  const [shareEnabled, setShareEnabled] = useState(false)
  const [latestClickEvent, setLatestClickEvent] = useState(false)
  const [openArticleModal, setOpenArticleModal] = useState(false)
  const [alertInfo, setAlertInfo] = useState<{
    type?: string | null
    header?: string | null
    message?: string | null
  }>({
    type: '',
    header: '',
    message: '',
  })

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const shareButtonRef = useRef<HTMLButtonElement>(null)

  const currentFilterOptions = useAppSelector(
    (state) => state.insights.availableTagFilters,
  )

  // Check if there are filter options in redux,
  // And if not, make the api call to load them
  // Occurs if insights list page hasn't been loaded yet
  const checkFiltersLoaded = async (
    filterOptions: Map<keyof InsightsFilterObj, Set<string>>,
  ) => {
    if (filterOptions.size === 0) {
      const sortedFilterMap = await getFilters()
      dispatch(setAvailableTagFilters(sortedFilterMap))
    }
  }

  const handleFavoriteClick = useCallback(
    (selectedArticle: ArticleMetaData) => {
      api.cms.insights_ToggleFavoriteArticle(selectedArticle?.ecpId ?? null)
      // eslint-disable-next-line no-param-reassign
      selectedArticle.favorite = !selectedArticle.favorite
      setLatestClickEvent(!latestClickEvent)
    },
    [
      api.cms.insights_ToggleFavoriteArticle,
      setLatestClickEvent,
      latestClickEvent,
    ],
  )

  const userInfo = useSelector(getUserInfo)
  const cemFeatures = useSelector(getCemFeatures)

  const LoadEngagements = () => {
    dispatch(fetchAllEngagements({ isEmployee: userInfo.isEmployee }))
  }

  useEffect(() => {
    if (userInfo.isEmployee !== undefined) {
      LoadEngagements()

      // Used to show/hide the two (2) share buttons
      const enableShare = isCemFeatureEnabled(
        CEM_FEATURE_ARTICLESHARE,
        cemFeatures,
        'any',
      )
      setShareEnabled(enableShare)
    }
  }, [userInfo, cemFeatures, dispatch, LoadEngagements])

  useEffect(() => {
    const articleContainer = document.getElementById('articleContent')
    articleContainer?.insertAdjacentHTML('afterbegin', articleContent)
  }, [articleContent])

  if (articleContent.length < 1 || articleMetadata.ecpId === '') {
    return <Spinner open />
  }

  const onClickChip = async (
    tag: string,
    filters: Map<keyof InsightsFilterObj, Set<string>>,
  ) => {
    await checkFiltersLoaded(filters)
    dispatch(setFilterOnMatchingTagValue({ tag }))
    navigate(`/insights`)
  }

  const openAlertMessage = (
    result: ShareArticleModalResult | undefined,
    mailNotSendUsers: Record<string, any>,
    mailSendUsers: Record<string, any>,
  ) => {
    let message = ''
    let type = ''
    let header = ''

    if (
      result?.users?.length === 1 &&
      result.users.find((user) => user.success)
    ) {
      type = 'success'
      message = `${t('InsightArticle.ArticleSharedSuccessMessage', {
        name: result?.users[0]?.name,
      })}`
      header = 'InsightArticle.ArticleSharedSuccess'
    } else if (mailNotSendUsers.length === 0) {
      type = 'success'
      message = t('InsightArticle.ArticleSharedSuccessMessageMultiUser')
      header = 'InsightArticle.ArticleSharedSuccess'
    } else if (mailSendUsers.length && mailNotSendUsers.length) {
      type = 'warning'
      message = `${t('InsightArticle.ArticleSharedFailMessageMultiUser', {
        names: mailNotSendUsers.toString(),
      })}`
      header = `${t('InsightArticle.ArticleSharedWarning', {
        count: mailNotSendUsers.length,
      })}`
    } else if (
      (mailNotSendUsers.length && mailSendUsers.length === 0) ||
      !result
    ) {
      type = 'error'
      message = `${t('InsightArticle.ArticleSharedFailMessageMultiUser', {
        names: mailNotSendUsers.toString(),
      })}`
      header = `InsightArticle.ArticleSharedFail`
    }

    setAlertInfo({ message, type, header })
  }

  const handleShareArticleModalClosed = (
    result: ShareArticleModalResult | undefined,
  ) => {
    setOpenArticleModal(false)
    setAlertInfo({ header: '', type: '', message: '' })
    if (result?.users) {
      const mailSendUsers: Record<string, any> = []
      const mailNotSendUsers: Record<string, any> = []

      if (result?.users?.length) {
        result?.users.forEach((user) => {
          if (user.success) {
            mailSendUsers.push(user.name)
          } else {
            mailNotSendUsers.push(user.name)
          }
        })
      }
      openAlertMessage(result, mailNotSendUsers, mailSendUsers)
    }
  }

  const renderAlerts = () => {
    const onCloseAlert = () => {
      setAlertInfo({ ...alertInfo, type: '' })
      shareButtonRef.current?.focus()
    }
    const { header, message, type } = alertInfo

    return (
      <>
        <CustomSuccessAlert
          header={header || ''}
          message={message || ''}
          open={Boolean(type === 'success')}
          close={onCloseAlert}
        />
        <CustomErrorAlert
          header={header || ''}
          message={message || ''}
          close={onCloseAlert}
          open={Boolean(type === 'error')}
        />
        <CustomWarningAlert
          header={header || ''}
          message={message || ''}
          close={onCloseAlert}
          open={Boolean(type === 'warning')}
        />
      </>
    )
  }

  return (
    <Box sx={styles.articleContainer}>
      <ArticleHeader
        articleMetadata={articleMetadata}
        sharedArticleInfoData={sharedArticleInfoData}
      />
      {renderAlerts()}
      <Box sx={styles.articleHeaderContainer}>
        <Box
          data-test-id="main-article-section"
          sx={styles.mainArticleContainer}>
          <Box sx={styles.actionIconsContainer}>
            <>
              {shareEnabled && (
                <Tooltip
                  disableTouchListener
                  title={t('InsightArticle.ShareArticle') as string}>
                  <Button
                    id={`${articleMetadata?.ecpId}share`}
                    onClick={() => setOpenArticleModal(true)}
                    aria-label={t('InsightArticle.ShareArticle')}
                    aria-describedby="insightTitle"
                    aria-pressed={articleMetadata.favorite}
                    data-testid="ShareIconButton">
                    {isDesktop ? (
                      <ShareIcon
                        color={muiTheme.palette.common.black}
                        size="3.75rem"
                      />
                    ) : (
                      <ShareIcon
                        color={muiTheme.palette.common.black}
                        size="1.875rem"
                      />
                    )}
                  </Button>
                </Tooltip>
              )}
              <Tooltip
                disableTouchListener
                title={
                  articleMetadata?.favorite
                    ? (t('InsightArticle.RemoveFromFavorites') as string)
                    : (t('InsightArticle.AddToFavorites') as string)
                }>
                <Button
                  id={`${articleMetadata?.ecpId}favorites`}
                  onClick={() => handleFavoriteClick(articleMetadata)}
                  aria-label={
                    articleMetadata?.favorite
                      ? t('InsightArticle.RemoveFromFavorites')
                      : t('InsightArticle.AddToFavorites')
                  }
                  aria-describedby="insightTitle"
                  aria-pressed={articleMetadata.favorite}
                  data-testid={
                    articleMetadata?.favorite
                      ? 'Btn_Insight_Favorite_Remove'
                      : 'Btn_Insight_Favorite_Add'
                  }>
                  {isDesktop ? (
                    <Styled.BookmarkIconMainDesktop
                      icon={
                        articleMetadata?.favorite ? faBookmarkSolid : faBookmark
                      }
                      color={
                        articleMetadata.favorite
                          ? muiTheme.palette.secondary.main
                          : muiTheme.palette.common.black
                      }
                    />
                  ) : (
                    <Styled.BookmarkIconMainMobile
                      icon={
                        articleMetadata?.favorite ? faBookmarkSolid : faBookmark
                      }
                      color={
                        articleMetadata.favorite
                          ? muiTheme.palette.secondary.main
                          : muiTheme.palette.common.black
                      }
                    />
                  )}
                </Button>
              </Tooltip>
            </>
          </Box>
          <Box
            id="articleContent"
            className="article-content"
            sx={styles.contentContainer}
          />
          <Box
            display="flex"
            alignItems="center"
            gap="1.75rem"
            sx={styles.actionButtonsContainer}>
            <Button
              variant={articleMetadata.favorite ? 'outlined' : 'contained'}
              sx={styles.favoriteButton}
              aria-describedby="insightTitle"
              aria-live="polite"
              onClick={() => handleFavoriteClick(articleMetadata)}
              data-testid={
                articleMetadata?.favorite
                  ? 'Btn_Insight_AltFavorite_Remove'
                  : 'Btn_Insight_AltFavorite_Add'
              }>
              {articleMetadata.favorite
                ? t('InsightArticle.RemoveFromFavorites')
                : t('InsightArticle.AddToFavorites')}
              <Styled.BookmarkIcon
                icon={articleMetadata?.favorite ? faBookmarkSolid : faBookmark}
              />
            </Button>

            {shareEnabled && (
              <Button
                ref={shareButtonRef}
                variant="outlined"
                sx={styles.shareButton}
                aria-describedby="insightTitle"
                aria-live="polite"
                onClick={() => setOpenArticleModal(true)}
                data-testid="Btn_Insight_Share_Article">
                {t('InsightArticle.ShareArticle')}
                <Box sx={styles.shareIconContainer}>
                  <ShareIcon size="1.09375rem" />
                </Box>
              </Button>
            )}
          </Box>
        </Box>
        <Box
          data-test-id="article-right-side-additional-details"
          sx={styles.additionalDetailsContainer}>
          {isDesktop && <Box sx={styles.verticalDivider} />}
          <Box sx={styles.chipsContainer}>
            <Typography variant="h2" sx={styles.headingContainer}>
              {t('InsightArticle.Tags')}
            </Typography>
            <Box data-testid="Txt_Insight_ArticleTags">
              {articleMetadata.tags?.map((tag: string, index: number) => (
                <Chip
                  component="a"
                  role="link"
                  onClick={
                    isFeatureFlagEnabled('InsightArticle_ClickableTags')
                      ? () => onClickChip(tag, currentFilterOptions)
                      : undefined
                  }
                  key={tag}
                  sx={styles.chip}
                  label={
                    <OverFlowToolTip>
                      <Typography
                        display="inline"
                        component="span"
                        sx={styles.tag}>
                        {tag}
                      </Typography>
                    </OverFlowToolTip>
                  }
                  variant="outlined"
                  data-testid={`Lnk_Insight_ArticleTag_${index + 1}`}
                />
              ))}
            </Box>
          </Box>
        </Box>

        {articleMetadata?.contributors &&
        articleMetadata.contributors.length > 0 ? (
          <ArticleContributors contributors={articleMetadata.contributors} />
        ) : null}
      </Box>
      <ShareArticleModal
        articleId={articleMetadata?.ecpId}
        open={openArticleModal}
        onClose={(info: ShareArticleModalResult | undefined) =>
          handleShareArticleModalClosed(info)
        }
      />
    </Box>
  )
}

export default Article
