import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { Styles } from '../../../types'
import { formatCurrency } from '../../../rsmCoreComponents/utils/formatters'
import { Heading } from '../../typography'
import ArrowLeftIcon from '../../icons/ArrowLeftIcon'

const styles: Styles = {
  link: {
    display: 'block',
    textDecoration: 'underline',
    marginLeft: '0.5rem',
  },
  textButton: (theme) => ({
    color: theme.palette.secondary.main,
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    justifyContent: 'start',
    verticalAlign: 'top',
    padding: 0,
    marginBottom: '1rem',
  }),
  banner: (theme) => ({
    color: theme.palette.text.primary,
    fontFamily: 'Prelo-Book, sans-serif',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1.875rem',
    [theme.breakpoints.up('tablet')]: {
      marginTop: '-1rem',
    },
    [theme.breakpoints.only('desktop')]: {
      marginLeft: '6.5rem',
      marginRight: '6.5rem',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
    [theme.breakpoints.only('mobile')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
  }),
  bannerPayableAmount: (theme) => ({
    textAlign: 'center',
    [theme.breakpoints.only('mobile')]: {
      display: 'none',
    },
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    [theme.breakpoints.up('tablet')]: {
      marginLeft: '1.5rem',
    },
    [theme.breakpoints.only('tablet')]: {
      minWidth: '50%',
    },
  }),
  bannerMainContent: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
  },
  secondaryHeader: {
    textTransform: 'uppercase',
  },
  secondaryHeaderContainer: {
    float: 'right',
    textAlign: 'center',
    paddingRight: '1rem',
  },
}
interface InvoicesHeaderProps {
  amountHeaderId: string
  amountHeaderText: string
  amount: number
  backButtonId: string
  backButtonText: string
  primaryHeaderId: string
  primaryHeaderText: string
  primaryHeaderDescription: string
  handleNavigation: () => void
  buttonSlot?: React.ReactNode
}

const defaultProps = {
  buttonSlot: undefined,
}

const InvoicesHeader = ({
  amountHeaderId,
  amountHeaderText,
  amount,
  backButtonId,
  backButtonText,
  primaryHeaderId,
  primaryHeaderText,
  primaryHeaderDescription,
  handleNavigation,
  buttonSlot,
}: InvoicesHeaderProps) => (
  <Box sx={styles.banner}>
    <Box sx={styles.bannerMainContent}>
      <Button
        data-testid={`Btn_Invoicing_${backButtonId}`}
        onClick={handleNavigation}
        sx={styles.textButton}
        role="link">
        <ArrowLeftIcon />
        <Box component="span" sx={styles.link}>
          {backButtonText}
        </Box>
      </Button>
      <Heading
        variant="h1WithH2Styling"
        data-testid={`Hed_Invoicing_${primaryHeaderId}`}>
        {primaryHeaderText}
      </Heading>
      <Typography variant="body1" sx={{ marginTop: '1rem' }}>
        {primaryHeaderDescription}
      </Typography>
    </Box>
    <Box sx={styles.bannerPayableAmount}>
      <Box
        sx={{
          paddingBottom: '0.4375rem',
        }}>
        <Box sx={styles.secondaryHeaderContainer}>
          <Heading
            variant="h2WithH6Styling"
            data-testid={`Hed_Invoicing_${amountHeaderId}`}>
            <Box component="span" sx={styles.secondaryHeader}>
              {amountHeaderText}
            </Box>
          </Heading>
          <Heading variant="h2">{formatCurrency(amount)}</Heading>
        </Box>
      </Box>
      <Box>
        <Box sx={{ float: 'right' }} data-testid="buttonSlot">
          {buttonSlot}
        </Box>
      </Box>
    </Box>
  </Box>
)

InvoicesHeader.defaultProps = defaultProps

export default InvoicesHeader
