import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '..'

// Define Types used int he slice
export declare type SelectedAccountTypeState =
  | 'Unavailable'
  | 'Unselected'
  | 'New'
  | 'Existing'

// Define a type for the slice state
interface PaymentInfoState {
  selectedPaymentMethod: string
  selectedPayerId: string
  selectedBankAccount: number | undefined
  selectedCreditCard: number | undefined
  newPaymentMethod: boolean
  accountSelectedType: SelectedAccountTypeState
  doNotSave: boolean
  paymentMethodError: boolean
}

// Define the initial state using that type
const initialState: PaymentInfoState = {
  selectedPaymentMethod: '',
  selectedPayerId: '',
  selectedBankAccount: undefined,
  selectedCreditCard: undefined,
  newPaymentMethod: false,
  accountSelectedType: 'Unselected',
  doNotSave: false,
  paymentMethodError: false,
}

export const paymentInfoSlice = createSlice({
  name: 'paymentInfo',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setSelectedPaymentMethod: (state, { payload }) => {
      state.selectedPaymentMethod = payload
    },
    setSelectedPayerId: (state, { payload }) => {
      state.selectedPayerId = payload
    },
    setSelectedBankAccount: (state, { payload }) => {
      state.selectedBankAccount = payload
    },
    setSelectedCreditCard: (state, { payload }) => {
      state.selectedCreditCard = payload
    },
    setNewPaymentMethod: (state, { payload }) => {
      state.newPaymentMethod = payload
    },
    setAccountSelectedType: (state, { payload }) => {
      state.accountSelectedType = payload
    },
    setDoNotSave: (state, { payload }) => {
      state.doNotSave = payload
    },
    setPaymentMethodError: (state, { payload }) => {
      state.paymentMethodError = payload
    },
  },
})

export const { setSelectedPaymentMethod } = paymentInfoSlice.actions
export const getSelectedPaymentMethod = (state: RootState) =>
  state.paymentInfo.selectedPaymentMethod

export const { setSelectedPayerId } = paymentInfoSlice.actions
export const getSelectedPayerId = (state: RootState) =>
  state.paymentInfo.selectedPayerId

export const { setSelectedBankAccount } = paymentInfoSlice.actions
export const getSelectedBankAccount = (state: RootState) =>
  state.paymentInfo.selectedBankAccount

export const { setSelectedCreditCard } = paymentInfoSlice.actions
export const getSelectedCreditCard = (state: RootState) =>
  state.paymentInfo.selectedCreditCard

export const { setNewPaymentMethod } = paymentInfoSlice.actions
export const getNewPaymentMethod = (state: RootState) =>
  state.paymentInfo.newPaymentMethod

export const { setAccountSelectedType } = paymentInfoSlice.actions
export const getAccountSelectedType = (state: RootState) =>
  state.paymentInfo.accountSelectedType

export const { setDoNotSave } = paymentInfoSlice.actions
export const getDoNotSave = (state: RootState) => state.paymentInfo.doNotSave

export const { setPaymentMethodError } = paymentInfoSlice.actions
export const getPaymentMethodError = (state: RootState) =>
  state.paymentInfo.paymentMethodError
