import React, { useState } from 'react'
import { Box } from '@mui/material'
import { ArrowDownIcon, ArrowUpIcon } from '../icons'

interface SortableHeaderProps {
  columnName: string
  currentSortColumn: string
  sortDirection: string | undefined
  handleSort: (column: string, direction: 'asc' | 'desc') => void // Update handleSort function signature
}

const SortableHeader: React.FC<SortableHeaderProps> = ({
  columnName,
  currentSortColumn,
  sortDirection,
  handleSort,
  children,
}) => {
  const [isHovered, setIsHovered] = useState(false)

  // Function to toggle sort direction
  const toggleSortDirection = () => {
    handleSort(columnName, sortDirection === 'asc' ? 'desc' : 'asc') // Pass both column name and direction to handleSort
  }

  const handleMouseEnter = () => {
    if (columnName !== currentSortColumn) {
      setIsHovered(true)
    }
  }

  return (
    <Box
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => setIsHovered(false)}
      onClick={toggleSortDirection} // Call toggleSortDirection when clicked
      display="inline-flex"
      className="sortable-header">
      {children}
      {(currentSortColumn === columnName || isHovered) && (
        <Box>
          {currentSortColumn === columnName &&
            !isHovered &&
            (sortDirection === 'asc' ? <ArrowUpIcon /> : <ArrowDownIcon />)}
          {isHovered && <ArrowDownIcon />}
        </Box>
      )}
    </Box>
  )
}

export default SortableHeader
