import React from 'react'
import { Box, Select, Link, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Styles } from '../../../types'
import { PersonIcon } from '../../icons'
import { getUserInfo } from '../../../store/userInfo/userInfoSlice'
import { useAppSelector } from '../../../utils/hooks'
import { useGetUserContactPreferenceDetailsQuery } from '../../../store/userService'
import tokens from '../../../styles/tokens.json'

const styles: Styles = {
  cardContainer: (theme) => ({
    overflow: 'auto',
    background: theme.palette.common.white,
    border: `1px solid ${tokens.colors.rsmGray.disabled}`,
    padding: '1.5rem',
    fontFamily: 'prelo-book',
    fontWeight: 500,
    display: 'flex',
    flexGrow: 1,

    [theme.breakpoints.only('mobile')]: {
      width: '100%',
      flexWrap: 'wrap',
    },
    [theme.breakpoints.only('tablet')]: {
      maxWidth: '50.625rem',
    },
  }),
  userDetails: (theme) => ({
    fontWeight: '400',
    lineHeight: '1.5rem',
    color: theme.palette.text.primary,
    marginTop: '0.5rem',
    flexDirection: 'column',

    [theme.breakpoints.up('mobile')]: {
      lineHeight: '1.5rem',
    },
  }),
  userDetailContainer: (theme) => ({
    flex: '0 1 auto',

    [theme.breakpoints.up('tablet')]: {
      marginTop: '0.5rem',
    },
  }),
  personIcon: (theme) => ({
    flex: '0 1 auto',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingRight: '1.5rem',

    [theme.breakpoints.only('mobile')]: {
      paddingRight: '1rem',
    },
    [theme.breakpoints.up('tablet')]: {
      marginTop: '0.5rem',
    },
  }),
  companiesPayContainer: (theme) => ({
    display: 'block',
    width: '16.4375rem',
    [theme.breakpoints.up('tablet')]: {
      marginLeft: 'auto',
    },
    [theme.breakpoints.only('mobile')]: {
      marginTop: '1.5rem',
      width: '100%',
    },
  }),
  companiesSelect: (theme) => ({
    width: '16.4375rem',

    [theme.breakpoints.only('mobile')]: {
      width: '100%',
    },
  }),
  uploadLink: (theme) => ({
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    fontSize: '0.75rem',
    fontweight: 300,
    lineHeight: '1.0625rem',
  }),
  company: {
    fontWeight: '700',
    fontSize: '1rem',
    fontStyle: 'normal',
  },
  amountOwed: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  payContainer: {
    display: 'flex',
    marginTop: '1rem',
    justifyContent: 'space-between',
  },
}

interface InvoiceCompanyProps {
  userAgreementAccepted: boolean
}

const InvoiceCompany = ({ userAgreementAccepted }: InvoiceCompanyProps) => {
  const { t } = useTranslation()
  const userInfo = useAppSelector(getUserInfo)
  const userContact = useGetUserContactPreferenceDetailsQuery()
  const getClientBusinessNameValue = () => {
    let clientBusinessNameValue = userInfo.isEmployee
      ? t('RSMUS')
      : t('RSMUSClient')
    if (
      userContact?.data?.data.clientBusinessName &&
      userContact?.data?.data.clientBusinessName.length > 0
    ) {
      clientBusinessNameValue = userContact?.data?.data.clientBusinessName
    }
    return clientBusinessNameValue
  }

  return (
    <Box sx={styles.cardContainer} data-testid="Inv_UserInfo">
      <Box sx={styles.personIcon}>
        <PersonIcon />
        <Box>
          <Link href="#ref" sx={styles.uploadLink}>
            {t('Invoicing.UploadImage')}
          </Link>
        </Box>
      </Box>
      <Box sx={styles.userDetailContainer}>
        <Box sx={styles.company}>{getClientBusinessNameValue()}</Box>
        <Box sx={styles.userDetails}>
          <Box>{`${userInfo.firstName} ${userInfo.lastName}`}</Box>
          <Box>123 Main Street, Suite 200</Box>
          <Box>Denver, CO 12345</Box>
        </Box>
      </Box>
      <Box component="span" sx={styles.companiesPayContainer}>
        <Select native sx={styles.companiesSelect} defaultValue="All Companies">
          <option value="All Companies">All Companies</option>
          <option value="1">Test Company1</option>
          <option value="2">Test Company2</option>
        </Select>
        {userAgreementAccepted && (
          <Box sx={styles.payContainer}>
            <Box
              sx={{
                flex: '0 1 auto',
                display: 'flex',
                flexDirection: 'column',
              }}>
              <Box sx={styles.amountOwed}>
                {t('Invoicing.CurrentAmountOwed')}
              </Box>
              <Box
                sx={{
                  fontSize: '1.5rem',
                  lineHeight: '1.75rem',
                  fontWeight: 400,
                }}>
                $20000.00
              </Box>
            </Box>
            <Button variant="contained" onClick={() => {}}>
              {t('Invoicing.Pay')}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default InvoiceCompany
