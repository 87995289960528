import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TextField,
  Typography,
  SelectChangeEvent,
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, useForm, useFieldArray } from 'react-hook-form'
import { useDeviceType } from '../../../rsmCoreComponents/hooks/useDeviceType'
import CheckboxIcon from '../../../rsmCoreComponents/components/icons/CheckboxIcon'
import CheckboxOutlinedIcon from '../../../rsmCoreComponents/components/icons/CheckboxOutlinedIcon'
import { InvoiceData } from '../../../store/invoices/invoiceSearchService'
import tokens from '../../../styles/tokens.json'
import { ArrowDownIcon } from '../../icons'
import { Styles } from '../../../types'
import OverflowTooltip from '../../OverFlowToolTip'
import {
  getSelectedPaymentMethod,
  setSelectedPaymentMethod,
} from '../../../store/invoices/paymentInfoSlice'
import {
  setSelectedInvoices,
  getSelectedInvoices,
  setPayingInvoices,
  getPayingInvoices,
  setPayableAmount,
  getPayableAmount,
  setInvoicePayments,
  getInvoicePayments,
  setInvoicePayers,
} from '../../../store/invoices/invoiceSelectedInvoicesSlice'
import { formatCurrency } from '../../../rsmCoreComponents/utils/formatters'
import InvoicesHeader from './InvoicesHeader'
import useInvoicesSearch from './useInvoicesSearch'
import api from '../../../api'
import UnrelatedInvoicesDialog from './UnrelatedInvoicesDialog'
import { USER_PREF_INVOICING_IGNOREUNRELATED } from '../../../utils/constants/constants'

const styles: Styles = {
  tableContainer: (theme) => ({
    [theme.breakpoints.only('desktop')]: {
      paddingLeft: '6.5rem',
      paddingRight: '6.5rem',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
  }),
  invoicesPaymentTable: (theme) => ({
    // -------------------------------------------------------------------------
    // TODO: (Ideally move these styles to a RsmTable variant, if possible.)
    // -------------------------------------------------------------------------
    '&.MuiTable-root': {
      tableLayout: 'fixed',
      width: '100%',
      '& .MuiTableCell-root': {
        borderBottom: 'none',
      },
      '& th.MuiTableCell-root': {
        fontFamily: 'Prelo-Bold, sans-serif',
      },
      '& td.MuiTableCell-root': {
        fontFamily: 'Prelo-Book, sans-serif',
      },
      '& .MuiTableCell-head': {
        padding: '1rem',
        fontSize: '0.875rem',
        lineHeight: '1rem',
        textTransform: 'uppercase',
        verticalAlign: 'top',
      },
      '& tbody': {
        '& .MuiTableRow-root': {
          '&:nth-of-type(odd)': {
            backgroundColor: tokens.colors.rsmGray.accessibility,
          },
          '&:last-of-type()': {
            '& .MuiTableCell-root': {
              marginTop: '1rem',
              marginBottom: '1rem',
            },
          },
        },
      },
      '& .MuiTableCell-body, .MuiTableRow-root:not(:nth-last-of-type(2))>.MuiTableCell-footer':
        {
          padding: '1.5rem 1rem',
          fontSize: '1rem',
          lineHeight: '1.5rem',
        },
      '.MuiFormControlLabel-root': {
        margin: '0rem',
      },
      '.MuiCheckbox-root': {
        boxSizing: 'content-box',
        padding: '0.5rem',
      },
    },
    // -----------------------------------------------------------------------
    // Invoicing Payment Table-specific Styles
    // -----------------------------------------------------------------------
    '& .MuiTableRow-root:not(.MuiTableRow-footer)': {
      '& .MuiTableCell-root': {
        '&:nth-of-type(1)': {
          width: '3.5rem',
          padding: '0.5rem',
        },
        '&:nth-of-type(8)': {
          minWidth: '5.75rem',
        },
      },
    },
    '& .MuiTableCell-body': {
      '&:nth-of-type(2), &:nth-of-type(3), :nth-of-type(5), :nth-of-type(6), :nth-of-type(9)':
        {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      ':nth-of-type(6)': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    [theme.breakpoints.only('tablet')]: {
      '& .MuiTableCell-root:not(.MuiTableCell-footer)': {
        '&:nth-of-type(3)': {
          display: 'none',
        },
      },
    },
    [theme.breakpoints.only('mobile')]: {
      '& .MuiTableCell-root:not(.MuiTableCell-footer)': {
        '&:nth-of-type(2), &:nth-of-type(3), &:nth-of-type(7)': {
          display: 'none',
        },
      },
    },
  }),
  // -----------------------------------------------------------------------
  // TODO: Possible RSM Table Utility Styles
  // -----------------------------------------------------------------------
  date: {
    textTransform: 'uppercase',
  },
  tooltipText: {
    padding: '1rem',
  },
  tooltipIconButton: {
    width: '2.25rem',
    height: '2.25rem',
    marginLeft: 0,
    marginRight: 0,
  },
  statusTooltip: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  link: (theme) => ({
    display: 'block',
    textDecoration: 'underline',
    color: theme.palette.secondary.main,
    marginLeft: '0.5rem',
  }),
  banner: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '2.5rem',
    [theme.breakpoints.only('desktop')]: {
      marginLeft: '6.5rem',
      marginRight: '6.5rem',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
    [theme.breakpoints.only('mobile')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
  }),
  bannerPayableAmount: (theme) => ({
    textAlign: 'center',
    [theme.breakpoints.down('desktop')]: {
      display: 'none',
    },
  }),
  continue: (theme) => ({
    textAlign: 'center',
    paddingBottom: '2rem',
    [theme.breakpoints.only('desktop')]: {
      textAlign: 'right',
      marginRight: '6.5rem',
    },
  }),
  paymentTypeSelect: (theme) => ({
    width: '16.4375rem',
    [theme.breakpoints.only('mobile')]: {
      width: '100%',
    },
  }),
  paymentTypeSelectContainer: {
    marginTop: '2.5rem',
    marginBottom: '2.5rem',
  },
}

type InvoicePayments = {
  invoices: { invoice: InvoiceData; partialAmount: string }[]
}

const InvoicesPaymentTable = () => {
  const selectedInvoices = useSelector(getSelectedInvoices)
  const selectedPaymentMethod = useSelector(getSelectedPaymentMethod)
  const payingInvoices = useSelector(getPayingInvoices)
  const invoicePayments = useSelector(getInvoicePayments)
  const { isMobile, isTablet } = useDeviceType()
  const [unrelatedInvoicesDialogOpen, setUnrelatedInvoicesDialogOpen] =
    useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { handleSubmit, control, getValues, setValue, watch } =
    useForm<InvoicePayments>({
      defaultValues: {
        // If coming to the page the first time, show the invoices that the user selected on the invoices page
        // else if returning to the page, show the invoices that the user originally selected but with the payment amounts they entered
        // AND with any checkbox state changes that they made while on the invoice payment page.
        invoices: !invoicePayments?.length
          ? selectedInvoices.map((x) => ({
              invoice: x,
              partialAmount: x.openAmount?.toString() || '',
            }))
          : payingInvoices.map((x) => ({
              invoice: x as InvoiceData,
              partialAmount:
                invoicePayments
                  .find((ip) => ip.id === x.id)
                  ?.paidAmount?.toString() || '',
            })),
      },
    })
  const { fields } = useFieldArray({ control, name: 'invoices' })
  const watchFieldArray = watch('invoices')
  const controlledFields = fields.map((field, index) => ({
    ...field,
    ...watchFieldArray[index],
  }))
  const dispatch = useDispatch()
  const payableAmount = useSelector(getPayableAmount)
  const { changeSearchFilterAndSearch } = useInvoicesSearch()

  // Toggle the selection of an invoice
  const toggleInvoiceSelection = (invoice: InvoiceData, index: number) => {
    if (selectedInvoices.find((row) => row?.id === invoice.id)) {
      // Check if the invoice is already selected
      dispatch(
        setSelectedInvoices(
          selectedInvoices.filter((row) => row.id !== invoice.id),
        ),
      )
      setValue(`invoices.${index}.partialAmount`, '')
    } else {
      // If not selected, add it to both the selected invoices and paying invoices lists
      setValue(
        `invoices.${index}.partialAmount`,
        invoice.openAmount?.toString() || '',
      )
      dispatch(setSelectedInvoices([...selectedInvoices, invoice]))
    }
  }

  const handleBackToInvoices = () => {
    dispatch(setSelectedInvoices([]))
    dispatch(setPayingInvoices([]))
    dispatch(setInvoicePayments([]))
    dispatch(setPayableAmount(0))
    navigate('/invoicing/invoices')
  }

  const getHrColSpan = () => {
    if (isMobile) return 4
    if (isTablet) return 5
    return 6
  }

  const getTotalLeftColSpan = () => {
    if (isMobile) return 2
    if (isTablet) return 2
    return 2
  }

  const getTotalRightColSpan = () => {
    if (isMobile) return 1
    if (isTablet) return 2
    return 3
  }

  const paymentTypeChange = (e: SelectChangeEvent<string>) => {
    dispatch(setSelectedPaymentMethod(e.target.value))
  }

  const onPaymentChanged = (newValue: number, invoice: InvoiceData) => {
    if (newValue === 0) {
      dispatch(
        setSelectedInvoices(
          selectedInvoices.filter((row) => row.id !== invoice.id),
        ),
      )
    } else if (newValue > 0) {
      if (!selectedInvoices.some((row) => row.id === invoice.id)) {
        dispatch(setSelectedInvoices([...selectedInvoices, invoice]))
      }
    }
  }

  const calcPayableAmount = (newValue: number, index: number | null) =>
    dispatch(
      setPayableAmount(
        controlledFields.reduce((a, v, i) => {
          const partialAmount = index === i ? newValue : +v.partialAmount
          const payment =
            partialAmount > 0 ? partialAmount : v.invoice.openAmount
          const selected = selectedInvoices.some((x) => x.id === v.invoice.id)
          const amount = selected ? payment : 0
          // eslint-disable-next-line no-param-reassign, no-return-assign
          return (a += amount)
        }, 0),
      ),
    )

  useEffect(() => {
    // Clears out "search by keywords"
    changeSearchFilterAndSearch('')

    // Prevent user from accessing this page directly.
    if (selectedInvoices.length === 0) {
      navigate('/invoicing')
    }
  }, [])

  useEffect(() => {
    calcPayableAmount(0, null)
  }, [selectedInvoices.length])

  const continueToPaymentType = () => {
    navigate('/invoicing/invoices/payment-type')
  }

  const onSubmit = async () => {
    const values = getValues()
    const invoicesWithPayments = values.invoices
      .filter((x) => selectedInvoices.some((i) => i.id === x.invoice.id))
      .map((row) => ({
        ...row.invoice,
        paidAmount: +row.partialAmount || row.invoice.openAmount,
      }))
    const invoiceIds = invoicesWithPayments.map((x) => x.id)

    // Run in parallel to save time
    const [payerResult, ignoreUnrelatedResult] = await Promise.all([
      api.finance.invoice_GetInvoicePayers(invoiceIds),
      api.user.configuration_GetUserStorage(
        USER_PREF_INVOICING_IGNOREUNRELATED,
      ),
    ])

    const payers = payerResult?.data || []
    const ignoreUnrelated = ignoreUnrelatedResult?.data || false

    // Add payers to slice so they can be used in the payment type page.
    dispatch(setInvoicePayers(payers))
    dispatch(setInvoicePayments(invoicesWithPayments))

    if (!ignoreUnrelated) {
      // Check if the invoices are all related to the same customer
      const invoiceMasterClientIds = selectedInvoices.map(
        (x) =>
          payers.find((p) => p.payerId === x.customerNumber)?.masterClientId,
      )
      const areInvoicesUnrelated = invoiceMasterClientIds.some(
        (masterClientId) => masterClientId !== invoiceMasterClientIds[0],
      )

      if (areInvoicesUnrelated) {
        setUnrelatedInvoicesDialogOpen(true)
        return
      }
    }

    continueToPaymentType()
  }

  const handleUnrelatedInvoicesDialogClose = (proceed = false) => {
    setUnrelatedInvoicesDialogOpen(false)
    if (proceed) {
      continueToPaymentType()
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InvoicesHeader
        primaryHeaderId="PaySelected"
        primaryHeaderText={t('Invoicing.PaySelectedInvoices')}
        primaryHeaderDescription={t('Invoicing.PaySelectedHelperText')}
        backButtonId="BackToInvoices"
        backButtonText={t('Invoicing.BackToInvoices')}
        handleNavigation={handleBackToInvoices}
        amountHeaderId="PaySelected"
        amountHeaderText={t('Invoicing.TotalPayableAmount')}
        amount={payableAmount}
      />
      <TableContainer component={Paper} sx={styles.tableContainer}>
        <Box sx={styles.paymentTypeSelectContainer}>
          <Select
            id="SelectPaymentType"
            onChange={(e) => {
              paymentTypeChange(e)
            }}
            inputProps={{
              'aria-required': true,
              'aria-label': t('Invoicing.SelectPaymentType'),
            }}
            native
            IconComponent={ArrowDownIcon}
            sx={styles.paymentTypeSelect}
            value={selectedPaymentMethod}>
            <option value="Select Payment Type">
              {t('Invoicing.SelectPaymentType')}
            </option>
            <option value="ACH">{t('Invoicing.BankAccount')}</option>
            <option value="Credit card">{t('Invoicing.CreditCard')}</option>
          </Select>
        </Box>
        <Table
          // variant="rsm" // TODO: Can we create a variant of MuiTable in the MUI theme?
          id="invoicesPaymentTable"
          sx={styles.invoicesPaymentTable}>
          <Box component="caption" sx={visuallyHidden}>
            {t('Invoicing.Invoices')}
          </Box>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>{t('Invoicing.CustomerName')}</TableCell>
              <TableCell>{t('Invoicing.CustomerNumber')}</TableCell>
              <TableCell>{t('Invoicing.InvoiceNumber')}</TableCell>
              <TableCell>{t('Invoicing.InvoiceAmount')}</TableCell>
              <TableCell>{t('Invoicing.PaymentAmount')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {controlledFields.map((field, index) => (
              <TableRow key={`invoice-${field.id}`} sx={{ height: '7rem' }}>
                <TableCell>
                  <FormControlLabel
                    label=""
                    aria-label={`${t('Invoicing.SelectInvoice')} ${
                      field.invoice.invoiceNumber
                    }`}
                    htmlFor={`select-invoice-checkbox-${field.invoice.id}`}
                    control={
                      <Checkbox
                        id={`select-invoice-checkbox-${field.invoice.id}`}
                        icon={<CheckboxOutlinedIcon />}
                        checkedIcon={<CheckboxIcon />}
                        checked={selectedInvoices.some(
                          (row) => row.id === field.invoice.id,
                        )}
                        disableRipple
                        onClick={() =>
                          toggleInvoiceSelection(field.invoice, index)
                        }
                      />
                    }
                  />
                </TableCell>
                <TableCell>
                  <OverflowTooltip>
                    {field.invoice.customerName}
                  </OverflowTooltip>
                </TableCell>
                <TableCell>
                  <OverflowTooltip>
                    {field.invoice.customerNumber}
                  </OverflowTooltip>
                </TableCell>
                <TableCell>
                  {/* <OverflowTooltip> */}
                  {field.invoice.invoiceNumber}
                  {/* </OverflowTooltip> */}
                </TableCell>
                <TableCell>
                  <OverflowTooltip>
                    {formatCurrency(field.invoice.openAmount)}
                  </OverflowTooltip>
                </TableCell>
                <TableCell>
                  <Controller
                    name={`invoices.${index}.partialAmount`}
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, ref },
                      fieldState: { error },
                    }) => (
                      <TextField
                        key={`txt-${field.invoice.id}`}
                        value={value}
                        onChange={(event) => {
                          onChange(event)
                          onPaymentChanged(+event.target.value, field.invoice)
                          calcPayableAmount(+event.target.value, index)
                        }}
                        onBlur={(event) => {
                          onBlur()
                          calcPayableAmount(+event.target.value, index)
                        }}
                        error={!!error}
                        inputRef={ref}
                        inputProps={{
                          'aria-label': t(
                            'Invoicing.PartialPaymentForInvoice',
                            {
                              invoiceNumber: field.invoice.invoiceNumber,
                            },
                          ),
                        }}
                        helperText={
                          error ? (
                            <OverflowTooltip>
                              <Typography
                                sx={(theme) => ({
                                  display: 'inline',
                                  float: 'left',
                                  fontFamily: 'Prelo-Book, sans-serif',
                                  fontSize: '0.875rem',
                                  fontWeight: 400,
                                  color: theme.palette.error.main,
                                  paddingTop: '0rem',
                                })}>
                                {error?.message}
                              </Typography>
                            </OverflowTooltip>
                          ) : (
                            ''
                          )
                        }
                      />
                    )}
                    rules={{
                      validate: (value) => {
                        // eslint-disable-next-line prefer-destructuring
                        const openAmount = field.invoice.openAmount
                        const isValidInput = /^(0|[1-9]\d*)(\.\d+)?$/.test(
                          value.toString(),
                        ) // Only positivie, decimal numbers
                        const isValidCurrency = /^\d*\.?\d{0,2}$/.test(
                          value.toString(),
                        ) // no more than two decimal points
                        const isLessThanMaxAmount =
                          parseFloat(value) <= openAmount // less than the open invoice amount

                        if (value.length > 0 && !isValidInput)
                          return t('ValidInput').toString()
                        if (!isValidCurrency)
                          return t('ValidCurrency').toString()
                        if (value.length > 0 && !isLessThanMaxAmount)
                          return t(
                            'Invoicing.ErrorMessages.MustBeLessThanOpenAmount',
                          ).toString()
                        return true
                      },
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow key="separator">
              <TableCell colSpan={getHrColSpan()} sx={{ padding: 0 }}>
                <hr aria-hidden />
              </TableCell>
            </TableRow>
            <TableRow key="total">
              <TableCell colSpan={getTotalLeftColSpan()}>
                {t('Invoicing.SelectedOfTotalInvoicesSelected', {
                  selected: selectedInvoices?.length || 0,
                  total: payingInvoices?.length || 0,
                })}
              </TableCell>
              <TableCell
                colSpan={getTotalRightColSpan()}
                sx={{ textAlign: 'right' }}>
                <Box component="span" sx={{ fontWeight: 'bold' }}>
                  {t('Invoicing.TotalNetPayableAmount')}:
                </Box>
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>
                {' '}
                {formatCurrency(payableAmount)}{' '}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Box sx={styles.continue}>
        <Button
          sx={{ marginTop: '2rem', textAlign: 'right' }}
          type="submit"
          color="primary"
          variant="contained"
          disabled={
            selectedPaymentMethod === 'Select Payment Type' ||
            selectedPaymentMethod === '' ||
            selectedInvoices.length === 0
          }
          data-testid="Btn_Invoicing_ContinueToPayment">
          {t('Invoicing.ContinueToPayment')}
        </Button>
      </Box>
      <UnrelatedInvoicesDialog
        open={unrelatedInvoicesDialogOpen}
        onAccept={() => handleUnrelatedInvoicesDialogClose(true)}
        onClose={() => setUnrelatedInvoicesDialogOpen(false)}
      />
    </form>
  )
}

export default InvoicesPaymentTable
