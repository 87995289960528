/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  DocumentRequestSummaryModel,
  IApiResponseOfIEnumerableOfDocumentRequestSummaryModel,
} from '@rsmus/ecp-documentrequestservice-legacy'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import GetClientDocumentRequestsSummary from '../../../api/documentRequest/getClientSummary'
import GetEmployeeDocumentRequestsSummary from '../../../api/documentRequest/getEmployeeSummary'
import SectionHeader from '../../layouts/SectionHeader'
import RequestSummary from '../../domain/Engagements/RequestSummary'
import { useAppSelector } from '../../../utils/hooks'
import { getUserInfo } from '../../../store/userInfo/userInfoSlice'
import PieChartParent from '../../../rsmCoreComponents/visualizations/pieChart/PieChartParent'
import {
  calculateRequestSummaryData,
  RequestSummaryProps,
  calculateStatusBreakdownData,
} from './EngagementVisualizations.service'
import PieChartProps from '../../../rsmCoreComponents/visualizations/pieChart/PieChartProps'
import EmptyStatusBreakdown from './EmptyStatusBreakdown'
import Spinner from '../../forms/Spinner/Spinner'
import { Styles } from '../../../types'
import BackButton from '../../../rsmCoreComponents/components/BackButton/BackButton'
import { publish } from '../../../utils/helpers/PubSub.service'

const styles: Styles = {
  container: (theme) => ({
    [theme.breakpoints.only('desktop')]: {
      display: 'flex',
      paddingLeft: '6.5rem',
      paddingRight: '6.5rem',
    },
    [theme.breakpoints.only('mobile')]: {
      display: 'block',
    },
  }),
  requestSummarySection: (theme) => ({
    backgroundColor: 'white',
    [theme.breakpoints.only('desktop')]: {
      width: '66.67%',
    },
    [theme.breakpoints.only('mobile')]: {
      width: '100%',
    },
    paddingTop: '2.5rem',
    paddingBottom: '2.5rem',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
  }),
  requestTaskCompletionSection: (theme) => ({
    backgroundColor: 'white',
    [theme.breakpoints.only('desktop')]: {
      width: '33.34%',
      marginLeft: '2rem',
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem',
      marginTop: '0rem',
    },
    [theme.breakpoints.only('mobile')]: {
      width: '100%',
      marginLeft: '0rem',
      paddingLeft: '1.125rem',
      paddingRight: '1.125rem',
      marginTop: '2rem',
    },
    [theme.breakpoints.only('tablet')]: {
      width: '100%',
    },
    paddingTop: '2.5rem',
    paddingBottom: '2.5rem',
  }),
}

interface EngagementSummarySectionsProps {
  engagementId: string | undefined
}

const pieChartId = 'EngSummaryPie'

const emptyRequestSummaryProps: RequestSummaryProps = {
  all: {
    name: undefined,
    total: null,
    overdueRequests: null,
    priorityOutstandingRequests: null,
    followUpRequests: null,
  },
  userSubset: {
    name: undefined,
    total: null,
    overdueRequests: null,
    priorityOutstandingRequests: null,
    followUpRequests: null,
  },
}

const emptyPieChartProps: PieChartProps = {
  chartId: '0',
  chartItems: [],
  chartTitle: 'No data yet',
}

const EngagementVisualizations = ({
  engagementId,
}: EngagementSummarySectionsProps) => {
  const unauthorizedEngagementTopic = 'unauthorizedEngagement'
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [chartProps, setChartProps] = useState<PieChartProps>()
  const [requestSummaryData, setRequestSummaryData] = useState(
    emptyRequestSummaryProps,
  )
  const userInfo = useSelector(getUserInfo)
  const { t } = useTranslation()

  const isEmployee = useAppSelector(
    (state) => state.userInfo.UserInfo.isEmployee,
  )

  const getStatusBreakdownData = useCallback(
    (data: DocumentRequestSummaryModel[]) => {
      if (data.length > 0) {
        setChartProps(calculateStatusBreakdownData(t, data, pieChartId))
      } else {
        setChartProps(emptyPieChartProps)
      }
    },
    [setChartProps, calculateStatusBreakdownData, t],
  )

  const processRequestsummaryData = useCallback(
    (
      response: IApiResponseOfIEnumerableOfDocumentRequestSummaryModel,
      user: string,
    ) => {
      const data: DocumentRequestSummaryModel[] = response.data
        ? response.data
        : []
      setRequestSummaryData(calculateRequestSummaryData(data, user))
      getStatusBreakdownData(data)
      setIsLoading(false)
    },
    [
      setRequestSummaryData,
      calculateRequestSummaryData,
      getStatusBreakdownData,
    ],
  )

  const getEngagementSummaryData = useCallback(
    (id: string | undefined, employee: boolean, user: string) => {
      if (!id) {
        return
      }
      setIsLoading(true)
      if (employee) {
        GetEmployeeDocumentRequestsSummary(id)
          .then((response) => {
            processRequestsummaryData(response, user)
          })
          .catch((reason) => {
            if (reason?.statusCode === 403 || reason?.statusCode === 404) {
              publish(unauthorizedEngagementTopic, id)
            }
          })
      } else {
        GetClientDocumentRequestsSummary(id)
          .then((response) => {
            processRequestsummaryData(response, user)
          })
          .catch((reason) => {
            if (reason?.statusCode === 403 || reason?.statusCode === 404) {
              publish(unauthorizedEngagementTopic, id)
            }
          })
      }
    },
    [processRequestsummaryData, setIsLoading],
  )

  useEffect(() => {
    if (
      JSON.stringify(userInfo) !== '{}' &&
      chartProps === undefined &&
      engagementId !== undefined
    ) {
      getEngagementSummaryData(engagementId, isEmployee, userInfo.userId ?? '')
    }
  }, [userInfo?.userId]) // only if userid changed not on multiple userinfo fetched if any.

  if (isLoading) {
    return <Spinner open={isLoading} />
  }

  return (
    <Box>
      <Box sx={styles.container}>
        <Box>
          <BackButton linkToRedirect="/engagements">
            {t('EngagementDashboard.ViewAllEngagements')}
          </BackButton>
        </Box>
      </Box>
      <Box sx={styles.container}>
        <Box component="section" sx={styles.requestSummarySection}>
          <SectionHeader
            title={t('EngagementDashboard.RequestSummaryHeader')}
          />
          <RequestSummary
            all={requestSummaryData.all}
            userSubset={requestSummaryData.userSubset}
          />
        </Box>

        <Box component="section" sx={styles.requestTaskCompletionSection}>
          <SectionHeader
            title={t('EngagementDashboard.RequestTaskCompletionHeader')}
          />
          <Box
            sx={{
              display: 'flex',
              marginTop: '.0625rem',
              marginBottom: '.0625rem',
            }}>
            {chartProps?.chartItems?.length === 0 ? (
              <EmptyStatusBreakdown />
            ) : (
              <PieChartParent pProps={chartProps} />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default EngagementVisualizations
