/* eslint-disable react/destructuring-assignment */
import * as React from 'react'
import { SVGProps } from 'react'

const VisaCardIcon = (props: SVGProps<SVGSVGElement>) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    viewBox="0 0 1024 768">
    <title>Visa</title>
    <g transform="matrix(0.3025522 0 0 0.3025522 -0 69.67095)">
      <path
        d="M1461.26 739.84L1209.89 1339.5801L1045.89 1339.5801L922.19 860.9601C 914.68 831.4801 908.15 820.68005 885.31 808.2601C 848.02 788.0301 786.44 769.05005 732.26 757.2701L732.26 757.2701L735.94 739.8401L999.93 739.8401C 1033.58 739.8401 1063.83 762.2401 1071.47 800.9901L1071.47 800.9901L1136.7999 1148.0302L1298.2599 739.83014L1461.26 739.83014L1461.26 739.84zM2103.84 1143.77C 2104.5 985.48004 1884.9601 976.76 1886.4701 906.05005C 1886.9401 884.53 1907.43 861.65 1952.28 855.81006C 1974.51 852.9001 2035.76 850.68005 2105.23 882.6501L2105.23 882.6501L2132.48 755.4701C 2095.15 741.9201 2047.12 728.88007 1987.36 728.88007C 1834.01 728.88007 1726.09 810.4001 1725.1799 927.13007C 1724.19 1013.4701 1802.21 1061.65 1860.99 1090.3401C 1921.46 1119.7201 1941.75 1138.6001 1941.52 1164.8801C 1941.09 1205.1101 1893.29 1222.8701 1848.62 1223.5701C 1770.64 1224.77 1725.39 1202.4701 1689.32 1185.7001L1689.32 1185.7001L1661.2 1317.0901C 1697.45 1333.7201 1764.36 1348.2301 1833.73 1348.9601C 1996.72 1348.96 2103.34 1268.45 2103.84 1143.77zM2508.78 1339.58L2652.27 1339.58L2527.02 739.83997L2394.58 739.83997C 2364.8 739.83997 2339.6802 757.18 2328.56 783.83997L2328.56 783.83997L2095.75 1339.58L2258.66 1339.58L2291 1250L2490.05 1250L2508.78 1339.58zM2335.67 1127.08L2417.3298 901.89996L2464.3298 1127.08L2335.67 1127.08zM1682.93 739.84L1554.64 1339.5801L1399.5 1339.5801L1527.84 739.8401L1682.93 739.8401z"
        stroke="none"
        fill="#1434CB"
        fillRule="nonzero"
      />
    </g>
  </svg>
)
export default VisaCardIcon
