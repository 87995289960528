import { format } from 'date-fns'

export const formatDate = (date: string | null): string =>
  format(new Date(date as string), 'MMM d, yyyy')

export const formatCurrency = (amount: number, currency?: string): string =>
  new Intl.NumberFormat('default', {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency: currency ?? 'USD',
  }).format(amount)

export const formatCurrencyNoDecimal = (
  amount?: number,
  currency?: string,
): string => {
  if (!amount) return '0'

  return new Intl.NumberFormat('default', {
    style: 'currency',
    maximumFractionDigits: 0,
    currency: currency ?? 'USD',
  }).format(amount)
}
