import React, { useCallback, useState } from 'react'
import { Box, Button, Menu, MenuItem } from '@mui/material'
import tokens from '../../../styles/tokens.json'
import { Styles } from '../../../types'
import EllipsisIcon from '../../icons/EllipsisIcon'
import { InvoiceData } from '../../../store/invoices/invoiceSearchService'
import { PaymentData } from '../../../store/invoices/paymentSearchService'
import CustomErrorAlert from '../../forms/Alert/CustomErrorAlert'

const styles: Styles = {
  actions: {
    position: 'relative',
  },
  actionsButton: {
    display: 'flex',
    justifyContent: 'center',
    boxSizing: 'content-box',
    margin: '0rem',
    padding: '0.5rem',
    minWidth: 'unset',
    width: '1.5rem',
    height: '1.5rem',
  },
  actionsMenu: {
    '& .MuiPaper-elevation': {
      marginTop: '2.25rem',
      boxShadow: '0rem 0rem 0.5rem rgba(0, 0, 0, 0.125)',
    },
    '& .MuiMenu-list': {
      border: `0.0625rem solid ${tokens.colors.halfWhite}`,
      borderBottom: 'none',
      width: 'fit-content',
    },
    '& .MuiMenuItem-root': {
      borderBottom: `0.0625rem solid ${tokens.colors.halfWhite}`,
    },
  },
}

export interface ActionMenuItem<T> {
  label: string
  handleAction?: (data: T) => void
  handleDownloadAction?: (
    data: T,
    isSuccess: React.Dispatch<React.SetStateAction<boolean>>,
    isNotFound: React.Dispatch<React.SetStateAction<boolean>>,
    open: React.Dispatch<React.SetStateAction<boolean>>,
  ) => void
}

interface ActionMenuProps<T extends InvoiceData | PaymentData> {
  actionButtonDescribedBy: string
  actionButtonAriaControls: string
  id: string
  data: T
  errorHeader?: string
  errorMessage?: string
  errorMessageForNotFound?: string
  menuItems: ActionMenuItem<T>[]
}

const defaultProps = {
  errorHeader: '',
  errorMessage: '',
  errorMessageForNotFound: '',
}

const ActionMenu = <T extends InvoiceData | PaymentData>({
  actionButtonDescribedBy,
  actionButtonAriaControls,
  id,
  data,
  menuItems,
  errorHeader,
  errorMessage,
  errorMessageForNotFound,
}: ActionMenuProps<T>) => {
  const [actionsMenuAnchorEl, setActionsMenuAnchorEl] =
    useState<null | HTMLElement>(null)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isNotFound, setIsNotFound] = useState(false)
  const [open, setOpen] = useState(false)
  const isActionsMenuOpen = Boolean(actionsMenuAnchorEl)

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  return (
    <Box sx={styles.actions}>
      <Button
        sx={styles.actionsButton}
        id={`menu-button-${id}`}
        data-testid={`menu-button-${id}`}
        aria-label="Actions"
        aria-describedby={actionButtonDescribedBy}
        aria-controls={isActionsMenuOpen ? actionButtonAriaControls : undefined}
        aria-haspopup="true"
        aria-expanded={isActionsMenuOpen ? 'true' : undefined}
        disableRipple
        onClick={(event) => setActionsMenuAnchorEl(event.currentTarget)}>
        <EllipsisIcon />
      </Button>
      <Menu
        sx={styles.actionsMenu}
        id={`basic-menu-${id}`}
        data-testid={`basic-menu-${id}`}
        anchorEl={actionsMenuAnchorEl}
        open={isActionsMenuOpen}
        onClose={() => setActionsMenuAnchorEl(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        MenuListProps={{
          'aria-label': `menu-button-${id}`,
        }}>
        {menuItems.map((item) => (
          <MenuItem
            key={`${item.label}MenuItem${id}`}
            data-testid={`${item.label}MenuItem${id}`}
            onClick={() => {
              if (
                item.label.toLowerCase() === 'download' &&
                item.handleDownloadAction
              ) {
                item.handleDownloadAction(
                  data,
                  setIsSuccess,
                  setIsNotFound,
                  setOpen,
                )
              } else if (item.handleAction) {
                item.handleAction(data)
              }
              setActionsMenuAnchorEl(null)
            }}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
      <Box data-testid="errorAlert">
        {!isSuccess && (
          <CustomErrorAlert
            header={errorHeader}
            open={open}
            close={handleClose}
            message={isNotFound ? errorMessageForNotFound : errorMessage}
          />
        )}
      </Box>
    </Box>
  )
}

ActionMenu.defaultProps = defaultProps

export default ActionMenu
