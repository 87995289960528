import React from 'react'

const PenIcon = () => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.3572 3.64195L16.3578 8.64282L5.49911 19.502L1.04063 19.9942C0.443772 20.0602 -0.0605117 19.5555 0.00589283 18.9586L0.501974 14.4968L11.3572 3.64195ZM19.4507 2.89741L17.1027 0.549325C16.3703 -0.183108 15.1825 -0.183108 14.45 0.549325L12.2411 2.75835L17.2418 7.75921L19.4507 5.55019C20.1831 4.81736 20.1831 3.62985 19.4507 2.89741Z"
        fill="#515356"
      />
    </svg>
  )

export default PenIcon
