import { CheckCircle } from '@mui/icons-material'
import { Box, Button, Theme } from '@mui/material'
import React, { useCallback } from 'react'
import { Styles } from '../../../types'
import EmptyBoxIcon from '../../icons/EmptyBoxIcon'
import { Interest } from '../types'

const styles: Styles = {
  container: {
    margin: '0.375rem',
  },
  check: (theme) => ({
    borderRadius: '50%',
    width: '1.5rem',
    height: '1.5rem',
    background:
      'radial-gradient(circle, rgba(255 255 255 / 1) 50%, rgba(255 255 255 / 0) 50%)',
    position: 'absolute',
    top: '-0.5rem',
    right: '-0.5rem',
    zIndex: 1,
    color: theme.palette.secondary.main,
    [theme.breakpoints.up('tablet')]: {
      top: '0.375rem',
      right: '0.375rem',
    },
    '& .MuiSvgIcon-root': {
      display: 'block',
    },
  }),
  icon: (theme) => ({
    [theme.breakpoints.down('tablet')]: {
      display: 'none',
    },
  }),
  name: (theme) => ({
    fontSize: '0.75rem',
    lineHeight: '1rem',
    letterSpacing: '0.0625rem',
    textTransform: 'uppercase',
    [theme.breakpoints.up('tablet')]: {
      marginTop: '0.75rem',
    },
  }),
}

interface InterestTileProps {
  checked?: boolean
  index: number
  interest: Interest
  onClick: (check: boolean, interest: Interest) => void
}

const defaultProps = {
  checked: false,
}

const InterestTile = ({
  checked,
  index,
  interest,
  onClick,
}: InterestTileProps) => {
  const computedStyles = {
    button: useCallback(
      (theme: Theme) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        borderColor: checked ? theme.palette.primary.main : 'black',
        borderRadius: 0,
        borderStyle: 'solid',
        borderWidth: checked ? '0.125rem' : '0.0625rem',
        padding: '0.5rem',
        width: '10rem',
        height: '4rem',
        textAlign: 'center',
        [theme.breakpoints.up('tablet')]: {
          height: '10rem',
        },
      }),
      [checked],
    ),
  }

  return (
    <Box component="li" sx={styles.container}>
      <Button
        sx={(theme) => computedStyles.button(theme)}
        role="checkbox"
        aria-checked={checked}
        data-testid={`Btn_Interest_${index}`}
        onClick={() => onClick(!checked, interest)}>
        {checked && (
          <Box sx={styles.check}>
            <CheckCircle />
          </Box>
        )}
        <Box sx={styles.icon}>
          {interest.icon ? interest.icon : <EmptyBoxIcon />}
        </Box>
        <Box sx={styles.name}>{interest.name}</Box>
      </Button>
    </Box>
  )
}

InterestTile.defaultProps = defaultProps

export default InterestTile
