/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PaymentSearchFilter } from '@rsmus/ecp-financeservice'
import {
  useGetPaymentSearchFiltersQuery,
  useUpdatePaymentSearchResultsMutation,
} from '../../../store/invoices/paymentSearchService'
import {
  clearAllFilters,
  clearCategoryFilters,
  getPaymentSelectedFilters,
  PaymentSelectedFiltersState,
  setPageSize,
  toNextPage,
  toPreviousPage,
  updateFilterValue,
  amountRangeFilterMax,
  updateSearch as updateSearchFilter,
  toPageNumber,
  getPageInfo,
  updateSorting,
} from '../../../store/invoices/paymentSelectedFiltersSlice'
import { setSelectedPayments } from '../../../store/invoices/paymentSelectedPaymentsSlice'
import { RsmPaginationPageSizes } from '../../../rsmCoreComponents/components/RsmPagination/RsmPagination'

const transformFilters = (
  selectedFilters: PaymentSelectedFiltersState,
): PaymentSearchFilter =>
  ({
    pageNumber: selectedFilters.pageNumber,
    pageSize: selectedFilters.pageSize,
    searchString: selectedFilters.searchString,
    paymentStatus: selectedFilters.paymentStatus,
    paymentStartDate: selectedFilters.paymentStartDate,
    paymentEndDate: selectedFilters.paymentEndDate,
    paymentAmountFrom: selectedFilters.paymentAmountFrom,
    paymentAmountTo:
      selectedFilters.paymentAmountTo === amountRangeFilterMax
        ? 0
        : selectedFilters.paymentAmountTo,
    currency: selectedFilters.currency,
    sortBy: selectedFilters.sortby,
    IsSortAscending: selectedFilters.IsSortAscending,
  } as PaymentSearchFilter)

// this HOOK should have all ACTIONS related to payments search page
const usePaymentsSearch = () => {
  const pageInfo = useSelector(getPageInfo)
  const selectedFilters = useSelector(getPaymentSelectedFilters)
  const [isSearchPending, setIsSearchPending] = useState<boolean>(false)
  const dispatch = useDispatch()
  const {
    isSuccess: filterIsSuccess,
    isError: filterIsError,
    data: filterData,
    error: filterError,
  } = useGetPaymentSearchFiltersQuery()

  const [
    updateSearch,
    {
      isLoading: paymentsIsLoading,
      isError: paymentsIsError,
      data: paymentsData,
      error: paymentsError,
    },
  ] = useUpdatePaymentSearchResultsMutation()

  const submitSearch = useCallback(async () => {
    if (isSearchPending && filterIsSuccess) {
      const filters = transformFilters(selectedFilters)
      await updateSearch(filters).unwrap()
      setIsSearchPending(false)
    }
  }, [
    isSearchPending,
    filterIsSuccess,
    transformFilters,
    selectedFilters,
    setIsSearchPending,
    updateSearch,
  ])

  const requestSearch = useCallback(
    () => setIsSearchPending(true),
    [setIsSearchPending],
  )

  const setPaymentPageSize = useCallback(
    (pageSize: RsmPaginationPageSizes) => {
      dispatch(setPageSize(pageSize))
      requestSearch()
    },
    [dispatch, setPageSize, requestSearch],
  )

  const toPage = useCallback(
    (pageNumber: number) => {
      dispatch(toPageNumber(pageNumber))
      requestSearch()
    },
    [dispatch, toPageNumber, requestSearch],
  )

  const nextPage = useCallback(() => {
    dispatch(toNextPage())
    requestSearch()
  }, [dispatch, toNextPage, requestSearch])

  const previousPage = useCallback(() => {
    dispatch(toPreviousPage())
    requestSearch()
  }, [dispatch, toPreviousPage, requestSearch])

  const SortPaymentHistoryData = useCallback(
    (sortBy: string, isAscending: 'asc' | 'desc') => {
      // Update the state or dispatch an action to handle sorting
      const ascending = isAscending === 'asc'
      dispatch(updateSorting({ sortBy, isAscending: ascending }))
      requestSearch()
    },
    [dispatch, requestSearch],
  )

  const changeFilterValue = useCallback(
    (filterValue: string) => {
      dispatch(updateFilterValue(filterValue))
    },
    [dispatch, updateFilterValue],
  )

  const clearFilters = useCallback(() => {
    dispatch(setSelectedPayments([]))
    dispatch(clearAllFilters())
    requestSearch()
  }, [dispatch, setSelectedPayments, clearAllFilters, requestSearch])

  const clearCategoryFilter = useCallback(
    (categoryValue: string) => {
      dispatch(setSelectedPayments([]))
      dispatch(clearCategoryFilters(categoryValue))
      requestSearch()
    },
    [dispatch, setSelectedPayments, clearCategoryFilters, requestSearch],
  )

  const changeFilterAndSearch = useCallback(
    (filterValue: string) => {
      dispatch(setSelectedPayments([]))
      changeFilterValue(filterValue)
      requestSearch()
    },
    [dispatch, setSelectedPayments, changeFilterValue, requestSearch],
  )

  const changeSearchFilterAndSearch = useCallback(
    (searchString: string) => {
      dispatch(updateSearchFilter(searchString))
      requestSearch()
    },
    [updateSearchFilter, requestSearch, dispatch],
  )

  useEffect(() => {
    submitSearch()
  }, [isSearchPending, filterIsSuccess])

  return {
    filterIsSuccess,
    filterIsError,
    filterData,
    filterError,
    paymentsIsLoading,
    paymentsIsError,
    paymentsData,
    paymentsError,
    nextPage,
    setPaymentPageSize,
    toPage,
    pageInfo,
    previousPage,
    clearFilters,
    clearCategoryFilter,
    changeFilterValue,
    requestSearch,
    changeFilterAndSearch,
    changeSearchFilterAndSearch,
    SortPaymentHistoryData,
  }
}

export default usePaymentsSearch
