import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import { FileResponse } from '@rsmus/ecp-financeservice'
import { PaymentData } from '../../../store/invoices/paymentSearchService'
import {
  setSelectedInvoices,
  setPayingInvoices,
} from '../../../store/invoices/invoiceSelectedInvoicesSlice'
import downloadFileStream from '../../../rsmCoreComponents/utils/fileStreamUtils'
import api from '../../../api'
import { InvoiceData } from '../../../store/invoices/invoiceSearchService'

// this HOOK should have all ACTIONS related to action menu
const useActionMenu = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handlePayInvoiceAction = (invoice: InvoiceData) => {
    dispatch(setSelectedInvoices([invoice]))
    dispatch(setPayingInvoices([invoice]))
    navigate('/invoicing/invoices/pay-invoices')
  }

  const handleDownloadInvoiceAction = useCallback(
    async (
      invoice: InvoiceData,
      setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>,
      setIsNotFound: React.Dispatch<React.SetStateAction<boolean>>,
      setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    ) => {
      try {
        setIsNotFound(false)
        const response: FileResponse =
          await api.finance.invoiceDocument_DownloadInvoice(invoice.id)

        if (response.data?.size > 0) {
          downloadFileStream(response)
          setIsSuccess(true)
        } else {
          if (response?.status === 404) {
            setIsNotFound(true)
          }
          setIsSuccess(false)
          setOpen(true)
        }
      } catch (e: any) {
        if (e?.status === 404) {
          setIsNotFound(true)
        }
        setIsSuccess(false)
        setOpen(true)
      }
    },
    [api.finance.invoiceDocument_DownloadInvoice],
  )

  const handleDownloadReceipt = useCallback(
    async (payment: PaymentData) => {
      const response: FileResponse =
        await api.finance.invoiceDocument_DownloadPaymentReceipt(
          payment.transactionId,
        )
      downloadFileStream(response)
    },
    [api.finance.invoiceDocument_DownloadPaymentReceipt, downloadFileStream],
  )

  const handleEditPayment = (payment: PaymentData) => {
    // eslint-disable-next-line no-alert
    window.alert(
      `${t('Invoicing.EditPayment')} Transaction ID ${payment.transactionId}`,
    )
  }

  const handleCancelPayment = (payment: PaymentData) => {
    // eslint-disable-next-line no-alert
    window.alert(
      `${t('Invoicing.CancelPayment')} Transaction ID ${payment.transactionId}`,
    )
  }

  return {
    handlePayInvoiceAction,
    handleDownloadInvoiceAction,
    handleDownloadReceipt,
    handleEditPayment,
    handleCancelPayment,
  }
}

export default useActionMenu
