import { Box, Typography } from '@mui/material'
import { FormikProps } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormValues } from '../../../rsmCoreComponents/components/DynamicForm/models'
import ResponsiveModal from '../../../rsmCoreComponents/components/ResponsiveModal'
import { useDeviceType } from '../../../rsmCoreComponents/hooks/useDeviceType'
import { getUserInfo } from '../../../store/userInfo/userInfoSlice'
import { Styles } from '../../../types'
import { useAppSelector } from '../../../utils/hooks'
import ContactPreferenceForm from '../Preferences/FormControls/ContactPreferenceForm'
import Wizard from '../Wizard'
import WizardStep from '../Wizard/WizardStep'
import IndustriesSelector from './IndustriesSelector'
import InterestSelectorFieldset from './InterestSelectorFieldset'
import ServiceLineSelector from './ServiceLineSelector'
import TopicsSelector from './TopicsSelector'

const styles: Styles = {
  wizardContainer: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden',
    '& h2': {
      marginTop: '1rem',
      marginBottom: '0.75rem',
      fontSize: '1.125rem',
      [theme.breakpoints.up('tablet')]: {
        marginTop: '2rem',
      },
      '&.welcome': {
        marginBottom: '0.5rem',
        fontFamily: 'Prelo-Light, sans-serif',
        fontSize: '1.875rem',
        [theme.breakpoints.only('tablet')]: {
          marginTop: '1rem',
        },
        [theme.breakpoints.up('desktop')]: {
          marginTop: '1.5rem',
          fontSize: '2.25rem',
        },
      },
    },
    '& .RsmWizard-root': {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      overflow: 'hidden',
      '& .RsmWizard-content': {
        flexGrow: 1,
        overflow: 'auto',
        padding: '1rem 1rem 0',
        [theme.breakpoints.only('tablet')]: {
          padding: '1rem 4rem 0',
        },
        [theme.breakpoints.up('desktop')]: {
          padding: '0 4rem',
        },
        '& .RsmWizardStep-root': {
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          overflow: 'hidden',
        },
      },
    },
  }),
}

interface ProfileModalProps {
  defaultValue?: number[]
  onClose: () => void
  onSubmit: (value: number[]) => void
  open: boolean
  skipProfile?: boolean
}

const defaultProps = {
  defaultValue: undefined,
  skipProfile: false,
}

const ProfileModal = ({
  defaultValue,
  onClose,
  onSubmit,
  open,
  skipProfile,
}: ProfileModalProps) => {
  const { isMobile, isTablet } = useDeviceType()
  const [data, setData] = useState<number[]>(defaultValue ?? [])
  const formRef = useRef<FormikProps<FormValues>>()
  const { t } = useTranslation()
  const userInfo = useAppSelector(getUserInfo)

  const setWelcomeMessage = () => {
    let welcomeMessage

    if (isMobile) {
      welcomeMessage = 'UserProfile.ProfileWizard.WelcomeMessageMobile'
    } else if (isTablet) {
      welcomeMessage = 'UserProfile.ProfileWizard.WelcomeMessageTablet'
    } else {
      welcomeMessage = 'UserProfile.ProfileWizard.WelcomeMessageDesktop'
    }

    return t(welcomeMessage, { firstName: userInfo.firstName })
  }

  const setCustomizationMessage = () =>
    t(
      isMobile
        ? 'UserProfile.ProfileWizard.CustomizationMessageShort'
        : 'UserProfile.ProfileWizard.CustomizationMessage',
    )

  const handleContactPreferenceDone = () => {
    // execute submit on contact preference form first to get error validations
    formRef?.current?.handleSubmit()
    if (
      formRef?.current?.errors === undefined ||
      Object.keys(formRef?.current?.errors).length === 0
    ) {
      // no errors found complete wizard step handle done submit
      onSubmit(data)
    }
  }

  const handleSave = () => {
    onSubmit(data)
    formRef?.current?.handleSubmit()
  }

  useEffect(() => {
    if (defaultValue) setData(defaultValue)
  }, [defaultValue])

  return (
    <Box className="Rsm-ProfileModal" sx={styles.modalContainer}>
      <ResponsiveModal
        open={open}
        onClose={onClose}
        aria-label={t('UserProfile.ECPProfileTitle')}
        desktopMaxHeight="55.375rem"
        hasRsmLogo={isMobile || isTablet}
        disableContentScrolling
        removeContentSpacing>
        <Box sx={styles.wizardContainer}>
          <Wizard>
            <WizardStep handleSave={handleSave}>
              <Box
                component="h2"
                className="welcome"
                data-testid="Hed_ProfileModal_WelcomeMessage">
                {setWelcomeMessage()}
              </Box>
              <Box data-testid="Lbl_ProfileModal_CustomizationMessage">
                {setCustomizationMessage()}
              </Box>
              <InterestSelectorFieldset interestText="UserProfile.ProfileWizard.Industries">
                <IndustriesSelector defaultSelected={data} onChange={setData} />
              </InterestSelectorFieldset>
            </WizardStep>

            <WizardStep handleSave={handleSave}>
              <InterestSelectorFieldset interestText="UserProfile.ProfileWizard.ServiceLines">
                <ServiceLineSelector
                  defaultSelected={data}
                  onChange={setData}
                />
              </InterestSelectorFieldset>
            </WizardStep>

            <WizardStep
              handleSave={skipProfile ? undefined : handleSave}
              handleDone={skipProfile ? handleSave : undefined}>
              <InterestSelectorFieldset interestText="UserProfile.ProfileWizard.Topics">
                <TopicsSelector defaultSelected={data} onChange={setData} />
              </InterestSelectorFieldset>
            </WizardStep>
            {!skipProfile && (
              <WizardStep handleDone={handleContactPreferenceDone}>
                <Typography variant="h2">
                  {t('UserProfile.ProfileWizard.ContactInfoPrefsMessage')}
                </Typography>
                <ContactPreferenceForm
                  formRef={formRef}
                  spacing={{ xs: '0.5rem', sm: '1rem', md: '1rem', lg: '1rem' }}
                  excluded={{ firstName: true, lastName: true, footer: true }}
                  formType="New"
                />
              </WizardStep>
            )}
          </Wizard>
        </Box>
      </ResponsiveModal>
    </Box>
  )
}

ProfileModal.defaultProps = defaultProps

export default ProfileModal
