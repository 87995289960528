import { Box, Pagination, Select, SelectChangeEvent } from '@mui/material'
import React, { ChangeEvent } from 'react'
import { t } from 'i18next'
import { useDeviceType } from '../../hooks/useDeviceType'

interface RsmPaginationProps {
  pageNumber: number
  pageSize: RsmPaginationPageSizes
  totalRows: number
  onPageChange: (pageNumber: number, pageSize: RsmPaginationPageSizes) => void
}

export type RsmPaginationPageSizes = 10 | 25 | 50 | 100

export interface RsmPaginationOutput {
  pageNumber: number
  pageSize: RsmPaginationPageSizes
}

const RsmPagination = ({
  pageNumber,
  pageSize,
  totalRows,
  onPageChange,
}: RsmPaginationProps) => {
  const { isMobile } = useDeviceType()

  const handleChangePageNumber = (_: ChangeEvent<unknown>, value: number) => {
    onPageChange(value, pageSize)
  }

  const handleChangePageSize = (
    event: SelectChangeEvent<RsmPaginationPageSizes>,
  ) => {
    onPageChange(1, event.target.value as RsmPaginationPageSizes)
  }

  const styles = {
    pagination: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '1rem',
      marginTop: '1rem',
      marginBottom: '1rem',
    },
  }

  return (
    <Box component="div" sx={styles.pagination}>
      <Select
        id="pagination-rows-per-page"
        native
        value={pageSize}
        inputProps={{
          'aria-label': t('Pagination:RowsPerPage'),
        }}
        onChange={handleChangePageSize}>
        <option key={10} value={10}>
          10
        </option>
        <option key={25} value={25}>
          25
        </option>
        <option key={50} value={50}>
          50
        </option>
        <option key={100} value={100}>
          100
        </option>
      </Select>
      <Pagination
        count={Math.ceil(totalRows / pageSize)}
        page={pageNumber}
        showFirstButton
        showLastButton
        siblingCount={isMobile ? 0 : 1}
        size={isMobile ? 'small' : 'large'}
        onChange={handleChangePageNumber}
      />
    </Box>
  )
}

export default RsmPagination
