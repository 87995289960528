import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import {
  faAngleDoubleUp,
  faAngleDoubleDown,
} from '@fortawesome/free-solid-svg-icons'
import { FileResponse } from '@rsmus/ecp-financeservice'
import { format } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { visuallyHidden } from '@mui/utils'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import tokens from '../../../styles/tokens.json'
import { Styles } from '../../../types'
import OverflowTooltip from '../../OverFlowToolTip'
import {
  setSelectedInvoices,
  setPayingInvoices,
  setPayableAmount,
  getPayableAmount,
  getInvoicePayments,
  setInvoicePayments,
  getPaymentTransaction,
} from '../../../store/invoices/invoiceSelectedInvoicesSlice'
import { formatCurrency } from '../../../rsmCoreComponents/utils/formatters'
import { useDeviceType } from '../../../rsmCoreComponents/hooks/useDeviceType'
import InvoicesHeader from './InvoicesHeader'
import api from '../../../api'
import downloadFileStream from '../../../rsmCoreComponents/utils/fileStreamUtils'
import { isCemFeatureEnabled } from '../../../rsmCoreComponents/utils/featureFlagUtils'
import {
  CEM_FEATURE_PAYMENT_PAY_INVOICES,
  CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
} from '../../../utils/constants/constants'
import { getCemFeatures } from '../../../store/userInfo/userInfoSlice'

const styles: Styles = {
  tableContainer: (theme) => ({
    [theme.breakpoints.only('desktop')]: {
      paddingLeft: '6.5rem',
      paddingRight: '6.5rem',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
  }),
  invoicesPaymentTable: (theme) => ({
    // -------------------------------------------------------------------------
    // TODO: (Ideally move these styles to a RsmTable variant, if possible.)
    // -------------------------------------------------------------------------
    '&.MuiTable-root': {
      tableLayout: 'fixed',
      width: '100%',
      '& .MuiTableCell-root': {
        borderBottom: 'none',
      },
      '& th.MuiTableCell-root': {
        fontFamily: 'Prelo-Bold, sans-serif',
      },
      '& td.MuiTableCell-root': {
        fontFamily: 'Prelo-Book, sans-serif',
      },
      '& .MuiTableCell-head': {
        padding: '1rem',
        fontSize: '0.875rem',
        lineHeight: '1rem',
        textTransform: 'uppercase',
        verticalAlign: 'top',
      },
      '& tbody': {
        '& .MuiTableRow-root': {
          '&:nth-of-type(odd)': {
            backgroundColor: tokens.colors.rsmGray.accessibility,
          },
        },
      },
      '& .MuiTableCell-body': {
        padding: '1.5rem 1rem',
        fontSize: '1rem',
        lineHeight: '1.5rem',
      },
      '.MuiFormControlLabel-root': {
        margin: '0rem',
      },
      '.MuiCheckbox-root': {
        boxSizing: 'content-box',
        padding: '0.5rem',
      },
    },
    '& .MuiTableCell-root': {
      '&:nth-of-type(10)': {
        width: '3.5rem',
        padding: '0.5rem',
      },
    },
    '& .MuiTableCell-body': {
      '&:nth-of-type(2), &:nth-of-type(3), :nth-of-type(5), :nth-of-type(6), :nth-of-type(9)':
        {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      ':nth-of-type(6)': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    [theme.breakpoints.only('tablet')]: {
      '& .MuiTableCell-root': {
        '&:nth-of-type(2), &:nth-of-type(4), &:nth-of-type(5), &:nth-of-type(6), &:nth-of-type(8), &:nth-of-type(9)':
          {
            display: 'none',
          },
      },
    },
    [theme.breakpoints.only('mobile')]: {
      '& .MuiTableCell-root': {
        '&:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(4), &:nth-of-type(5), &:nth-of-type(6), &:nth-of-type(8), &:nth-of-type(9)':
          {
            display: 'none',
          },
        '&:nth-of-type(3)': {
          width: '50%',
        },
        '&:nth-of-type(7)': {
          width: '50%',
        },
      },
    },
  }),
  date: {
    textTransform: 'uppercase',
  },
  tooltipText: {
    padding: '1rem',
  },
  tooltipIconButton: {
    width: '2.25rem',
    height: '2.25rem',
    marginLeft: 0,
    marginRight: 0,
  },
  statusTooltip: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  textButton: (theme) => ({
    color: theme.palette.secondary.main,
    marginBottom: '3.125rem',
  }),
  link: (theme) => ({
    display: 'block',
    textDecoration: 'underline',
    color: theme.palette.secondary.main,
    marginLeft: '0.5rem',
  }),
  toggle: (theme) => ({
    display: 'block',
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    marginRight: '0.5rem',
  }),
  banner: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '2.5rem',
    [theme.breakpoints.only('desktop')]: {
      marginLeft: '6.5rem',
      marginRight: '6.5rem',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
    [theme.breakpoints.only('mobile')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
  }),
  bannerPayableAmount: (theme) => ({
    textAlign: 'center',
    [theme.breakpoints.down('desktop')]: {
      display: 'none',
    },
  }),
  detailContainer: (theme) => ({
    paddingTop: '4rem',
    [theme.breakpoints.only('desktop')]: {
      marginLeft: '6.5rem',
      marginRight: '6.5rem',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
    [theme.breakpoints.only('mobile')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
  }),
  detailSection: () => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'start',
  }),
  details: () => ({
    flex: '1 1 auto',
  }),
  detailRow: () => ({
    flex: '1 1 auto',
    display: 'flex',
    justifyContent: 'start',
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
  }),
  detailLabel: () => ({
    // add a minimum width to prevent the label from wrapping
    minWidth: '10rem',
    fontWeight: 'bold',
  }),
  autopay: () => ({
    flex: '0 0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '20rem',
    height: '10rem',
  }),
  footer: (theme) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1rem',
    marginBottom: '1rem',
    [theme.breakpoints.only('desktop')]: {
      marginLeft: '6.5rem',
      marginRight: '6.5rem',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
    [theme.breakpoints.only('mobile')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
  }),
  continue: (theme) => ({
    textAlign: 'center',
    paddingBottom: '2rem',
    [theme.breakpoints.only('desktop')]: {
      textAlign: 'right',
      marginRight: '6.5rem',
    },
  }),
}

type PaidInvoices = { customerName: string; invoices: string }

const InvoicePaymentSuccess = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const cemFeatures = useSelector(getCemFeatures)
  const invoicePayments = useSelector(getInvoicePayments)
  const payableAmount = useSelector(getPayableAmount)
  const paymentTransaction = useSelector(getPaymentTransaction)
  const navigate = useNavigate()
  const [invoiceViewState, setInvoiceViewState] = useState(false)
  const { isDesktop } = useDeviceType()

  const handleBackToInvoices = () => {
    dispatch(setSelectedInvoices([]))
    dispatch(setPayingInvoices([]))
    dispatch(setInvoicePayments([]))
    dispatch(setPayableAmount(0))
    navigate('/invoicing/invoices')
  }

  useEffect(() => {
    // Prevent user from accessing this page directly.
    if (invoicePayments.length === 0) {
      navigate('/invoicing')
    }
  }, [])

  const handleTogglePaidInvoices = () => {
    setInvoiceViewState(!invoiceViewState)
  }

  const handleDownloadReceipt = useCallback(async () => {
    const response: FileResponse =
      await api.finance.invoiceDocument_DownloadPaymentReceipt(
        paymentTransaction.transactionId || '',
      )
    downloadFileStream(response)
  }, [
    api.finance.invoiceDocument_DownloadPaymentReceipt,
    downloadFileStream,
    paymentTransaction,
  ])

  const getPaymentMethodDisplay = () =>
    paymentTransaction.paymentMethod === t('Invoicing.ACH')
      ? `${t('Invoicing.BankAccountEnding')} ${
          paymentTransaction.accountNumber
        }`
      : `${t('Invoicing.CreditCardEnding')} ${paymentTransaction.accountNumber}`

  const getPaidInvoices = (): PaidInvoices[] => {
    const groupedInvoices = invoicePayments.reduce(
      (group: { [key: string]: string[] }, invoice) => {
        if (!group[invoice.customerName]) {
          // eslint-disable-next-line no-param-reassign
          group[invoice.customerName] = []
        }
        group[invoice.customerName].push(invoice.invoiceNumber)
        return group
      },
      {},
    )

    const keys = Object.keys(groupedInvoices)
    const paidInvoices: PaidInvoices[] = []
    keys.forEach((key) =>
      paidInvoices.push({
        customerName: key,
        invoices: groupedInvoices[key].join(', '),
      }),
    )

    return paidInvoices
  }

  const getButtonSlot = (): JSX.Element | undefined => {
    if (paymentTransaction.scheduledPaymentDate) {
      return undefined
    }
    return isCemFeatureEnabled(
      [
        CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
        CEM_FEATURE_PAYMENT_PAY_INVOICES,
      ],
      cemFeatures,
      'any',
    ) ? (
      <Button
        color="primary"
        variant="contained"
        data-testid="Btn_Invoicing_DownloadReceipt"
        onClick={handleDownloadReceipt}>
        {t('Invoicing.PaymentSuccessPage.DownloadReceipt')}
      </Button>
    ) : undefined
  }

  return (
    <>
      <InvoicesHeader
        primaryHeaderId="PaySelected"
        primaryHeaderText={
          paymentTransaction.scheduledPaymentDate
            ? t('Invoicing.PaymentSuccessPage.PaymentScheduled')
            : t('Invoicing.PaymentSuccessPage.PaymentSuccess')
        }
        primaryHeaderDescription=""
        buttonSlot={getButtonSlot()}
        backButtonId="BackToInvoices"
        backButtonText={t('Invoicing.BackToInvoices')}
        handleNavigation={handleBackToInvoices}
        amountHeaderId="PaidAmount"
        amountHeaderText={
          paymentTransaction.scheduledPaymentDate
            ? t('Invoicing.AmountScheduled')
            : t('Invoicing.PaidAmount')
        }
        amount={payableAmount}
      />
      <Box sx={{ backgroundColor: tokens.colors.white }}>
        <Box sx={styles.detailContainer}>
          <Box sx={styles.detailSection}>
            <Box sx={styles.details}>
              {!paymentTransaction.scheduledPaymentDate && (
                <>
                  <Box sx={styles.detailRow}>
                    <Box sx={styles.detailLabel}>
                      {t('Invoicing.PaymentSuccessPage.TransactionId')}
                    </Box>
                    <Box>{paymentTransaction.transactionId}</Box>
                  </Box>
                  <hr aria-hidden="true" />
                </>
              )}
              <Box sx={styles.detailRow}>
                <Box sx={styles.detailLabel}>
                  {paymentTransaction.scheduledPaymentDate
                    ? t('Invoicing.PaymentSuccessPage.DateScheduled')
                    : t('Invoicing.PaymentSuccessPage.TransactionDate')}
                </Box>
                <Box>
                  {t('Invoicing.PaymentSuccessPage.TransactionDateFormat', {
                    date: paymentTransaction.scheduledPaymentDate
                      ? paymentTransaction.scheduledPaymentDate
                      : paymentTransaction.transactionDate,
                  })}
                </Box>
              </Box>
              <hr aria-hidden="true" />
              <Box sx={styles.detailRow}>
                <Box sx={styles.detailLabel}>
                  {t('Invoicing.PaymentSuccessPage.PaymentMethod')}
                </Box>
                <Box>{getPaymentMethodDisplay()}</Box>
              </Box>
              <hr aria-hidden="true" />
              <Box sx={styles.detailRow}>
                <Box sx={styles.detailLabel}>
                  {t('Invoicing.PaymentSuccessPage.PayerName')}
                </Box>
                <Box>{paymentTransaction.payer}</Box>
              </Box>
              <hr aria-hidden="true" />
              <Box sx={styles.detailRow}>
                <Box sx={styles.detailLabel}>
                  {paymentTransaction.scheduledPaymentDate
                    ? t('Invoicing.PaymentSuccessPage.Invoices')
                    : t('Invoicing.PaymentSuccessPage.InvoicesPaid')}
                </Box>
                <Box>
                  {getPaidInvoices().map((invoice) => (
                    <Box
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: 'auto auto',
                      }}>
                      <Box>{invoice.customerName}</Box>
                      <Box sx={{ paddingLeft: '1rem', textAlign: 'end' }}>
                        {invoice.invoices}
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
            {isDesktop && <Box sx={styles.autopay} />}
          </Box>
          <Button
            data-testid="Btn_Invoicing_ViewPaidInvoices"
            onClick={handleTogglePaidInvoices}
            aria-atomic
            aria-expanded={invoiceViewState}
            aria-controls="invoicesPaymentTable"
            aria-live="polite"
            sx={styles.textButton}>
            <Box component="span" sx={styles.toggle}>
              {invoiceViewState
                ? t('Invoicing.PaymentSuccessPage.HidePaidInvoiceDetails')
                : t('Invoicing.PaymentSuccessPage.ViewPaidInvoiceDetails')}
            </Box>
            <FontAwesomeIcon
              icon={invoiceViewState ? faAngleDoubleUp : faAngleDoubleDown}
            />
          </Button>
        </Box>
        {invoiceViewState && (
          <TableContainer component={Paper} sx={styles.tableContainer}>
            <Table id="invoicesPaymentTable" sx={styles.invoicesPaymentTable}>
              <Box component="caption" sx={visuallyHidden}>
                {t('Invoicing.Invoices')}
              </Box>
              <TableHead>
                <TableRow>
                  <TableCell>{t('Invoicing.CustomerName')}</TableCell>
                  <TableCell>{t('Invoicing.CustomerNumber')}</TableCell>
                  <TableCell>{t('Invoicing.InvoiceNumber')}</TableCell>
                  <TableCell>{t('Invoicing.InvoiceDate')}</TableCell>
                  <TableCell>{t('Invoicing.OriginalAmount')}</TableCell>
                  <TableCell>{t('Invoicing.OpenAmount')}</TableCell>
                  <TableCell>{t('Invoicing.PaidAmount')}</TableCell>
                  <TableCell>{t('Invoicing.Status')}</TableCell>
                  <TableCell>{t('Invoicing.Company')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoicePayments.map((invoice) => (
                  <TableRow key={`invoice-${invoice.id}`}>
                    <TableCell>
                      <OverflowTooltip>{invoice.customerName}</OverflowTooltip>
                    </TableCell>
                    <TableCell>
                      <OverflowTooltip>
                        {invoice.customerNumber}
                      </OverflowTooltip>
                    </TableCell>
                    <TableCell>
                      <OverflowTooltip>{invoice.invoiceNumber}</OverflowTooltip>
                    </TableCell>
                    <TableCell sx={{ 'text-transform': 'uppercase' }}>
                      <OverflowTooltip>
                        {format(new Date(`${invoice.date}Z`), 'MMM d, yyyy')}
                      </OverflowTooltip>
                    </TableCell>
                    <TableCell>
                      <OverflowTooltip>
                        {formatCurrency(invoice.originalAmount)}
                      </OverflowTooltip>
                    </TableCell>
                    <TableCell>
                      <OverflowTooltip>
                        {formatCurrency(invoice.openAmount)}
                      </OverflowTooltip>
                    </TableCell>
                    <TableCell>
                      <OverflowTooltip>
                        {formatCurrency(invoice.paidAmount)}
                      </OverflowTooltip>
                    </TableCell>
                    <TableCell>
                      <OverflowTooltip>{invoice.status}</OverflowTooltip>
                    </TableCell>
                    <TableCell>
                      <OverflowTooltip>{invoice.company}</OverflowTooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <hr />
          </TableContainer>
        )}
      </Box>
    </>
  )
}

export default InvoicePaymentSuccess
