import { createSlice } from '@reduxjs/toolkit'
import {
  InvoicePayerViewModel,
  PaymentConfirmResponseViewModel,
} from '@rsmus/ecp-financeservice'
import type { RootState } from '..'
import { InvoiceData } from './invoiceSearchService'

type InvoicesWithPayments = (InvoiceData & { paidAmount: number })[]
type ConfirmPaymentResponse = PaymentConfirmResponseViewModel & {
  payer: string
  paymentMethod: string
  scheduledPaymentDate: Date | null
}

// Define a type for the slice state
interface InvoicesSelectedState {
  selectedInvoices: InvoiceData[]
  payingInvoices: InvoiceData[]
  invoicePayments: InvoicesWithPayments
  payableAmount: number
  paymentTransaction: ConfirmPaymentResponse
  invoicePayers: InvoicePayerViewModel[]
}

// Define the initial state using that type
const initialState: InvoicesSelectedState = {
  selectedInvoices: [] as InvoiceData[],
  payingInvoices: [] as InvoiceData[],
  invoicePayments: [] as InvoicesWithPayments,
  payableAmount: 0,
  paymentTransaction: {} as ConfirmPaymentResponse,
  invoicePayers: [] as InvoicePayerViewModel[],
}

export const invoiceSelectedInvoicesSlice = createSlice({
  name: 'invoiceSelectedInvoices',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setSelectedInvoices: (state, { payload }) => {
      state.selectedInvoices = payload
    },
    setPayingInvoices: (state, { payload }) => {
      state.payingInvoices = payload
    },
    setInvoicePayments: (state, { payload }) => {
      state.invoicePayments = payload
    },
    setPayableAmount: (state, { payload }) => {
      state.payableAmount = payload
    },
    setPaymentTransaction: (state, { payload }) => {
      state.paymentTransaction = payload
    },
    setInvoicePayers: (state, { payload }) => {
      state.invoicePayers = payload
    },
  },
})

// Holds which invoices the user has selected via checkboxes on the invoice search page
// and can also be modified via the checkboxes on the invoice payment page
export const { setSelectedInvoices } = invoiceSelectedInvoicesSlice.actions
export const getSelectedInvoices = (state: RootState) =>
  state.invoiceSelectedInvoices.selectedInvoices

// Holds which invoices the user has originally selected to pay on the invoice search page.
// This is separate from the selectedInvoices state because the user may unselect some
// invoices on the invoice payment page, and we don't want to lose that information
export const { setPayingInvoices } = invoiceSelectedInvoicesSlice.actions
export const getPayingInvoices = (state: RootState) =>
  state.invoiceSelectedInvoices.payingInvoices

// Holds the combined states of selectedInvoices and payingInvoices in that it only contains
// the invoices that the user has selected to pay (from selectedInvoices) along with the payment
// amount for each invoice (from payingInvoices).
export const { setInvoicePayments } = invoiceSelectedInvoicesSlice.actions
export const getInvoicePayments = (state: RootState) =>
  state.invoiceSelectedInvoices.invoicePayments

export const { setPayableAmount } = invoiceSelectedInvoicesSlice.actions
export const getPayableAmount = (state: RootState) =>
  state.invoiceSelectedInvoices.payableAmount

export const { setPaymentTransaction } = invoiceSelectedInvoicesSlice.actions
export const getPaymentTransaction = (state: RootState) =>
  state.invoiceSelectedInvoices.paymentTransaction

export const { setInvoicePayers } = invoiceSelectedInvoicesSlice.actions
export const getInvoicePayers = (state: RootState) =>
  state.invoiceSelectedInvoices.invoicePayers

export default invoiceSelectedInvoicesSlice.reducer
