import {
  ApiResponseOfIEnumerableOfTeamDocsUserMappingDomainModel,
  TeamDocsUserMappingDomainModel,
} from '@rsmus/ecp-userservice'
import { useCallback, useEffect, useState } from 'react'
import api from '../..'

function useProjects() {
  const [state, setState] = useState<{
    results: TeamDocsUserMappingDomainModel[]
    loading: boolean
    called: boolean
    error?: Error
  }>({
    loading: false,
    results: [],
    called: false,
  })

  async function fetch() {
    try {
      setState({ ...state, loading: true, called: true })

      const response =
        (await api.user.teamDocsUserMappings_GetTeamDocsUserMappings()) as ApiResponseOfIEnumerableOfTeamDocsUserMappingDomainModel

      setState({ ...state, results: response.data || [], loading: false })
    } catch (error) {
      setState({ ...state, results: [], loading: false, error: error as Error })
    }
  }

  useEffect(() => {
    fetch()
  }, [])

  const refetch = useCallback(() => fetch(), [])

  const { results, loading, called, error } = state

  return { results, loading: loading && called, error, refetch }
}

export default useProjects
