/* eslint-disable react/destructuring-assignment */
import * as React from 'react'
import { SVGProps } from 'react'

const AmexCardIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width}
    height={props.height}
    viewBox="0 0 80 80"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}>
    <title>American Express</title>
    <switch>
      <g>
        <path
          d="M15 16v50h50V16H15z"
          style={{
            fill: '#fff',
          }}
        />
        <path
          d="m56.083 28.267 1.6-4.35H65V16H15v50h50v-7.9h-6.9l-2.55-2.983L52.9 58.1H33.3V42.217h-6.467l8.084-18.3h7.866l1.9 4.15v-4.15h9.767l1.633 4.35zm-5.516 2.883-.017-1.75.667 1.75 3.25 8.683H57.7l3.267-8.683.633-1.733v10.416H65V26.5h-5.65l-2.567 6.767L56.1 35.1l-.7-1.833-2.583-6.767h-5.65v13.333h3.4V31.15zm-7.317 8.683h3.917L41.3 26.5h-4.55l-5.9 13.333h3.867l1.033-2.566h6.467l1.033 2.566zm-4.933-8.816.666-1.667.667 1.667 1.383 3.366h-4.1l1.384-3.366zm-2.284 11.216v13.284h11.134v-2.884h-7.734v-2.316h7.584V47.45h-7.584v-2.333h7.734v-2.884H36.033zM59.55 55.517h4.417l-6.234-6.667 6.234-6.617h-4.35L55.6 46.55l-4-4.317h-4.433l6.216 6.667-6.216 6.617h4.3l4.05-4.334 4.033 4.334zm1.683-6.684L65 52.667V45.05l-3.767 3.783z"
          style={{
            fill: '#006fcf',
          }}
        />
      </g>
    </switch>
  </svg>
)
export default AmexCardIcon
