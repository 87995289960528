/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Outlet, useLocation } from 'react-router-dom'
import processAdobePageView from '../../analytics/adobe/pageView'
import { UserType } from '../../analytics/adobe/types'
import InvoicingMenu from '../../components/Invoicing/InvoicingMenu'
import MainContent from '../../components/layouts/MainContent'
import MainMenu from '../../components/layouts/MainMenu'
import { useAITracking } from '../../rsmCoreComponents/hooks/useAITracking'
import { getCemFeatures, getUserInfo } from '../../store/userInfo/userInfoSlice'
import { useDeviceType } from '../../rsmCoreComponents/hooks/useDeviceType'
import MobileMenu from '../../components/Navigation/MobileMenu/MobileMenu'
import { DropdownObject } from '../../components/Dropdown/Dropdown'
import { isCemFeatureEnabled } from '../../rsmCoreComponents/utils/featureFlagUtils'
import { CEM_FEATURE_PAYMENT_CLIENT_ADMIN } from '../../utils/constants/constants'

function parseLocationKey(path: string): string {
  const fragments = path?.split('/')
  if (fragments?.length > 1) return fragments[2]
  return path ?? ''
}

const InvoiceBaseLayout = () => {
  const { t } = useTranslation()
  const userInfo = useSelector(getUserInfo)
  const cemFeatures = useSelector(getCemFeatures)
  const userType: UserType = userInfo.isEmployee ? 'Internal' : 'External'
  const { pathname } = useLocation()
  const { isMobile } = useDeviceType()
  const mobileDropdownData: DropdownObject[] = useMemo(() => {
    const isClientAdmin = isCemFeatureEnabled(
      CEM_FEATURE_PAYMENT_CLIENT_ADMIN,
      cemFeatures,
      'any',
    )
    const menuItems = []
    if (isClientAdmin) {
      menuItems.push({
        value: `/invoicing/dashboard`,
        display: t('Navigation.Dashboard'),
      })
    }
    menuItems.push(
      {
        value: `/invoicing/invoices`,
        display: t('Navigation.Invoices'),
      },
      {
        value: `/invoicing/payments`,
        display: t('Navigation.Payments'),
      },
      {
        value: `/invoicing/administrative`,
        display: t('Navigation.Administrative'),
      },
    )
    return menuItems
  }, [cemFeatures])

  useAITracking(t('Invoicing.ECPInvoicingTitle'), window.location.href)

  useEffect(() => {
    document.title = t('Invoicing.ECPInvoicingTitle')
  }, [])

  useEffect(() => {
    if (pathname?.length > 0) processAdobePageView(pathname, userType)
  }, [pathname])

  return (
    <>
      <MainMenu currentItem="invoicing" />
      {isMobile ? (
        <MobileMenu
          dropdownData={mobileDropdownData}
          currentSelected={parseLocationKey(pathname)}
          dropdownName="InvoicingSubNavigation"
          dropdownAriaLabel="Invoicing Sub Navigation"
          ariaLabel="invoices menu"
        />
      ) : (
        <InvoicingMenu currentItem={parseLocationKey(pathname)} />
      )}
      <MainContent>
        <Outlet />
      </MainContent>
    </>
  )
}

export default InvoiceBaseLayout
