import { Box } from '@mui/material'
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Styles } from '../../../types'

const styles: Styles = {
  fieldset: {
    marginTop: '2rem',
  },
  legend: (theme) => ({
    marginTop: '1rem',
    marginBottom: '0.75rem',
    fontSize: '1.125rem',
    lineHeight: 1.2,
    [theme.breakpoints.up('tablet')]: {
      marginTop: '2rem',
    },
  }),
  interestName: {
    fontFamily: 'Prelo-Bold, sans-serif',
  },
  interestsList: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'flex-start',
    overflow: 'auto',
    margin: '-0.125rem 0 -0.375rem',
  },
}

interface InterestSelectorFieldsetProps {
  children: ReactNode
  interestText: string
}

const InterestSelectorFieldset = ({
  children,
  interestText,
}: InterestSelectorFieldsetProps) => {
  const { t } = useTranslation()

  return (
    <Box
      component="fieldset"
      sx={styles.fieldset}
      data-testid="Pnl_Interests_Selector">
      <Box component="legend" sx={styles.legend}>
        {t('UserProfile.ProfileWizard.SelectThe')}{' '}
        <Box
          data-testid="Lbl_Interests_Type"
          component="strong"
          sx={styles.interestName}>
          {t(interestText)}
        </Box>{' '}
        {t('UserProfile.ProfileWizard.YouAreInterestedIn')}
      </Box>
      <Box component="ul" sx={styles.interestsList}>
        {children}
      </Box>
    </Box>
  )
}

export default InterestSelectorFieldset
