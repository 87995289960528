import React, { useState } from 'react'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { SearchIcon } from '../../icons'
import { Styles } from '../../../types'
import tokens from '../../../styles/tokens.json'

interface SearchBoxProps {
  handleClick: (v: string) => void
  placeholderText?: string
  testId?: string
  style?: object
}

const defaultProps = {
  placeholderText: '',
  testId: '',
  style: {},
}

const styles: Styles = {
  adornment: (theme) => ({
    backgroundColor: theme.palette.secondary.main,
    maxHeight: '100%',
    height: '100%',
    minWidth: '3.5rem',
  }),
  iconButton: {
    height: '100%',
    width: '100%',
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
}

const SearchBox = ({
  handleClick,
  placeholderText,
  testId,
  style,
}: SearchBoxProps) => {
  const { t } = useTranslation()
  const [value, setValue] = useState('')

  return (
    <>
      <TextField
        id="navigation_search_box"
        role="search"
        sx={{
          '& .MuiOutlinedInput-root': {
            paddingRight: 0,
          },
        }}
        style={style}
        placeholder={placeholderText}
        variant="outlined"
        className="subvariant-search"
        onChange={(event) => setValue(event.target.value)}
        value={value}
        InputProps={{
          'aria-describedby': 'search-instructions',
          endAdornment: (
            <InputAdornment
              variant="filled"
              sx={styles.adornment}
              position="end">
              <IconButton
                sx={styles.iconButton}
                aria-label="search button"
                onClick={() => handleClick(value)}
                data-testid="Btn_SearchBox_Search">
                <SearchIcon fill={tokens.colors.white} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        fullWidth
        data-testid={testId ?? ''}
      />
      <span hidden id="search-instructions">
        {t('SearchScreenReaderInstructions')}
      </span>

      <span hidden aria-live="polite">
        {value} {t('Results')}
      </span>
    </>
  )
}

SearchBox.defaultProps = defaultProps

export default SearchBox
