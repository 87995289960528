import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer/dist/internal'
import type { RootState } from '..'
import { RsmPaginationPageSizes } from '../../rsmCoreComponents/components/RsmPagination/RsmPagination'

// These constants are KEYS and should match with
// PaymentSelectedFiltersState for key here and server data for values returned by paymentfilters
// DO NOT change values here unless warranted.
export const PaymentSelectedFiltersConstants = {
  Seperator: '##',
  DefaultCurrency: 'usd',
  searchString: 'search',
  paymentStatus: 'status',
  paymentDates: 'daterange',
  paymentStartDate: 'startdate',
  paymentEndDate: 'enddate',
  paymentDate: 'date',
  paymentAmounts: 'amountrange',
  paymentAmountFrom: 'paymentAmountFrom',
  paymentAmountTo: 'paymentAmountTo',
  currency: 'currency',
  paymentIds: 'paymentids',
  SortBy: 'Initiated', //  constant for sorting
  IsSortAscending: false, //  constant for sorting
}

export const setFilterValue = (
  category: string,
  filter: string | Date | null,
) => `${category}${PaymentSelectedFiltersConstants.Seperator}${filter}`

export const amountRangeFilterMax = 1000000

const setError = (
  state: WritableDraft<PaymentSelectedFiltersState>,
  errorMessage: string,
) => {
  state.filterStateValidation = {
    isValid: false,
    errorMessages: [...state.filterStateValidation.errorMessages, errorMessage],
  }
}

const clearError = (state: WritableDraft<PaymentSelectedFiltersState>) => {
  state.filterStateValidation = {
    isValid: true,
    errorMessages: [],
  }
}

const changeRowsPerPage = (
  state: WritableDraft<PaymentSelectedFiltersState>,
  pageSize: RsmPaginationPageSizes,
) => {
  if (pageSize <= 0) setError(state, 'pagesize cannot be negative')
  else state.pageSize = pageSize
}

const changePageNumber = (
  state: WritableDraft<PaymentSelectedFiltersState>,
  pageNumber: number,
) => {
  if (pageNumber <= 0) setError(state, 'pagenumber cannot be negative')
  else state.pageNumber = pageNumber
}

const changeSorting = (
  state: WritableDraft<PaymentSelectedFiltersState>,
  sortBy: string,
  isAscending: boolean,
) => {
  state.sortby = sortBy
  state.IsSortAscending = isAscending
}

const clearCategoryFilter = (
  state: WritableDraft<PaymentSelectedFiltersState>,
  category: string,
) => {
  clearError(state)
  if (!category) {
    setError(state, 'category required for clear category')
    return
  }
  switch (category) {
    case PaymentSelectedFiltersConstants.paymentStatus:
      state.paymentStatus = []
      break

    case PaymentSelectedFiltersConstants.paymentDates:
      state.paymentStartDate = null
      state.paymentEndDate = null
      state.paymentDateRange = ''
      clearError(state)
      break

    case PaymentSelectedFiltersConstants.paymentAmounts:
      state.paymentAmountFrom = undefined
      state.paymentAmountTo = undefined
      clearError(state)
      break

    case PaymentSelectedFiltersConstants.currency:
      state.currency = PaymentSelectedFiltersConstants.DefaultCurrency
      break

    default:
      setError(
        state,
        'invalid category in filter, should be defined in constants locally',
      )
  }

  state.pageNumber = 1
}

const changeFilterValue = (
  state: WritableDraft<PaymentSelectedFiltersState>,
  filter: string,
) => {
  clearError(state)
  const [category, value] = filter?.split(
    PaymentSelectedFiltersConstants.Seperator,
  ) ?? [undefined, undefined]
  if (!category || !value) {
    setError(state, 'filter category and value not combined properly')
    return
  }
  let idx = -1
  switch (category) {
    case PaymentSelectedFiltersConstants.paymentStatus:
      idx = state.paymentStatus.indexOf(value)
      if (idx >= 0) state.paymentStatus.splice(idx, 1)
      else state.paymentStatus.push(value)
      break

    case PaymentSelectedFiltersConstants.paymentStartDate:
      if (value === 'null') {
        state.paymentStartDate = null
        state.paymentDateRange = ''
      } else {
        state.paymentStartDate = new Date(value)
      }
      break

    case PaymentSelectedFiltersConstants.paymentEndDate:
      if (value === 'null') {
        state.paymentEndDate = null
        state.paymentDateRange = ''
      } else {
        state.paymentEndDate = new Date(value)
      }
      break

    case PaymentSelectedFiltersConstants.paymentDate:
      state.paymentDateRange = value
      break

    case PaymentSelectedFiltersConstants.paymentAmounts:
      {
        const [start, end] = value.split('**')
        state.paymentAmountFrom = Number(start)
        state.paymentAmountTo = Number(end)
      }
      break

    case PaymentSelectedFiltersConstants.currency:
      state.currency = value
      break

    default:
      setError(
        state,
        'invalid category in filter, should be defined in constants locally',
      )
  }

  state.pageNumber = 1
}

export interface FilterStateValidation {
  isValid: boolean
  errorMessages: string[]
}

// ALL Filter data for Payment table go here
export interface PaymentSelectedFiltersState {
  pageNumber: number
  pageSize: RsmPaginationPageSizes
  searchString: string | undefined
  paymentStatus: string[]
  paymentStartDate: Date | null
  paymentEndDate: Date | null
  paymentDateRange: string
  paymentAmountFrom: number | undefined
  paymentAmountTo: number | undefined
  currency: string
  paymentIds: string[]
  filterStateValidation: FilterStateValidation
  sortby: string | undefined // Added SortBy property
  IsSortAscending: boolean | true // Added isSortAscending property
}

// Define the initial state
const initialState: PaymentSelectedFiltersState = {
  pageNumber: 1,
  pageSize: 10,
  searchString: undefined,
  paymentStatus: [],
  paymentStartDate: null,
  paymentEndDate: null,
  paymentDateRange: '',
  paymentAmountFrom: 0,
  paymentAmountTo: 0,
  currency: PaymentSelectedFiltersConstants.DefaultCurrency,
  paymentIds: [],
  sortby: 'initiated',
  IsSortAscending: false,
  filterStateValidation: {
    isValid: true,
    errorMessages: [],
  },
}

export const paymentSelectedFiltersSlice = createSlice({
  name: 'paymentSelectedFilters',
  initialState,
  reducers: {
    clearAllFilters: (): PaymentSelectedFiltersState => initialState,
    clearCategoryFilters(state, { payload }: PayloadAction<string>) {
      clearCategoryFilter(state, payload)
    },
    setPageSize(state, { payload }: PayloadAction<RsmPaginationPageSizes>) {
      changeRowsPerPage(state, payload)
    },
    toPageNumber(state, { payload }: PayloadAction<number>) {
      changePageNumber(state, payload)
    },
    toNextPage(state) {
      state.pageNumber += 1
    },
    toPreviousPage(state) {
      changePageNumber(state, state.pageNumber - 1)
    },
    updateFilterValue(state, { payload }: PayloadAction<string>) {
      changeFilterValue(state, payload)
    },
    updateSearch(state, { payload }: PayloadAction<string>) {
      state.searchString = payload
      state.pageNumber = 1
    },
    updateSorting(
      state,
      { payload }: PayloadAction<{ sortBy: string; isAscending: boolean }>,
    ) {
      changeSorting(state, payload.sortBy, payload.isAscending)
    },
  },
})

export const {
  clearAllFilters,
  clearCategoryFilters,
  setPageSize,
  toPageNumber,
  toNextPage,
  toPreviousPage,
  updateFilterValue,
  updateSearch,
  updateSorting,
} = paymentSelectedFiltersSlice.actions

export const getPageInfo = (state: RootState) => ({
  pageNumber: state.paymentSelectedFilters.pageNumber,
  pageSize: state.paymentSelectedFilters.pageSize,
})

export const getPaymentSelectedFilters = (state: RootState) =>
  state.paymentSelectedFilters

export const getAmountRangeFilterMax = (): number => amountRangeFilterMax
// retrieval of max amount range value from central location to be used in PaymentChips.tsx and PaymentAmountRangeFilter.tsx
// setting max to 1000000 due to steps in currency range slider dividing by 100

export default paymentSelectedFiltersSlice.reducer
