import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { Styles } from '../../../types'
import InvoiceSupport from './InvoiceSupport'
import PaymentSettings from './PaymentSettings'
import InvoiceSearch from './InvoiceSearch'
import ActivityFeed from './ActivityFeed'
import InvoiceCompany from './InvoiceCompany'
import api from '../../../api'

const styles: Styles = {
  container1: (theme) => ({
    [theme.breakpoints.only('desktop')]: {
      marginRight: '6.5rem',
      marginLeft: '6.5rem',
      marginTop: '1.25rem',
    },
  }),
  container2: (theme) => ({
    display: 'grid',
    height: 'fit-content',
    width: '100%',
    [theme.breakpoints.down('tablet')]: {
      gridTemplateColumns: 'repeat(1,1fr)',
    },
    [theme.breakpoints.only('desktop')]: {
      gridTemplateColumns: 'fit-content(100%) 1fr',
    },
  }),
  container3: (theme) => ({
    display: 'grid',
    height: 'fit-content',
    maxWidth: '49rem',
    gap: '1rem',
    [theme.breakpoints.only('mobile')]: {
      gridTemplateColumns: 'repeat(1,1fr)',
      maxWidth: '24rem',
    },
    [theme.breakpoints.only('tablet')]: {
      gridTemplateColumns: 'repeat(1,2fr)',
    },
    [theme.breakpoints.only('desktop')]: {
      gridTemplateColumns: 'repeat(1,2fr)',
    },
  }),
  content: (theme) => ({
    display: 'grid',
    height: 'fit-content',
    width: '100%',
    gap: '1rem',
    justifyContent: 'start',
    gridTemplateColumns: 'repeat(2, 1fr)',
    [theme.breakpoints.only('mobile')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
    [theme.breakpoints.only('tablet')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
      paddingBottom: '1rem',
    },
  }),
  activityFeed: (theme) => ({
    display: 'grid',
    height: 'fit-content',
    width: '100%',
    justifyContent: 'start',
    gridTemplateColumns: 'repeat(1, 1fr)',
    [theme.breakpoints.up('tablet')]: {
      paddingBottom: '1rem',
    },
    [theme.breakpoints.only('desktop')]: {
      paddingLeft: '1rem',
    },
  }),
}

const DashboardLayout = () => {
  const [userAgreementAccepted, setUserAgreementAccepted] =
    useState<boolean>(false)

  const getUserAgreement = async () => {
    const agreement = await api.finance.userAgreement_GetUserAgreement()
    if (!agreement?.data?.acceptedAgreementDate) {
      setUserAgreementAccepted(false)
    }
    setUserAgreementAccepted(true)
  }

  useEffect(() => {
    getUserAgreement()
  }, [])

  return (
    <Box sx={styles.container1}>
      <Box sx={styles.container2}>
        <Box sx={styles.container3}>
          <InvoiceCompany userAgreementAccepted={userAgreementAccepted} />
          <InvoiceSearch />
          <Box sx={styles.content}>
            <PaymentSettings userAgreementAccepted={userAgreementAccepted} />
            <InvoiceSupport />
          </Box>
        </Box>
        <Box sx={styles.activityFeed}>
          <ActivityFeed userAgreementAccepted={userAgreementAccepted} />
        </Box>
      </Box>
    </Box>
  )
}

export default DashboardLayout
