import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AppBar, Box, Slide } from '@mui/material'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import { useDeviceType } from '../../../rsmCoreComponents/hooks/useDeviceType'
import { setMenuBarIsHidden } from '../../../store/configuration/configurationSlice'
import { Styles } from '../../../types'
import CombinedMenus from '../CombinedMenus'

const styles: Styles = {
  appBar: (theme) => ({
    backgroundColor: theme.palette.common.white,
    zIndex: 1300,
  }),
  spacer: {
    height: '7.9375rem',
  },
}

type MainMenuProps = {
  currentItem?: string
}

const defaultProps = {
  currentItem: '',
}

interface SliderProps {
  children: React.ReactElement
}

const HideOnScroll = ({ children }: SliderProps) => {
  const dispatch = useDispatch()
  const trigger = useScrollTrigger()

  useEffect(() => {
    dispatch(setMenuBarIsHidden(trigger))
  }, [trigger])

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

const MainMenu = ({ currentItem }: MainMenuProps) => {
  const { isMobile, isTablet } = useDeviceType()

  return (
    <header>
      <Box sx={styles.spacer} />

      {/* We can't put AppBar into CombinedMenus :(  */}
      {isMobile || isTablet ? (
        <HideOnScroll>
          <AppBar sx={styles.appBar}>
            <CombinedMenus currentItem={currentItem} />
          </AppBar>
        </HideOnScroll>
      ) : (
        <AppBar sx={styles.appBar}>
          <CombinedMenus currentItem={currentItem} />
        </AppBar>
      )}
    </header>
  )
}

MainMenu.defaultProps = defaultProps

export default MainMenu
