/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { Box, styled } from '@mui/material'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import processAdobePageView from '../../analytics/adobe/pageView'
import { UserType } from '../../analytics/adobe/types'
import Faq from '../../components/domain/Support/Faq'
import SupportForm from '../../components/domain/Support/SupportForm/SupportForm'
import MainContent from '../../components/layouts/MainContent'
import MainMenu from '../../components/layouts/MainMenu'
import { Heading } from '../../components/typography'
import { useAITracking } from '../../rsmCoreComponents/hooks/useAITracking'
import { getUserInfo } from '../../store/userInfo/userInfoSlice'
import {
  getIsDashIssue,
  setIsDashIssue,
} from '../../store/support/supportSlice'
import { Styles } from '../../types'
import CallUsCard from '../../rsmCoreComponents/components/CallUsCard/CallUsCard'

const pageTitle = 'Support Page'

const styles: Styles = {
  container: (theme) => ({
    paddingTop: '1.25rem',
    paddingBottom: '1.25rem',
    [theme.breakpoints.only('mobile')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
    [theme.breakpoints.only('desktop')]: {
      paddingLeft: '6.5rem',
      paddingRight: '6.5rem',
    },
  }),
}

const StyledGrid = styled('div')(({ theme }) => ({
  display: 'grid',
  height: 'fit-content',
  marginTop: '2rem',
  gap: '1.25rem',
  [theme.breakpoints.only('tablet')]: {
    gridTemplateColumns: 'repeat(1,1fr)',
  },
  [theme.breakpoints.only('desktop')]: {
    gridTemplateColumns: 'repeat(3,1fr)',
    gap: '1.75rem',
  },
}))

const StyledFormGrid = styled('div')({
  gridColumn: 'span 2 / span 2',
})

const StyledCardsGrid = styled('div')(({ theme }) => ({
  display: 'grid',
  height: 'fit-content',
  gap: '1.25rem',
  justifyContent: 'start',
  [theme.breakpoints.only('tablet')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [theme.breakpoints.only('desktop')]: {
    gridTemplateColumns: 'repeat(1, 3fr)',
    gap: '1.75rem',
  },
  [theme.breakpoints.only('mobile')]: {
    gridColumn: 'span 2 / span 2',
  },
}))

const Support = () => {
  const { t } = useTranslation()
  const userInfo = useSelector(getUserInfo)
  const userType: UserType = userInfo.isEmployee ? 'Internal' : 'External'
  const isDashIssue = useSelector(getIsDashIssue)
  const internalPhone = '877-462-7573'
  const externalPhone = '833-518-3394'
  const supportPhone = userInfo.isEmployee ? internalPhone : externalPhone
  const supportDescriptionKey = userInfo.isEmployee
    ? 'CallUs.InternalDescription'
    : 'CallUs.ExternalDescription'

  const dispatch = useDispatch()
  useAITracking(t('ECPSupportTitle'), window.location.href)

  useEffect(() => {
    document.title = t('ECPSupportTitle')
    processAdobePageView(pageTitle, userType)
  }, [])

  useEffect(
    () => () => {
      dispatch(setIsDashIssue(false))
    },
    [],
  )

  return (
    <>
      <MainMenu currentItem="support" />
      <MainContent>
        <Box sx={styles.container}>
          <Heading variant="h1" data-testid="Hed_Support_SupportFormPage">
            {t('RSMSupportHeading')}
          </Heading>

          <StyledGrid>
            <StyledFormGrid>
              <SupportForm />
            </StyledFormGrid>

            <StyledCardsGrid>
              <Faq />
              {!isDashIssue && (
                <CallUsCard
                  isLoading={userInfo.isEmployee === undefined}
                  supportPhone={supportPhone}
                  supportDescriptionKey={supportDescriptionKey}
                />
              )}
            </StyledCardsGrid>
          </StyledGrid>
        </Box>
      </MainContent>
    </>
  )
}

export default Support
