import React from 'react'
import { Box } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { PaymentActivityModel } from '@rsmus/ecp-financeservice'
import { useTranslation } from 'react-i18next'
import { Styles } from '../../../types'

const styles: Styles = {
  activityFeedItemContainer: {
    marginTop: '1rem',
  },
  activityFeedItemHeader: {
    fontWeight: 700,
    fontSize: '1rem',
    lineWeight: '1.5rem',
    marginBottom: '0.3rem',
  },
  activityFeedItemHeaderError: (theme) => ({
    fontWeight: 700,
    fontSize: '1rem',
    lineWeight: '1.5rem',
    marginBottom: '0.3rem',
    color: theme.palette.error.main,
    display: 'flex',
  }),
  activityFeedItemDate: {
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: '0.875rem',
    lineWeight: '1rem',
  },
  activityFeedItemBody: {
    fontWeight: 400,
    fontSize: '1rem',
    lineWeight: '1.2rem',
  },
}

interface ActivityFeedItemProps {
  items: PaymentActivityModel[]
}

const ActivityFeedItem = ({ items }: ActivityFeedItemProps) => {
  const dateToday = new Date()
  const { t } = useTranslation()

  const calcNumDays = (date1: Date, date2: Date) => {
    // To calculate the time difference of two dates
    const differenceInTime = date2.getTime() - date1.getTime()
    // To calculate the no. of days between two dates
    const differenceInDays = Math.trunc(differenceInTime / (1000 * 3600 * 24))
    return differenceInDays
  }

  return (
    <Box role="feed" tabIndex={0}>
      {items.map((item) => (
        <article key={`key-${item.invoiceNumber}`}>
          <Box sx={styles.activityFeedItemContainer}>
            <Box
              sx={
                item.status === 'Overdue'
                  ? styles.activityFeedItemHeaderError
                  : styles.activityFeedItemHeader
              }>
              {item.status === 'Overdue' && (
                <>
                  {t('Invoicing.ActivityFeed.InvoicePastDue')}
                  <Box sx={{ marginLeft: '0.5rem' }}>
                    <FontAwesomeIcon icon={faClock} />
                  </Box>
                </>
              )}
              {item.status === 'PaymentScheduled' &&
                t('Invoicing.ActivityFeed.PaymentScheduled')}
              {item.status === 'New' && t('Invoicing.ActivityFeed.NewInvoice')}
              {(item.status === 'PaidInFull' ||
                item.status === 'PartialPaymentPosted') &&
                t('Invoicing.ActivityFeed.InvoicePaymentProcessed')}
            </Box>
            <Box sx={styles.activityFeedItemDate}>
              {`${item.invoiceDate?.toLocaleString('en-us', {
                month: 'short',
              })} ${item.invoiceDate?.getDate()}, ${item.invoiceDate?.getFullYear()} ${t(
                'Invoicing.ActivityFeed.At',
              )} ${item.invoiceDate?.toLocaleTimeString(['en-US'], {
                hour: 'numeric',
                minute: '2-digit',
              })}`}
            </Box>
            <Box sx={styles.activityFeedItemBody}>
              {item.dueDate &&
                dateToday > item.dueDate &&
                `${item.invoiceNumber} ${
                  item.companyName
                } $${item.invoiceAmount?.toFixed(2)} ${calcNumDays(
                  item.dueDate,
                  dateToday,
                )} ${t('Invoicing.ActivityFeed.DaysOverdue')}`}
              {item.scheduledPaymentDate &&
                `${item.invoiceNumber} ${
                  item.companyName
                } $${item.invoiceAmount?.toFixed(2)} ${t(
                  'Invoicing.ActivityFeed.ScheduledPaymentFor',
                )} ${item.scheduledPaymentDate?.toLocaleDateString()}`}
              {(item.status === 'PaidInFull' ||
                item.status === 'PartialPaymentPosted') &&
                `${item.invoiceNumber} $${item.invoiceAmount?.toFixed(2)}`}
              {!item.lastPaymentDate &&
                !item.scheduledPaymentDate &&
                item.dueDate &&
                item.dueDate > dateToday &&
                `${item.invoiceNumber} ${
                  item.companyName
                } $${item.invoiceAmount?.toFixed(2)}`}
            </Box>
          </Box>
        </article>
      ))}
    </Box>
  )
}

export default ActivityFeedItem
