import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '..'
import { UserAppModel } from '../../api/idm/getAllUserApplications'

interface UserAppsState {
  UserApps: UserAppModel[]
  loading: boolean
  called: boolean
  error?: Error
}

const initialState: UserAppsState = {
  UserApps: [] as UserAppModel[],
  loading: false,
  called: false,
}

export const userAppsSlice = createSlice({
  name: 'userApps',
  initialState,
  reducers: {
    setUserApps: (state, { payload }) => {
      state.UserApps = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    setCalled: (state, { payload }) => {
      state.called = payload
    },
    setError: (state, { payload }) => {
      state.error = payload
    },
  },
})

export const getUserApps = (state: RootState) => state.userApps.UserApps
export const getCalled = (state: RootState) => state.userApps.called
export const getLoading = (state: RootState) => state.userApps.loading
export const getError = (state: RootState) => state.userApps.error
export const { setUserApps, setCalled, setLoading, setError } =
  userAppsSlice.actions

export default userAppsSlice.reducer
