import { Box } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDeviceType } from '../../../rsmCoreComponents/hooks/useDeviceType'
import tokens from '../../../styles/tokens.json'
import { Styles } from '../../../types'
import SectionHeader from '../../layouts/SectionHeader'
import SearchBar from './SearchBox'

const styles: Styles = {
  mainContainer: (theme) => ({
    display: 'flex',
    height: '11.1875rem',
    maxWidth: '49rem',
    [theme.breakpoints.only('mobile')]: {
      height: '9.8125rem',
      padding: '1rem',
    },
    [theme.breakpoints.only('tablet')]: {
      maxWidth: '50.625rem',
    },
    background: theme.palette.common.white,
    border: `1px solid ${tokens.colors.rsmGray.disabled}`,
    padding: '1.5rem',
    fontFamily: 'prelo-book',
    fontWeight: 500,
  }),
  contentContainer: { display: 'flex-initial', width: '100%' },
  displayContent: (theme) => ({
    marginTop: '1.5rem',
    marginBottom: '1.5rem',
    height: '50px',
    [theme.breakpoints.up('tablet')]: {
      width: '95%',
    },
  }),
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function DummySetFilter(s: string) {}

const InvoiceSearch = () => {
  const { isMobile } = useDeviceType()
  const { t } = useTranslation()

  return (
    <Box sx={styles.mainContainer} data-testid="Inv_InvoiceSearch">
      <Box sx={styles.contentContainer}>
        <SectionHeader
          title={t('Invoicing.FindAnInvoice')}
          testId="Lbl_InvoiceSearch_Header"
        />
        <Box sx={styles.displayContent}>
          <SearchBar
            handleClick={() => {}}
            placeholderText={
              isMobile
                ? t('Invoicing.EnterInvoiceOrKeywords')
                : t('Invoicing.EnterInvoiceOrKeywordsSeperateCommas')
            }
            testId="Txt_InvoiceSearch_SearchBox"
          />
        </Box>
      </Box>
    </Box>
  )
}

export default InvoiceSearch
