import { Box, Button } from '@mui/material'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import SectionHeader from '../../layouts/SectionHeader'
import tokens from '../../../styles/tokens.json'
import { Styles } from '../../../types'
import { PenIcon } from '../../icons'

const styles: Styles = {
  mainContainer: (theme) => ({
    display: 'flex',
    minHeight: '19rem',
    maxWidth: '24rem',
    background: theme.palette.common.white,
    border: `0.0625rem solid ${tokens.colors.rsmGray.disabled}`,
    padding: '1.5rem',
    fontFamily: 'prelo-book',
    fontWeight: 500,
    [theme.breakpoints.up('tablet')]: {
      minWidth: '24rem',
    },
  }),
  contentContainer: {
    width: '100%',
    fontSize: '1.125rem',
    lineHeight: '2.25rem',
    fontStyle: 'normal',
  },
  displayContent: {
    marginTop: '1.125rem',
    marginBottom: '1.125rem',
    hr: (theme) => ({
      marginTop: '0.875rem',
      borderColor: theme.palette.grey[500],
    }),
  },
  itemIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
    marginRight: '1rem',
  },
  grid: (theme) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    [theme.breakpoints.only('mobile')]: {
      gridTemplateColumns: '65% 35%',
    },
  }),
  response: {
    display: 'flex',
    justifyContent: 'right',
  },
}

interface PaymentSettingsProps {
  userAgreementAccepted: boolean
}

const PaymentSettings = ({ userAgreementAccepted }: PaymentSettingsProps) => {
  const { t } = useTranslation()
  const renderDisplayContent = useCallback(
    (
      settingText: string,
      settingResponse: string,
      displayEdit: boolean,
      settingResponseColor = '',
    ) => (
      <Box sx={styles.displayContent}>
        <Box sx={styles.grid}>
          {settingText}:
          {userAgreementAccepted && (
            <Box sx={styles.grid}>
              <Box
                sx={styles.response}
                style={{
                  color: `${
                    settingResponseColor.length > 0
                      ? settingResponseColor
                      : tokens.colors.rsmText
                  }`,
                }}>
                {settingResponse}
              </Box>
              {displayEdit && (
                <Button
                  sx={styles.itemIcon}
                  data-testid="Btn_Pen_EditPaymentSetting"
                  aria-label={t('Inv.PaymentSettings.Edit')}
                  onClick={() => {}}>
                  <PenIcon />
                </Button>
              )}
            </Box>
          )}
        </Box>
        <hr aria-hidden="true" />
      </Box>
    ),
    [userAgreementAccepted],
  )

  return (
    <Box sx={styles.mainContainer} data-testid="Inv_PaymentSettings">
      <Box sx={styles.contentContainer}>
        <SectionHeader
          title={t('Invoicing.PaymentSettings')}
          testId="Lbl_PaymentSettings_Header"
        />
        {renderDisplayContent(t('Invoicing.AutoPay'), 'Yes', true)}
        {renderDisplayContent(t('Invoicing.PaymentType'), 'ACH', true)}
        {renderDisplayContent(
          t('Invoicing.ScheduledPayments'),
          '14',
          false,
          '#3F9C35',
        )}
      </Box>
    </Box>
  )
}

export default PaymentSettings
