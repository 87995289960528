import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react'
import {
  Radio,
  RadioGroup,
  Box,
  Select,
  styled,
  Button,
  FormControlLabel,
  FormHelperText,
  Autocomplete,
  TextField,
} from '@mui/material'
import {
  InvoicePayerViewModel,
  PayerPaymentAccountViewModel,
} from '@rsmus/ecp-financeservice'
import { CheckCircle } from '@mui/icons-material'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { useDeviceType } from '../../../rsmCoreComponents/hooks/useDeviceType'
import InvoicesHeader from './InvoicesHeader'
import { ArrowDownIcon } from '../../icons'
import { Styles } from '../../../types'
import TermsAndConditionsDialog from './TermsAndConditionsDialog'
import {
  getPayableAmount,
  getInvoicePayments,
  setPaymentTransaction,
  setInvoicePayments,
  getInvoicePayers,
} from '../../../store/invoices/invoiceSelectedInvoicesSlice'
import {
  setSelectedBankAccount,
  setSelectedCreditCard,
  setSelectedPayerId,
  getSelectedBankAccount,
  getSelectedCreditCard,
  getSelectedPayerId,
  getSelectedPaymentMethod,
  setAccountSelectedType,
  SelectedAccountTypeState,
  getDoNotSave,
  setNewPaymentMethod,
  getPaymentMethodError,
  setPaymentMethodError,
} from '../../../store/invoices/paymentInfoSlice'
import CustomErrorAlert from '../../forms/Alert/CustomErrorAlert/CustomErrorAlert'
import api from '../../../api'
import PaymentGatewayModal from '../Payments/PaymentGatewayModal'
import {
  PAYMENT_GATEWAY_IFRAME_URL,
  REACT_APP_INSTALLMENT_FREQUENCIES,
} from '../../../envVariables'
import { getUserInfo } from '../../../store/userInfo/userInfoSlice'
import Spinner from '../../forms/Spinner/Spinner'
import PaymentMethod from './PaymentMethod'
import PaymentDate from './PaymentDate'
import { tokens } from '../../../styles/materialTheme'
import { getHighContrast } from '../../../store/userSettings/userSettingsSlice'

const styles: Styles = {
  PaymentTypeContainer: (theme) => ({
    color: theme.palette.text.primary,
    fontFamily: 'Prelo-Book, sans-serif',
    [theme.breakpoints.only('desktop')]: {
      paddingLeft: '6.5rem',
      paddingRight: '6.5rem',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
    [theme.breakpoints.only('mobile')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
  }),
  PaymentTypeSelection: (theme) => ({
    paddingBottom: '3rem',
    paddingTop: '3rem',
    [theme.breakpoints.only('mobile')]: {
      paddingTop: '1rem',
    },
  }),
  PaymentMethodSelection: (theme) => ({
    width: '50%',
    [theme.breakpoints.only('mobile')]: {
      width: '100%',
    },
  }),
  FrequencyContainer: (theme) => ({
    width: '50%',
    paddingTop: 0,
    [theme.breakpoints.only('mobile')]: {
      width: 'calc(112% - 0.01rem)',
      paddingLeft: 0,
    },
    [theme.breakpoints.only('tablet')]: {
      width: 'calc(90% - 1.9rem)',
      paddingLeft: 0,
    },
  }),
  InstallmentsContainer: (theme) => ({
    width: '50%',
    paddingTop: 0,
    paddingbottom: '2rem',
    [theme.breakpoints.only('mobile')]: {
      width: 'calc(112% - 0.01rem)',
      paddingLeft: 0,
    },
    [theme.breakpoints.only('tablet')]: {
      width: 'calc(90% - 1.9rem)',
      paddingLeft: 0,
    },
  }),
  SelectPaymentMethodContainer: (theme) => ({
    width: '50%',
    paddingTop: 0,
    [theme.breakpoints.only('mobile')]: {
      width: 'calc(110% - 0.01rem)',
      paddingLeft: 0,
      paddingTop: '3rem',
    },
    [theme.breakpoints.only('tablet')]: {
      width: 'calc(89% - 1.9rem)',
      paddingLeft: 0,
      paddingTop: '3rem',
    },
  }),
  SelectPaymentDateContainer: (theme) => ({
    width: 'calc(50% - 4rem)',
    paddingLeft: '0',
    paddingTop: '0.5rem',
    [theme.breakpoints.only('mobile')]: {
      width: 'calc(100% - 1.9rem)',
      paddingLeft: 0,
      paddingTop: '0.09rem',
    },
    [theme.breakpoints.only('tablet')]: {
      width: 'calc(82.1% - 1.9rem)',
      paddingLeft: 0,
      paddingTop: '0.09rem',
    },
  }),
  ConfirmPaymentContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  TermsAndConditionsButton: (theme) => ({
    marginRight: '1rem',
    marginBottom: 0,
    [theme.breakpoints.only('mobile')]: {
      marginRight: 0,
      marginBottom: '1.5rem',
      paddingRight: '1rem',
      paddingLeft: '1rem',
    },
  }),
  ButtonContainer: (theme) => ({
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: '6rem',
    width: '100%',
    backgroundColor: 'white',
    marginBottom: '2rem',
    [theme.breakpoints.only('mobile')]: {
      display: 'inline-block',
    },
  }),
  CheckCircle: (theme) => ({
    marginRight: '.5rem',
    [theme.breakpoints.only('mobile')]: {
      marginRight: '.25rem',
    },
  }),
  ConfirmPaymentButton: {
    '&.Mui-disabled': {
      color: '#FFFFFF',
    },
  },
  requiredError: {
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '0.875rem',
    color: tokens.colors.rsmRed.secondary,
  },

  AccountInformationSubLabel: (theme) => ({
    fontFamily: 'Prelo, sans-serif',
    fontSize: '1rem',
    fontStyle: 'italic',
    fontWeight: '300',
    paddingBottom: '1.5rem',
    color: theme.palette.text.primary,
    display: 'block',
  }),
  SelectPaymentpayerContainer: (theme) => ({
    width: 'calc(69% - 4rem)',
    marginTop: '0.5rem',
    [theme.breakpoints.only('mobile')]: {
      width: 'calc(100% - 1.9rem)',
      paddingLeft: 0,
      paddingBottom: '0.05rem',
    },
    [theme.breakpoints.only('tablet')]: {
      width: 'calc(88.1% - 1.9rem)',
      paddingLeft: 0,
    },
  }),
}

const StyledHeading = styled('h3')(() => ({
  fontFamily: 'Prelo-Bold, sans-serif',
  fontSize: '1.25rem',
  lineHeight: '1.5rem',
  paddingBottom: '1.25rem',
}))

const StyledSelectField = styled(Select)({
  '.MuiOutlinedInput-root': {
    width: '100%',
    maxWidth: '100%',
  },
  '.MuiSelect-select': { fontFamily: 'Prelo-Book !important' },
  width: 'calc(100% - 4rem)',
})

const StyledSubHeading = styled('h2')(() => ({
  fontFamily: 'Prelo-Bold, sans-serif',
  fontSize: '1.25rem',
  lineHeight: '1.5rem',
  paddingBottom: '1.25rem',
}))

const StyledLegend = styled('legend')(({ theme }) => ({
  fontFamily: 'Prelo-Black, sans-serif',
  fontSize: '1rem',
  paddingBottom: '0.5rem',
  color: theme.palette.text.primary,
  display: 'block',
}))

const StyledLabel = styled('label')(({ theme }) => ({
  fontFamily: 'Prelo-Black, sans-serif',
  fontSize: '1rem',
  paddingBottom: '0.5rem',
  color: theme.palette.text.primary,
  display: 'block',
}))

const BodyArea = styled('div')(() => ({
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'row',
  flexBasis: 'auto',
  flexWrap: 'wrap',
}))

const FormArea = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexBasis: 'auto',
  backgroundColor: 'white',
  [theme.breakpoints.down('desktop')]: {
    width: '100%',
  },
}))

const AutopayArea = styled('div')(() => ({
  backgroundColor: 'white',
  width: '33%',
}))

const RowAreaFixed = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexBasis: 'auto',
}))

const RowArea = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexBasis: 'auto',
  [theme.breakpoints.only('mobile')]: {
    flexDirection: 'column',
  },
}))

const PaymentType = () => {
  const {
    handleSubmit,
    reset,
    control,
    clearErrors,
    getValues,
    setValue,
    formState: { errors, isSubmitting },
    setFocus,
    watch,
  } = useForm({
    mode: 'onSubmit',
    shouldFocusError: false, // This is handled manually below
    reValidateMode: 'onChange',
  })
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [paymentGatewayIframeSrc, setPaymentGatewayIframeSrc] = useState('')
  const [isUserOpeningPaymentModal, setIsUserOpeningModal] = useState(false)
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false)
  const payableAmount = useSelector(getPayableAmount)
  const invoicePayments = useSelector(getInvoicePayments)
  const invoicePayers = useSelector(getInvoicePayers)
  const { t } = useTranslation()
  const { isDesktop, isMobile } = useDeviceType()
  const paymentFrequencies = [
    t('Invoicing.PayNow'),
    t('Invoicing.SchedulePayment'),
    t('Invoicing.SetupInstallments'),
  ]
  const [selectedPaymentFrequency, setSelectedPaymentFrequency] =
    useState<string>(t('Invoicing.PayNow'))
  const doNotSave = useSelector(getDoNotSave)
  const paymentMethods = [t('Invoicing.ACH'), t('Invoicing.CreditCard')]
  const selectedBankAccount = useSelector(getSelectedBankAccount)
  const selectedCreditCard = useSelector(getSelectedCreditCard)
  const selectedPayerId = useSelector(getSelectedPayerId)
  const selectedPaymentMethod = useSelector(getSelectedPaymentMethod)
  const paymentMethodError = useSelector(getPaymentMethodError)

  const [
    acceptedSingleTermsAndConditions,
    setAcceptedSingleTermsAndConditions,
  ] = useState<boolean>(false)
  const [
    acceptedRecurringTermsAndConditions,
    setAcceptedRecurringTermsAndConditions,
  ] = useState<boolean>(false)
  const [singleTermsRead, setSingleTermsRead] = useState(false)
  const [recurringTermsRead, setRecurringTermsRead] = useState(false)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [payerBankAccounts, setPayerBankAccounts] = useState<
    PayerPaymentAccountViewModel[]
  >([])
  const [payerCreditCards, setPayerCreditCards] = useState<
    PayerPaymentAccountViewModel[]
  >([])
  const [sessionId, setSessionId] = useState('')
  const [openAlert, setOpenAlert] = useState(false)
  const [isSuccess, setIsSuccess] = useState(true)
  const [confirmPaymentClicked, setConfirmPaymentClicked] = useState(false)
  const [isGenericError, setIsGenericError] = useState(false)
  const confirmButtonRef = useRef<HTMLButtonElement>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const unavailbleStatus: SelectedAccountTypeState = 'Unavailable'
  const userInfo = useSelector(getUserInfo)
  const isHighContrast = useSelector(getHighContrast)
  const installmentFrequencies = useMemo(
    () => JSON.parse(REACT_APP_INSTALLMENT_FREQUENCIES || '{}'),
    [],
  )

  // For convenience, this section derives some values from other state conditions.
  const isRecurring = selectedPaymentFrequency !== t('Invoicing.PayNow')
  const acceptedTermsAndConditions = isRecurring
    ? acceptedRecurringTermsAndConditions
    : acceptedSingleTermsAndConditions
  const hasUserReadTerms = isRecurring ? recurringTermsRead : singleTermsRead

  const installmentRange = useCallback(() => {
    const installmentInfo = installmentFrequencies.frequencies?.filter(
      (x: { name: string }) => x.name === watch('frequency'),
    )[0]?.installments

    if (!installmentInfo) return []
    const { min, max, step } = installmentInfo
    const newMax = userInfo.isEmployee ? max * 2 : max
    return Array.from(
      { length: (newMax - min) / step + 1 },
      (_, i) => min + i * step,
    )
  }, [installmentFrequencies, watch, userInfo])

  const loadPayerAccountData = async (
    payerId: string,
    paymentMethod: string,
  ) => {
    if (
      payerId !== undefined &&
      payerId !== '' &&
      paymentMethod === t('Invoicing.ACH')
    ) {
      const bankAccounts =
        (await api.finance.payment_GetPayerBankAccounts(payerId))?.data || []
      setPayerBankAccounts(bankAccounts)
    } else if (
      payerId !== undefined &&
      payerId !== '' &&
      paymentMethod === t('Invoicing.CreditCard')
    ) {
      const creditCards =
        (await api.finance.payment_GetPayerCreditCards(payerId))?.data || []
      setPayerCreditCards(creditCards)
    }
  }

  const handleBackToPreviousPage = () => {
    const unselected: SelectedAccountTypeState = 'Unselected'
    setPayerBankAccounts([])
    setPayerCreditCards([])
    dispatch(setSelectedBankAccount(undefined))
    dispatch(setSelectedCreditCard(undefined))
    dispatch(setSelectedPayerId(undefined))
    dispatch(setAccountSelectedType(unselected))
    dispatch(setNewPaymentMethod(false))
    navigate('/invoicing/invoices/pay-invoices')
  }

  const handleClose = useCallback(() => {
    if (!confirmPaymentClicked) {
      setOpenAlert(false)
      confirmButtonRef.current?.focus()
    } else {
      setConfirmPaymentClicked(false)
    }
  }, [
    setOpenAlert,
    setConfirmPaymentClicked,
    confirmButtonRef,
    confirmPaymentClicked,
  ])

  const onDecline = useCallback(
    (wereTermsRead: boolean) => {
      setOpenDialog(false)
      if (isRecurring) {
        setRecurringTermsRead(wereTermsRead)
      } else {
        setSingleTermsRead(wereTermsRead)
      }
    },
    [setOpenDialog, isRecurring, setRecurringTermsRead, setSingleTermsRead],
  )

  const onAccept = useCallback(() => {
    if (isRecurring) {
      setAcceptedRecurringTermsAndConditions(true)
    } else {
      setAcceptedSingleTermsAndConditions(true)
    }
    setOpenDialog(false)
  }, [
    isRecurring,
    setAcceptedRecurringTermsAndConditions,
    setAcceptedSingleTermsAndConditions,
    setOpenDialog,
  ])

  const handlePayerChange = async (payerId: string) => {
    const unselected: SelectedAccountTypeState = 'Unselected'
    setPayerBankAccounts([])
    setPayerCreditCards([])
    dispatch(setSelectedBankAccount(undefined))
    dispatch(setSelectedCreditCard(undefined))
    dispatch(setSelectedPayerId(payerId))
    dispatch(setAccountSelectedType(unselected))
    loadPayerAccountData(payerId, selectedPaymentMethod)
  }

  const getPaymentMethod = () => {
    switch (selectedPaymentMethod.toUpperCase()) {
      case 'ACH':
        return 'CHECK'
      case 'CREDIT CARD':
        return userInfo.isEmployee ? 'SRED' : 'CARD'
      default:
        return ''
    }
  }

  const addPaymentMethod = async () => {
    setIsLoading(true)
    const paymentMethodIndex =
      selectedPaymentMethod === t('Invoicing.ACH')
        ? payerBankAccounts.length
        : payerCreditCards.length
    try {
      const sessionTokenId = await api.finance.payment_GetPaymentTokenSessionId(
        selectedPayerId.replaceAll('-', ''),
        getPaymentMethod(),
        paymentMethodIndex + 1,
        isHighContrast,
      )

      if (sessionTokenId.data) {
        setSessionId(sessionTokenId.data)
        setIsUserOpeningModal(true)
      } else {
        setIsGenericError(true)
      }
    } catch (error) {
      setIsGenericError(true)
    }
    setIsLoading(false)
  }

  const closeErrorAlert = () => {
    setIsGenericError(false)
  }

  const closePaymentMethodAlert = () => {
    dispatch(setPaymentMethodError(false))
  }

  const closePaymentModal = () => {
    setIsUserOpeningModal(false)
    setIsPaymentModalOpen(false)
  }

  const handleConfirmPayment = useCallback(async () => {
    if (acceptedTermsAndConditions === false) {
      setOpenDialog(true)
      return
    }
    if (openAlert) {
      setConfirmPaymentClicked(true)
    }
    setIsLoading(true)

    try {
      if (
        getValues('paymentType') === t('Invoicing.SchedulePayment') ||
        getValues('paymentType') === t('Invoicing.SetupInstallments')
      ) {
        const response = await api.finance.payment_SchedulePayment({
          amount: payableAmount,
          scheduledDate: getValues('paymentDate'),
          clientPaymentMethodId:
            selectedPaymentMethod === paymentMethods[0]
              ? selectedBankAccount ?? 0
              : selectedCreditCard ?? 0,
          invoices: invoicePayments.map((invoice) => ({
            invoiceId: invoice.id,
            invoiceNumber: invoice.invoiceNumber,
            amount: invoice.paidAmount,
          })),
        })

        const paymentTransaction = {
          ...response?.data,
          payer: invoicePayers.find(
            (payer) => payer.payerId === selectedPayerId,
          )?.name,
          paymentMethod: selectedPaymentMethod,
          scheduledPaymentDate: getValues('paymentDate'),
        }

        const invoicesWithPayments = invoicePayments.map((invoice) => ({
          ...invoice,
          status: 'Open',
        }))
        dispatch(setInvoicePayments(invoicesWithPayments))
        dispatch(setPaymentTransaction(paymentTransaction))

        setIsSuccess(true)
        reset()
        navigate('/invoicing/invoices/payment-success')
      } else {
        const response = await api.finance.payment_ConfirmPayment({
          clientPaymentMethodId:
            selectedPaymentMethod === paymentMethods[0]
              ? selectedBankAccount ?? 0
              : selectedCreditCard ?? 0,
          amount: payableAmount,
          doNotSave,
          invoices: invoicePayments.map((invoice) => ({
            invoiceId: invoice.id,
            invoiceNumber: invoice.invoiceNumber,
            amount: invoice.paidAmount,
          })),
        })

        if (response?.data?.isSuccess || false) {
          const paymentTransaction = {
            ...response?.data,
            payer: invoicePayers.find(
              (payer) => payer.payerId === selectedPayerId,
            )?.name,
            paymentMethod: selectedPaymentMethod,
          }

          const invoicesWithPayments = invoicePayments.map((invoice) => ({
            ...invoice,
            openAmount: invoice.openAmount - invoice.paidAmount,
            status:
              invoice.openAmount - invoice.paidAmount > 0 ? 'Open' : 'Closed',
          }))
          dispatch(setInvoicePayments(invoicesWithPayments))
          dispatch(setPaymentTransaction(paymentTransaction))

          setIsSuccess(true)
          reset()
          navigate('/invoicing/invoices/payment-success')
        } else {
          setIsSuccess(false)
          setOpenAlert(true)
        }
      }
    } catch {
      setIsSuccess(false)
      setOpenAlert(true)
    }
    setIsLoading(false)
  }, [
    acceptedTermsAndConditions,
    openAlert,
    payableAmount,
    selectedPaymentMethod,
    selectedBankAccount,
    selectedCreditCard,
    invoicePayments,
    invoicePayers,
    selectedPayerId,
    getValues,
    dispatch,
    reset,
    navigate,
  ])

  const refreshPaymentData = (payerId: string, paymentMethod: string) => {
    loadPayerAccountData(payerId, paymentMethod)
  }

  const handleRadioChange = useCallback(() => {
    const val = getValues('paymentType')
    setValue('payer', '')
    setValue('paymentDate', '')
    setValue('numberOfInstallments', '')
    setValue('frequency', '')
    setValue('paymentMethod', '')
    clearErrors([
      'payer',
      'paymentDate',
      'numberOfInstallments',
      'frequency',
      'paymentMethod',
    ])
    setSelectedPaymentFrequency(val)
    setPayerBankAccounts([])
    setPayerCreditCards([])
    dispatch(setSelectedBankAccount(undefined))
    dispatch(setSelectedCreditCard(undefined))
    dispatch(setSelectedPayerId(undefined))
  }, [dispatch, getValues, clearErrors])

  useEffect(() => {
    if (isUserOpeningPaymentModal && paymentGatewayIframeSrc) {
      setIsPaymentModalOpen(true)
    }
  }, [isUserOpeningPaymentModal, paymentGatewayIframeSrc])

  useEffect(() => {
    if (
      (selectedPayerId !== '' &&
        selectedPaymentMethod === t('Invoicing.ACH') &&
        payerBankAccounts.length === 0) ||
      (selectedPayerId !== '' &&
        selectedPaymentMethod === t('Invoicing.CreditCard') &&
        payerCreditCards.length === 0)
    ) {
      loadPayerAccountData(selectedPayerId, selectedPaymentMethod)
    }
  }, [selectedPaymentMethod])

  useEffect(() => {
    if (!sessionId) {
      setPaymentGatewayIframeSrc('')
      setIsUserOpeningModal(false)
    } else {
      const REPADDDATACARD_QUERY_PARAMS = `?SESSIONID=${sessionId}${'&PAGE=HOSTEDPAYMENT'}`
      setPaymentGatewayIframeSrc(
        `${PAYMENT_GATEWAY_IFRAME_URL}${REPADDDATACARD_QUERY_PARAMS}`,
      )
    }
  }, [sessionId])

  useEffect(() => {
    // Prevent user from accessing this page directly.
    if (invoicePayments.length === 0) {
      navigate('/invoicing')
    }

    dispatch(setAccountSelectedType(unavailbleStatus)) // TODO update slice so payload is of tye SelectedAccountTypeSate and not Any
  }, [])

  // unmount useEffect to reset store values when navigating away from this page
  useEffect(
    () => () => {
      dispatch(setSelectedBankAccount(0))
      dispatch(setSelectedCreditCard(0))
      dispatch(setSelectedPayerId(''))
    },
    [],
  )

  // When selected credit card or bank account has been updated programmatically, set the value in the form.
  useEffect(() => {
    const paymentMethodType =
      selectedPaymentMethod === paymentMethods[0] ? 'BankAccount' : 'CreditCard'
    setValue(
      'paymentMethod',
      paymentMethodType === 'BankAccount'
        ? selectedBankAccount
        : selectedCreditCard,
    )
  }, [selectedBankAccount, selectedCreditCard])

  // Focus on first error
  useEffect(() => {
    // Don't focus on first render
    if (!confirmButtonRef.current) {
      return
    }

    // Don't focus if not submitting
    if (!isSubmitting) {
      return
    }

    // Don't focus if no errors
    if (!errors || Object.keys(errors).length === 0) {
      return
    }

    // Define the field order (we have to do this because the hidden fields get registered in a different order than they appear on the page)
    const fieldOrder = [
      'payer',
      'paymentDate',
      'frequency',
      'numberOfInstallments',
      'paymentMethod',
    ]

    // Focus on first error
    const firstError = fieldOrder.find((field) => errors[field])
    if (firstError) {
      setFocus(String(firstError))
    }
  }, [errors, setFocus, isSubmitting])

  return (
    <>
      <Spinner open={isLoading} />
      {!isSuccess && (
        <CustomErrorAlert
          open={openAlert}
          close={handleClose}
          disableAutoHide
          header="Alert.PaymentFailed"
          message="Alert.PaymentUnableToProcess"
        />
      )}
      <CustomErrorAlert
        header="Alert.GenericErrorHeader"
        open={isGenericError}
        close={closeErrorAlert}
        disableAutoHide
        message="Alert.GenericErrorMessage"
      />
      <CustomErrorAlert
        open={paymentMethodError}
        close={closePaymentMethodAlert}
        disableAutoHide
        header="Alert.PaymentMethodErrorHeader"
        message="Alert.PaymentMethodError"
      />
      <InvoicesHeader
        primaryHeaderId="PaymentType"
        primaryHeaderText={t('Invoicing.Payment')}
        primaryHeaderDescription={
          isMobile
            ? t('Invoicing.PaymentInfo')
            : t('Invoicing.PaymentSelectionWillApply')
        }
        backButtonId="BackToPreviousPage"
        backButtonText={t('Invoicing.BackToPreviousPage')}
        handleNavigation={handleBackToPreviousPage}
        amountHeaderId="TotalPayableAmount"
        amountHeaderText={t('Invoicing.TotalPayableAmount')}
        amount={payableAmount}
      />
      <form onSubmit={handleSubmit(handleConfirmPayment)}>
        <BodyArea sx={styles.PaymentTypeContainer}>
          <FormArea>
            <Box style={{ padding: '0 ', width: '100%' }}>
              <Box sx={styles.PaymentTypeSelection}>
                <StyledSubHeading>
                  {t('Invoicing.PaymentInformationHeader')}
                </StyledSubHeading>
                <StyledLegend>{t('Invoicing.whenWillYouPay')}</StyledLegend>
                <Controller
                  name="paymentType"
                  control={control}
                  defaultValue={paymentFrequencies[0]}
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      defaultValue={paymentFrequencies[0]}
                      onChange={(val) => {
                        onChange(val)
                        handleRadioChange()
                      }}
                      value={value}>
                      <RowArea>
                        {paymentFrequencies.map((option: string) => (
                          <RowAreaFixed key={option}>
                            <FormControlLabel
                              sx={{ marginRight: '3.5rem' }}
                              value={option}
                              label={option}
                              control={<Radio />}
                            />
                          </RowAreaFixed>
                        ))}
                      </RowArea>
                    </RadioGroup>
                  )}
                />
              </Box>
              <RowArea sx={{ paddingBottom: '0.10rem' }}>
                <Box>
                  <StyledLabel htmlFor="payer">
                    {t('Invoicing.Payer')}
                    <span className="sr-only">{t('srOnlyRequired')}</span>
                  </StyledLabel>
                  <div id="payerMessage">
                    <i>{t('Invoicing.PayerMessage')}</i>
                  </div>
                  <Controller
                    name="payer"
                    control={control}
                    defaultValue={null}
                    rules={{ required: `${t('Invoicing.PayerRequired')}` }}
                    render={({
                      field: { onChange, value, ref },
                      fieldState: { error },
                    }) => (
                      <>
                        <Autocomplete
                          popupIcon={<ArrowDownIcon />}
                          id="payer"
                          fullWidth
                          value={value}
                          onChange={(
                            event: any,
                            newValue: InvoicePayerViewModel | null,
                          ) => {
                            onChange(newValue)
                            handlePayerChange(newValue?.payerId || '')
                          }}
                          options={invoicePayers}
                          getOptionLabel={(payer) =>
                            payer ? `${payer.name} ( ${payer.payerId} )` : ''
                          }
                          isOptionEqualToValue={(option, val) =>
                            option?.payerId === val?.payerId
                          }
                          sx={styles.SelectPaymentpayerContainer}
                          renderInput={(params) => (
                            <TextField
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...params}
                              inputRef={ref}
                              error={!!error}
                              inputProps={{
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                ...params.inputProps,
                                'aria-describedby': error
                                  ? 'payerErrorText'
                                  : 'payerMessage',
                              }}
                              placeholder={t('searchByPayor')}
                            />
                          )}
                        />
                        {error && (
                          <FormHelperText
                            id="payerErrorText"
                            sx={styles.requiredError}>
                            {error.message}
                          </FormHelperText>
                        )}
                      </>
                    )}
                  />
                </Box>
              </RowArea>
              <RowArea sx={{ marginTop: '1rem', paddingBottom: '3rem' }}>
                {(selectedPaymentFrequency === t('Invoicing.SchedulePayment') ||
                  selectedPaymentFrequency ===
                    t('Invoicing.SetupInstallments')) && (
                  <Box sx={styles.SelectPaymentDateContainer}>
                    <PaymentDate
                      control={control}
                      labelKey={
                        getValues('paymentType') ===
                        t('Invoicing.SetupInstallments')
                          ? 'Invoicing.PaymentsStartDate'
                          : 'Invoicing.PaymentDate'
                      }
                    />
                  </Box>
                )}
              </RowArea>
              {selectedPaymentFrequency ===
                t('Invoicing.SetupInstallments') && (
                <RowArea sx={{ paddingBottom: '3rem' }}>
                  <Box sx={styles.FrequencyContainer}>
                    <StyledLabel htmlFor="frequency">
                      {t('Invoicing.Frequency')} *
                      <span className="sr-only">{t('srOnlyRequired')}</span>
                    </StyledLabel>
                    <Controller
                      name="frequency"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: `${t('Invoicing.FrequencyRequired')}`,
                      }}
                      render={({
                        field: { onChange, value, ref },
                        fieldState: { error },
                      }) => (
                        <>
                          <StyledSelectField
                            native
                            id="frequency"
                            fullWidth
                            value={value}
                            inputRef={ref}
                            IconComponent={ArrowDownIcon}
                            onChange={(e) => {
                              setValue('numberOfInstallments', '')
                              clearErrors('numberOfInstallments')
                              onChange(e)
                            }}
                            error={!!error}
                            inputProps={{
                              'aria-describedby': 'frequencyErrorText',
                            }}>
                            <option style={{ display: 'none' }} label=" " />
                            {installmentFrequencies.frequencies?.map(
                              (f: { name: string }) => (
                                <option key={f.name} value={f.name}>
                                  {t(`Invoicing.${f.name}`)}
                                </option>
                              ),
                            )}
                          </StyledSelectField>
                          {error && (
                            <FormHelperText
                              id="frequencyErrorText"
                              sx={styles.requiredError}>
                              {error.message}
                            </FormHelperText>
                          )}
                        </>
                      )}
                    />
                  </Box>
                </RowArea>
              )}
              {selectedPaymentFrequency ===
                t('Invoicing.SetupInstallments') && (
                <RowArea sx={{ paddingBottom: '3rem' }}>
                  <Box sx={styles.InstallmentsContainer}>
                    <StyledLabel htmlFor="numberOfInstallments">
                      {t('Invoicing.NumberOfInstallments')} *
                      <span className="sr-only">{t('srOnlyRequired')}</span>
                    </StyledLabel>
                    <Controller
                      name="numberOfInstallments"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: `${t(
                          'Invoicing.NumberOfInstallmentsRequired',
                        )}`,
                      }}
                      render={({
                        field: { onChange, value, ref },
                        fieldState: { error },
                      }) => (
                        <>
                          <StyledSelectField
                            native
                            id="numberOfInstallments"
                            fullWidth
                            value={value}
                            inputRef={ref}
                            IconComponent={ArrowDownIcon}
                            onChange={onChange}
                            error={!!error}
                            disabled={!watch('frequency')}
                            inputProps={{
                              'aria-describedby': 'installmentsErrorText',
                            }}>
                            <option style={{ display: 'none' }} label=" " />
                            {installmentRange()?.map((i: number) => (
                              <option key={i} value={i}>
                                {i}
                              </option>
                            ))}
                          </StyledSelectField>
                          {error && (
                            <FormHelperText
                              id="installmentsErrorText"
                              sx={styles.requiredError}>
                              {error.message}
                            </FormHelperText>
                          )}
                        </>
                      )}
                    />
                  </Box>
                </RowArea>
              )}
              <StyledHeading>{t('Invoicing.AccountInformation')}</StyledHeading>
              <Box sx={styles.AccountInformationSubLabel}>
                {t('Invoicing.AccountInformationSub')}
              </Box>
              <RowArea sx={{ marginTop: '0.5rem' }}>
                <Box sx={styles.SelectPaymentMethodContainer}>
                  <PaymentMethod
                    control={control}
                    addPaymentMethod={addPaymentMethod}
                    payerPaymentAccounts={
                      selectedPaymentMethod === paymentMethods[0]
                        ? payerBankAccounts
                        : payerCreditCards
                    }
                    paymentMethodType={
                      selectedPaymentMethod === paymentMethods[0]
                        ? 'BankAccount'
                        : 'CreditCard'
                    }
                    isScheduledPayment={
                      selectedPaymentFrequency !== t('Invoicing.PayNow')
                    }
                  />
                </Box>
              </RowArea>
            </Box>
          </FormArea>
          {isDesktop && (
            <AutopayArea>
              <span /> {/* Autopay box will go here */}
            </AutopayArea>
          )}
          <Box sx={styles.ButtonContainer}>
            <Button
              disableFocusRipple
              variant="outlined"
              sx={styles.TermsAndConditionsButton}
              onClick={() => setOpenDialog(true)}>
              {acceptedTermsAndConditions ? (
                <>
                  <CheckCircle
                    titleAccess={t(
                      'Invoicing.PaymentTermsAndConditions.Accepted',
                    )}
                    fontSize="small"
                    sx={styles.CheckCircle}
                  />
                  {t(
                    'Invoicing.PaymentTermsAndConditions.PaymentTermsAndConditions',
                  )}
                </>
              ) : (
                t(
                  'Invoicing.PaymentTermsAndConditions.ViewPaymentTermsAndConditions',
                )
              )}
            </Button>
            <Box sx={styles.ConfirmPaymentContainer}>
              <Button
                type="submit"
                disableFocusRipple
                ref={confirmButtonRef}
                sx={styles.ConfirmPaymentButton}
                disabled={!acceptedTermsAndConditions}
                variant="contained">
                {t('Invoicing.ConfirmPayment')}
              </Button>
            </Box>
            <TermsAndConditionsDialog
              isAutomaticPayment={isRecurring}
              open={openDialog}
              onAccept={onAccept}
              onClose={onDecline}
              hasUserReadTerms={hasUserReadTerms}
            />
            <PaymentGatewayModal
              closeHandler={() => closePaymentModal()}
              refreshPaymentData={refreshPaymentData}
              open={isPaymentModalOpen}
              iframeSrc={paymentGatewayIframeSrc}
              paymentMethod={getPaymentMethod()}
            />
          </Box>
        </BodyArea>
      </form>
    </>
  )
}

export default PaymentType
