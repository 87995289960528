/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/require-default-props */
import React from 'react'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import styles from './WelcomeModal.styles'
import { useDeviceType } from '../../rsmCoreComponents/hooks/useDeviceType'
import CaretLink from '../Navigation/CaretLink'

interface BoxLayoutProps {
  title: string
  desc: string
  link: string
  linkText: string
  icon?: React.ReactNode
  navigateOnContentClick?: boolean
  onNavigatingTo?: (link: string) => void
}

const NavigationCard = ({
  title,
  desc,
  link,
  icon,
  linkText,
  onNavigatingTo,
  navigateOnContentClick,
}: BoxLayoutProps) => {
  const { isMobile } = useDeviceType()

  const navigate = useNavigate()

  const handleNavigation = () => {
    if (onNavigatingTo) {
      onNavigatingTo(link)
    }
    navigate(link)
  }

  const handleContentClick = (e: any) => {
    if (navigateOnContentClick) {
      handleNavigation()
    }
    e.preventDefault()
  }

  const handleClick = (e: any) => {
    if (!navigateOnContentClick) {
      handleNavigation()
    }
    e.preventDefault()
  }

  const renderLink = () => (
    <Box sx={styles.boxLinkRoot}>
      <CaretLink onClick={handleClick} href={link}>
        <span>{linkText}</span>
      </CaretLink>
    </Box>
  )

  const getMaxWidth = () => {
    if (isMobile) return '100%'
    if (icon) {
      return '26.875rem'
    }
    return '18.75rem'
  }

  return (
    <Box
      onClick={handleContentClick}
      sx={styles.navCardContainer}
      style={{ maxWidth: getMaxWidth() }}>
      <Box sx={styles.navDescriptionContainer}>
        <Box sx={{ marginBottom: '2rem' }}>
          <Box component="h2" sx={styles.boxTitle}>
            {title}
          </Box>
          <p>{desc}</p>
        </Box>
        {icon && (
          <Box sx={{ paddingTop: '1.5rem' }} data-testid="cardIcon">
            {icon}
          </Box>
        )}
      </Box>
      {renderLink()}
    </Box>
  )
}

export default NavigationCard
