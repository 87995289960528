import locales from './locales'

export default {
  [locales.ENGLISH]: {
    translation: {
      srOnlyRequired: 'required',
      Navigation: {
        AccountSettings: 'Account settings',
        Administrative: 'Administrative',
        CloseMainNavigationMenu: 'Close main navigation menu',
        Dashboard: 'Dashboard',
        DocumentRequests: 'Document requests',
        Documents: 'Documents',
        Engagements: 'Engagements',
        HighContrast: 'High contrast',
        Home: 'Home',
        Insights: 'Insights',
        Invoices: 'Invoices',
        Invoicing: 'Invoicing',
        Logout: 'Logout',
        Main: 'Main',
        OpenMainNavigationMenu: 'Open main navigation menu',
        Payments: 'Payment Transactions',
        PleaseSelect: 'Please select',
        Profile: 'Profile',
        SkipToMainContent: 'Skip to main content',
        Support: 'Support',
        Welcome: 'Welcome',
      },
      Pagination: {
        RowsPerPage: 'Rows per page',
      },
      ActivityTemplates: {
        RequestCreatedSubject: 'Request created by {{FirstName}} {{LastName}}',
        RequestUpdatedSubject:
          'Request details changed by {{FirstName}} {{LastName}}',
        RequestNameUpdatedBody:
          'Request name changed from "{{OldRequestName}}" to "{{NewRequestName}}".',
        RequestFunctionalAreaUpdatedBody:
          'Functional area changed from "{{OldArea}}" to "{{NewArea}}".',
        RequestGroupUpdatedBody:
          'Group changed from "{{OldGroup}}" to "{{NewGroup}}".',
        RequestSubgroupUpdatedBody:
          'Subgroup changed from "{{OldSubgroup}}" to "{{NewSubgroup}}".',
        RequestDescriptionUpdatedBody:
          'Description changed from "{{OldDescription}}" to "{{NewDescripion}}".',
        RequestStatusUpdatedSubject:
          'Status updated from $t({{From}}) to $t({{To}}) by {{FirstName}} {{LastName}}.',
        RequestDueDateUpdatedSubject:
          'Due date updated from {{FromDate, Datetime}} to {{ToDate, Datetime}} by {{FirstName}} {{LastName}}',
        RequestPrioritySetSubject:
          'Marked as priority by {{FirstName}} {{LastName}}',
        RequestPriorityUnsetSubject:
          'Not Marked as priority by {{FirstName}} {{LastName}}',
        RequestLockSetSubject: 'Request locked by {{FirstName}} {{LastName}}',
        RequestLockUnsetSubject:
          'Request unlocked by {{FirstName}} {{LastName}}',
        RequestDeletedSubject: 'Request deleted by {{FirstName}} {{LastName}}',
        RequestAssignedSubject: 'Assignees added by {{FirstName}} {{LastName}}',
        RequestAssignedBody: '{{Val, List}} added to the request.',
        RequestUnassignedSubject:
          'Assignees removed by {{FirstName}} {{LastName}}',
        RequestUnassignedBody: '{{Val, List}} removed from the request.',
        DocumentsAddedSubject:
          '{{DocumentCount}} document(s) uploaded by {{FirstName}} {{LastName}}.',
        DocumentsAddedBody: '{{Val, List}} have been uploaded.',
        DocumentsDeletedSubject:
          '{{DocumentCount}} document(s) deleted by {{FirstName}} {{LastName}}.',
        DocumentsDeletedBody: '{{Val, List}} have been deleted.',
        DocumentsDownloadedSubject:
          '{{DocumentCount}} document(s) downloaded by {{FirstName}} {{LastName}}.',
        DocumentsDownloadedBody: '{{Val, List}} have been downloaded.',
        CommentAddedSubject: 'Comments added by {{FirstName}} {{LastName}}',
        FollowupCommentAddedSubject:
          'Request follow-up comments added by {{FirstName}} {{LastName}}',
      },
      Alert: {
        CloseAlert: 'Close alert',
        GenericErrorHeader: 'Error',
        GenericErrorMessage: 'Something went wrong',
        PaymentFailed: 'Payment unsuccessful',
        PaymentMethodError:
          'Please validate the payment information and retry. If the issue persists, contact your bank or card provider for assistance.',
        PaymentMethodErrorHeader: 'The Payment information could not be saved',
        PaymentUnableToProcess:
          'The payment was unsuccessful. Please try again or attempt with another payment method. If the issue persists, contact your bank or card provider for assistance.',
      },
      Authorization: {
        NotAuthorizedForApplication: {
          NotAuthorizedHeader: "Something's not working",
          NotAuthorizedReason: "You don't have permission to view this page.",
          NotAuthorizedPrimarySuggestion: 'Please double-check your request.',
          NotAuthorizedSecondarySuggestion:
            'If you think this is a mistake, please contact your RSM account administrator to get access.',
        },
      },
      UserProfile: {
        ECPProfileTitle: 'myRSM - Profile',
        PageContactInfoPrefsHeading: 'Contact Information & Preferences',
        PageInterestsHeading: 'Interests',
        PageNoInterestsMessage:
          'You haven’t selected any interests yet. It only takes a few minutes and helps us customize your myRSM experience.',
        PageAddInterestsButton: 'Add interests',
        PageUpdateInterestsButton: 'Update interests',
        IndustryLabel: 'Industries',
        ServiceLabel: 'Service Lines',
        TopicLabel: 'Topics',
        ProfileWizard: {
          StepCounter: 'Step {{currentStep}} of {{totalSteps}} ',
          WelcomeMessageDesktop: 'Hi {{firstName}}, welcome to myRSM!',
          WelcomeMessageTablet: 'Welcome to myRSM!',
          WelcomeMessageMobile: 'Welcome, {{firstName}}!',
          CustomizationMessage:
            'Tell us how you plan on using myRSM and we will customize the experience for you. This information will not be shared outside of myRSM and will only be used to enhance this tool for you.',
          CustomizationMessageShort:
            'Tell us how you plan on using myRSM and we will customize the experience for you. This information will not be shared outside of myRSM.',
          SelectThe: 'Select the',
          Industries: 'Industries',
          ServiceLines: 'Service Lines',
          Topics: 'Topics',
          ContactInfoPrefs: 'Contact Information & Preferences',
          YouAreInterestedIn: 'you are interested in.',
          ContactInfoPrefsMessage:
            'Please verify and/or add the following information. All fields are optional.',
          Back: 'Back',
          Next: 'Next',
          SaveAndContinueLater: 'Save and continue later',
          SaveAndExit: 'Save and exit',
        },
        ProfilePopover: {
          IncompleteMessage:
            "We noticed you weren't able to finish your profile setup.",
          CompleteMessage:
            'You can adjust your interest selections at any time in the profile section of myRSM.',
          ContinueMessage: 'Go to profile to finish',
          AdjustProfileMessage: 'Go to profile',
          FinishProfile: 'Finish profile',
          CloseDialog: 'Close dialog',
        },
        ContactPreferenceForm: {
          FirstName: 'First Name',
          LastName: 'Last Name',
          Name: 'Name',
          ClientOrBusinessName: 'Client/Business name(s)',
          RegionOrTimeZone: 'Region/time zone',
          BusinessPhoneNumber: 'Business phone',
          BusinessEmailAddress: 'Business email',
          PreferredContactMethod: 'Preferred contact method',
          Email: 'Email',
          Phone: 'Phone',
          EngagementActivityNotificationFrequency:
            'Engagement activity notification frequency',
          InsightsActivityNotificationFrequency:
            'Insights activity notification frequency',
          Submit: 'Submit',
          Edit: 'Edit',
          Save: 'Save',
          Cancel: 'Cancel',
          TimeZones: {
            AlaskaTimeZone: 'Alaska',
            ArizonaTimeZone: 'Arizona',
            CentralTimeZone: 'Central Time (US & Canada)',
            PacificTimeZone: 'Pacific Time (US & Canada)',
            MountainTimeZone: 'Mountain Time (US & Canada)',
            HawaiiTimeZone: 'Hawaii',
            EasternTimeZone: 'Eastern Time (US & Canada)',
          },
          NotificationFrequencies: {
            WeeklyTextAlert: 'Weekly text alert',
            DailyTextAlert: 'Daily text alert',
            NewItemTextAlert: 'Text alert as soon as a new item is added',
            NoTextAlert: 'No text alerts, just in app notifications',
            WeeklyEmailDigest: 'Weekly email digest',
            DailyEmailDigest: 'Daily email digest',
            NewItemEmailDigest: 'Email as soon as a new item is added',
            NoEmailDigest: 'No emails, just in app notifications',
          },
          ContactMethods: {
            EmailContactMethod: 'Email',
            PhoneContactMethod: 'Phone',
          },
        },
      },
      Download: 'Download',
      DownloadAll: 'Download All',
      Delete: 'Delete',
      DocumentRequests: {
        Title: 'Document Requests',
        Results: 'Results',
        Actions: {
          CreateNewRequest: 'Create a new request',
          DownloadRequest: 'Download request list',
          MoreOptions: 'More actions',
          Upload: 'Upload request list (.csv)',
          Download: 'Download.csv template',
          Export: 'Export request list',
        },
        Statistics: {
          TotalRequests: 'total requests',
          RequestAssign: 'requests assigned to you',
          OverdueRequests: 'overdue requests',
          InProgress: 'In progress',
          FollowUp: 'Require follow-up',
          Submitted: 'Submitted',
        },
        Filters: {
          SearchByKeyword: 'Search by keyword',
          ExpandAll: 'Expand all',
          CollapseAll: 'Collapse all',
        },
        List: {
          Columns: {
            RequestName: 'Request Name',
            Assignee: 'Assignee',
            DueDate: 'Due Date',
            Status: 'Status',
            Activity: 'Activity',
            Priority: 'Priority',
            Locked: 'Locked',
          },
        },
        LoadMore: 'Load more',
      },
      DocumentRequestListHeader: 'DOCUMENT REQUEST LIST',
      DocumentRequestButtonCreateRequest: 'Create a new request',
      DocumentRequestSearchLabel: 'Search by doc name',
      DocumentRequestCreateRequestModalTitle: 'Create a new document request',
      DocumentRequestCreateRequestModalSubtitle:
        'Please fill in the fields below to create a document request',
      DocumentRequestCsvUploadErrorModalTitle: 'Error Report',
      DocumentRequestCsvUploadErrorModalSubtitle:
        'Failed to create a new document request.',
      Docs: {
        ECPDocsTitle: 'myRSM - Docs',
        DocumentListHeader: 'ALL DOCUMENTS',
        DocumentSearchLabel: 'Search By title:',
        ConfirmDeleteTitle_one: '{{count}} selected item will be deleted.',
        ConfirmDeleteTitle_other: '{{count}} selected items will be deleted.',
        ConfirmDeleteDescription:
          'Are you sure you wish to delete the selected document(s)?',
        AlertDeletedTitle: 'Delete Successful.',
        AlertDeletedDescription: 'The selected document(s) are deleted.',
        NoDocumentsForCurrentUser: 'You don’t have any documents yet.',
        NoSearchResult: 'No results found',
        NoDocumentsFound: 'No requests have uploaded documents.',
        TrySearchText:
          'Try broadening your search term or removing filters to see results.',
        ViewDocRequesButtonText: 'Visit Document Requests page',
        Headers: {
          documentName: 'Document Name',
          createdBy: 'Uploaded By',
          createdDate: 'Date',
          group: 'Group',
          subgroup: 'Subgroup',
          status: 'Status',
          contentType: 'Content Type',
          request: 'Request',
        },
      },
      EngagementList: {
        NoSearchResultsTitle: 'No results found',
        NoSearchResultsSubTitle: 'Try broadening your search term(s).',
        GoToTop: 'Go to top',
      },
      EngagementDashboard: {
        RequestSummaryHeader: 'REQUEST SUMMARY',
        ViewAllEngagements: 'View all engagements',
        RequestTaskCompletionHeader: 'STATUS BREAKDOWN',
        DueDateCalendarHeader: 'DUE DATE CALENDAR',
        TeamMembersHeader: 'TEAM MEMBERS',

        TeamMembersSubHeader:
          'These people currently have access to this engagement.',
        EmptyStatusBreakdownMainHeader: "You're all caught up!",
        EmptyStatusBreakdownSubHeader:
          'No requests need attention at this time.',
        Summary: {
          AssignedToYou: 'Assigned to you',
          CreatedByYou: 'Created by you',
          AllRequests: 'All requests',
          OutOf: 'out of',
          ViewAllRequests: 'View all requests',
          OverdueRequestsPhrase: 'requests are currently overdue',
          OutstandingRequestsPhrase: 'priority requests are outstanding',
          FollowUpRequestsPhrase: 'requests require follow-up',
          StatusBreakDown: {
            TotalRequests: 'This engagement currently has {{count}} requests',
            CompletedLabel: 'Completed',
            FollowUpLabel: 'Follow-up',
            SubmittedLabel: 'Submitted',
            InProgressLabel: 'In Progress',
            RequestedLabel: 'Requested',
          },
        },
        TeamMembers: {
          RSMTeam: 'RSM team',
          ClientTeam: 'Client team',
        },
      },
      ECPAllEngagementsTitle: 'myRSM - All Engagements',
      ECPEngagementsTitle: 'myRSM - Engagement',
      EngagementListHeader: 'ALL ENGAGEMENTS',
      EngagementMembersHeader: 'TEAM MEMBERS',
      ErrorBoundary: {
        DefaultErrorMessage: 'Sorry... there was an error',
      },
      FilterChips: {
        ClearAll: 'Clear all',
        ClearAllAppliedFilters: 'Clear all applied filters',
        RemoveFilter: 'Remove',
        ShowLess: 'Show less',
        ShowLessAppliedFilters: 'Show less applied filters',
        ShowMoreAppliedFilters: 'Show {{count}} more applied filters',
      },
      Form: {
        RequiredField: 'This field is required.',
        Submit: 'Submit',
        Maximum: 'Max the length for this field is- {{MAX_VALUE}} characters',
        Minimum: 'Min the length for this field is- {{MIN_VALUE}} characters',
        MaximumReached: 'You have reached the character limit',
        Characters: 'characters',
      },
      HomeEngagementsHeader: 'YOUR ENGAGEMENTS',
      HomeToolsHeader: 'TOOLS',
      HomeRequestsHeader: 'UPCOMING REQUESTS',
      HomeFavoritedHeader: 'FAVORITED',
      HomeInsightsHeader: 'LATEST INSIGHTS',
      HomePage: {
        ECPHomeTitle: 'myRSM - Home',
        EngagementsHeader: 'ENGAGEMENTS',
        Header: 'myRSM Homepage',
        InsightsHeader: 'RECENTLY ADDED',
        ToolsHeader: 'TOOLS',
        ToolsLoadMore: 'Load more',
        Launch: 'Launch',
        ViewProjects: 'View my projects',
        SubmitSupportTicket: 'Submit a support ticket',
        AreYouMissingTools: 'ARE YOU MISSING TOOLS?',
        RequestMissingTools:
          'Request additional tools by completing the myRSM support form.',
        AreYouMissingToolsCategory: 'EXAMPLE: DASH, TEAMDOCS, ETC.',
        ArticleFeaturedContent: 'Featured Content: Article',
        ArticleReadMore: 'Read more',
        ArticleReadNow: 'Read now',
        ViewAllEngagements: 'View all engagements',
        ViewAllInsights: 'View all insights',
        TeamDocsCategory: 'Consulting',
        TeamDocsDescription:
          'Collaborate with your project team using this engagement tool',
      },
      Projects: {
        Title: 'Project Sites',
        LoadMore: 'Load more',
        GoToTop: 'Go to top',
        HeaderMessage:
          'Missing projects? Check your email for an invitation to the project site in question. If you do not have any invites contact your project team. Once added or removed from a TeamDocs project, myRSM will be updated within 24 hours.',
        SupportCard: {
          Name: 'Support',
          Category: 'Support documentation',
          SecuritySummaryLabel: 'TeamDocs security summary',
          SecuritySummaryUrl:
            'https://rsmnet.sharepoint.com/:b:/r/teams/cd/Shared%20Documents/External%20-%20Published/RSM%20TeamDocs%20Security%20Summary.pdf?csf=1&web=1&e=e5o4Qr',
          LoggingInWith365Label: 'TeamDocs log in with O365 Account',
          LoggingInWith365Url:
            'https://rsmnet.sharepoint.com/:b:/r/teams/cd/Shared%20Documents/External%20-%20Published/External%20User%20Login%20with%20O365%20Account%20into%20TeamDocs.pdf?csf=1&web=1&e=rZKcqQ',
          LoggingInWithout365Label: 'TeamDocs log in without O365 Account',
          LoggingInWithout365Url:
            'https://rsmnet.sharepoint.com/:b:/r/teams/cd/Shared%20Documents/External%20-%20Published/External%20User%20Login%20without%20an%20O365%20Account%20into%20TeamDocs.pdf?csf=1&web=1&e=qh1PB4',
          ContactSupport: 'Contact support',
        },
        NoSearchResultsTitle: 'No results found',
        NoSearchResultsSubTitle: 'Try broadening your search term(s).',
        ReportCard: {
          Name: 'Report an issue',
          Category:
            'Experiencing issues like missing projects or broken links? Report your issue here.',
        },
        ReportAnIssueForm: {
          title: 'Report an issue form',
          description:
            'Please describe the issue you are experiencing by submitting the form below and an RSM US representative will follow up with you. *Required field.',
          descriptionLabel: 'Description',
          issue: 'Issue',
          projectName: 'Project Name',
          issueOptions: {
            notAccurate: 'My Project list is not accurate',
            notLoading: 'Project not loading when I click the link',
            incorrect: 'Something is incorrect with a project',
            other: 'Other',
          },
          issueWarning:
            '*The project list can take up to 24 hours to be updated.',
          toast: {
            title: 'Support ticket submitted',
            description:
              'Your Service Desk request has been received. Expect an email response within 48 hours.',
          },
        },
      },
      InsightsList: {
        ECPInsightsHeading: 'Latest RSM Insights',
        InsightsFilter: 'Filters',
        InsightsClearChips: 'Clear all',
        InsightsChipAriaPrefix: 'Remove filter ',
        // NOTE: FilterLabels are tightly coupled to the Redux store.
        // Modifying the 2 values below risks breaking the Insights chip functionality.
        FilterLabels: {
          all: 'All Insights',
          favorites: 'favorites',
          recentlyViewed: 'recently-viewed',
          myInterestsLabel: 'My Interests',
          sharedWithMe: 'shared-with-me',
        },
        FilterHeaders: {
          yourinsights: 'Your insights',
          role: 'Role',
          industry: 'Industry',
          service: 'Service line',
          topic: 'Topic',
        },
        ReadMore: 'Read more',
        LoadMore: 'Load more',
        GoTotop: 'Go to top',
        NoSearchResults: 'No results found',
        NoSearchResultsAdvice:
          'Try broadening your search term or removing filters to see results.',
      },
      InsightArticle: {
        ShareArticle: 'Share article',
        AddToFavorites: 'Favorite this article',
        RemoveFromFavorites: 'Remove from Favorites',
        BackToInsights: 'View all Insights',
        InsightArticle: 'Insight Article',
        RSMContributors: 'RSM Contributors',
        Tags: 'Tags',
        DefaultTag: ' ',
        ArticleDate: '{{date, medium}}',
        ArticleSharedSuccess: 'Article shared successfully',
        ArticleSharedFail: 'Unable to share article',
        ArticleSharedWarning:
          'Unable to share with {{count}} of the recipients',
        ArticleSharedSuccessMessageMultiUser:
          'The article has been emailed to all recipients.',
        ArticleSharedFailMessageMultiUser: `We were unable to send this article to {{names}} due to an unexpected error. `,
        ArticleSharedSuccessMessage: 'Article was sent to {{name}}.',
        ArticleSharedError: 'Unable to share',
        ArticleSharedErrorMessage:
          'There was an error and we were unable to send your message. Please try again or contact support if the issue continues.',
        ShareArticleMessage: 'shared This Article with you',
      },
      ShareArticleModal: {
        Title: 'Share this insight article',
        DefaultMessage: `Hi {{receiverName}},\nI was on myRSM and found this article I thought you might be interested in.\n\nBest,\n{{senderName}}`,
        DefaultMessageNonEcpUser: `Hi {{receiverName}},\nI was on myRSM and found this article I thought you might be interested in.\nYou'll need to join myRSM to view it, but I think it's well worth it!\n\nBest,\n{{senderName}}`,
        DefaultMessageMultiple: `Hi, \nI was on myRSM and found this article I thought you might be interested in.\n\nBest,\n{{senderName}}`,
        IncludeMessageLabel: 'Include a message:',
        RequiredField: 'This is a required field.',
        UserSearchInputPlaceholder: 'Search by first or last name',
        AriaLabel: {
          ShareArticle: 'Share Article',
          AriaCloseDialog: 'Close dialog',
        },
        NoOptionsText: 'No results found for any user by that name.',
        EcpWarning:
          'This user does not have myRSM access yet.  If you continue they will get access to myRSM which is required to view any shared article.',
        NonEcpUserAckMessage:
          'Yes, give these users myRSM access. This is required to view this article.',
        NonEcpUserAckRequired:
          'This is a required field, please acknowledge or remove non-myRSM users to continue.',
        NonEcpUserInfoMessage: "These recipients don't have myRSM access yet.",
        MaxUserLimit: 'Maximum number of recipients reached',
      },
      NotificationTemplates: {
        ArticleShared:
          '{{FirstName}} {{LastName}} shared an article <0>{{ArticleName}}</0> with you',
        NoMessages: 'You don’t have any notifications.',
        NotificationsTitle: 'Notifications',
        NotificationsLabel: 'Notifications, {{count}} new',
        DeleteNotification: 'Delete notification',
        DateAgo: '{{date, ago}}',
      },
      ECPInsightsTitle: 'myRSM - Insights',
      InsightsFeaturedTopicsHeader: 'FEATURED TOPICS: CATEGORIES',
      InsightsIndustryInsightsHeader: 'INDUSTRY INSIGHTS: ARTICLES',
      InsightsServiceLineRelatedHeader:
        'SERVICE-LINE RELATED INSIGHTS: ARTICLES',
      ECPNoMatchTitle: 'myRSM - Not Found',
      NoMatchHeader: 'No Match',
      ECPNotificationsTitle: 'myRSM - Notifications',
      NotificationsHeader: 'NOTIFICATIONS',
      ECPSupportTitle: 'myRSM - Support',
      RSMSupportHeading: 'myRSM Support',
      SupportFormTitle: 'SUPPORT FORM',
      ActivityFeedTitle: 'Activity feed',
      OutOfTime: 'Out of time',
      LockStatusRequest:
        'Locking this request will make details viewable only to the assignee and the RSM members in this engagement.',
      Engagement: {
        Name: 'ENGAGEMENT NAME',
        StartDate: 'START DATE',
        EndDate: 'END DATE',
        Client: 'CLIENT',
        Entity: 'ENTITY',
        Type: 'TYPE',
        LOB: 'LOB',
        LineOfBusiness: 'LINE OF BUSINESS',
        ViewEngagement: 'View engagement',
        ViewEngagementAriaLabel: 'View {{engagement}} engagement',
      },
      DocReq: {
        NewForm: {
          Success: 'Success!',
          RequestName: 'Request name',
          DocReqInformation: 'Document request information',
          DueDate: 'Due date',
          FunctionalArea: 'Functional area',
          Group: 'Group',
          GroupPlaceholder: 'Type name to search or enter as new group',
          Subgroup: 'Subgroup',
          SubgroupPlaceholder: 'Type name to search or enter as new subgroup',
          RequestSettings: 'Request settings',
          MarkAsPriority: 'Mark as priority',
          PriorityTooltip:
            'Marking as Priority will flag this request and enable related email notifications.',
          LockRequest: 'Lock request',
          LockTooltip:
            'Locking this request will make details viewable only to the assignee and the RSM members in this engagement.',
          Description: 'Description',
          AssignRequest: 'Assign request',
          SearchForUser: 'Search for user',
          AssigneeNoMatch: 'No results found',
          MaxAssignees:
            'You have reached the max of {{MaxAssignees}} assignees per request',
          MaxAssigneesMobile: 'Maximum of {{MaxAssignees}} assignees',
          TypeNameOrEmail: 'Type name or email',
          ClientAssignees: 'Client assignees',
          RSMAssignees: 'RSM assignees',
          Submit: 'Submit',
          AriaLabel: {
            RequestName: 'Request name field',
            DueDate: 'Due date field',
            FunctionalArea: 'Functional area field',
            Group: 'Group field',
            Subgroup: 'Subgroup field',
            PriorityIcon: 'Mark as priority checkbox',
            LockIcon: 'Lock request checkbox',
            SearchForUser: 'Assignee Search field',
            ClientAssignees: 'Client assignees field',
            RSMAssignees: 'RSM assignees field',
            FLAdded: '{{Name}} added.',
            FLRemoved: '{{Name}} removed.',
            UpDownNavigate: 'Use up or down arrows to navigate.',
            BackspaceToRemove:
              'To remove assignee press the delete button or backspace',
            Description: 'Description field',
            SearchEmail: 'Search email field',
            Submit: 'Submit',
          },
          SuccessModal: {
            Title: 'Success!',
            Subtitle: 'Your document request has been successfully created.',
            RequestName: 'Request name',
            DueDate: 'Due date',
            FunctionalArea: 'Functional area',
            Group: 'Group',
            Subgroup: 'Subgroup',
            PriorityStatus: 'Priority status',
            PriorityStatusIsPriority: 'Marked as priority',
            PriorityStatusIsNotPriority: 'Not marked as priority',
            LockStatus: 'Lock status',
            LockStatusLocked: 'Locked',
            LockStatusUnlocked: 'Unlocked',
            Description: 'Description',
            ClientAssignees: 'Client assignees',
            RSMAssignees: 'RSM assignees',
            Close: 'Close',
          },
        },
        RequestFollowUpModal: {
          Title: 'Request follow-up',
          SubTitle: 'What information do you need from client?',
          AddComment: 'Add a comment',
          MaximumReached:
            'Comment must be {{MIN_LENGTH}} to {{MAX_LENGTH}} characters long.',
          AriaLabel: {
            CommentAdded: 'Comment added',
            CharsEntered: '{{charCount}} of {{MAX_LENGTH}} characters entered',
            CloseDialog: 'Close Dialog',
            name: 'reqeust-followup',
          },
        },
        RequestAddComment: {
          AriaCloseDialog: 'Close dialog',
          AriaCommentAdded: 'Comment added',
          Title: 'Add comment',
          PlaceholderNote: 'Add a comment',
          MaximumReached:
            'Comment must be {{MIN_LENGTH}} to {{MAX_LENGTH}} characters long.',
          AriaCharactersEntered:
            '{{charCount}} of {{MAX_LENGTH}} characters entered',
        },
        ViewDetails: {
          MarkedAsPriority: 'Marked as priority',
          NoPriority: 'Not marked as priority',
          Priority: 'Priority',
          FunctionalArea: 'Functional area',
          DueDate: 'Due date',
          Group: 'Group',
          Subgroup: 'Subgroup',
          Lock: 'Lock',
          Locked: 'Locked',
          Unlocked: 'Unlocked',
          CurrentStatus: 'Current status',
          CurrentStatusCountMsg: 'Updated {{countDays}} days ago',
          CurrentStatusDateMsg: 'Updated on',
          DueDateMsg: 'Due in {{countDays}} Days',
          DueToday: 'Due Today',
          UpdatedToday: 'Updated Today',
          CurrentClientAssignees: 'Current client assignees',
          CurrentRSMAssignees: 'Current RSM assignees',
          DocumentRequestStatus: {
            Requested: 'Requested',
            InProgress: 'In progress',
            Submitted: 'Submitted',
            FollowUp: 'Follow Up',
            Complete: 'Complete',
          },
          ActivityDesc: {
            ShowMore: '+ More',
            ShowLess: '- Less',
          },
        },
        CurrentAssignees: 'Current assignees',
        Summary:
          'This is a list of documents you’ve requested. Currently {{uploaded}} out of {{total}} documents requested have been uploaded. {{inProgress}} requests are in progress and {{followUp}} require follow-up.&nbsp;',
        SummaryOverdue: '{{overdue}} request is currently overdue.',
        ActionButtons: {
          Upload: 'Upload document(s)',
          Submit: 'Submit request',
          FollowUp: 'Request follow up',
          Complete: 'Mark as complete',
        },
        Documents: {
          SectionTitle: 'Uploaded document(s)',
          DownloadAll: 'Download all',
          Download: 'Download',
          Delete: 'Delete',
          NoDocs: "There's nothing here yet",
        },
      },
      AutoComplete: {
        AriaLabel: {
          FLAdded: '{{list}} added.',
          FLRemoved: '{{list}} removed.',
          UpDownNavigate: 'Use up or down arrows to navigate.',
        },
      },
      SearchUserByEmail: 'Search for user by email',
      DocumentRequest: 'Document Request',
      Assignee: 'Assignee',
      DueDate: 'Due Date',
      Status: 'Status',
      Activity: 'Activity',
      SearchByDocName: 'Search by document name',
      SearchByKeyword: 'Search by keyword',
      searchByPayor: 'Start typing to search',
      SearchScreenReaderInstructions: 'Results will filter below as you type',
      Results: 'results',
      CreateNewDocReq: 'Create a new request',
      SubmitDocReq: 'Submit request',
      MarkComplete: 'Mark as complete',
      FollowUp: 'Request follow-up',
      UploadDoc: 'Upload document',
      AddComment: 'Add comment',
      CsvErrorModal: {
        ErrorReport: 'Error Report',
        ExplanationMessage: 'Failed to create a new document request.',
      },
      NewDocReqModal: {
        Title: 'Create a new document request',
        SubTitle:
          'Please fill in the fields below to create a document request',
      },
      Name: 'Name',
      EngagementName: 'Engagement name',
      ContactMethod: 'Preferred contact method',
      BusinessEmail: 'Business e-mail address',
      BusinessPhone: 'Business phone number',
      ClientBusName: 'Client/Business name',
      DashIssue: 'Is this a DASH issue?',
      DashIssueShortened: 'Dash issue',
      DashIssueExplanation:
        'DASH issues cannot be submitted through myRSM, please <2>submit an issue with DASH</2>',
      DashSupportLink: 'dash support page link',
      LOB: 'Line of service',
      SysPortal: 'System/Portal trying to access',
      IssueDesc: 'Issue',
      IsARequiredField: 'is a required field.',
      ReadMore: 'Read More',
      RequestAccess: {
        ThankYou: 'Thank you for your interest in the myRSM.',
        SubmitRequest:
          'Please submit your request to initiate access for the following account.',
      },
      Reset: 'Reset',
      Submit: 'Submit',
      SelectEngagement: 'Select engagement',
      SelectBusiness: 'Select',
      Other: 'Other',
      SessionTimeout: 'Session Timeout',
      SessionTimeoutExplination: 'Your session is about to expire.',
      SupportSuccess: 'Support ticket submitted',
      SupportSuccessMessage:
        'Your Service Desk request has been received. Expect an email response within 48 hours.',
      SupportError: 'ALERT',
      SupportErrorMessage:
        'We were unable to process this request due to a system error. ',
      UpdateSuccess: 'Updated successfully',
      UpdateSuccessMessage: 'Your profile preferences have been updated.',
      UpdateError: 'ALERT',
      UpdateErrorMessage:
        'We were unable to process this request due to a system error. ',
      SupportSuccessLink: 'Longer CTA Message Lorem ipsum dolor sit amet',
      SupportFormDesc:
        'Please describe myRSM issue you are experiencing by submitting the form below and a myRSM representative will follow up with you. *Required field.',
      SupportFormDashDropdown: {
        Yes: 'Yes',
        No: 'No',
        ClearSelection: 'Clear selection',
      },
      RequiredField: 'This is a required field.',
      ValidPhoneNumber: 'Please enter a valid phone number',
      ValidCurrency: 'Please enter a valid amount',
      ValidDate: 'Please enter a valid date',
      ValidDateInRange:
        'Please enter a date between {{minDate, P}} and {{maxDate, P}}',
      ValidInput: 'Invalid input',
      ShortRequiredField: 'Required field',
      Filters: {
        Clear: 'Clear',
        CollapsedToggle: {
          SeeMore: 'See more',
          SeeLess: 'See less',
        },
      },
      Dialogs: {
        OkText: 'OK',
        ConfirmText: 'Confirm',
        CancelText: 'Cancel',
        SaveText: 'Save',
      },
      CallUs: {
        Heading: 'SUPPORT',
        Title: 'Call us',
        TollFree: 'Toll Free:',
        Description:
          'Accounts Receivable customer service hours are \nMonday - Friday, 9AM - 4PM CST.',
        InternalDescription:
          'Customer Service hours are:\nSun 9pm - Fri 9pm CST\nSat & Sun 8am - 5pm CST',
        ExternalDescription:
          'Customer Service hours are:\nSun 9pm - Fri 9pm CST',
        USandCANADA: 'US and Canada',
      },
      Faq: {
        Title: 'FAQs',
        Description:
          'Many questions can be quickly answered by checking our FAQs.',
        ButtonText: 'Read FAQs',
        FormTitle: 'Frequently asked questions',
        FormDesc:
          'How can we help you? This page provides help with the most common questions please find them listed below.',
        FaqErrorTitle: 'Uh oh...',
        FaqErrorDesc:
          'It seems that the system couldn’t load the FAQs. Please try refreshing the page or coming back later to see if this issue has resolved.',
      },
      RobotPage: {
        TechnicalDifficulties: 'Experiencing technical difficulties',
        SomeSectionsUnloadable:
          'Some sections of this page were unable to load.',
        TryAgainLaterMessage: 'Please refresh or try again later.',
      },
      UploadFile: {
        Title: 'Upload Document',
        Description: 'Description goes here',
      },
      PageUnavailableHeader: 'Page unavailable',
      TechnicalIssuesMessage: 'We are experiencing technical issues.',
      PleaseTryAgainMessage: 'Please try again later.',
      TryAgainButtonLabel: 'Try again',
      SomethingsNotWorkingHeader: "Something's not working",
      ItemMovedOrDeletedMessage:
        "The item you're looking for may have been moved or deleted, or you may not have permission to view it. Please double-check your request.",
      SubmitSupportTicketMessage:
        'If you think this is a mistake, please <2>submit a support ticket.</2>',
      NoToolsMessage: "You don't have any RSM tools at this time.",
      IfYouAreMissingTools: "If you're missing tools, ",
      SubmitSupportTicket: 'submit a support ticket.',
      SearchLayout: {
        Apply: 'Apply',
        ClearAll: 'Clear all',
        Filters: 'Filters',
        ScrollToTop: 'Scroll to top',
      },
      Invoicing: {
        Accept: 'Accept',
        ACH: 'ACH',
        Actions: 'Actions',
        ActivityFeed: {
          InvoicePastDue: 'Invoice past due',
          InvoicePaymentProcessed: 'Invoice payment processed',
          PaymentScheduled: 'Payment scheduled',
          NewInvoice: 'New invoice',
          DaysOverdue: 'days overdue',
          ScheduledPaymentFor: 'scheduled a payment for',
          PaymentMade: 'payment made',
          At: 'AT',
        },
        AddNewBankAccount: '+ New bank account',
        AddNewCreditCard: '+ New credit card',
        AgreementModal: {
          TermsConditionInv: 'Invoicing terms and conditions',
          AcceptTermsCondition:
            'In order to accept or decline, you must scroll through the terms and conditions displayed below.',
        },
        Frequency: 'Frequency',
        FrequencyRequired: 'Frequency is a required field.',
        NumberOfInstallments: 'Number of installments',
        NumberOfInstallmentsRequired:
          'Number of installments is a required field.',
        PaymentsStartDate: 'Payments start date',
        PaymentsStartDateRequired: 'Payments start date is a required field.',
        Monthly: 'Monthly',
        BiWeekly: 'Bi-weekly',
        Weekly: 'Weekly',
        AccountInformation: 'Account Information',
        AccountInformationSub:
          'Select from an existing account on file, or add a new account. New accounts can be saved for future payments or used for one time only payments.',
        AccountInformationDoNotSave: 'Do not save account information',
        Amount: 'Amount',
        AmountScheduled: 'Amount scheduled',
        AmountSelected: 'Amount selected',
        AutoPay: 'Auto pay',
        BackToInvoices: 'Back to invoices',
        BackToPreviousPage: 'Back to previous page',
        BankAccountEnding: 'Bank account ending',
        CancelPayment: 'Cancel payment',
        ChooseBankAccount: 'Choose an existing bank account',
        ChooseCreditCard: 'Choose an existing credit card',
        Clear: 'Clear',
        ClearSelection: 'Clear selection',
        Closed: 'Closed',
        Company: 'Company',
        ConfirmPayment: 'Confirm payment',
        ContinueToPayment: 'Continue to payment',
        CreditCardEnding: 'Credit card ending',
        CurrentAmountOwed: 'Current Amount Owed',
        CustomerName: 'Customer Name',
        CustomerNumber: 'Customer Number',
        BankAccount: 'Bank account',
        CreditCard: 'Credit card',
        Decline: 'Decline',
        Download: 'Download',
        DownloadError: 'Failed to Download',
        DownloadErrorMessage:
          'An Error occurred during download. Please try again.',
        DownloadErrorMessageForNotFound:
          'One or more invoice(s) selected cannot be downloaded at this time. Please call Accounts Receivable at 833.597.2118 for support.',
        DownloadReceipt: 'Download receipt',
        ECPInvoicesTitle: 'myRSM - Invoices',
        ECPInvoicingAdministrativeTitle: 'myRSM - Invoicing Administrative',
        ECPInvoicingTitle: 'myRSM - Invoicing',
        ECPPaymentsTitle: 'myRSM - Payments',
        Edit: 'Edit',
        EditPayment: 'Edit payment',
        EmailUs: 'Email us',
        EnterInvoiceOrKeywords: 'Enter invoice(s) # or keywords.',
        EnterInvoiceOrKeywordsSeperateCommas:
          'Enter invoice(s) # or keywords. Seperate multiple invoices with commas.',
        ErrorMessages: {
          MustBeLessThanOpenAmount: 'Input cannot exceed Invoice amount',
        },
        ExpYourSessionIsAboutToExpire: 'Your session is about to expire',
        Exp59Sec: '59 seconds left, your session is about to expire',
        Exp30Sec: '30 seconds until your session expires',
        Exp10Sec: '10 seconds until your session expires',
        Exp5Sec: '5 seconds until your session expires',
        FindAnInvoice: 'Find an invoice',
        FilterGroups: {
          amountrange: 'Open amount',
          amountRangeHeader: '$ range:',
          amountRangeTooltip:
            'Amount slider range, Select a start and end amount.',
          daterange: 'Date range',
          enddate: 'End date',
          date: 'Date',
          invoiceAmountFrom: 'Open Amount',
          paymentAmountFrom: 'Open Amount',
          startdate: 'Start date',
          status: 'Status',
        },
        FilterCheckboxes: {
          open: 'Open',
          closed: 'Closed',
          pending: 'Pending',
          paid: 'Paid',
          scheduled: 'Scheduled',
          cancelled: 'Cancelled',
          failed: 'Failed',
        },
        IframeGenericErrorMessage:
          'Please validate the payment information and retry. If the issue persists, contact your bank or card provider for assistance.',
        IframeGenericErrorMessageHeader:
          'The Payment information could not be saved',
        IframeTitle: 'Payment Information Form',
        InvoiceDate: 'Invoice Date',
        InvoiceDateValue: '{{date, Datetime}}',
        InvoiceDateErrors: {
          EndDateIsRequired: 'End Date Required',
          InvalidDateRange: 'Invalid Date Range',
          InvalidStartDate: 'Invalid Start Date',
          InvalidEndDate: 'Invalid End Date',
          StartDateIsRequired: 'Start Date Required',
        },
        InvoiceNumber: 'Invoice Number',
        InvoiceAmount: 'Invoice Amount',
        PaymentAmount: 'Payment Amount',
        PartialAmount: 'Partial Payment',
        PaymentDate: 'Payment Date',
        PaymentDateMessage:
          'Select a pay date no later than 30 days from the starting date',
        PaymentDateRequired: 'Payment date is a required field.',
        NetPayment: 'Net Payment',
        Initiated: 'Payment Date',
        Invoices: 'Invoices',
        InvoiceSelected: 'Invoice selected',
        InvoicesSelected: 'Invoices selected',
        Max: 'Max',
        Method: 'Payment Type',
        NewBankAccount: 'New Bank Account',
        NewCreditCard: 'New Credit Card Account',
        NewPaymentMethod: {
          CardEndingIn: 'ending in {{num}}',
          BankAccountEndingIn: '{{name}} account ending in {{num}}',
        },
        NoBankInfoSaved: 'No Bank Account Saved',
        NoCardSaved: 'No Cards Saved',
        NoDataFound: 'No data found.',
        NoData: 'No data found',
        OpenActionsMenuForInvoice: 'Open actions menu for invoice',
        OpenAmount: 'Open Amount',
        OriginalAmount: 'Original Amount',
        Paid: 'Paid',
        PaidAmount: 'Amount',
        PaidBy: 'Paid by',
        PartialPaymentForInvoice:
          'Partial payment for invoice {{invoiceNumber}}',
        Pay: 'Pay',
        PaymentInformationHeader: 'Payment Information',
        Payments: 'Payments',
        PaymentGateway: {
          AddNewAchAccount: 'Add a New ACH Account',
          AddNewCreditCardAccount: 'Add a New Credit Card Account',
          PaymentInformation: 'Payment Information',
          TimeoutMessage:
            'You will have 5 mins to complete this form.  Once timed out the modal will close and all changes will not be saved.',
        },
        PaymentHistory: 'Payment history',
        PaymentHistoryDisclaimer:
          'Disclaimer: Only payments made on myRSM will be displayed here. Checks mailed in will not display in the payment history of myRSM.',
        Payer: 'Payer',
        PayerMessage:
          'When adding a new account to the payer, all associated users to the selected payer will have access to that account.',
        PayerRequired: 'Payer is a required field.',
        PaySelectedInvoices: 'Pay selected invoice(s)',
        PaySelectedHelperText:
          'The status of any invoice paid via CC after 5:30 PM or ACH after 7 PM (CST) will not be updated in myRSM for 2 business days.',
        PaymentInfo: 'Payment info will apply to all invoices selected.',
        PaymentMethod: 'Payment method',
        PaymentMade: 'Payment made',
        PaymentSelectionWillApply:
          'These payment selections will apply to all the invoices you’ve selected. For individual payments use the three dot menu next to an invoice and select ‘Pay.’',
        PaymentSettings: 'Payment settings',
        PaymentTermsAndConditions: {
          Accepted: 'Accepted',
          AcceptTermsAndConditions:
            'I accept these payment terms and conditions',
          PaymentTermsAndConditions: 'Payment terms and conditions',
          ViewPaymentTermsAndConditions:
            'View payment terms and conditions to continue',
        },
        Payment: 'Payment',
        PayNow: 'Pay now',
        Pending: 'Pending',
        ProcessedBy: 'Processed By',
        Retention:
          'Due to retention policy, the invoices from {{date}} Cannot be displayed. Please email your request to the appropriate email address.',
        Scheduled: 'Scheduled',
        ScheduledOn: 'Scheduled on',
        SchedulePayment: 'Schedule a payment',
        ScheduledPayments: 'Scheduled payments',
        SelectAllInvoices: 'Select all invoices',
        SelectAllPayments: 'Select all payments',
        SelectInvoice: 'Select invoice',
        SelectPaymentType: 'Select payment type',
        SetupInstallments: 'Set up installments',
        Share: 'Share',
        ShareReceipt: 'Share receipt',
        Status: 'Status',
        TotalAmount: 'Total amount',
        TotalNetPayableAmount: 'Total net payable amount',
        TotalPayableAmount: 'Total payable amount',
        TotalOpenAmount: 'Total open amount',
        TransactionId: 'Transaction ID',
        PaymentSuccessPage: {
          CancelPayment: 'Cancel payment',
          Edit: 'Edit',
          PaymentScheduled: 'Payment scheduled',
          PaymentSuccess: 'Payment success',
          TransactionId: 'Transaction ID',
          TransactionDate: 'Transaction date',
          DateScheduled: 'Date scheduled',
          TransactionDateFormat: '{{date, PP}}',
          PaymentMethod: 'Payment method',
          PayerName: 'Payer name',
          Invoices: 'Invoices',
          InvoicesPaid: 'Invoices paid',
          DownloadReceipt: 'Download receipt',
          ShareReceipt: 'Share receipt',
          ViewPaidInvoiceDetails: 'View paid invoice details',
          HidePaidInvoiceDetails: 'Hide paid invoice details',
        },
        UnrelatedInvoicesDialog: {
          Title: 'Invoices selected are not related',
          Subtitle: 'Do you wish to proceed to payment?',
          Cancel: 'Cancel',
          Proceed: 'Proceed',
          DontAskAgain: "Don't ask again",
        },
        UploadImage: 'Upload image',
        SelectedOfTotalInvoicesSelected:
          '{{selected}} of {{total}} invoices selected',
        whenWillYouPay: 'When will you be paying',
      },
      WelcomeMessageModal: {
        WelcomeTitle: 'Hi {{firstName}}, welcome to myRSM!',
        WelcomeDesc:
          'Welcome to myRSM!  This online platform is a convenient, efficient and secure location for you to seamlessly access key applications provided by RSM, collaborate with RSM employees and also receive timely thought leadership based on your interests.',
        WelcomeQuestion: 'How would you like to begin your myRSM experience?',
        CustomizeMyExperienceTitle: 'CUSTOMIZE MY EXPERIENCE',
        CustomizeMyExperienceDesc:
          'Take a quick 4 step wizard to select which Industries, Service Lines and Topics you want to see more of.',
        CustomizeMyExperienceLink: 'Set up your interests',
        NoThanksTitle: 'No, thanks',
        NoThanksDesc: 'I just want to dive in and start using myRSM.',
        NoThanksLink: 'Start using myRSM',
      },
      Footer: {
        PrivacyPolicy: 'Privacy policy',
        Disclaimer:
          'RSM US LLP is a limited liability partnership and the U.S. member firm of RSM International, a global network of independent audit, tax and consulting firms. The member firms of RSM International collaborate to provide services to global clients, but are separate and distinct legal entities that cannot obligate each other. Each member firm is responsible only for its own acts and omissions, and not those of any other party. Visit rsmus.com/aboutus for more information regarding RSM US LLP and RSM International.',
        Copyright: '© 2022 RSM International Association. All rights reserved.',
      },
      ModalDialog: {
        CloseDialog: 'Close dialog',
      },
      RSMUS: 'RSMUS',
      RSMUSClient: 'RSMUS Client',
      ReturnToCP: 'Return to myRSM',
    },
  },
  [locales.SPANISH]: {
    translation: {
      srOnlyRequired: 'requerido',
      Navigation: {
        AccountSettings: 'Configuraciones de la cuenta',
        Administrative: 'Administrativa',
        CloseMainNavigationMenu: 'Cerrar menú de navegación principal',
        Dashboard: 'Panel',
        DocumentRequests: 'Solicitudes de Documentos',
        Documents: 'Documentos',
        Engagements: 'Compromisos',
        HighContrast: 'Alto contraste',
        Home: 'Casa',
        Insights: 'Perspectivas',
        Invoices: 'Facturas',
        Invoicing: 'Facturación',
        Logout: 'Cerrar sesión',
        Main: 'Principal',
        OpenMainNavigationMenu: 'Abrir menú de navegación principal',
        Payments: 'Transacciones de pago',
        Profile: 'Perfil',
        PleaseSelect: 'Por favor seleccione',
        SkipToMainContent: 'Saltar al contenido principal',
        Support: 'Apoyo',
        Welcome: 'Bienvenido',
      },
      Pagination: {
        RowsPerPage: 'Filas por página',
      },
      ActivityTemplates: {
        RequestCreatedSubject:
          'Solicitud creada por {{FirstName}} {{LastName}}',
        RequestUpdatedSubject:
          'Solicitar detalles modificados por {{FirstName}} {{LastName}}',
        RequestNameUpdatedBody:
          'El nombre de la solicitud cambió de "{{OldRequestName}}" a "{{NewRequestName}}".',
        RequestFunctionalAreaUpdatedBody:
          'Área funcional cambiada de "{{OldArea}}" a "{{NewArea}}".',
        RequestGroupUpdatedBody:
          'Grupo cambiado de "{{OldGroup}}" a "{{NewGroup}}".',
        RequestSubgroupUpdatedBody:
          'Subgrupo cambiado de "{{OldSubgroup}}" a "{{NewSubgroup}}".',
        RequestDescriptionUpdatedBody:
          'Descripción cambiada de "{{OldDescription}}" a "{{NewDescripion}}".',
        RequestStatusUpdatedSubject:
          'Estado actualizado desde $t({{From}}) a $t({{To}}) por {{FirstName}} {{LastName}}.',
        RequestDueDateUpdatedSubject:
          'Fecha de vencimiento actualizada desde {{From, Datetime}} a {{To, Datetime}} por {{FirstName}} {{LastName}}',
        RequestPrioritySetSubject:
          'Marcado como prioritario por {{FirstName}} {{LastName}}',
        RequestPriorityUnsetSubject:
          'No marcado como prioridad por {{FirstName}} {{LastName}}',
        RequestLockSetSubject:
          'Solicitud bloqueada por {{FirstName}} {{LastName}}',
        RequestLockUnsetSubject:
          'Solicitud desbloqueada por {{FirstName}} {{LastName}}',
        RequestDeletedSubject:
          'Solicitud eliminada por {{FirstName}} {{LastName}}',
        RequestAssignedSubject:
          'Asignados añadidos por {{FirstName}} {{LastName}}',
        RequestAssignedBody: '{{Val, List}} agregado a la solicitud.',
        RequestUnassignedSubject:
          'Asignados eliminados por {{FirstName}} {{LastName}}',
        RequestUnassignedBody: '{{Val, List}} eliminado de la solicitud.',
        DocumentsAddedSubject:
          '{{DocumentCount}} documento(s) subido por {{FirstName}} {{LastName}}.',
        DocumentsAddedBody: '{{Val, List}} han sido subidos.',
        DocumentsDeletedSubject:
          '{{DocumentCount}} documento(s) borrado por {{FirstName}} {{LastName}}.',
        DocumentsDeletedBody: '{{Val, List}} han sido eliminados.',
        DocumentsDownloadedSubject:
          '{{DocumentCount}} documento(s) descardado por {{FirstName}} {{LastName}}.',
        DocumentsDownloadedBody: '{{Val, List}} han sido descardados.',
        CommentAddedSubject:
          'Comentarios añadidos por {{FirstName}} {{LastName}}',
        FollowupCommentAddedSubject:
          'Solicitar comentarios de seguimiento agregados por {{FirstName}} {{LastName}}',
      },
      Alert: {
        CloseAlert: 'Cerrar alerta',
        PaymentFailed: 'Pago fallido',
        PaymentUnableToProcess:
          'El pago no tuvo éxito. Inténtalo de nuevo o inténtalo con otro método de pago. Si el problema persiste, comuníquese con su banco o proveedor de tarjeta para obtener ayuda.',
      },
      Authorization: {
        NotAuthorizedForApplication: {
          NotAuthorizedHeader: 'Algo no funciona',
          NotAuthorizedReason: 'No tienes permiso para ver esta página.',
          NotAuthorizedPrimarySuggestion:
            'Por favor verifique dos veces su solicitud.',
          NotAuthorizedSecondarySuggestion:
            'Si cree que se trata de un error, póngase en contacto con el administrador de su cuenta de RSM para obtener acceso.',
        },
      },
      UserProfile: {
        ECPProfileTitle: 'myRSM - Perfil',
        PageContactInfoPrefsHeading: 'Información de contacto y preferencias',
        PageInterestsHeading: 'Intereses',
        PageNoInterestsMessage:
          'Aún no has seleccionado ningún interés. Solo toma unos minutos y nos ayuda a personalizar su experiencia myRSM.',
        PageAddInterestsButton: 'Agregar intereses',
        PageUpdateInterestsButton: 'Actualizar intereses',
        IndustryLabel: 'Industrias',
        ServiceLabel: 'Líneas de servicio',
        TopicLabel: 'Temas',
        ProfileWizard: {
          StepCounter: 'Paso {{currentStep}} de {{totalSteps}} ',
          WelcomeMessageDesktop: '¡Hola {{firstName}}, bienvenido a myRSM!',
          WelcomeMessageTablet: 'Bienvenido a myRSM!',
          WelcomeMessageMobile: '¡Bienvenido, {{firstName}}!',
          CustomizationMessage:
            'Díganos cómo planea usar myRSM y personalizaremos la experiencia para usted. Esta información no se compartirá fuera de myRSM y solo se utilizará para mejorar esta herramienta para usted.',
          CustomizationMessageShort:
            'Díganos cómo planea usar myRSM y personalizaremos la experiencia para usted. Esta información no se compartirá fuera de myRSM.',
          SelectThe: 'Selecciona el',
          Industries: 'Industrias',
          ServiceLines: 'Líneas de servicio',
          Topics: 'Temas',
          ContactInfoPrefs: 'Información de contacto y preferencias',
          YouAreInterestedIn: 'que le interesan.',
          ContactInfoPrefsMessage:
            'Verifique y/o agregue la siguiente información. Todos los campos son opcionales.',
          Back: 'Atrás',
          Next: 'Próxima',
          SaveAndContinueLater: 'Guardar y continuar más tarde',
          SaveAndExit: 'Guardar y Salir',
        },
        ProfilePopover: {
          IncompleteMessage:
            'Notamos que no pudiste terminar de configurar tu perfil.',
          CompleteMessage:
            'Puede ajustar sus selecciones de interés en cualquier momento en la sección de perfil del Portal del cliente.',
          ContinueMessage: 'Ir al perfil para terminar',
          AdjustProfileMessage: 'Ir al perfil',
          FinishProfile: 'Perfil de acabado',
          CloseDialog: 'Cerrar diálogo',
        },
        ContactPreferenceForm: {
          FirstName: 'Nombre de pila',
          Name: 'Nombre',
          LastName: 'Apellido',
          ClientOrBusinessName: 'nombres de clientes o empresa(s)',
          RegionOrTimeZone: 'Región/Zona horaria',
          BusinessPhoneNumber: 'Número de teléfono laboral',
          BusinessEmailAddress: 'Dirección de correo electrónico comercial',
          PreferredContactMethod: 'Método de Contacto Preferido',
          Email: 'Correo electrónico',
          Phone: 'Teléfono',
          EngagementActivityNotificationFrequency:
            'Frecuencia de notificación de actividad de interacción',
          InsightsActivityNotificationFrequency:
            'Frecuencia de notificación de actividad de Insights',
          Submit: 'Enviar',
          Edit: 'Editar',
          Save: 'Salvar',
          Cancel: 'Cancelar',
          TimeZones: {
            AlaskaTimeZone: 'Alaska',
            ArizonaTimeZone: 'Arizona',
            CentralTimeZone: 'Hora central (EE. UU. y Canadá)',
            PacificTimeZone: 'Hora del Pacífico (EE. UU. y Canadá)',
            MountainTimeZone: 'Hora de la montaña (EE. UU. y Canadá)',
            HawaiiTimeZone: 'Hawai',
            EasternTimeZone: 'Hora del Este (EE. UU. y Canadá)',
          },
          NotificationFrequencies: {
            WeeklyTextAlert: 'Alerta de texto semanal',
            DailyTextAlert: 'Alerta de texto diaria',
            NewItemTextAlert:
              'Alerta de texto tan pronto como se agregue un nuevo elemento',
            NoTextAlert:
              'Sin alertas de texto, solo en las notificaciones de la aplicación',
            WeeklyEmailDigest: 'Resumen de correo electrónico semanal',
            DailyEmailDigest: 'Resumen diario de correo electrónico',
            NewItemEmailDigest:
              'Correo electrónico tan pronto como se agregue un nuevo elemento',
            NoEmailDigest:
              'Sin correos electrónicos, solo en las notificaciones de la aplicación',
          },
          ContactMethods: {
            EmailContactMethod: 'Correo electrónico',
            PhoneContactMethod: 'Teléfono',
          },
        },
      },
      Download: 'Descargar',
      DownloadAll: 'Descargar Todos',
      Delete: 'Eliminar',
      DocumentRequestListHeader: 'LISTA DE SOLICITUDES DE DOCUMENTOS',
      DocumentRequestButtonCreateRequest: 'Crear nueva solicitud',
      DocumentRequestSearchLabel: 'Buscar por nombre',
      DocumentRequestCreateRequestModalTitle:
        'Crear nueva solicitud de documento',
      DocumentRequestCreateRequestModalSubtitle:
        'Porfavor llena los campos de abajo para crear una solicitud de documento',
      DocumentRequestCsvUploadErrorModalTitle: 'Reporte de Errores',
      DocumentRequestCsvUploadErrorModalSubtitle:
        'Ha fallado la creación de la nueva solicitud de documento.',
      Docs: {
        ECPDocsTitle: 'myRSM - Documentos',
        DocumentListHeader: 'ALL DOCUMENTOS',
        DocumentSearchLabel: 'Buscar por título:',
        ConfirmDeleteTitle_one:
          'Se eliminarán {{count}} elementos seleccionados.',
        ConfirmDeleteTitle_other:
          'Se eliminarán {{count}} elementos seleccionados.',
        ConfirmDeleteDescription:
          '¿Está seguro de que desea eliminar el documento(s) seleccionado(s)?',
        AlertDeletedTitle: 'Eliminar exitoso.',
        AlertDeletedDescription: 'Los documentos seleccionados se eliminan.',
        Headers: {
          documentName: 'nombre del documento',
          createdBy: 'Subido por',
          createdDate: 'Fecha',
          group: 'Grupo',
          subgroup: 'Subgrupo',
          status: 'Estado',
          contentType: 'Tipo de contenido',
        },
      },
      EngagementList: {
        NoSearchResultsTitle: 'No se han encontrado resultados',
        NoSearchResultsSubTitle:
          'Intente ampliar su(s) término(s) de búsqueda.',
        ReportCard: {
          Name: 'Reportar un problema',
          Category:
            '¿Tiene problemas como proyectos perdidos o enlaces rotos? Informe su problema aquí.',
        },
        ReportAnIssueForm: {
          title: 'Informar un formulario de problema',
          description:
            'Describa el problema que está experimentando enviando el siguiente formulario y un representante de RSM en EE. UU. se comunicará con usted. *Campo requerido.',
          descriptionLabel: 'Descripción',
          issue: 'Asunto',
          projectName: 'Nombre del proyect',
          issueOptions: {
            notAccurate: 'Mi lista de proyectos no es precisa',
            notLoading: 'El proyecto no se carga cuando hago clic en el enlace',
            incorrect: 'Algo está mal con un proyecto',
            other: 'Otro',
          },
          issueWarning:
            '*La lista de proyectos puede tardar hasta 24 horas en actualizarse.',
          toast: {
            title: 'Ticket de soporte enviado',
            description:
              'Se ha recibido su solicitud de mesa de servicio. Espere una respuesta por correo electrónico dentro de las 48 horas.',
          },
        },
        GoToTop: 'Ve arriba',
      },
      EngagementDashboard: {
        RequestSummaryHeader: 'RESUMEN DE SOLICITUD',
        ViewAllEngagements: 'Ver todos los compromisos',
        RequestTaskCompletionHeader: 'DESGLOSE DEL ESTADO',
        DueDateCalendarHeader: 'CALENDARIO DE FECHA DE VENCIMIENTO',
        TeamMembersHeader: 'MIEMBROS DEL EQUIPO',
        EmptyStatusBreakdownMainHeader: '¡Estáis todos atrapados!',
        EmptyStatusBreakdownSubHeader:
          'Ninguna solicitud necesita atención en este momento.',
        Summary: {
          AssignedToYou: 'Asignado a usted',
          CreatedByYou: 'Creado por usted',
          AllRequests: 'Todas las solicitudes',
          OutOf: 'de',
          ViewAllRequests: 'Ver todas las solicitudes',
          OverdueRequestsPhrase: 'las solicitudes están actualmente vencidas',
          OutstandingRequestsPhrase:
            'las solicitudes prioritarias están pendientes',
          FollowUpRequestsPhrase: 'las solicitudes requieren seguimiento',
          StatusBreakDown: {
            TotalRequests:
              'Este compromiso cuenta actualmente con {{count}} solicitudes',
            CompletedLabel: 'Completado',
            FollowUpLabel: 'Seguimiento',
            SubmittedLabel: 'Presentado',
            InProgressLabel: 'En curso',
            RequestedLabel: 'Solicitado',
          },
        },
        TeamMembers: {
          RSMTeam: 'Equipo del RSM',
          ClientTeam: 'Equipo del cliente',
        },
      },
      ECPAllEngagementsTitle: 'myRSM - Todos los compromisos',
      ECPEngagementsTitle: 'myRSM - Compromisos',
      EngagementListHeader: 'TODOS LOS COMPROMISOS',
      EngagementMembersHeader: 'MIEMBROS',
      ErrorBoundary: {
        DefaultErrorMessage: 'Lo sentimos, ha habido un error',
      },
      FilterChips: {
        ClearAll: 'Limpiar todo',
        ClearAllAppliedFilters: 'Borrar todos los filtros aplicados,',
        RemoveFilter: 'Quitar',
        ShowLess: 'Muestra menos',
        ShowLessAppliedFilters: 'Muestra menos filtros aplicados',
        ShowMoreAppliedFilters: 'Mostrar {{count}} filtros aplicados más',
      },
      Form: {
        RequiredField: 'El campo es requerido.',
        Submit: 'Enviar',
        Maximum:
          'El limite maximo para este campo es- {{MAX_VALUE}} caracteres',
        Minimum:
          'El limite minimo para este canmpo es- {{MIN_VALUE}} caracteres',
        Characters: 'caracteres',
        MaximumReached: 'Has alcanzado el límite de caracteres',
      },
      HomeEngagementsHeader: 'TUS CONTRATOS',
      HomeToolsHeader: 'HERRAMIENTAS',
      HomeRequestsHeader: 'PRÓXIMAS SOLICITUDES',
      HomeFavoritedHeader: 'FAVORITOS',
      HomeInsightsHeader: 'ÚLTIMAS SUGERENCIAS',
      HomePage: {
        ECPHomeTitle: 'myRSM - Inicio',
        EngagementsHeader: 'COMPROMISOS',
        Header: 'Página de inicio de myRSM',
        ArticleFeaturedContent: 'Contenido destacado: Artículo',
        InsightsHeader: 'AÑADIDO RECIENTEMENTE',
        ToolsHeader: 'HERRAMIENTAS',
        ToolsLoadMore: 'Carga más',
        Launch: 'Lanzamiento',
        ViewProjects: 'Ver mis proyectos',
        SubmitSupportTicket: 'Enviar un ticket de soporte',
        AreYouMissingTools: '¿TE FALTAN HERRAMIENTAS?',
        RequestMissingTools:
          'Solicite herramientas adicionales completando el formulario de soporte de myRSM.',
        AreYouMissingToolsCategory: 'EJEMPLO: DASH, TEAMDOCS, ETC.',
        ArticleReadMore: 'Leer más',
        ArticleReadNow: 'Lee ahora',
        ViewAllEngagements: 'Ver todos los compromisos',
        ViewAllInsights: 'Ver todos los conocimientos',
        TeamDocsCategory: 'Consultante',
        TeamDocsDescription:
          'Colabore con su equipo de proyecto usando esta herramienta de participación',
      },
      Projects: {
        Title: 'Localidades de Projectos',
        LoadMore: 'Descargar más',
        GoToTop: 'Volver al inicio',
        HeaderMessage:
          'Proyectos perdidos? Revise su correo electrónico para ver si hay una invitación al sitio del proyecto en cuestión. Si no tiene ninguna invitación, comuníquese con su equipo de proyecto.',
        SupportCard: {
          Name: 'Soporte',
          Category: 'Documentación de Soporte',
          SecuritySummaryLabel: 'Resument de seguridad para TeamDocs',
          SecuritySummaryUrl:
            'https://rsmnet.sharepoint.com/:b:/r/teams/cd/Shared%20Documents/External%20-%20Published/RSM%20TeamDocs%20Security%20Summary.pdf?csf=1&web=1&e=e5o4Qr',
          LoggingInWith365Label: 'Ingresar a TeamDocs con cuenta O365',
          LoggingInWith365Url:
            'https://rsmnet.sharepoint.com/:b:/r/teams/cd/Shared%20Documents/External%20-%20Published/External%20User%20Login%20with%20O365%20Account%20into%20TeamDocs.pdf?csf=1&web=1&e=rZKcqQ',
          LoggingInWithout365Label: 'Ingresar a TeamDocs sin cuenta O365',
          LoggingInWithout365Url:
            'https://rsmnet.sharepoint.com/:b:/r/teams/cd/Shared%20Documents/External%20-%20Published/External%20User%20Login%20without%20an%20O365%20Account%20into%20TeamDocs.pdf?csf=1&web=1&e=qh1PB4',
          ContactSupport: 'Contactar soporte',
        },
        NoSearchResultsTitle: 'No se han encontrado resultados',
        NoSearchResultsSubTitle:
          'Intente ampliar su(s) término(s) de búsqueda.',
      },
      InsightsList: {
        ECPInsightsHeading: 'Últimas Perspectivas de RSM',
        InsightsFilter: 'Filtro',
        InsightsClearAll: 'Borrar todo',
        InsightsChipAriaPrefix: 'Quitar filtro ',
        FilterLabels: {
          favorites: 'Favoritos',
          recentlyViewed: 'Visto recientemente',
          myInterestsLabel: 'mis-intereses',
          sharedWithMe: 'compartido-conmigo',
        },
        FilterHeaders: {
          yourinsights: 'Tus Ideas',
          role: 'El Oficio',
          industry: 'Idustria',
          service: 'Línea de servicio',
          topic: 'Tema',
        },
        ReadMore: 'Leer más',
        LoadMore: 'Cargar más',
        GoTotop: 'Ir al principio',
        NoSearchResults: 'No se han encontrado resultados',
        NoSearchResultsAdvice:
          'Intenta ampliar el término de búsqueda o eliminar filtros para ver los resultados.',
      },
      InsightArticle: {
        AddToFavorites: 'Agregar a Favoritos',
        RemoveFromFavorites: 'Eliminar de favoritos',
        BackToInsights: 'Ver todas las perspectivas',
        InsightArticle: 'Artículo de Información',
        RSMContributors: 'Colaboradores de RSM',
        Tags: 'Etiquetas',
        DefaultTag: ' ',
        ArticleDate: '{{date, medium}}',
        ArticleSharedSuccess: 'Artículo compartido con éxito',
        ArticleSharedFail: 'No se puede compartir el artículo',
        ArticleSharedWarning:
          'No se puede compartir con {{count}} de las destinatarias',
        ArticleSharedSuccessMessageMultiUser:
          'El artículo ha sido enviado por correo electrónico a todos los destinatarios.',
        ArticleSharedSuccessMessage:
          'El artículo fue enviado a {{firstName}} {{lastName}} ({{email}}).',
        ArticleSharedError: 'No se puede compartir',
        ArticleSharedErrorMessage:
          'Hubo un error y no pudimos enviar su mensaje. Vuelva a intentarlo o póngase en contacto con el soporte si el problema continúa.',
        ShareArticleMessage: 'compartió este artículo contigo',
      },
      ShareArticleModal: {
        Title: 'Comparte este artículo de información',
        DefaultMessage: `Hola {{receiverName}},\nEstaba en myRSM y encontré este artículo que pensé que podría interesarle.\n\nEl mejor,\n{{senderName}}`,
        DefaultMessageNonEcpUser: `Hola {{receiverName}},\nEstaba en myRSM y encontré este artículo que pensé que podría interesarle.\nDeberá unirse a myRSM para verlo, ¡pero creo que vale la pena!\n\nBest,\n{{senderName}}`,
        DefaultMessageMultiple: `Hola, \nEstaba en myRSM y encontré este artículo que pensé que podría interesarle.\n\nBest,\n{{senderName}}`,
        IncludeMessageLabel: 'Incluye un mensaje:',
        RequiredField: 'Este es un campo obligatorio.',
        UserSearchInputPlaceholder: 'Buscar por nombre o apellido',
        AriaLabel: {
          ShareArticle: 'Compartir artículo',
          AriaCloseDialog: 'Cerrar cuadro de diálogo',
        },
        NoOptionsText:
          'No se encontraron resultados para ningún usuario con ese nombre.',
        EcpWarning:
          'Este usuario aún no tiene acceso a myRSM. Si continúa, tendrán acceso a  myRSM, que es necesario para ver cualquier artículo compartido.',
        NonEcpUserAckMessage:
          'Sí, dé a estos usuarios acceso a myRSM. Esto es necesario para ver este artículo.',
        NonEcpUserAckRequired:
          'Este es un campo obligatorio, reconozca o elimine a los usuarios que no sean myRSM para continuar.',
        NonEcpUserInfoMessage:
          'Estos destinatarios aún no tienen acceso al Portal del cliente.',
        MaxUserLimit: 'Número máximo de destinatarias alcanzadas',
      },
      NotificationTemplates: {
        ArticleShared:
          '<strong>{{FirstName}} {{LastName}} compartió un artículo <0>{{ArticleName}}</0> con usted</strong>',
        NoMessages: 'No tienes ninguna notificación.',
        NotificationsTitle: 'Notificaciones',
        NotificationsLabel: 'Notificaciones, {{count}} nuevo',
        DeleteNotification: 'Eliminar notificación',
        DateAgo: '{{date, atrás}}',
      },
      ECPInsightsTitle: 'myRSM - Sugerencias',
      InsightsFeaturedTopicsHeader: 'TEMAS DESTACADOS: CATEGORÝAS',
      InsightsIndustryInsightsHeader: 'SUGERENCIAS DE LA INDUSTRIA: ARTÝCULOS',
      InsightsServiceLineRelatedHeader:
        'SUGERENCIAS RELACIONADAS A LA LÝNEA DE SERVICIO: ARTÝCULOS',
      ECPNoMatchTitle: 'myRSM - No Encontrado',
      NoMatchHeader: 'Sin Coincidencias',
      ECPNotificationsTitle: 'myRSM - Notificaciones',
      NotificationsHeader: 'NOTIFICACIONES',
      ActivityFeedTitle: 'Fuente de actividad',
      OutOfTime: 'Fuera de tiempo',
      ECPSupportTitle: 'myRSM - Apoyo',
      RSMSupportHeading: 'myRSM Soporte',
      SupportFormTitle: 'FORMULARIO DE APOYO',
      FirstName: 'Primer nombre',
      LastName: 'Apellido',
      EngagementName: 'Nombre de compromiso',
      ContactMethod: 'Método de Contacto Preferido',
      BusinessEmail: 'email de negocios',
      BusinessPhone: 'Teléfono de negocios',
      ClientBusName: 'Cliente/Nombre de la empresa',
      DashIssue: '¿Es esto un problema de DASH?',
      DashIssueShortened: 'Problema con DASH',
      DashSupportLink: 'enlace de la página de soporte de dash',
      LOB: 'Línea de servicio',
      SysPortal: 'Sistema/Portal tratando de acceder',
      IssueDesc: 'Asunto',
      IsARequiredField: 'es un campo obligatorio.',
      ReadMore: 'Leer Más',
      RequestAccess: {
        ThankYou: 'Gracias por su interés en myRSM.',
        SubmitRequest:
          'Envíe su solicitud para iniciar el acceso a la siguiente cuenta.',
      },
      Reset: 'Reiniciar',
      Submit: 'Enviar',
      SelectEngagement: 'Seleccionar compromiso',
      SessionTimeout: 'Hora de término de la sesión',
      SessionTimeoutExplination: 'Su sesión está a punto de caducar',
      SelectBusiness: 'Seleccionar',
      Other: 'Otro/Otra',
      SupportSuccess: 'Ticket de soporte enviado',
      SupportSuccessMessage:
        'Su solicitud de Service Desk ha sido recibida. Espere una respuesta por correo electrónico dentro de las 48 horas.',
      SupportSuccessLink: 'Mensaje de CTA más largo Lorem ipsum dolor sit amet',
      SupportError: 'Alerta',
      SupportErrorMessage: 'Ocurrió un error.',
      SupportFormDesc:
        'Describa el problema de myRSM que está experimentando enviando el siguiente formulario y un representante de myRSM se comunicará con usted. *Campo requerido.',
      SupportFormDashDropdown: {
        Yes: 'Sí',
        No: 'No',
        ClearSelection: 'Borrar selección',
      },
      UpdateSuccess: 'Actualizado correctamente',
      UpdateSuccessMessage: 'Sus preferencias de perfil han sido actualizadas.',
      UpdateError: 'ALERTA',
      UpdateErrorMessage:
        'No pudimos procesar esta solicitud debido a un error del sistema.',
      RequiredField: 'Este campo es requerido.',
      ValidCurrency: 'Por favor ingrese un valor válido',
      ValidDate: 'Por favor ingrese una fecha válida',
      ValidDateInRange:
        'Ingrese una fecha entre el {{minDate, P}} y el {{maxDate, P}}',
      ValidInput: 'Entrada inválida',
      Filters: {
        Clear: 'Limpiar',
        CollapsedToggle: {
          ShowMore: 'Ver más',
          ShowLess: 'Ver menos',
        },
      },
      Engagement: {
        Name: 'NOMBRE DEL COMPROMISO',
        DateCreated: 'FECHA DE CREACION',
        EndDate: 'FECHA FINAL',
        Client: 'CLIENTE',
        Entity: 'ENTIDAD',
        Type: 'TIPO',
        ViewEngagement: 'Ver interacción',
        ViewEngagementAriaLabel: 'Ver {{engagement}} compromiso',
      },
      DocReq: {
        NewForm: {
          Success: '¡Éxito!',
          RequestName: 'Solicitar nombre',
          DocReqInformation: 'Información de solicitud de documentos',
          DueDate: 'Fecha de vencimiento',
          FunctionalArea: 'Área funcional',
          Group: 'Grupo',
          GroupPlaceholder:
            'Escriba el nombre para buscar o ingrese como nuevo grupo',
          Subgroup: 'Subgrupo',
          SubgroupPlaceholder:
            'Escriba el nombre para buscar o ingrese como nuevo subgrupo',
          RequestSettings: 'Solicitar configuración',
          MarkAsPriority: 'Marcar como prioridad',
          PriorityTooltip:
            'Marcar como prioridad marcará esta solicitud y habilitará las notificaciones por correo electrónico relacionadas.',
          LockRequest: 'Solicitud de bloqueo',
          LockTooltip:
            'Al bloquear esta solicitud, los detalles solo serán visibles para el cesionario y los miembros de RSM en este compromiso.',
          Description: 'Descripción',
          AssignRequest: 'Asignar solicitud',
          SearchForUser: 'Buscar usuario',
          AssigneeNoMatch: 'No se han encontrado resultados',
          MaxAssignees:
            'Ha alcanzado el máximo de {{MaxAssignees}} el apoderados por solicitud',
          MaxAssigneesMobile: 'Máximo de {{MaxAssignees}} los apoderados',
          TypeNameOrEmail: 'Escriba el nombre o el correo electrónico',
          ClientAssignees: 'Cesionarios del cliente',
          RSMAssignees: 'Cesionarios del RSM',
          Submit: 'Enviar',
          AriaLabel: {
            RequestName: 'Solicitar campo de nombre',
            DueDate: 'Campo de fecha de vencimiento',
            FunctionalArea: 'Campo de área funcional',
            Group: 'Campo de grupo',
            Subgroup: 'Campo de subgrupo',
            PriorityIcon: 'Marcar como casilla de prioridad',
            LockIcon: 'Casilla de solicitud de bloqueo',
            SearchForUser: 'Campo de búsqueda de cesionario',
            EnterNameOrEmail: 'Ingrese nombre o correo electrónico',
            ClientAssignees: 'Campo de asignatarios de clientes',
            RSMAssignees: 'Campo de asignados de RSM',
            FLAdded: '{{Name}} agregado.',
            FLRemoved: '{{Name}} eliminado.',
            UpDownNavigate:
              'Utilice las flechas hacia arriba o hacia abajo para navegar.',
            BackspaceToRemove:
              'Para eliminar al asignado, presione el botón eliminar o retroceder',
            Description: 'Campo de descripción',
            SearchEmail: 'Buscar campo de correo electrónico',
            Submit: 'Enviar',
          },
          SuccessModal: {
            Title: '¡Éxito!',
            Subtitle: 'Su solicitud de documento ha sido creada con éxito.',
            RequestName: 'Solicitar nombre',
            DueDate: 'Fecha de vencimiento',
            FunctionalArea: 'Área funcional',
            Group: 'Grupo',
            Subgroup: 'Subgrupo',
            PriorityStatus: 'Estado de prioridad',
            PriorityStatusIsPriority: 'Marcado como prioridad',
            PriorityStatusIsNotPriority: 'No marcado como prioridad',
            LockStatus: 'Estado de bloqueo',
            LockStatusLocked: 'Bloqueado',
            LockStatusUnlocked: 'Desbloqueado',
            Description: 'Descripción',
            ClientAssignees: 'Cesionarios del cliente',
            RSMAssignees: 'Cesionarios del RSM',
            Close: 'Cerca',
          },
        },
        RequestFollowUpModal: {
          Title: 'Solicitar seguimiento',
          SubTitle: '¿Qué información necesita del cliente?',
          AddComment: 'Agregar comentario',
          MaximumReached:
            'El comentario debe estar entre {{MIN_LENGTH}} y {{MAX_LENGTH}} Caracteres largas',
          AriaLabel: {
            CommentAdded: 'Comentario añadido',
            CharsEntered:
              '{{charCount}} de {{MAX_LENGTH}} Caracteres ingresadas',
            CloseDialog: 'Cerrar diálogo',
            name: 'solicitud de seguimiento',
          },
        },
        RequestAddComment: {
          AriaCloseDialog: 'Cerrar cuadro de diálogo',
          AriaCommentAdded: 'Comentario añadido',
          Title: 'Agregar comentario',
          PlaceholderNote: 'Añadir un comentario',
          MaximumReached:
            'El comentario debe tener entre {{MIN_LENGTH}} y {{MAX_LENGTH}} caracteres.',
          AriaCharactersEntered:
            '{{charCount}} de {{MAX_LENGTH}} caracteres ingresados',
        },
        ViewDetails: {
          MarkedAsPriority: 'Marcado como prioridad',
          NoPriority: 'sin prioridad',
          Priority: 'Prioridad',
          FunctionalArea: 'Área funcional',
          DueDate: 'Fecha de vencimiento',
          Group: 'Grupo',
          Subgroup: 'Subgrupo',
          Lock: 'Cerrar',
          Locked: 'Bloqueada',
          Unlocked: 'Desbloqueada',
          CurrentStatus: 'Estado actual',
          CurrentStatusCountMsg: 'Actualizada {{countDays}} hace días',
          CurrentStatusDateMsg: 'Actualizado en',
          DueDateMsg: 'Debido en {{countDays}} Días',
          DueToday: 'Para hoy',
          UpdatedToday: 'Actualizado hoy',
          CurrentClientAssignees: 'Asignatarios de clientes actuales',
          CurrentRSMAssignees: 'Asignadas actuales de RSM',
          DocumentRequestStatus: {
            Requested: 'Solicitada',
            InProgress: 'En progreso',
            Submitted: 'Presentada',
            FollowUp: 'Hacer un seguimiento',
            Complete: 'Completo',
          },
        },
        CurrentAssignees: 'Current assignees',
        Summary:
          'Esta es una lista de los documentos que ha solicitado. Actualmente {{uploaded}} fuera de {{total}} los documentos solicitados han sido subidos. {{inProgress}} las solicitudes están en curso y  {{followUp}} requieren seguimiento.&nbsp;',
        SummaryOverdue: '{{overdue}} la solicitud está vencida actualmente.',
        ActionButtons: {
          Upload: 'Subir documentos',
          Submit: 'Enviar peticion',
          FollowUp: 'Solicitar seguimiento',
          Complete: 'Marcar como completo',
        },
        Documents: {
          SectionTitle: 'Documento(s) subido(s)',
          DownloadAll: 'Descargar todo',
          Download: 'Descargar ',
          Delete: 'Borrar',
          NoDocs: 'No hay nada aquí todavía',
        },
      },
      SearchUserByEmail: 'Buscar usuario por correo electrónico',
      DocumentRequest: 'Solicitud de documento',
      Assignee: 'Cesionario',
      DueDate: 'Fecha de vencimiento',
      Status: 'Estado',
      Activity: 'Actividad',
      SearchByDocName: 'Buscar por nombre de documento',
      SearchByKeyword: 'Buscar por palabra clave',
      SearchByPayor: 'Empieza a escribir para buscar',
      SearchScreenReaderInstructions:
        'Los resultados se filtrarán a continuación a medida que escriba',
      ScreenReaderRemoveFilterMessage:
        'Para eliminar filtros, pulse el botón Eliminar o retroceso',
      Results: 'resultados',
      CreateNewDocReq: 'Crear una nueva solicitud',
      SubmitDocReq: 'Enviar peticion',
      MarkComplete: 'Marcar como completo',
      FollowUp: 'Solicitar seguimiento',
      UploadDoc: 'Subir documento',
      AddComment: 'Agregar comentario',
      CsvErrorModal: {
        ErrorReport: 'Reporte de error',
        ExplanationMessage:
          'No se pudo crear una nueva solicitud de documento.',
      },
      NewDocReqModal: {
        Title: 'Crear una nueva solicitud de documento',
        SubTitle:
          'Complete los campos a continuación para crear una solicitud de documento',
      },
      Faq: {
        Title: 'FAQs',
        Description:
          'Muchas preguntas se pueden responder rápidamente consultando nuestras preguntas frecuentes.',
        ButtonText: 'Leer FAQs',
      },
      Dialogs: {
        OkText: 'OK',
        ConfirmText: 'Confirmar',
        CancelText: 'Cancelar',
        SaveText: 'Ahorrar',
      },
      CallUs: {
        Title: 'Llámanos',
        TollFree: 'Gratuito:',
        Description:
          'El horario de atención al cliente de Cuentas por Cobrar es: de lunes a viernes, de 9 a.m. a 4 p.m. CST.',
        InternalDescription:
          'El horario de atención al cliente es: domingo de 9 p. m. a viernes de 9 p. m. CST, sábados y domingos de 8 a. m. a 5 p. m. CST',
        ExternalDescription:
          'El horario de atención al cliente es: domingo 9 p. m. - viernes 9 p. m. CST',
        USandCANADA: 'Estados Unidos y Canadá',
      },
      RobotPage: {
        TechnicalDifficulties: 'Experimentar dificultades técnicas',
        SomeSectionsUnloadable:
          'Algunas secciones de esta página no pudieron cargarse.',
        TryAgainLaterMessage: 'Actualiza o vuelve a intentarlo más tarde.',
      },
      PageUnavailableHeader: 'Página no disponible',
      TechnicalIssuesMessage: 'Estamos experimentando problemas técnicos. ',
      PleaseTryAgainMessage: 'Por favor, inténtelo de nuevo más tarde.',
      TryAgainButtonLabel: 'Intentar otra vez',
      NoToolsMessage: 'No tiene ninguna herramienta RSM en este momento.',
      IfYouAreMissingTools: 'Si te faltan herramientas, ',
      SubmitSupportTicket: 'enviar un ticket de soporte.',
      SearchLayout: {
        Apply: 'Aplicar',
        ClearAll: 'Limpiar todo',
        Filters: 'Filtros',
        ScrollToTop: 'Vuelve al comienzo',
      },
      Invoicing: {
        Actions: 'Comportamiento',
        Accept: 'Aceptar',
        ActivityFeed: {
          InvoicePastDue: 'Factura vencida',
          InvoicePaymentProcessed: 'Pago de factura procesado',
          PaymentScheduled: 'Pago programado',
          NewInvoice: 'Nueva factura',
          InvoicePayment: 'Pago de la factura',
          DaysOverdue: 'dias atrasados',
          ScheduledPaymentFor: 'programó un pago para',
          PaymentMade: 'pago realizado',
          At: 'EN',
        },
        AddNewBankAccount: '+ nueva cuenta bancaria',
        AddNewCreditCard: '+ nueva tarjeta de crédito',
        AgreementModal: {
          TermsCondition: 'Términos y condiciones de facturación',
          AcceptTermsCondition:
            'Para aceptar o rechazar, debe desplazarse por los términos y condiciones que se muestran a continuación.',
        },
        Frequency: 'Frecuencia',
        FrequencyRequired: 'La frecuencia es obligatoria',
        NumberOfInstallments: 'Número de cuotas',
        NumberOfInstallmentsRequired: 'El número de cuotas es obligatorio',
        PaymentsStartDate: 'Fecha de inicio de los pagos',
        PaymentsStartDateRequired:
          'La fecha de inicio de los pagos es obligatoria',
        Monthly: 'Mensual',
        BiWeekly: 'Quincenal',
        Weekly: 'Semanal',
        AccountInformationDoNotSave: 'No guardar información de la cuenta',
        AccountInformation: 'Información de la cuenta',
        AccountInformationSub:
          'Seleccione desde una cuenta existente registrada o agregue una cuenta nueva. Las cuentas nuevas se pueden guardar para pagos futuros o usarse para pagos únicos.',
        Amount: 'Cantidad',
        AmountScheduled: 'Monto programado',
        AmountSelected: 'Cantidad seleccionada',
        AutoPay: 'Pago automático',
        BackToInvoices: 'Volver a las facturas',
        BackToPreviousPage: 'Volver a la página anterior',
        BankAccountEnding: 'Terminación de cuenta bancaria',
        CancelPayment: 'Cancelar el pago',
        ChooseBankAccount: 'Elija una cuenta bancaria existente',
        ChooseCreditCard: 'Elija una tarjeta de crédito existente',
        Clear: 'Claro',
        ClearSelection: 'Selección clara',
        Closed: 'Cerrado',
        Company: 'Empresa',
        ConfirmPayment: 'Confirmar pago',
        ContinueToPayment: 'Continuar con el tipo de pago',
        CreditCardEnding: 'Terminación de la tarjeta de crédito',
        CurrentAmountOwed: 'Monto actual adeudado',
        CustomerName: 'Nombre del cliente',
        CustomerNumber: 'Número de cliente',
        BankAccount: 'cuenta bancaria',
        CreditCard: 'Tarjeta de crédito',
        Decline: 'Rechazar',
        Download: 'Descargar recibo',
        DownloadError: 'Error al descargar',
        DownloadErrorMessage:
          'Ocurrió un error durante la descarga. Inténtalo de nuevo.',
        DownloadErrorMessageForNotFound:
          'Una o más facturas seleccionadas no se pueden descargar en este momento. Llame a Cuentas por cobrar al 833.597.2118 para obtener ayuda.',
        DownloadReceipt: 'Descargar ',
        ECPInvoicesTitle: 'myRSM - Facturas',
        ECPInvoicingAdministrativeTitle: 'myRSM - Invoicing Administrativa',
        ECPInvoicingTitle: 'myRSM - Facturación',
        ECPPaymentsTitle: 'myRSM - Pagos',
        Edit: 'Editar',
        EditPayment: 'Editar pago',
        EmailUs: 'Envíanos un correo electrónico',
        EndDateIsRequired: 'La fecha de finalización es obligatoria',
        EnterInvoiceOrKeywords:
          'Ingrese el número de factura(s) o palabras clave.',
        EnterInvoiceOrKeywordsSeperateCommas:
          'Ingrese el número de factura(s) o palabras clave. Separe varias facturas con comas.',
        ErrorMessages: {
          MustBeLessThanOpenAmount:
            'La entrada no puede exceder el monto de la factura',
        },
        ExpYourSessionIsAboutToExpire: 'Su sesión está a punto de caducar',
        Exp59Sec: 'Quedan 59 segundos, tu sesión está a punto de expirar',
        Exp30Sec: 'Quedan 30 segundos, tu sesión está a punto de expirar',
        Exp10Sec: 'Quedan 10 segundos, tu sesión está a punto de expirar',
        Exp5Sec: 'Quedan 5 segundos, tu sesión está a punto de expirar',
        FindAnInvoice: 'Buscar una factura',
        FilterGroups: {
          amountrange: 'rango de cantidad',
          amountRangeHeader: 'rango de $:',
          amountRangeTooltip:
            'Rango del control deslizante de cantidad, seleccione una cantidad inicial y final.',
          daterange: 'Rango de fechas',
          enddate: 'fecha final',
          invoiceAmountFrom: 'monto abierto',
          paymentAmountFrom: 'monto abierto',
          startdate: 'fecha de inicio',
          status: 'Estado',
        },
        FilterCheckboxes: {
          open: 'Abierto',
          closed: 'Cerrado',
          pending: 'Pendiente',
          paid: 'Pagado',
          scheduled: 'Programado',
          cancelled: 'Cancelado',
          failed: 'Fallido',
        },
        IframeGenericErrorMessage:
          'Por favor valide la información de pago y vuelva a intentarlo. Si el problema persiste, comuníquese con su banco o proveedor de tarjeta para obtener ayuda.',
        IframeGenericErrorMessageHeader:
          'La información de pago no se pudo guardar.',
        IframeTitle: 'Formulario de Información de Pago',
        InvoiceDate: 'Fecha de la factura',
        InvoiceDateValue: '{{date, Datetime}}',
        InvoiceNumber: 'Número de factura',
        InvoiceAmount: 'Cantidad de factura',
        PaymentAmount: 'Monto de pago',
        PartialAmount: 'Pago parcial',
        PaymentDate: 'Fecha de pago',
        PaymentDateMessage:
          'Seleccione una fecha de pago a más tardar 30 días desde la fecha de inicio',
        PaymentDateRequired: 'La fecha de pago es obligatoria',
        NetPayment: 'Pago neto',
        Initiated: 'Fecha de pago',
        Invoices: 'Facturas',
        InvoiceSelected: 'Factura seleccionada',
        InvoicesSelected: 'Facturas seleccionadas',
        Max: 'Máximo',
        Method: 'Tipo de pago',
        NoBankInfoSaved: 'No hay tarjetas/cuenta bancaria guardada',
        NoCardSaved: 'No hay tarjetas guardadas',
        NoDataFound: 'Datos no encontrados.',
        NoData: 'Datos no encontrados',
        OpenActionsMenuForInvoice: 'Abrir menú de acciones para factura',
        OpenAmount: 'Monto abierto',
        OriginalAmount: 'Cantidad original',
        Paid: 'Pagado',
        PaidAmount: 'Cantidad',
        PaidBy: 'Pagado por',
        PartialPaymentForInvoice:
          'Pago parcial de la factura {{invoiceNumber}}',
        Pay: 'Pagar',
        PaymentInformationHeader: 'Información de Pago',
        Payments: 'Pagos',
        PaymentGateway: {
          AddNewAchAccount: 'Agregar una nueva cuenta ACH',
          AddNewCreditCardAccount:
            'Agregar una nueva cuenta de tarjeta de crédito',
          PaymentInformation: 'Información del pago',
          TimeoutMessage:
            'Tendrá 5 minutos para completar este formulario. Una vez que se agote el tiempo, el modal se cerrará y no se guardarán todos los cambios.',
        },
        PaymentHistory: 'Historial de pagos',
        PaymentHistoryDisclaimer:
          'Descargo de responsabilidad: aquí solo se mostrarán los pagos realizados en myRSM. Los cheques enviados por correo no aparecerán en el historial de pagos myRSM.',
        Payer: 'Pagador',
        PayerMessage:
          'Al agregar una nueva cuenta al pagador, todos los usuarios asociados al pagador seleccionado tendrán acceso a esa cuenta.',
        PayerRequired: 'El pagador es obligatorio',
        PaySelectedInvoices: 'Pagar facturas seleccionadas',
        PaySelectedHelperText:
          'El estado de cualquier factura pagada mediante CC después de las 5:30 p. m. o ACH después de las 7 p. m. (CST) no se actualizará en myRSM durante 2 días hábiles.',
        PaymentMade: 'Pago realizado',
        PaymentMethod: 'Método de pago',
        PaymentMethodRequired: 'El método de pago es obligatorio',
        PaymentSettings: 'Configuración de pago',
        PaymentTermsAndConditions: {
          Accepted: 'Acceptado',
          AcceptTermsAndConditions:
            'Acepto estos términos y condiciones de pago',
          PaymentTermsAndConditions: 'Términos y condiciones de pago',
          ViewPaymentTermsAndConditions:
            'Ver términos y condiciones de pago para continuar',
        },
        PaymentType: 'Tipo de pago',
        ProcessedBy: 'Procesado por',
        Retention:
          'Debido a la política de retención, las facturas del {{date}} no se pueden mostrar. Envíe su solicitud por correo electrónico a la dirección de correo electrónico correspondiente.',
        Pending: 'Pendiente',
        Scheduled: 'Programado',
        ScheduledOn: 'Programado el',
        ScheduledPayments: 'Pagos programados',
        SelectAllInvoices: 'Seleccionar todas las facturas',
        SelectAllPayments: 'Seleccionar todos los pagos',
        SelectInvoice: 'Seleccionar factura',
        SelectPaymentType: 'Seleccione tipo de pago',
        SetupInstallments: 'Establecer cuotas',
        Share: 'Compartir',
        ShareReceipt: 'Compartir recibo',
        StartDateIsRequired: 'La fecha de inicio es obligatoria',
        Status: 'Estado',
        TotalAmount: 'Cantidad total',
        TransactionId: 'ID de transacción',
        PaymentSuccessPage: {
          CancelPayment: 'Cancelar el pago',
          Edit: 'Editar',
          PaymentScheduled: 'Pago programado',
          PaymentSuccess: 'Pago exitoso',
          TransactionId: 'ID de transacción',
          TransactionDate: 'Fecha de transacción',
          DateScheduled: 'Fecha prevista',
          TransactionDateFormat: '{{date, PP}}',
          PaymentMethod: 'Método de pago',
          PayerName: 'Nombre del pagador',
          Invoices: 'Facturas',
          InvoicesPaid: 'Facturas pagadas',
          DowloadReceipt: 'Descargar recibo',
          ShareReceipt: 'Compartir recibo',
          ViewPaidInvoiceDetails: 'Ver detalles de la factura pagada',
          HidePaidInvoiceDetails: 'Ocultar detalles de la factura pagada',
        },
        UnrelatedInvoicesDialog: {
          Title: 'Las facturas seleccionadas no están relacionadas',
          Subtitle: '¿Desea continuar con el pago?',
          Cancel: 'Cancelar',
          Proceed: 'Continuar',
          DontAskAgain: 'No pregunte de nuevo',
        },
        TotalNetPayableAmount: 'Cantidad total neta a pagar',
        TotalPayableAmount: 'Cantidad total a pagar',
        TotalOpenAmount: 'Monto abierto total',
        UploadImage: 'Cargar imagen',
        SelectedOfTotalInvoicesSelected:
          '{{selected}} de {{total}} facturas seleccionadas',
      },
      WelcomeMessageModal: {
        WelcomeTitle: 'Hola {{firstName}}, ¡Bienvenido al myRSM!',
        WelcomeDesc:
          '¡Bienvenido al myRSM! Esta plataforma en línea es una ubicación conveniente, eficiente y segura para que pueda acceder sin problemas a las aplicaciones clave proporcionadas por RSM, colaborar con los empleados de RSM y también recibir liderazgo de pensamiento oportuno en función de sus intereses.',
        WelcomeQuestion: '¿Cómo le gustaría comenzar su experiencia en myRSM?',
        CustomizeMyExperienceTitle: 'PERSONALIZAR MI EXPERIENCIA',
        CustomizeMyExperienceDesc:
          'Tome un asistente rápido de 4 pasos para seleccionar qué industrias, líneas de servicio y temas desea ver más.',
        CustomizeMyExperienceLink: 'Configura tus intereses',
        NoThanksTitle: 'No, gracias',
        NoThanksDesc: 'Solo quiero sumergirme y comenzar a usar myRSM.',
        NoThanksLink: 'Empezar a usar myRSM',
      },
      Footer: {
        PrivacyPolicy: 'Política de privacidad',
        Disclaimer:
          'RSM US LLP is a limited liability partnership and the U.S. member firm of RSM International, a global network of independent audit, tax and consulting firms. The member firms of RSM International collaborate to provide services to global clients, but are separate and distinct legal entities that cannot obligate each other. Each member firm is responsible only for its own acts and omissions, and not those of any other party. Visit rsmus.com/aboutus for more information regarding RSM US LLP and RSM International.',
        Copyright: '© 2022 RSM International Association. All rights reserved.',
      },
      ModalDialog: {
        CloseDialog: 'Cerrar diálogo',
      },
      RSMUS: 'RSMUS',
      RSMUSClient: 'RSMUS Cliente',
      ReturnToCP: 'Volver a myRSM',
    },
  },
}
