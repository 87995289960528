import {
  Box,
  Checkbox,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Tooltip,
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { EntityLevelEnum } from '@rsmus/ecp-userservice'
import CheckboxIcon from '../../../rsmCoreComponents/components/icons/CheckboxIcon'
import CheckboxOutlinedIcon from '../../../rsmCoreComponents/components/icons/CheckboxOutlinedIcon'
import {
  InvoiceData,
  InvoicesData,
} from '../../../store/invoices/invoiceSearchService'
import tokens from '../../../styles/tokens.json'
import { Styles } from '../../../types'
import OverflowTooltip from '../../OverFlowToolTip'
import {
  setSelectedInvoices,
  getSelectedInvoices,
} from '../../../store/invoices/invoiceSelectedInvoicesSlice'
import { InfoIcon } from '../../icons'
import {
  formatCurrency,
  formatDate,
} from '../../../rsmCoreComponents/utils/formatters'
import ActionMenu, { ActionMenuItem } from './ActionMenu'
import useActionMenu from './useActionMenu'
import { isCemFeatureEnabled } from '../../../rsmCoreComponents/utils/featureFlagUtils'
import {
  CEM_FEATURE_PAYMENT_PAY_INVOICES,
  CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
} from '../../../utils/constants/constants'
import { getCemFeatures } from '../../../store/userInfo/userInfoSlice'
import SortableHeader from '../../../rsmCoreComponents/components/SortableHeader/SortableHeader'

const styles: Styles = {
  invoicesTable: (theme) => ({
    // -------------------------------------------------------------------------
    // TODO: (Ideally move these styles to a RsmTable variant, if possible.)
    // -------------------------------------------------------------------------
    '&.MuiTable-root': {
      tableLayout: 'fixed',
      width: '100%',
      '& .MuiTableCell-root': {
        borderBottom: 'none',
      },
      '& th.MuiTableCell-root': {
        fontFamily: 'Prelo-Bold, sans-serif',
      },
      '& td.MuiTableCell-root': {
        fontFamily: 'Prelo-Book, sans-serif',
      },
      '& .MuiTableCell-head': {
        padding: '1rem',
        paddingRight: 'revert',
        fontSize: '0.875rem',
        lineHeight: '1rem',
        textTransform: 'uppercase',
        verticalAlign: 'top',
        alignItems: 'center',
      },
      '& tbody': {
        '& .MuiTableRow-root': {
          '&:nth-of-type(odd)': {
            backgroundColor: tokens.colors.rsmGray.accessibility,
          },
        },
      },
      '& .MuiTableCell-body': {
        padding: '1.5rem 1rem',
        fontSize: '1rem',
        lineHeight: '1.5rem',
      },
      '.MuiFormControlLabel-root': {
        margin: '0rem',
      },
      '.MuiCheckbox-root': {
        boxSizing: 'content-box',
        padding: '0.5rem',
      },
    },
    // -----------------------------------------------------------------------
    // Invoicing Table-specific Styles
    // -----------------------------------------------------------------------
    '& .MuiTableCell-root': {
      '&:nth-of-type(1), &:nth-of-type(10)': {
        width: '3.5rem',
        padding: '0.5rem',
      },
    },
    '.sortable-header': {
      cursor: 'pointer',
    },
    '& .MuiTableCell-body': {
      '&:nth-of-type(2), &:nth-of-type(3), :nth-of-type(5), :nth-of-type(6), :nth-of-type(9)':
        {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
    },
    [theme.breakpoints.down('tablet')]: {
      '& .MuiTableCell-root': {
        '&:nth-of-type(2), &:nth-of-type(3), &:nth-of-type(5), &:nth-of-type(6), &:nth-of-type(8), &:nth-of-type(9)':
          {
            display: 'none',
          },
      },
    },
    [theme.breakpoints.only('desktop')]: {
      '& .MuiTableCell-root': {
        '&:nth-of-type(2)': {
          width: '17%',
        },
        '&:nth-of-type(3)': {
          width: '14%',
        },
        '&:nth-of-type(4)': {
          width: '12%',
        },
        '&:nth-of-type(5)': {
          width: '10%',
        },
        '&:nth-of-type(6)': {
          width: '12%',
        },
        '&:nth-of-type(7)': {
          width: '10%',
        },
        '&:nth-of-type(8)': {
          width: '6.5rem',
        },
        '&:nth-of-type(9)': {
          width: '13%',
        },
      },
    },
    [theme.breakpoints.only('tablet')]: {
      '& .MuiTableCell-root': {
        '&:nth-of-type(3), &:nth-of-type(6), &:nth-of-type(9)': {
          display: 'none',
        },
        '&:nth-of-type(2)': {
          width: '32%',
        },
        '&:nth-of-type(4)': {
          width: '16%',
        },
        '&:nth-of-type(5)': {
          width: '16%',
        },
        '&:nth-of-type(7)': {
          width: '17%',
        },
        '&:nth-of-type(8)': {
          width: '19%',
          minWidth: '6.25rem',
        },
      },
    },
    [theme.breakpoints.only('mobile')]: {
      '& .MuiTableCell-root': {
        '&:nth-of-type(4)': {
          width: '50%',
        },
        '&:nth-of-type(7)': {
          width: '50%',
        },
      },
    },
  }),
  // -----------------------------------------------------------------------
  // TODO: Possible RSM Table Utility Styles
  // -----------------------------------------------------------------------
  date: {
    textTransform: 'uppercase',
  },
  tooltipText: {
    padding: '1rem',
  },
  tooltipIconButton: {
    width: '2.25rem',
    height: '2.25rem',
    marginLeft: 0,
    marginRight: 0,
    fontSize: '1rem',
  },
  statusTooltip: {
    display: 'inline-flex',
    alignItems: 'center',
  },
}

interface InvoicesTableProps {
  invoicesData: InvoicesData
  onSort: (columnName: string, direction: 'asc' | 'desc') => void
  sortColumn: string
  sortDirection: 'asc' | 'desc'
}

const InvoicesTable = ({
  invoicesData,
  onSort,
  sortColumn,
  sortDirection,
}: InvoicesTableProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const cemFeatures = useSelector(getCemFeatures)
  const selectedInvoices = useSelector(getSelectedInvoices)
  const { handlePayInvoiceAction, handleDownloadInvoiceAction } =
    useActionMenu()
  const [open, setOpen] = useState<string | null>(null)

  // Hiding select all checkbox until we know how to handle it when pagingmight not be used at all
  // const selectAllInvoices = () => {
  //   dispatch(setSelectedInvoices(invoicesData.data))
  // }
  // const deselectAllInvoices = () => {
  //   dispatch(setSelectedInvoices([]))
  // }
  // const selectOrDeselectAllInvoices = () => {
  //   if (selectedInvoices.length === invoicesData.data.length) {
  //     deselectAllInvoices()
  //   } else {
  //     selectAllInvoices()
  //   }
  // }

  const toggleInvoiceSelection = (invoice: InvoiceData) => {
    if (selectedInvoices.find((row) => row?.id === invoice.id)) {
      dispatch(
        setSelectedInvoices(
          selectedInvoices.filter((row) => row.id !== invoice.id),
        ),
      )
    } else {
      dispatch(setSelectedInvoices([...selectedInvoices, invoice]))
    }
  }

  const getStatusToolTipText = (status: string) =>
    status.toLowerCase() === 'closed'
      ? t('Invoicing.Paid')
      : t('Invoicing.PaymentMade')

  const generateMenuItems = useCallback(
    (invoice: InvoiceData): ActionMenuItem<InvoiceData>[] => {
      const menuItems: ActionMenuItem<InvoiceData>[] = []
      if (
        invoice.status.toLowerCase() !== 'closed' &&
        (isCemFeatureEnabled(
          CEM_FEATURE_PAYMENT_PAY_INVOICES,
          cemFeatures,
          'any',
          EntityLevelEnum.Application,
        ) ||
          isCemFeatureEnabled(
            CEM_FEATURE_PAYMENT_PAY_INVOICES,
            cemFeatures,
            'any',
            EntityLevelEnum.LegalEntity,
            invoice.customerNumber,
          ))
      ) {
        menuItems.push({
          label: t('Invoicing.Pay'),
          handleAction: handlePayInvoiceAction,
        })
      }

      if (
        isCemFeatureEnabled(
          [
            CEM_FEATURE_PAYMENT_PAY_INVOICES,
            CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
          ],
          cemFeatures,
          'any',
        )
      ) {
        menuItems.push({
          label: t('Invoicing.Download'),
          handleDownloadAction: handleDownloadInvoiceAction,
        })
      }
      return menuItems
    },
    [cemFeatures, handlePayInvoiceAction, handleDownloadInvoiceAction],
  )

  const handleSort = (columnName: string) => {
    // Determine the new sort direction based on the current state
    const newSortDirection: 'asc' | 'desc' =
      sortColumn === columnName && sortDirection === 'asc' ? 'desc' : 'asc'
    // Pass both the column name and sort direction to the onSort callback
    onSort(columnName, newSortDirection)
  }

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTableCellElement>, columnName: string) => {
      if (event.key === ' ' || event.key === 'Enter') {
        event.preventDefault()
        handleSort(columnName)
      }
    },
    [handleSort],
  )

  return (
    <TableContainer component={Paper} sx={styles.tableContainer}>
      <Table
        // variant="rsm" // TODO: Can we create a variant of MuiTable in the MUI theme?
        id="invoicesTable"
        sx={styles.invoicesTable}>
        <Box component="caption" sx={visuallyHidden}>
          {t('Invoicing.Invoices')}
        </Box>
        <TableHead>
          <TableRow>
            <TableCell>
              {/*
               // Hiding select all checkbox until we know how to handle it when pagingmight not be used at all
               <FormControlLabel
                label=""
                aria-label={t('Invoicing.SelectAllInvoices')}
                htmlFor="select-all-invoices-checkbox"
                control={
                  <Checkbox
                    id="select-all-invoices-checkbox"
                    icon={<CheckboxOutlinedIcon />}
                    checkedIcon={<CheckboxIcon />}
                    checked={
                      selectedInvoices.length === invoicesData.data.length
                    }
                    disableRipple
                    onClick={() => selectOrDeselectAllInvoices()}
                  />
                }
              /> */}
            </TableCell>
            <TableCell
              tabIndex={0}
              onKeyDown={(event) => handleKeyDown(event, 'customerName')}>
              <SortableHeader
                columnName="customerName"
                currentSortColumn={sortColumn || ''}
                sortDirection={sortDirection || ''}
                handleSort={handleSort}>
                {t('Invoicing.CustomerName')}
              </SortableHeader>
            </TableCell>
            <TableCell
              tabIndex={0}
              onKeyDown={(event) => handleKeyDown(event, 'customerNumber')}>
              <SortableHeader
                columnName="customerNumber"
                currentSortColumn={sortColumn || ''}
                sortDirection={sortDirection || ''}
                handleSort={handleSort}>
                {t('Invoicing.CustomerNumber')}
              </SortableHeader>
            </TableCell>
            <TableCell
              tabIndex={0}
              onKeyDown={(event) => handleKeyDown(event, 'invoiceNumber')}>
              <SortableHeader
                columnName="invoiceNumber"
                currentSortColumn={sortColumn || ''}
                sortDirection={sortDirection || ''}
                handleSort={handleSort}>
                {t('Invoicing.InvoiceNumber')}
              </SortableHeader>
            </TableCell>
            <TableCell
              tabIndex={0}
              id="invoiceDate"
              onKeyDown={(event) => handleKeyDown(event, 'invoiceDate')}>
              <SortableHeader
                columnName="invoiceDate"
                currentSortColumn={sortColumn || ''}
                sortDirection={sortDirection || ''}
                handleSort={handleSort}>
                {t('Invoicing.InvoiceDate')}
              </SortableHeader>
            </TableCell>
            <TableCell
              tabIndex={0}
              onKeyDown={(event) => handleKeyDown(event, 'GrossInvoiceAmount')}>
              <SortableHeader
                columnName="GrossInvoiceAmount"
                currentSortColumn={sortColumn || ''}
                sortDirection={sortDirection || ''}
                handleSort={handleSort}>
                {t('Invoicing.OriginalAmount')}
              </SortableHeader>
            </TableCell>
            <TableCell
              tabIndex={0}
              id="openAmount"
              onKeyDown={(event) => handleKeyDown(event, 'openAmount')}>
              <SortableHeader
                columnName="openAmount"
                currentSortColumn={sortColumn || ''}
                sortDirection={sortDirection || ''}
                handleSort={handleSort}>
                {t('Invoicing.OpenAmount')}
              </SortableHeader>
            </TableCell>
            <TableCell>
              {/* <SortableHeader
                columnName="status"
                currentSortColumn={sortColumn || ""}
                sortDirection={sortDirection || ''}
                handleSort={handleSort}>                
              </SortableHeader> */}
              {t('Invoicing.Status')}
            </TableCell>
            <TableCell
              tabIndex={0}
              onKeyDown={(event) => handleKeyDown(event, 'company')}>
              <SortableHeader
                columnName="company"
                currentSortColumn={sortColumn || ''}
                sortDirection={sortDirection || ''}
                handleSort={handleSort}>
                {t('Invoicing.Company')}
              </SortableHeader>
            </TableCell>
            {isCemFeatureEnabled(
              [
                CEM_FEATURE_PAYMENT_PAY_INVOICES,
                CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
              ],
              cemFeatures,
              'any',
            ) && (
              <TableCell>
                <Box component="span" sx={visuallyHidden}>
                  {t('Invoicing.Actions')}
                </Box>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {invoicesData?.data?.map((invoice) => (
            <TableRow key={`invoice-${invoice.id}`}>
              <TableCell>
                <FormControlLabel
                  label=""
                  aria-label={`${t('Invoicing.SelectInvoice')} ${
                    invoice.invoiceNumber
                  }`}
                  htmlFor={`select-invoice-checkbox-${invoice.id}`}
                  control={
                    <Checkbox
                      id={`select-invoice-checkbox-${invoice.id}`}
                      icon={<CheckboxOutlinedIcon />}
                      checkedIcon={<CheckboxIcon />}
                      checked={
                        !!selectedInvoices.find((row) => row.id === invoice.id)
                      }
                      disableRipple
                      onClick={() => toggleInvoiceSelection(invoice)}
                    />
                  }
                />
              </TableCell>
              <TableCell data-testid={`customerName-cell-${invoice.id}`}>
                <OverflowTooltip>{invoice.customerName}</OverflowTooltip>
              </TableCell>
              <TableCell data-testid={`customerNumber-cell-${invoice.id}`}>
                <OverflowTooltip>{invoice.customerNumber}</OverflowTooltip>
              </TableCell>
              <TableCell data-testid={`invoiceNumber-cell-${invoice.id}`}>
                <OverflowTooltip id={invoice.invoiceNumber}>
                  {invoice.invoiceNumber}
                </OverflowTooltip>
              </TableCell>
              <TableCell data-testid={`date-cell-${invoice.id}`}>
                <Box component="span" sx={styles.date}>
                  <OverflowTooltip>{formatDate(invoice.date)}</OverflowTooltip>
                </Box>
              </TableCell>
              <TableCell data-testid={`originalAmount-cell-${invoice.id}`}>
                <OverflowTooltip>
                  {formatCurrency(invoice.originalAmount, invoice.currency)}{' '}
                  {invoice.currency}
                </OverflowTooltip>
              </TableCell>
              <TableCell data-testid={`openAmount-cell-${invoice.id}`}>
                <OverflowTooltip>
                  {formatCurrency(invoice.openAmount, invoice.currency)}{' '}
                  {invoice.currency}
                </OverflowTooltip>
              </TableCell>
              <TableCell
                sx={styles.statusTooltip}
                data-testid={`invoice-status-paid-date-cell-${invoice.id}`} // Add this line
              >
                {invoice.status.toLowerCase() === 'open' && invoice.status}
                {invoice.status.toLowerCase() === 'closed' && (
                  <Tooltip
                    id={`${invoice.invoiceNumber}-tooltip`}
                    disableTouchListener
                    open={open === invoice.id}
                    onOpen={() => setOpen(invoice.id)}
                    onClose={() => setOpen(null)}
                    PopperProps={{
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, -20],
                          },
                        },
                      ],
                    }}
                    title={
                      <Box
                        sx={styles.tooltipText}
                        data-testid={`invoice-paid-date-tooltip-${invoice.id}`}>
                        {`${getStatusToolTipText(invoice.status)} ${formatDate(
                          invoice.invoicePaidDate,
                        )}`}
                      </Box>
                    }
                    placement="right"
                    describeChild>
                    <Button
                      data-testid={`invoice-status-paid-date-cell-${invoice.id}`}
                      sx={styles.tooltipIconButton}
                      variant="text"
                      onClick={() => {
                        setOpen(open === null ? invoice.id : null)
                      }}
                      onBlurCapture={() => setOpen(null)}
                      disableRipple>
                      {invoice.status}
                      <Box component="span" title="status tooltip">
                        <InfoIcon />
                      </Box>
                    </Button>
                  </Tooltip>
                )}
              </TableCell>
              <TableCell
                data-testid={`company-status-paid-date-cell-${invoice.id}`}>
                <OverflowTooltip>{invoice.company}</OverflowTooltip>
              </TableCell>
              {(isCemFeatureEnabled(
                [
                  CEM_FEATURE_PAYMENT_PAY_INVOICES,
                  CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
                ],
                cemFeatures,
                'any',
                EntityLevelEnum.Application,
              ) ||
                isCemFeatureEnabled(
                  [
                    CEM_FEATURE_PAYMENT_PAY_INVOICES,
                    CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
                  ],
                  cemFeatures,
                  'any',
                  EntityLevelEnum.LegalEntity,
                  invoice.customerNumber,
                )) && (
                <TableCell>
                  <ActionMenu
                    actionButtonAriaControls={`payment-${invoice.id}-menu`}
                    actionButtonDescribedBy={invoice.id.toString()}
                    id={invoice.id}
                    data={invoice}
                    errorHeader="Invoicing.DownloadError"
                    errorMessage="Invoicing.DownloadErrorMessage"
                    errorMessageForNotFound="Invoicing.DownloadErrorMessageForNotFound"
                    menuItems={generateMenuItems(invoice)}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default InvoicesTable
