import { TabContext } from '@mui/lab'
import { AppBar, Box, List, Toolbar } from '@mui/material'
import React from 'react'
import { Styles } from '../../../types'
import OverFlowToolTip from '../../OverFlowToolTip'

const styles: Styles = {
  appBar: (theme) => ({
    marginTop: '-2.5rem',
    marginBottom: '2.5rem',
    borderBottomWidth: '0.125rem',
    borderColor: '#f4f4f4',
    boxShadow: 'none',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    [theme.breakpoints.down('tablet')]: {
      paddingX: '1rem',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingX: '2rem',
    },
    [theme.breakpoints.up('desktop')]: {
      paddingX: '6.5rem',
    },
  }),
  toolbar: {
    gap: '2.25rem',
    height: '3.375rem !important',
    minHeight: '3.375rem !important',
  },
  title: {
    fontFamily: 'Prelo-Bold, sans-serif',
    fontSize: '0.75rem',
    letterSpacing: '0.0625rem',
    textTransform: 'uppercase',
  },
  nav: {
    height: '100%',
  },
  list: (theme) => ({
    display: 'flex',
    flexDirection: 'row',
    flexShrink: 0,
    border: 'none',
    height: '100%',
    gap: '1rem',
    a: {
      '&[aria-current="true"]': {
        position: 'relative',
        '&::after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: 'calc(100% - 0.125rem)',
          right: 0,
          bottom: 0,
          left: 0,
          width: '100%',
          height: '0.125rem',
          backgroundColor: theme.palette.secondary.main,
          zIndex: 1,
        },
      },
    },
  }),
}

type SubMenuProps = {
  children: React.ReactNode
  currentItem?: string
  title?: string
}

const defaultProps = {
  currentItem: '',
  title: '',
}

const SubMenu = ({ children, currentItem = '', title }: SubMenuProps) => (
  <AppBar sx={styles.appBar} position="static">
    <Toolbar sx={styles.toolbar} disableGutters>
      {title && (
        <Box sx={styles.title} className="line-clamp-1">
          <OverFlowToolTip numberOfLines={1}>{title}</OverFlowToolTip>
        </Box>
      )}
      <TabContext value={currentItem}>
        <Box component="nav" sx={styles.nav} aria-label={`${title} Menu`}>
          <List sx={styles.list}>{children}</List>
        </Box>
      </TabContext>
    </Toolbar>
  </AppBar>
)

SubMenu.defaultProps = defaultProps

export default SubMenu
