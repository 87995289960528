/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { faPencil } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Paper } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  CustomErrorAlert,
  CustomSuccessAlert,
} from '../../components/forms/Alert'
import { CloseIcon } from '../../components/icons'
import MainContent from '../../components/layouts/MainContent'
import MainMenu from '../../components/layouts/MainMenu'
import SectionHeader from '../../components/layouts/SectionHeader'
import InterestsSection from '../../components/Profile/Interests'
import ContactPreferenceView from '../../components/Profile/Preferences/ContactPreferenceView'
import ContactPreferenceForm from '../../components/Profile/Preferences/FormControls/ContactPreferenceForm'
import { Heading } from '../../components/typography'
import { RootState } from '../../store'
import { setContactPreferenceFormState } from '../../store/profile/profilePageSlice'
import { Styles } from '../../types'

const styles: Styles = {
  container: { flex: 'grow' },
  content: (theme) => ({
    marginTop: '1.25rem',
    marginX: '1rem',
    [theme.breakpoints.only('tablet')]: {
      marginX: '2rem',
    },
    [theme.breakpoints.up('desktop')]: {
      marginX: '6.5rem',
    },
  }),
  section: (theme) => ({
    marginY: '2rem',
    border: '1px solid #ccc',
    paddingX: '1rem',
    paddingY: '2.5rem',
    [theme.breakpoints.only('tablet')]: {
      paddingX: '2rem',
    },
    [theme.breakpoints.up('desktop')]: {
      paddingX: '2.5rem',
    },
  }),
  modeButtonContainer: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'right',
  },
  contactPreferencesContainer: (theme) => ({
    flexGrow: 1,
    [theme.breakpoints.down('tablet')]: {
      marginX: '2%',
    },
    [theme.breakpoints.up('desktop')]: {
      marginX: '20%',
    },
  }),
}

const Profile = () => {
  const { search } = useLocation()
  // to refetch data after component loaded, used for interests and not contactform now
  const reloadKey = new URLSearchParams(search).get('tkey')
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)

  const isViewOnlyMode = useSelector(
    (state: RootState) => state.profilePage.FormValues.isViewOnlyMode,
  )

  const isFormSubmissionSuccessful = useSelector(
    (state: RootState) => state.profilePage.FormValues.isSubmissionSuccessful,
  )

  const isFormSubmissionFailed = useSelector(
    (state: RootState) => state.profilePage.FormValues.isSubmissionFailed,
  )

  useEffect(() => {
    document.title = t('UserProfile.ECPProfileTitle')
    setOpen(isViewOnlyMode)
  }, [isViewOnlyMode])

  useEffect(() => {
    dispatch(
      setContactPreferenceFormState({
        isViewOnlyMode: true,
        isSubmissionSuccessful: undefined,
        isSubmissionFailed: undefined,
      }),
    )
  }, [])

  const closeIconClicked = () => {
    dispatch(
      setContactPreferenceFormState({
        isViewOnlyMode: true,
        isSubmissionSuccessful: undefined,
        isSubmissionFailed: undefined,
      }),
    )
  }

  const closeAlertClicked = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const pencilIconClicked = () => {
    dispatch(
      setContactPreferenceFormState({
        isViewOnlyMode: false,
        isSubmissionSuccessful: undefined,
        isSubmissionFailed: undefined,
      }),
    )
  }

  return (
    <Box sx={styles.container}>
      <Box data-testid="successAlert">
        {isFormSubmissionSuccessful && (
          <CustomSuccessAlert
            header="UpdateSuccess"
            message="UpdateSuccessMessage"
            open={open}
            close={closeAlertClicked}
          />
        )}
        {isFormSubmissionFailed && (
          <CustomErrorAlert
            header="UpdateError"
            open={open}
            close={closeAlertClicked}
            message="UpdateErrorMessage"
          />
        )}
      </Box>

      <MainMenu currentItem="profile" />
      <MainContent>
        <Box sx={styles.content}>
          <Heading variant="h1" testId="Hed_Profile_ProfileMain">
            {t('Navigation.Profile')}
          </Heading>
          <Paper
            component="section"
            sx={styles.section}
            data-testid="Sec_Profile_ContactPreference">
            <SectionHeader
              title={t('UserProfile.PageContactInfoPrefsHeading')}
              testId="Hed_Profile_ContactPreference"
            />
            <Box sx={styles.modeButtonContainer}>
              {isViewOnlyMode ? (
                <Button
                  data-testid="Btn_Pencil_EditForm"
                  aria-label={t('UserProfile.ContactPreferenceForm.Edit')}
                  onClick={pencilIconClicked}>
                  <FontAwesomeIcon icon={faPencil} fontSize="23px" />
                </Button>
              ) : (
                <Button
                  data-testid="Btn_Close_CancelForm"
                  aria-label={t('UserProfile.ContactPreferenceForm.Cancel')}
                  onClick={closeIconClicked}>
                  <CloseIcon />
                </Button>
              )}
            </Box>
            <Box sx={styles.contactPreferencesContainer}>
              {isViewOnlyMode ? (
                <ContactPreferenceView />
              ) : (
                <ContactPreferenceForm />
              )}
            </Box>
          </Paper>
          <Paper
            component="section"
            sx={styles.section}
            data-testid="Sec_Profile_UserInterests">
            <SectionHeader
              title={t('UserProfile.PageInterestsHeading')}
              testId="Hed_Profile_UserInterests"
            />
            <InterestsSection reloadKey={reloadKey} />
          </Paper>
        </Box>
      </MainContent>
    </Box>
  )
}

export default Profile
