// CEM Features
export const CEM_FEATURE_PAYMENT_PAY_INVOICES = 'Payment-Pay Invoices'
export const CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS =
  'Payment-View Invoices and Documents'
export const CEM_FEATURE_PAYMENT_CLIENT_ADMIN = 'Payment-Client Admin'
export const CEM_FEATURE_WELCOME_OPT_OUT = 'Welcome Opt Out'
export const CEM_FEATURE_ARTICLESHARE = 'Insights Article Sharing'

// User Preferences
export const USER_PREF_INVOICING_IGNOREUNRELATED = 'Invoicing_IgnoreUnrelated'
