/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useCallback, useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { Box as MuiBox, Link as MuiLink, styled } from '@mui/material'
import { differenceInMinutes } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { Info } from '@mui/icons-material'
import { BoxProps } from '@mui/system'
import {
  dismissPopover,
  ProfileCompletionStatusMessage,
  setPopoverContent,
} from '../../../store/form/formSlice'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import {
  useGetTaxonomyPreferencesQuery,
  useSetTaxonomyPreferencesMutation,
} from '../../../store/cmsService'
import ProfileModal from './ProfileModal'
import { closeProfileModal } from '../../../store/profile/profileModalSlice'
import { useGetUserContactPreferenceDetailsQuery } from '../../../store/userService'
import { INTEREST_REMINDER_FREQUENCY } from '../../../envVariables'

const Box = styled(MuiBox)({
  pt: '1rem',
  pb: '4rem',
  px: '2.5rem',
  textAlign: 'center',
})

const InfoIcon = styled((props: BoxProps) => (
  <Box
    display="flex"
    sx={{ flexGrow: 1, pb: 1 }}
    alignItems="center"
    justifyContent="center"
    {...props}>
    <Info />
  </Box>
))(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
}))

const ProfileCompletionMessage = ({
  text,
  link,
}: {
  text: string
  link: string
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  return (
    <Box>
      <InfoIcon />
      <div>{t(text)}</div>
      <MuiLink
        color="secondary"
        component={Link}
        to="/profile"
        onClick={() => dispatch(dismissPopover())}>
        {t(link)} <span aria-hidden>&gt;</span>
      </MuiLink>
    </Box>
  )
}

export const popoverComponentFactory = (
  status?: ProfileCompletionStatusMessage,
) => {
  switch (status) {
    case 'Incomplete':
      return (
        <ProfileCompletionMessage
          text="UserProfile.ProfilePopover.IncompleteMessage"
          link="UserProfile.ProfilePopover.ContinueMessage"
        />
      )

    case 'Complete':
      return (
        <ProfileCompletionMessage
          text="UserProfile.ProfilePopover.CompleteMessage"
          link="UserProfile.ProfilePopover.AdjustProfileMessage"
        />
      )

    default:
      return null
  }
}

const ProfileModalContainer: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const taxonomyPreferences = useGetTaxonomyPreferencesQuery()
  const [setTaxonomyPreferences] = useSetTaxonomyPreferencesMutation()
  const getUserContactPreferenceDetails =
    useGetUserContactPreferenceDetailsQuery()
  const [open, setOpen] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const {
    popover: { isWelcomeShowing },
  } = useAppSelector((state) => state)

  useEffect(() => {
    if (searchParams.has('profileModalOpen')) {
      setOpen(true)
      searchParams.delete('profileModalOpen')
      setSearchParams(searchParams)
    }
  }, [searchParams])

  useEffect(() => {
    const interestReminderDate =
      localStorage.getItem('interestReminder') ?? undefined
    const dateDifference = interestReminderDate
      ? differenceInMinutes(new Date(), new Date(interestReminderDate))
      : 0

    if (!interestReminderDate) {
      localStorage.setItem('interestReminder', new Date().toISOString())
    }

    const reminderFrequency = INTEREST_REMINDER_FREQUENCY
      ? Number(INTEREST_REMINDER_FREQUENCY)
      : 10080

    if (
      getUserContactPreferenceDetails.data?.data.visited &&
      !!interestReminderDate &&
      dateDifference >= reminderFrequency &&
      taxonomyPreferences.data?.data.length === 0
    ) {
      localStorage.setItem('interestReminder', new Date().toISOString())
      // If the welcome dialog is showing, then it will take care of setting the popover content after it closes.
      if (!isWelcomeShowing) {
        dispatch(setPopoverContent('Incomplete'))
      }
    }
  }, [
    taxonomyPreferences.data,
    setPopoverContent,
    getUserContactPreferenceDetails.data,
  ])

  const closeDialog = useCallback(async () => {
    setOpen(false)
    dispatch(closeProfileModal())

    dispatch(setPopoverContent('Incomplete'))
  }, [closeProfileModal, setOpen, dispatch, t])

  const saveAllAndCloseDialog = useCallback(
    (data: number[]) => {
      setOpen(false)
      setTaxonomyPreferences(data)
      dispatch(closeProfileModal())
      dispatch(setPopoverContent('Complete'))
    },
    [
      setOpen,
      dispatch,
      closeProfileModal,
      setPopoverContent,
      setTaxonomyPreferences,
      t,
    ],
  )

  return (
    <ProfileModal
      open={open}
      onSubmit={saveAllAndCloseDialog}
      onClose={closeDialog}
    />
  )
}

export default ProfileModalContainer
