import React from 'react'
import {
  Box,
  Select,
  Button,
  InputLabel,
  FormHelperText,
  SelectChangeEvent,
  Checkbox,
  FormControlLabel,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { PayerPaymentAccountViewModel } from '@rsmus/ecp-financeservice'
import { useSelector, useDispatch } from 'react-redux'
import { Control, Controller, FieldValues } from 'react-hook-form'
import { Styles } from '../../../types'
import NewPaymentMethod from './NewPaymentMethod'
import {
  getSelectedBankAccount,
  setSelectedBankAccount,
  getSelectedCreditCard,
  setSelectedCreditCard,
  getNewPaymentMethod,
  SelectedAccountTypeState,
  setAccountSelectedType,
  getAccountSelectedType,
  setDoNotSave,
} from '../../../store/invoices/paymentInfoSlice'
import { tokens } from '../../../styles/materialTheme'
import { CheckboxIcon, CheckboxOutlinedIcon, ArrowDownIcon } from '../../icons'

const styles: Styles = {
  AccountSelect: (theme) => ({
    width: '100%',
    [theme.breakpoints.only('mobile')]: {
      padding: 0,
    },
  }),
  choosePaymentMethod: {
    '.MuiOutlinedInput-root': {
      width: '100%',
      maxWidth: '100%',
    },
    '.MuiSelect-select': { fontFamily: 'Prelo-Book !important' },
    width: 'calc(100% - 4rem)',
  },
  choosePaymentMethodLabel: (theme) => ({
    fontFamily: 'Prelo-Black, sans-serif',
    fontSize: '1rem',
    paddingBottom: '0.5rem',
    color: theme.palette.text.primary,
    display: 'block',
  }),
  addPaymentMethod: (theme) => ({
    color: theme.palette.secondary.main,
    textDecorationColor: theme.palette.secondary.main,
    marginLeft: '-0.5rem',
  }),
  doNotSaveText: (theme) => ({
    color: theme.palette.primary.main,
    fontSize: '1rem',
    textDecorationColor: theme.palette.primary.main,
    marginLeft: 0,
  }),
  edit: (theme) => ({
    color: theme.palette.secondary.main,
    textDecorationColor: theme.palette.secondary.main,
  }),
  requiredError: {
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '0.875rem',
    color: tokens.colors.rsmRed.secondary,
  },
  disabled: () => ({
    color: tokens.colors.rsmGray.disabled,
  }),
}

export interface PaymentMethodProps {
  paymentMethodType: string
  payerPaymentAccounts: PayerPaymentAccountViewModel[]
  addPaymentMethod: () => void
  isScheduledPayment: boolean
  control?: Control<FieldValues, any>
}

const PaymentMethod = ({
  paymentMethodType,
  payerPaymentAccounts,
  addPaymentMethod,
  isScheduledPayment,
  control,
}: PaymentMethodProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const accountSelectedType = useSelector(getAccountSelectedType)
  const isNewPaymentMethod = useSelector(getNewPaymentMethod)
  const selectedBankAccount = useSelector(getSelectedBankAccount)
  const selectedCreditCard = useSelector(getSelectedCreditCard)
  const accountSelectedTypeStatus: SelectedAccountTypeState = useSelector(
    getAccountSelectedType,
  )
  const unavailableStatus: SelectedAccountTypeState = 'Unavailable'
  const newStatus: SelectedAccountTypeState = 'New'
  const existingStatus: SelectedAccountTypeState = 'Existing'

  const getSelectedPaymentMethod = (): PayerPaymentAccountViewModel | null => {
    const selectedId =
      paymentMethodType === 'BankAccount'
        ? selectedBankAccount
        : selectedCreditCard

    const selectedPaymentMethod: PayerPaymentAccountViewModel[] =
      payerPaymentAccounts.filter(
        (a: PayerPaymentAccountViewModel) => a.id === selectedId,
      )

    if (selectedPaymentMethod.length === 0) return null
    if (selectedPaymentMethod.length > 1)
      throw new Error('Multiple payment accounts with the same id.')
    return selectedPaymentMethod[0]
  }

  const setSelectedPaymentMethod = (selectedValue: number) => {
    if (paymentMethodType === 'BankAccount') {
      dispatch(setSelectedBankAccount(selectedValue))
    } else {
      dispatch(setSelectedCreditCard(selectedValue))
    }
  }

  const handleDoNotSaveCheckboxChange = (checked: boolean) => {
    dispatch(setDoNotSave(checked))
  }

  return (
    <>
      <Box sx={styles.AccountSelect}>
        <Box sx={false ? styles.disabled : null}>
          <InputLabel
            disabled={
              accountSelectedTypeStatus === unavailableStatus ||
              accountSelectedTypeStatus === newStatus ||
              payerPaymentAccounts.length === 0
            }
            htmlFor={`choose${paymentMethodType}`}
            data-testid={`Lbl_PaymentMethod_Choose${paymentMethodType}`}
            sx={styles.choosePaymentMethodLabel}>
            {t(`Invoicing.Choose${paymentMethodType}`)}
            <span className="sr-only">{t('srOnlyRequired')}</span>
          </InputLabel>
          <Controller
            name="paymentMethod"
            control={control}
            defaultValue=""
            rules={{
              required: `${t(`Invoicing.Choose${paymentMethodType}`)} ${t(
                'IsARequiredField',
              )}`,
            }}
            render={({
              field: { onChange, value, ref },
              fieldState: { error },
            }) => (
              <>
                {payerPaymentAccounts.length > 0 ||
                accountSelectedTypeStatus === unavailableStatus ? (
                  <Select
                    native
                    sx={styles.choosePaymentMethod}
                    id={`choose${paymentMethodType}`}
                    inputRef={ref}
                    error={!!error}
                    IconComponent={ArrowDownIcon}
                    disabled={
                      accountSelectedTypeStatus === unavailableStatus ||
                      accountSelectedTypeStatus === newStatus
                    }
                    value={value}
                    onChange={(e: SelectChangeEvent<any>) => {
                      onChange(e.target.value)
                      setSelectedPaymentMethod(e.target.value as number)
                      dispatch(setAccountSelectedType(existingStatus))
                    }}
                    inputProps={{
                      'aria-label': t(`Invoicing.Choose${paymentMethodType}`),
                      'aria-describedby': 'PaymentMethodErrorText',
                      'data-testid': `Sel_PaymentType_${paymentMethodType}`,
                    }}>
                    <option style={{ display: 'none' }} label=" " />
                    {isNewPaymentMethod ||
                      payerPaymentAccounts?.map(
                        (paymentAccount: PayerPaymentAccountViewModel) => (
                          <option
                            key={paymentAccount.id}
                            data-testid={paymentAccount.id}
                            value={paymentAccount.id}>
                            {paymentAccount.name}
                          </option>
                        ),
                      )}
                  </Select>
                ) : (
                  <Box sx={styles.disabled}>
                    {paymentMethodType === 'CreditCard'
                      ? t(`Invoicing.NoCardSaved`)
                      : t(`Invoicing.NoBankInfoSaved`)}
                  </Box>
                )}
                {error && (
                  <FormHelperText
                    id="PaymentMethodErrorText"
                    sx={styles.requiredError}>
                    {error.message}
                  </FormHelperText>
                )}
              </>
            )}
          />
        </Box>
      </Box>
      <Box paddingTop="1.5rem">
        <InputLabel
          sx={styles.choosePaymentMethodLabel}
          disabled={
            accountSelectedTypeStatus === unavailableStatus ||
            accountSelectedTypeStatus === existingStatus
          }>
          `{t(`Invoicing.New${paymentMethodType}`)}
        </InputLabel>
      </Box>
      {!isScheduledPayment && (
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                id="doNotSaveCheckbox"
                sx={styles.doNotSaveText}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleDoNotSaveCheckboxChange(e.target.checked)
                }
                disableRipple
                aria-label={t(`Invoicing.AccountInformationDoNotSave`)}
                disabled={!(accountSelectedTypeStatus === newStatus)}
                icon={<CheckboxOutlinedIcon />}
                checkedIcon={<CheckboxIcon />}
              />
            }
            label={t('Invoicing.AccountInformationDoNotSave').toString()}
          />
        </Box>
      )}
      <Button
        role="button"
        onClick={addPaymentMethod}
        aria-label={t(`Invoicing.AddNew${paymentMethodType}`)}
        data-testid={`Btn_PaymentMethod_AddNew${paymentMethodType}`}
        disableRipple
        disabled={
          accountSelectedTypeStatus === unavailableStatus ||
          accountSelectedTypeStatus === existingStatus ||
          isNewPaymentMethod
        }
        sx={styles.addPaymentMethod}>
        {t(`Invoicing.AddNew${paymentMethodType}`)}
      </Button>
      {accountSelectedType === 'New' && getSelectedPaymentMethod() && (
        <NewPaymentMethod
          institutionName={getSelectedPaymentMethod()?.institutionName}
          accountNumberSuffix={getSelectedPaymentMethod()?.accountNumberSuffix}
          paymentMethodType={paymentMethodType}
        />
      )}
    </>
  )
}

PaymentMethod.defaultProps = {
  control: undefined,
}

export default PaymentMethod
