import React, { useEffect, useState } from 'react'
import {
  FormControl,
  Radio,
  RadioGroup,
  styled,
  alpha,
  FormControlLabel,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Control, Controller, FieldValues } from 'react-hook-form'
import { tokens } from '../../../../styles/materialTheme'
import { Styles } from '../../../../types'

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '.MuiFormControlLabel-label': {
    fontFamily: 'Prelo-Book',
    color: theme.palette.text.primary,
  },
}))

const styles: Styles = {
  bpmb32: (theme) => ({
    [theme.breakpoints.down('tablet')]: {
      width: '100%',
    },
    [theme.breakpoints.up('mobile')]: {
      width: '50%',
    },
    [theme.breakpoints.only('tablet')]: {},
  }),
  paddingContainer: (theme) => ({
    [theme.breakpoints.down('tablet')]: {
      width: '100%',
    },
    [theme.breakpoints.up('mobile')]: {
      width: '50%',
    },
    marginBottom: '2.5rem',
  }),
  bppr5mb9: (theme) => ({
    [theme.breakpoints.down('tablet')]: {
      width: '100%',
    },
    [theme.breakpoints.up('mobile')]: {
      width: '25%',
    },
    marginBottom: '2.5rem',
  }),
  bppr10mb8: (theme) => ({
    [theme.breakpoints.down('tablet')]: {
      width: '100%',
      marginBottom: '2.5rem',
    },
    [theme.breakpoints.up('mobile')]: {
      width: '50%',
    },
    [theme.breakpoints.up('lg')]: {
      paddingRight: '1.625rem',
    },
  }),
  error: {
    display: 'inline',
    float: 'left',
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '.875rem',
    fontWeight: 400,
    color: tokens.colors.rsmRed.secondary,
    paddingTop: '0rem',
  },
}

interface FormCardProps {
  control: Control<FieldValues, any>
  handleRadioChange: (value: string) => void
  contactMethod: string
}

const FormPreferredContactRadio = ({
  control,
  handleRadioChange,
  contactMethod,
}: FormCardProps) => {
  const { t } = useTranslation()
  const [preferredContact, setPreferredContact] = useState(contactMethod)

  useEffect(() => {
    setPreferredContact(contactMethod)
  }, [contactMethod])

  return (
    <FormControl
      style={{ padding: '0 1.4375rem' }}
      component="fieldset"
      sx={styles.paddingContainer}>
      <FormControl
        component="legend"
        sx={(theme) => ({
          fontFamily: 'Prelo-Black, sans-serif',
          fontSize: '1rem',
          paddingBottom: '0.5rem',
          color: theme.palette.text.primary,
          display: 'block',
        })}
        data-testid="Lbl_Report_ContactMethod">
        {t('ContactMethod')} *
      </FormControl>

      <Controller
        name="preferredContactMethod"
        control={control}
        defaultValue={preferredContact.toString()}
        render={() => (
          <RadioGroup
            row
            name="preferredContactMethod"
            defaultValue={preferredContact.toString()}
            onChange={(val) => {
              setPreferredContact(val.target.value)
              handleRadioChange(val.target.value)
            }}
            value={preferredContact}>
            <StyledFormControlLabel
              value="Email"
              htmlFor="preferredContactMethodEmail"
              sx={{
                paddingBottom: '0rem',
                paddingTop: '0rem',
                paddingRight: '3rem',
                borderColor: alpha('#991B1B', 1),
              }}
              control={
                <Radio
                  id="preferredContactMethodEmail"
                  key="preferredContactMethodEmail"
                />
              }
              label="Email"
            />
            <StyledFormControlLabel
              value="Phone"
              htmlFor="preferredContactMethodPhone"
              sx={{
                paddingBottom: '0rem',
                paddingTop: '0rem',
              }}
              control={
                <Radio
                  id="preferredContactMethodPhone"
                  key="preferredContactMethodPhone"
                />
              }
              label="Phone"
            />
          </RadioGroup>
        )}
      />
    </FormControl>
  )
}

export default FormPreferredContactRadio
