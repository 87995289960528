import { AnalyticEvent } from './types'

const analyticEvent: AnalyticEvent = 'Page_View'

const processAdobePageView = (dataAnalyticsId: string, userType: string) => {
  window.adobeDataLayer.push({
    event: analyticEvent,
    event_data_id: dataAnalyticsId,
    user: userType,
  })
}

export default processAdobePageView
