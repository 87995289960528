/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Select, TextField, Typography, styled } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import {
  CustomErrorAlert,
  CustomSuccessAlert,
} from '../../components/forms/Alert'
import { useGetUserContactPreferenceDetailsQuery } from '../../store/userService'
import FormAutoPopName from '../../rsmCoreComponents/components/DynamicForm/FormControls/FormAutoPopName'
import FormAutoPopEmail from '../../rsmCoreComponents/components/DynamicForm/FormControls/FormAutoPopEmail'
import FormBusinessPhoneNumber from '../../rsmCoreComponents/components/DynamicForm/FormControls/FormBusinessPhoneNumber'
import FormCard from '../../rsmCoreComponents/components/DynamicForm/FormControls/FormCard'
import FormPreferredContactRadio from '../../rsmCoreComponents/components/DynamicForm/FormControls/FormPreferredContactRadio'
import { ArrowDownIcon } from '../../components/icons'
import { Styles } from '../../types'
import { tokens } from '../../styles/materialTheme'
import api from '../../api'

const issueTextLimit = 2000

const StyledSelectField = styled(Select)({
  '.MuiOutlinedInput-root': {
    width: '100%',
    maxWidth: '100%',
  },
  '.MuiSelect-select': { fontFamily: 'Prelo-Book !important' },
})

const StyledLabel = styled('label')(({ theme }) => ({
  fontFamily: 'Prelo-Black, sans-serif',
  fontSize: '1rem',
  paddingBottom: '0.5rem',
  color: theme.palette.text.primary,
  display: 'block',
}))

const StyledTextField = styled(TextField)({
  '.MuiInputBase-root': {
    width: '100%',
    maxWidth: '100%',
  },
  '& .MuiFormHelperText-root': { margin: '0rem' },
})

const styles: Styles = {
  paddingContainer: (theme) => ({
    [theme.breakpoints.down('tablet')]: {
      width: '100%',
    },
    [theme.breakpoints.up('mobile')]: {
      width: '50%',
    },
    marginBottom: '2.5rem',
  }),
  error: {
    display: 'inline',
    float: 'left',
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '.875rem',
    fontWeight: 400,
    color: tokens.colors.rsmRed.secondary,
    paddingTop: '0rem',
  },
}

const ReportForm = () => {
  const { t } = useTranslation()
  const { handleSubmit, reset, control, clearErrors, formState, setValue } =
    useForm({ mode: 'onSubmit' })
  const [isSuccess, setIsSuccess] = useState(false)
  const [open, setOpen] = useState(false)
  const { data } = useGetUserContactPreferenceDetailsQuery()
  const contactMethodTxtKey = data?.data.preferredContactMethod?.contactMedium
  const contactMethod = contactMethodTxtKey
    ? `${t(
        `UserProfile.ContactPreferenceForm.ContactMethods.${contactMethodTxtKey}`,
      )}`
    : `${t(`UserProfile.ContactPreferenceForm.ContactMethods.Email`)}`
  const [preferredContact, setPreferredContact] = useState(contactMethod)

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const onSubmit = useCallback(
    async (request) => {
      try {
        const response: boolean = (
          await api.communication.issue_SubmitReportTeamDocsIssue(request)
        )?.data
        if (response) {
          setIsSuccess(true)
          reset()
        } else setIsSuccess(false)
      } catch (e) {
        setIsSuccess(false)
      }

      setOpen(true)
      setPreferredContact(
        `${t(`UserProfile.ContactPreferenceForm.ContactMethods.Email}`)}`,
      )
    },
    [
      api.communication.issue_SubmitReportTeamDocsIssue,
      setIsSuccess,
      reset,
      setOpen,
      setPreferredContact,
    ],
  )

  const handleRadioChange = (val: string) => {
    setPreferredContact(val)
    if (val === 'Email') {
      clearErrors('businessPhone')
    }
  }

  return (
    <div className="grow">
      <div data-testid="successAlert">
        {isSuccess && (
          <CustomSuccessAlert
            header={t('Projects.ReportAnIssueForm.toast.title')}
            message={t('Projects.ReportAnIssueForm.toast.description')}
            open={open}
            close={handleClose}
            testId="Sec_Report_SuccessAlert"
          />
        )}
        {!isSuccess && (
          <CustomErrorAlert
            header="ReportError"
            open={open}
            close={handleClose}
            message="ReportErrorMessage"
          />
        )}
      </div>
      <FormCard
        title={t('Projects.ReportAnIssueForm.title')}
        desc={t('Projects.ReportAnIssueForm.description')}
        handleSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', margin: '0 -1.4375rem' }}>
          <FormAutoPopName />
          <FormPreferredContactRadio
            control={control}
            handleRadioChange={handleRadioChange}
            contactMethod={contactMethod}
          />
        </Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', margin: '0 -1.4375rem' }}>
          <FormAutoPopEmail />
          <FormBusinessPhoneNumber
            formState={formState}
            setValue={setValue}
            control={control}
            required={preferredContact === 'Phone'}
          />
        </Box>
        <Box sx={styles.paddingContainer}>
          <StyledLabel htmlFor="projectName">
            {t('Projects.ReportAnIssueForm.projectName')}
          </StyledLabel>
          <Controller
            name="projectName"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <StyledTextField
                id="projectName"
                variant="outlined"
                fullWidth
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Box>
        <Box sx={styles.paddingContainer}>
          <StyledLabel htmlFor="issue">
            {t('Projects.ReportAnIssueForm.issue')}*
          </StyledLabel>
          <Controller
            name="issue"
            control={control}
            defaultValue="default"
            rules={{
              validate: (value) => {
                if (value === 'default') {
                  return `${t('Projects.ReportAnIssueForm.issue')} ${t(
                    'IsARequiredField',
                  )}`
                }
                return true
              },
              required: `${t('Projects.ReportAnIssueForm.issue')} ${t(
                'IsARequiredField',
              )}`,
            }}
            render={({
              field: { onChange, value, ref },
              fieldState: { error },
            }) => (
              <>
                <StyledSelectField
                  native
                  id="issue"
                  aria-describedby="issueWarning"
                  value={value}
                  onChange={onChange}
                  fullWidth
                  error={!!error}
                  IconComponent={ArrowDownIcon}
                  inputRef={ref}
                  inputProps={[
                    { 'aria-label': 'Issue' },
                    { 'aria-describedby': 'issueWarning' },
                  ]}
                  data-testid="Sel_Report_Issue"
                  displayEmpty>
                  <option value="default">{t('SelectBusiness')}</option>
                  <option value="My Project list is not accurate">
                    {t('Projects.ReportAnIssueForm.issueOptions.notAccurate')}
                  </option>
                  <option value="Project not loading when I click the link">
                    {t('Projects.ReportAnIssueForm.issueOptions.notLoading')}
                  </option>
                  <option value="Something is incorrect with a project">
                    {t('Projects.ReportAnIssueForm.issueOptions.incorrect')}
                  </option>
                  <option value="Other">
                    {t('Projects.ReportAnIssueForm.issueOptions.other')}
                  </option>
                </StyledSelectField>
                {error && (
                  <Typography
                    id="issueWarning"
                    aria-invalid="true"
                    sx={styles.error}>
                    {error?.message}
                  </Typography>
                )}
                {value === 'My Project list is not accurate' && (
                  <Typography id="issueWarning">
                    {t('Projects.ReportAnIssueForm.issueWarning')}
                  </Typography>
                )}
              </>
            )}
          />
        </Box>
        <Box sx={{ marginTop: '2.5rem' }}>
          <StyledLabel htmlFor="issue">
            {t('Projects.ReportAnIssueForm.descriptionLabel')}*
          </StyledLabel>

          <Controller
            name="description"
            control={control}
            defaultValue=""
            rules={{
              maxLength: issueTextLimit,
              required: `${t(
                'Projects.ReportAnIssueForm.descriptionLabel',
              )} ${t('IsARequiredField')}`,
            }}
            render={({
              field: { onChange, value, ref },
              fieldState: { error },
            }) => (
              <StyledTextField
                id="desc"
                variant="outlined"
                fullWidth
                multiline
                value={value}
                rows={4}
                inputRef={ref}
                inputProps={{
                  maxLength: issueTextLimit,
                }}
                onChange={(e) => {
                  onChange(e)
                }}
                error={!!error}
                helperText={
                  error ? (
                    <>
                      <Typography
                        component="span"
                        sx={styles.error}
                        data-testid="Txt_ContactUs_IssueError">
                        {error?.message}
                      </Typography>
                      <Typography
                        component="span"
                        sx={(theme) => ({
                          float: 'right',
                          fontFamily: 'Prelo-Book, sans-serif',
                          fontSize: '1rem',
                          fontWeight: 400,
                          color: theme.palette.text.primary,
                          paddingTop: '0rem',
                        })}
                        data-testid="Txt_ContactUs_IssueLength">
                        {value.length}/{issueTextLimit}
                      </Typography>
                    </>
                  ) : (
                    <Typography
                      component="span"
                      sx={(theme) => ({
                        float: 'right',
                        fontFamily: 'Prelo-Book, sans-serif',
                        fontSize: '1rem',
                        fontWeight: 400,
                        color: theme.palette.text.primary,
                        paddingTop: '0rem',
                      })}
                      data-testid="Txt_ContactUs_IssueLength">
                      {value.length}/{issueTextLimit}
                    </Typography>
                  )
                }
              />
            )}
          />
        </Box>
      </FormCard>
    </div>
  )
}

export default ReportForm
