import React from 'react'
import { Box } from '@mui/material'
import { ApiResponseOfDictionaryOfStringAndIEnumerableOfString } from '@rsmus/ecp-financeservice'
import { InvoiceSelectedFiltersConstants } from '../../../store/invoices/invoiceSelectedFiltersSlice'
import { Styles } from '../../../types'
import InvoicesFiltersDateRange from './InvoicesDateRangeFilters'
import InvoiceFiltersCheckBoxes from './InvoiceFiltersCheckBoxes'
import InvoicesAmountRangeFilter from './InvoicesAmountRangeFilter'

const styles: Styles = {
  container: {
    flexDirection: 'column',
    padding: 0,
  },
}

interface InvoicesFiltersProps {
  filterData?: ApiResponseOfDictionaryOfStringAndIEnumerableOfString
  isFilterError: boolean
  isFilterSuccess: boolean
  clearCategoryFilter: (category: string) => void
  changeFilterAndSearch: (filterValue: string) => void
}

const defaultProps = {
  filterData: undefined,
}

const InvoicesFilters = ({
  filterData,
  isFilterError,
  isFilterSuccess,
  clearCategoryFilter,
  changeFilterAndSearch,
}: InvoicesFiltersProps) => {
  if (isFilterError) {
    return <Box sx={styles.container}>Error getting filters</Box>
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isFilterSuccess && (
        <Box sx={styles.container} className="filters">
          {Object.keys(filterData?.data ?? []).map((filterCategory: string) => {
            switch (filterCategory) {
              case InvoiceSelectedFiltersConstants.invoiceStatus:
                return (
                  <InvoiceFiltersCheckBoxes
                    key={filterCategory}
                    filterCategory={filterCategory}
                    filterOptions={
                      filterData?.data
                        ? filterData.data[filterCategory] ?? []
                        : []
                    }
                    clearCategoryFilter={clearCategoryFilter}
                    changeFilterAndSearch={changeFilterAndSearch}
                    visibleItemsCount={5}
                  />
                )
              case InvoiceSelectedFiltersConstants.invoiceDates:
                return (
                  <InvoicesFiltersDateRange
                    key={filterCategory}
                    filterCategory={filterCategory}
                    clearCategoryFilter={clearCategoryFilter}
                    changeFilterAndSearch={changeFilterAndSearch}
                  />
                )

              case InvoiceSelectedFiltersConstants.invoiceAmounts:
                return (
                  <InvoicesAmountRangeFilter
                    key={filterCategory}
                    filterCategory={filterCategory}
                    clearCategoryFilter={clearCategoryFilter}
                    changeFilterAndSearch={changeFilterAndSearch}
                  />
                )

              default:
                return null
            }
          })}
        </Box>
      )}
    </>
  )
}

InvoicesFilters.defaultProps = defaultProps

export default InvoicesFilters
