/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react'
import {
  Box,
  FormHelperText,
  TextField,
  TextFieldProps,
  styled,
} from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { Control, Controller, FieldValues } from 'react-hook-form'
import { Styles } from '../../../types'
import RsmPickersDay from '../../../rsmCoreComponents/components/DynamicForm/FormControls/FormDatePicker/RsmPickersDay'
import { tokens } from '../../../styles/materialTheme'

export interface PaymentDateProps {
  labelKey?: string
  control?: Control<FieldValues, any>
}

const zIndex = 4000
const styles: Styles = {
  datePickerTextInput: {
    '& .MuiOutlinedInput-input': {
      textTransform: 'uppercase',
    },
  },
  requiredError: {
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '0.875rem',
    color: tokens.colors.rsmRed.secondary,
  },
  paymentDateContainer: (theme) => ({
    width: '100%',
    [theme.breakpoints.only('mobile')]: {
      padding: 0,
      paddingTop: '3rem',
    },
  }),
  paymentDateLabel: (theme) => ({
    fontFamily: 'Prelo-Black, sans-serif',
    fontSize: '1rem',
    paddingBottom: '0.5rem',
    color: theme.palette.text.primary,
    display: 'block',
  }),
  paymentDateMessage: (theme) => ({
    color: theme.palette.text.primary,
    fontSize: '1rem',
    fontStyle: 'italic',
  }),
}

const DatePicker = styled(MuiDatePicker)(() => ({
  '& .MuiPaper-root': {},
  marginTop: '0.5rem',
}))

const CalendarIcon = () => <FontAwesomeIcon icon={faCalendar} />

const PaymentDate = ({ labelKey, control }: PaymentDateProps) => {
  const { t } = useTranslation()
  const minDate = new Date()
  minDate.setDate(minDate.getDate() + 1)
  minDate.setHours(0, 0, 0, 0)
  const maxDate = new Date()
  maxDate.setDate(maxDate.getDate() + 30)
  maxDate.setHours(0, 0, 0, 0)
  const closeOnSelect = true
  const showToolbar = false
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement>(null)
  const buttonRefCallback = useCallback(
    (btnRef: HTMLButtonElement | null) => {
      if (btnRef && !anchorEl) {
        setAnchorEl(btnRef)
      }
    },
    [setAnchorEl, anchorEl],
  )

  const isValidDate = (d: any) =>
    // isNaN and Number.isNaN have different behavior, so need to disable the eslint rule.
    // eslint-disable-next-line no-restricted-globals
    d instanceof Date && !isNaN(d as Date as unknown as number)

  const validateDateRange = useCallback(
    (value: any) => {
      if (!value) return true
      if (!isValidDate(value)) return `${t('ValidDate')}`
      if (value < minDate || value > maxDate) {
        return `${t('ValidDateInRange', { minDate, maxDate })}`
      }
      return true
    },
    [t, minDate, maxDate],
  )

  return (
    <Box sx={styles.paymentDateContainer}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box
          component="label"
          htmlFor="PaymentDate"
          data-testid="Lbl_PaymentDate"
          sx={styles.paymentDateLabel}>
          {t(labelKey ?? '')} *
          <span className="sr-only">{t('srOnlyRequired')}</span>
        </Box>
        <Box
          component="div"
          id="paymentDateMessage"
          sx={styles.paymentDateMessage}>
          {t('Invoicing.PaymentDateMessage')}
        </Box>
        <Controller
          name="paymentDate"
          control={control}
          defaultValue=""
          rules={{
            required: `${t(`${labelKey}Required`)}`,
            validate: validateDateRange,
          }}
          render={({
            field: { onChange, value, ref },
            fieldState: { error },
          }) => (
            <>
              <DatePicker
                value={value}
                minDate={minDate}
                maxDate={maxDate}
                inputFormat="MM/dd/yyyy"
                renderDay={(date, selectedDates, pickersDayProps) => (
                  <RsmPickersDay {...pickersDayProps} />
                )}
                onChange={onChange}
                components={{
                  OpenPickerIcon: CalendarIcon,
                }}
                InputProps={{
                  'aria-describedby': 'paymentDateErrorText paymentDateMessage',
                }}
                renderInput={(params: TextFieldProps) => (
                  <TextField
                    {...params}
                    sx={styles.datePickerTextInput}
                    fullWidth
                    id="PaymentDate"
                    name="PaymentDate"
                    autoComplete="off"
                    inputRef={ref}
                    error={!!error}
                  />
                )}
                OpenPickerButtonProps={{ ref: buttonRefCallback }}
                PopperProps={{
                  placement: 'bottom',
                  'aria-label': 'Calendar',
                  style: { zIndex },
                  anchorEl,
                }}
                DialogProps={{
                  style: { zIndex },
                }}
                componentsProps={{ actionBar: { sx: { display: 'none' } } }}
                closeOnSelect={closeOnSelect}
                showToolbar={showToolbar}
              />
              {error && (
                <FormHelperText
                  id="paymentDateErrorText"
                  sx={styles.requiredError}>
                  {error?.message}
                </FormHelperText>
              )}
            </>
          )}
        />
      </LocalizationProvider>
    </Box>
  )
}

PaymentDate.defaultProps = {
  labelKey: 'Invoicing.PaymentDate',
  control: null,
}

export default PaymentDate
