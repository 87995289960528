/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Collapse,
  Typography,
} from '@mui/material'

import { styled } from '@mui/material/styles'
import { CheckboxIcon, CheckboxOutlinedIcon } from '../../icons'
import '../../../rsmCoreComponents/components/FiltersGroup/FiltersGroup.css'

import StyledButton from '../../DocumentRequests/components/StyledButton/StyledButton'
import {
  getInvoiceSelectedFilters,
  InvoiceSelectedFiltersConstants,
  setFilterValue,
} from '../../../store/invoices/invoiceSelectedFiltersSlice'
import InvoicesFilterCategoryContainer from './InvoicesFilterCategoryContainer'

const FilterList = styled(List)({
  border: 'none',
})

const FilterItem = styled(ListItem)(() => ({
  display: 'flex',
  alignItems: 'middle',
  padding: '0 0',
}))

const style = {
  button: {
    height: 56,
    color: 'secondary.main',
    textDecoration: 'underline',
    cursor: 'pointer',
    padding: '0 6px',
  },
  text: {
    color: 'secondary.main',
    fontWeight: 'medium',
    fontSize: '1.125rem',
    fontFamily: 'Prelo-Medium, sans-serif',
  },
}

interface MoreLess {
  showMoreLess: boolean
  alwaysVisibleItems: string[]
  moreItems: string[]
  showMore: boolean
}

function processMoreLess(
  filterOptions: string[],
  visibleItemsCount: number,
): MoreLess {
  const showMoreLess = filterOptions.length > visibleItemsCount
  const alwaysVisibleItems = showMoreLess
    ? filterOptions.slice(0, visibleItemsCount)
    : filterOptions
  const moreItems = showMoreLess
    ? filterOptions.slice(visibleItemsCount, filterOptions.length)
    : []

  return {
    showMoreLess,
    alwaysVisibleItems,
    moreItems,
    showMore: moreItems.length > 0,
  }
}

interface InvoiceFiltersCheckBoxesProps {
  filterCategory: string
  filterOptions: string[]
  clearCategoryFilter: (s: string) => void
  changeFilterAndSearch: (s: string) => void
  visibleItemsCount: number
}

const FilterItemLocal = ({
  filterCategory,
  option,
  isFilterChecked,
  changeFilterAndSearch,
}: any) => {
  const { t } = useTranslation()
  return (
    <FilterItem key={option}>
      <Checkbox
        id={`${filterCategory}_${option}`}
        icon={<CheckboxOutlinedIcon />}
        checkedIcon={<CheckboxIcon />}
        checked={isFilterChecked(filterCategory, option)}
        onChange={() =>
          changeFilterAndSearch(setFilterValue(filterCategory, option))
        }
        data-testid={`Btn_Invoice_Filter_${option}`}
      />
      <label htmlFor={`${filterCategory}_${option}`}>
        <ListItemText primary={t(`Invoicing.FilterCheckboxes.${option}`)} />
      </label>
    </FilterItem>
  )
}

const InvoiceFiltersCheckBoxes = ({
  filterCategory,
  filterOptions,
  clearCategoryFilter,
  changeFilterAndSearch,
  visibleItemsCount,
}: InvoiceFiltersCheckBoxesProps) => {
  const selectedFilters = useSelector(getInvoiceSelectedFilters)
  const { t } = useTranslation()
  const [moreLessData, setmoreLessData] = useState<MoreLess>({
    showMoreLess: false,
    alwaysVisibleItems: [],
    moreItems: [],
    showMore: false,
  })

  const isFilterChecked = useCallback(
    (category: string, option: string): boolean => {
      if (category === InvoiceSelectedFiltersConstants.invoiceStatus)
        return selectedFilters.invoiceStatus.indexOf(option) >= 0
      return false
    },
    [selectedFilters, InvoiceSelectedFiltersConstants],
  )

  const handleMoreLess = useCallback(() => {
    let moreless: MoreLess = {
      showMoreLess: true,
      alwaysVisibleItems: filterOptions,
      moreItems: [],
      showMore: false,
    }

    if (!moreLessData.showMore)
      moreless = processMoreLess(filterOptions, visibleItemsCount)
    setmoreLessData(moreless)
  }, [filterOptions, visibleItemsCount, moreLessData, setmoreLessData])

  useEffect(() => {
    const moreless = processMoreLess(filterOptions, visibleItemsCount)
    setmoreLessData(moreless)
  }, [filterOptions, visibleItemsCount])

  return (
    <InvoicesFilterCategoryContainer
      filterCategory={filterCategory}
      clearCategoryFilter={clearCategoryFilter}>
      <FilterList>
        {moreLessData.alwaysVisibleItems.map((option: string) => (
          <FilterItemLocal
            key={option}
            filterCategory={filterCategory}
            option={option}
            isFilterChecked={isFilterChecked}
            changeFilterAndSearch={changeFilterAndSearch}
          />
        ))}
      </FilterList>
      <Collapse in={!moreLessData.showMore} timeout="auto" unmountOnExit>
        {moreLessData.moreItems.map((option: string) => (
          <FilterItemLocal
            key={option}
            filterCategory={filterCategory}
            option={option}
            isFilterChecked={isFilterChecked}
            changeFilterAndSearch={changeFilterAndSearch}
          />
        ))}
      </Collapse>
      {moreLessData.showMoreLess && (
        <FilterItem>
          <StyledButton
            sx={style.button}
            data-testid={
              moreLessData.showMore
                ? `Btn_Insight_SeeMore${filterCategory}`
                : `Btn_Insight_SeeLess${filterCategory}`
            }
            onClick={handleMoreLess}
            aria-live="polite">
            <Typography sx={style.text}>
              {moreLessData.showMore
                ? t('Filters.CollapsedToggle.SeeMore')
                : t('Filters.CollapsedToggle.SeeLess')}
            </Typography>
          </StyledButton>
        </FilterItem>
      )}
    </InvoicesFilterCategoryContainer>
  )
}

export default InvoiceFiltersCheckBoxes
