import { Box } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import SearchBox from '../../../rsmCoreComponents/components/Search/SearchBox'
import { Styles } from '../../../types'

const styles: Styles = {
  container: (theme) => ({
    [theme.breakpoints.up('tablet')]: {
      width: '20rem',
    },
  }),
}

interface InvoicesHeaderProps {
  setFilter: React.Dispatch<React.SetStateAction<string>>
}

const InvoicesSearch = ({ setFilter }: InvoicesHeaderProps) => {
  const { t } = useTranslation()

  return (
    <Box
      id="invoices-filter-chips-clear-all-focus-target"
      sx={styles.container}>
      <SearchBox
        setFilter={setFilter}
        placeholderText={t('SearchByKeyword')}
        testId="Txt_Invoice_Search"
      />
    </Box>
  )
}

export default InvoicesSearch
