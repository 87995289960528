import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'usehooks-ts'
import { useLocation, useOutlet } from 'react-router-dom'
import SearchLayout from '../../layouts/SearchLayout'
import InvoicesActionBar from './InvoicesActionBar'
import InvoicesChips from './InvoicesChips'
import InvoicesFilters from './InvoicesFilters'
import InvoicesResults from './InvoicesResults'
import InvoicesSearch from './InvoicesSearch'
import useInvoicesSearch from './useInvoicesSearch'
import {
  setPayingInvoices,
  setInvoicePayments,
  setSelectedInvoices,
  setPayableAmount,
} from '../../../store/invoices/invoiceSelectedInvoicesSlice'
import InvoicesRetentionMessage from './InvoicesRetentionMessage'
import { RsmPaginationPageSizes } from '../../../rsmCoreComponents/components/RsmPagination/RsmPagination'
import { setSelectedPaymentMethod } from '../../../store/invoices/paymentInfoSlice'

const InvoicesLayout = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { t } = useTranslation()
  const outlet = useOutlet()
  const {
    changeFilterAndSearch,
    clearCategoryFilter,
    clearFilters,
    filterIsError,
    filterIsSuccess,
    filterData,
    invoicesData,
    invoicesIsError,
    requestSearch,
    changeSearchFilterAndSearch,
    setInvoicePageSize,
    toPage,
    pageInfo,
    SortInvoiceData,
  } = useInvoicesSearch()

  const handlePageChange = (
    pageNumber: number,
    pageSize: RsmPaginationPageSizes,
  ) => {
    setInvoicePageSize(pageSize)
    toPage(pageNumber)
  }

  const [filter, setFilter] = useState<string>('')
  const [sortColumn, setSortColumn] = useState<string>('invoiceDate') // Initialize sortColumn state
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc') // Initialize sortDirection state
  const debouncedFilter = useDebounce<string>(filter, 500)

  // Define a function to handle sorting of invoices based on provided parameters
  const handleSorting = (sortBy: string, isAscending: 'asc' | 'desc') => {
    setSortColumn(sortBy)
    setSortDirection(isAscending)
    SortInvoiceData(sortBy, isAscending)
    toPage(1)
  }

  // Loading invoices based on saved state
  // DO NOT use requestSearch anywhere else.
  useEffect(() => {
    requestSearch()
  }, [])

  useEffect(() => {
    if (location.pathname === '/invoicing/invoices') {
      dispatch(setSelectedInvoices([]))
      dispatch(setPayingInvoices([]))
      dispatch(setInvoicePayments([]))
      dispatch(setPayableAmount(0))
      dispatch(setSelectedPaymentMethod('Select Payment Type'))
    }
  }, [location.pathname])

  useEffect(() => {
    changeSearchFilterAndSearch(debouncedFilter)
  }, [debouncedFilter])

  return (
    outlet || (
      <SearchLayout
        title={t('Invoicing.Invoices')}
        drawerDialogSlot={
          <InvoicesFilters
            filterData={filterData}
            isFilterError={filterIsError}
            isFilterSuccess={filterIsSuccess}
            changeFilterAndSearch={changeFilterAndSearch}
            clearCategoryFilter={clearCategoryFilter}
          />
        }
        dialogSecondaryButtonClickHandler={clearFilters}
        searchSlot={<InvoicesSearch setFilter={setFilter} />}
        // removing currency dropdown for now
        // but leaving code in place so we can uncomment when ready to implement
        // searchAuxSlot={
        //   <CurrencyFilter
        //     changeFilterAndSearch={changeFilterAndSearch}
        //     selectedFilterConstant={InvoiceSelectedFiltersConstants.currency}
        //     setFilterValue={setFilterValue}
        //     getSelectedFilters={getInvoiceSelectedFilters}
        //     currencyCodes={filterData?.data?.currency}
        //   />
        // }
        chipsSlot={
          <InvoicesChips
            changeFilterAndSearch={changeFilterAndSearch}
            clearCategoryFilter={clearCategoryFilter}
            clearAllFilters={clearFilters}
          />
        }
        retentionInfo={<InvoicesRetentionMessage />}
        footerSlot={<InvoicesActionBar />}>
        <InvoicesResults
          pageNumber={pageInfo.pageNumber}
          pageSize={pageInfo.pageSize}
          invoicesData={invoicesData}
          isInvoicesError={invoicesIsError}
          onPageChange={handlePageChange}
          onSorting={handleSorting} // Pass down handleSorting
          sortColumn={sortColumn} // Pass down sortColumn
          sortDirection={sortDirection} // Pass down sortDirection
        />
      </SearchLayout>
    )
  )
}

export default InvoicesLayout
