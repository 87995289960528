/* eslint-disable react/destructuring-assignment */
import React, { SVGProps } from 'react'

const MasterCardIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 152.4 108"
    xmlSpace="preserve"
    width="26"
    height="21.28">
    <title>Mastercard</title>
    <g>
      <rect y="0" fill="none" width={props.width} height={props.height} />
      <g>
        <rect
          x="60.4"
          y="25.7"
          className="st1"
          fill="#FF5F00"
          width="31.5"
          height="56.6"
        />
        <path
          className="st2"
          fill="#EB001B"
          d="M62.4,54c0-11,5.1-21.5,13.7-28.3c-15.6-12.3-38.3-9.6-50.6,6.1C13.3,47.4,16,70,31.7,82.3
			c13.1,10.3,31.4,10.3,44.5,0C67.5,75.5,62.4,65,62.4,54z"
        />
        <path
          className="st3"
          fill="#F79E1B"
          d="M134.4,54c0,19.9-16.1,36-36,36c-8.1,0-15.9-2.7-22.2-7.7c15.6-12.3,18.3-34.9,6-50.6c-1.8-2.2-3.8-4.3-6-6
			c15.6-12.3,38.3-9.6,50.5,6.1C131.7,38.1,134.4,45.9,134.4,54z"
        />
      </g>
    </g>
  </svg>
)

export default MasterCardIcon
